import type RootStore from 'store/Rootstore'
import StoreContext from 'store/StoreContext'

import type { ReactNode } from 'react'

export interface StoreProviderProps {
  store: RootStore
  children: ReactNode
}

const StoreProvider = ({ store, children }: StoreProviderProps) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
StoreProvider.displayName = 'StoreProvider'

export default StoreProvider
