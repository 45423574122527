import { fetchAuthSession } from 'aws-amplify/auth'

import { LoggerService } from 'services/LogService/LogService'

export async function getAuthTokenId(): Promise<string> {
  try {
    // If the user is an admin trying to log in to debug, return that token instead.
    const isAdmin = localStorage.getItem('ADMIN_PANEL') === 'true'
    const hasToken = typeof localStorage.getItem('ADMIN_TOKEN') === 'string'
    if (isAdmin && hasToken) {
      return localStorage.getItem('ADMIN_TOKEN') as string
    }
    const user = await fetchAuthSession()
    // Each of the tokens has a toString() method that returns the actual token string rather than accessing it directly on deeply nested objects.
    return user?.tokens?.idToken?.toString() || ''
  } catch (error: unknown) {
    LoggerService.error({ message: 'error getAuthToken', error })
    return ''
  }
}
