const AlertIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-9.5 5.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM12 5c-1.086 0-1.95.912-1.89 1.997l.307 5.505a1.586 1.586 0 0 0 3.166 0l.306-5.505A1.892 1.892 0 0 0 12 5Z'
      clipRule='evenodd'
    />
  </svg>
)

export default AlertIcon
