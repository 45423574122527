import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { MetadataDescription, MetadataSearchItem, MetadataTypes } from 'models/metadata.model'
import type {
  CurrentDynamicInput,
  DynamicInputsResult,
  DynamicInputPosition,
  DynamicInputsMetadata,
  DynamicInputsMetadataOptions,
  MergeTagDatum,
  MetadataField,
  MetadataObject,
  MetadataPlatform,
  PayloadOptions,
  TokenList,
  ViewList,
  ViewListType,
} from 'models/motion/dynamicInput.model'
import type { CreateActionFields, Item, BreadcrumbInfo } from 'models/motion/motionBuilder.model'

export class DynamicInputStore implements ChildStore {
  /** The complete collection of metadata as token in a Merge Tag format for TinyMCE. */
  tokenList: TokenList = {
    isLoading: false,
    data: [],
    lookup: {},
  }
  /** The current platform, object, solutionInstanceId & magnifyDisplayName. */
  currentOptions: MetadataTypes = {}
  /** The collection of fields for the current platform & object combination being used for dynamic input. */
  viewList: ViewList = {
    data: [],
    isLoading: false,
    type: '',
  }
  filteredViewList: MetadataField[] = []
  /** The avaliable dynamic input options, broken out by platform (sendgrid, marketo, etc.),
   * then object type (Email, Message, etc.), then an array of platforms and objects. */
  dynamicInputsMetadataOptions: DynamicInputsMetadataOptions = {
    data: {},
    isLoading: false,
  }
  /** The current platform & object combination being used for dynamic input. */
  currentDynamicInput: CurrentDynamicInput = {
    platform: '',
    object: '',
  }
  platformsList: MetadataPlatform[] = []
  searchInput: string = ''
  searchViewList: { data: MetadataSearchItem[]; isLoading: boolean; isVisible: boolean } = {
    data: [],
    isLoading: false,
    isVisible: false,
  }
  breadCrumbItems: BreadcrumbInfo[] = []
  error: { isVisible: boolean; message: string } = {
    isVisible: false,
    message: '',
  }
  dynamicInputPosition: DynamicInputPosition | undefined

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.tokenList = {
      isLoading: false,
      data: [],
      lookup: {},
    }
    this.currentOptions = {}
    this.viewList = {
      isLoading: false,
      type: '',
      data: [],
    }
    this.filteredViewList = []
    this.dynamicInputsMetadataOptions = {
      isLoading: false,
      data: {},
    }
    this.currentDynamicInput = {
      platform: '',
      object: '',
    }
    this.platformsList = []
    this.searchInput = ''
    this.searchViewList = {
      data: [],
      isLoading: false,
      isVisible: false,
    }
    this.breadCrumbItems = []
    this.error = {
      isVisible: false,
      message: '',
    }
    this.dynamicInputPosition = undefined
    this.apiError = null
  }

  getViewList = async (options?: PayloadOptions) => {
    if (this.viewList.isLoading) {
      return
    }

    this.setCurrentOptions(options)

    this.viewList.isLoading = true

    try {
      const result = (await API.metadata.get(options)) as MetadataDescription
      if (result instanceof Error) {
        this.setErrorVisibility(true)
        this.setErrorMessage(result.message)
      } else if (result) {
        this.setErrorVisibility(false)
        this.setViewList({ ...options, result })
      }
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.viewList.isLoading = false
      })
    }
  }

  /**
   * Find the solution instance ID for a given platform in the current platformsList cache.
   * Reguardless of there being multple connections, the first connection is used.
   * @param {PayloadOptions} options The options
   * @param {string | undefined} options.platform The platform to search for
   * @returns {string | undefined} The solution instance ID for the given platform, or undefined if not found.
   */
  getSolutionInstanceId = ({ platform }: PayloadOptions = {}) => {
    const target = this.platformsList?.find(({ name }) => name.toLowerCase() === platform?.toLowerCase())

    return target?.connections[0]?.solutionInstanceId
  }

  getDynamicInputsMetadataOptions = async (options: CurrentDynamicInput) => {
    this.currentDynamicInput = options

    if (this.dynamicInputsMetadataOptions.data?.[options.platform]?.[options.object]?.length) {
      return
    }

    if (this.dynamicInputsMetadataOptions.isLoading) {
      return
    }

    try {
      this.dynamicInputsMetadataOptions.isLoading = true
      const result = await API.metadata.getDynamicInputsMetadataOptions(options)
      runInAction(() => {
        if (result instanceof Error) {
          this.setErrorVisibility(true)
          this.setErrorMessage(result.message)
        } else {
          this.setErrorVisibility(false)
          this.setDynamicInputsMetadataOptions(options, result)
        }

        this.dynamicInputsMetadataOptions.isLoading = false
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.dynamicInputsMetadataOptions.isLoading = false
      })
    }
  }

  setDynamicInputsMetadataOptions = (options: CurrentDynamicInput, result: DynamicInputsResult) => {
    this.dynamicInputsMetadataOptions.data = {
      ...this.dynamicInputsMetadataOptions.data,
      [options.platform]: {
        ...this.dynamicInputsMetadataOptions.data[options.platform],
        [options.object]: result.data,
      },
    }
  }

  fetchDynamicInputsMetadata = async (item: CreateActionFields | Item) => {
    const { platform, object, value } = item
    if (platform && object) {
      await this.getDynamicInputsMetadataOptions({
        platform,
        object,
      })
      await this.getViewList({
        platform: (value as Record<string, string>)?.platform,
        object: (value as Record<string, string>)?.object,
        solutionInstanceId: (value as Record<string, string>)?.solutionInstanceId,
        ...((value as Record<string, string>)?.magnifyDisplayName && {
          magnifyDisplayName: (value as Record<string, string>)?.magnifyDisplayName,
        }),
      })
    }
  }

  /**
   * Fetch all relevant metadata as a TinyMCE merge tag list for a given field (item).
   * @param {Item} item The current item to fetch metadata for.
   * @see {@link https://www.tiny.cloud/docs/tinymce/6/mergetags/}
   */
  fetchTokenList = async (item: CreateActionFields | Item) => {
    const { platform, object } = item

    if (this.tokenList.isLoading) {
      return
    }

    try {
      this.tokenList.isLoading = true

      // Keep a mapping to look up tags to pass along to the API.
      const lookup: Record<string, CreateActionFields> = {}

      // Fetch the list of platforms and solution instance IDs.
      const platformResponse = await API.metadata.getPlatforms()
      this.platformsList = platformResponse.data

      // Fetch the list of possible categories.
      const result = await API.metadata.getDynamicInputsMetadataOptions({ platform, object })

      // Loop over each platforms categories and fetch the metadata.
      const output = [] as MergeTagDatum[]
      for (const { platform: title, objects } of result.data) {
        const solutionInstanceId = this.getSolutionInstanceId({ platform: title?.toLowerCase() })
        // Loop over each object and fetch the metadata.
        for (const object of objects) {
          const fields = await API.metadata.get({
            platform: title?.toLowerCase(),
            solutionInstanceId,
            object,
          })

          if (!fields?.data || !Array.isArray(fields?.data)) {
            console.error('No fields data found', fields)
            return
          }

          // Convert the metadata fields to the TinyMCE merge tag format.
          const menu = []
          const items: CreateActionFields[] = fields.data as CreateActionFields[]
          for (const { key, name, type } of items) {
            const lowerCasePlatform = title.toLowerCase()
            const value = `${lowerCasePlatform}_${object}_${key}`
            lookup[value] = {
              field: name,
              key,
              type,
              platform: lowerCasePlatform,
              object,
              solutionInstanceId,
              // These fields are required for dynamic inputs to resolve
              isDynamicInput: true,
              // eslint-disable-next-line no-template-curly-in-string
              value: '${dynamicInput}',
            } as CreateActionFields
            menu.push({ title: name, value })
          }

          const platformWithObjectName = `${title} ${object}`
          output.push({ title: platformWithObjectName, menu })
        }
      }

      runInAction(() => {
        this.tokenList.data = output
        this.tokenList.lookup = lookup
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.tokenList.isLoading = false
      })
    }
  }

  setViewList = ({ platform, object, result, magnifyDisplayName }: ViewListType) => {
    if (platform && object) {
      this.breadCrumbItems = [
        {
          name: platform,
          path: [platform],
          entityType: 'platform',
        },
        {
          name: object,
          path: [object],
          entityType: 'object',
          ...(magnifyDisplayName && { magnifyDisplayName }),
        },
      ]
      this.viewList = {
        ...this.viewList,
        type: 'field',
        data: result.data,
      }
    } else if (platform) {
      this.breadCrumbItems = [
        {
          name: platform,
          path: [platform],
          entityType: 'platform',
        },
      ]

      const availableObjects = result.data.filter((object: MetadataObject) =>
        this.dynamicInputsMetadataOptions.data[this.currentDynamicInput.platform]?.[
          this.currentDynamicInput.object
        ]?.some((data: DynamicInputsMetadata) => data.objects.includes(object.name)),
      )

      this.viewList = {
        ...this.viewList,
        type: 'object',
        data: availableObjects,
      }
    } else {
      this.breadCrumbItems = []
      const availablePlatforms = result.data.filter((platform: MetadataDescription) =>
        this.dynamicInputsMetadataOptions.data[this.currentDynamicInput.platform]?.[
          this.currentDynamicInput.object
        ]?.some((data: { platform: string }) => data.platform.toLowerCase() === platform.name.toLowerCase()),
      )

      this.viewList = {
        ...this.viewList,
        type: 'platform',
        data: availablePlatforms,
      }

      this.platformsList = result.data as MetadataDescription[]
    }
  }

  setErrorMessage = (value: string) => {
    this.error.message = value
  }

  setErrorVisibility = (value: boolean) => {
    this.error.isVisible = value
  }

  setBreadCrumbItems = (breadcrumbs: BreadcrumbInfo[]) => {
    this.breadCrumbItems = breadcrumbs
  }

  setDisplaySearchResult = (value: boolean) => {
    this.searchViewList.isVisible = value
  }

  fetchSearch = async (searchValue: string) => {
    this.searchViewList.isLoading = true
    try {
      if (this.applyLocalMetadataFilter() && searchValue) {
        const result = this.filterFieldsByName(this.viewList.data, searchValue)
        this.setFilteredViewList(result)
      } else {
        const result = await API.metadata.search({ search: searchValue })
        runInAction(() => {
          if (result instanceof Error) {
            this.setErrorVisibility(true)
            this.setErrorMessage(result.message)
          } else {
            this.setErrorVisibility(false)
            this.setSearchData(result.data)
          }
        })
      }
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.searchViewList.isLoading = false
      })
    }
  }

  setSearchData = (searchData: MetadataSearchItem[]) => {
    this.searchViewList.data = this.filterDataByType(searchData)
  }

  setSearchInput = (value: string) => {
    this.searchInput = value
  }

  setLoadingSearchMetadata = (value: boolean) => {
    this.searchViewList.isLoading = value
  }

  setDynamicInputPosition = (value?: DynamicInputPosition) => {
    this.dynamicInputPosition = value
  }

  setCurrentOptions = (options?: PayloadOptions) => {
    if (!options) {
      this.currentOptions = {}
      return
    }

    // TODO: Why are we saving the solutionInstanceId with no platform?
    if (!options.solutionInstanceId) {
      options.solutionInstanceId = this.currentOptions.solutionInstanceId
    }

    this.currentOptions = options
  }

  resetSearch = () => {
    this.setSearchInput('')
    this.setDisplaySearchResult(false)
    this.setErrorVisibility(false)
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }

  filterFieldsByName = (data: MetadataField[], searchValue: string) => {
    const filteredFields = data.filter((field) => {
      const fieldName = field.magnifyDisplayName ?? field.name
      return fieldName?.toLowerCase().includes(searchValue.toLowerCase())
    })
    return filteredFields
  }

  applyLocalMetadataFilter = () => !!this.currentOptions.platform && !!this.currentOptions.object

  filterDataByType = (searchData: MetadataSearchItem[]) => {
    return searchData.filter(
      (data) =>
        (data.type === 'platform' &&
          this.dynamicInputsMetadataOptions.data[this.currentDynamicInput.platform]?.[
            this.currentDynamicInput.object
          ]?.some(
            (metadata: { platform: string }) => metadata.platform.toLowerCase() === data.order[0].key.toLowerCase(),
          )) ||
        ((data.type === 'object' || data.type === 'field') &&
          this.dynamicInputsMetadataOptions.data[this.currentDynamicInput.platform]?.[
            this.currentDynamicInput.object
          ]?.some(
            (metadata: { platform: string }) => metadata.platform.toLowerCase() === data.order[0].key.toLowerCase(),
          ) &&
          this.dynamicInputsMetadataOptions.data[this.currentDynamicInput.platform]?.[
            this.currentDynamicInput.object
          ]?.some((metadata: DynamicInputsMetadata) => metadata.objects.includes(data.order[1].key))),
    )
  }

  setFilteredViewList = (items: MetadataField[]) => {
    this.filteredViewList = items
  }
}
