import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Divider } from 'antd'
import { Link } from 'react-router-dom'

import { Logo } from 'components/common'

import type { InsightV2 } from 'models/insights'

interface BannerProps {
  driverId?: string
  insight: InsightV2
  selectedDimension: string
  showDimension: boolean
}

const Banner = ({ driverId = '', insight, selectedDimension, showDimension }: BannerProps) => {
  const driver = insight.event.short_name
  const platform = insight.family.platform
  const field = insight.family.field
  const object = insight.family.object

  // Product only wants to show the integer value of the driverId
  const [driverType, driverNumberRaw] = driverId?.split('-')
  const driverNumber = Number.parseInt(driverNumberRaw, 10) || 0
  const driverCapitalized = driver.charAt(0).toUpperCase() + driver.slice(1)

  // The title logic depends on the type of driver
  let driverTitle = `#${driverNumber} Customer Driver`
  if (driverType === '1') {
    driverTitle = `#${driverNumber} Customer Metric`
  } else if (driverType === '2') {
    driverTitle = `#${driverNumber} Customer ${driverCapitalized} Driver`
  }

  return (
    <div className='banner' data-testid='insight-details-banner'>
      <div className='banner__rank'>
        <h4 data-testid='insight-details-banner-heading' className='banner__rank__text'>
          {showDimension && <span className='dimension'>{selectedDimension} &gt;</span>}
          {driverTitle}
        </h4>

        <CloseButton data-testid='insight-details-banner-close-button' type='ghost' shape='circle'>
          <Link to='/dashboard' title='Back to Dashboard'>
            <CloseOutlined />
          </Link>
        </CloseButton>
      </div>

      <div data-testid='insight-details-banner-driver-detail' className='banner__driver-detail'>
        <div className='banner__driver-detail__heading'>
          <Logo platformName={platform} bordered={false} />
          <p className='banner__driver-detail__label'>
            <span>{field}</span> from {object}
          </p>
        </div>
      </div>

      <Divider />
    </div>
  )
}
Banner.displayName = 'Banner'

export default Banner
