import { calculateClassName, NumberFormat } from 'services/Utils'

import type { Level } from 'models/dashboard.model'
import { type Impact } from 'models/insights'

interface PercentageCellProps {
  level: Level
  value: number | null
  impact?: Impact
  columnKey: string
  className?: string
}

/**
 * Return the value in a colored way based on it's level red - low, green - high
 * @param {Level} level - It represents the feature buckets of each account.  0, 1 and 2 indicate “Low”, “Medium” and “High” correspondingly.
 * @param {number | null} value - the value to display in cell.
 */

const PercentageCell = ({ level, value, impact, columnKey, className }: PercentageCellProps) => {
  const modifier = calculateClassName(level, impact, columnKey)
  const isValue = value != null

  if (isValue) {
    return (
      <span className={`cell ${modifier} cell-value ${className}`} data-testid='cell-value'>
        {NumberFormat.beautifyFloat(value * 100, 1)} %
      </span>
    )
  }
  return (
    <span className={`cell ${className}`} data-testid='cell-null'>
      N/A
    </span>
  )
}

export default PercentageCell
