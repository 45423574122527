const SettingsIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props} data-testid='settings-icon'>
    <path
      fill='#999DA8'
      fillRule='evenodd'
      d='M5.333 3.381a1.715 1.715 0 0 1-1.727-.007l-.163-.096c-.404-.238-.925-.174-1.214.195a7.326 7.326 0 0 0-1.034 1.794c-.175.435.03.918.438 1.148l.163.093a1.715 1.715 0 0 1 0 2.984l-.164.093c-.408.232-.613.715-.437 1.15a7.4 7.4 0 0 0 1.035 1.792c.289.37.81.433 1.214.195l.162-.095a1.715 1.715 0 0 1 2.585 1.491l-.002.189c-.004.468.312.887.776.953.681.098 1.379.1 2.071.002.464-.066.78-.485.775-.954l-.001-.19a1.715 1.715 0 0 1 2.584-1.492l.164.096c.404.238.925.174 1.214-.195a7.325 7.325 0 0 0 1.034-1.794c.174-.435-.03-.918-.438-1.148l-.164-.093a1.715 1.715 0 0 1 0-2.985l.164-.093c.408-.23.613-.714.438-1.149a7.406 7.406 0 0 0-1.035-1.792c-.29-.37-.81-.433-1.214-.195l-.162.095a1.715 1.715 0 0 1-2.586-1.492l.002-.188C9.815 1.225 9.5.806 9.036.74A7.326 7.326 0 0 0 6.964.738c-.464.066-.78.485-.775.954l.002.189a1.715 1.715 0 0 1-.858 1.5Zm4 6.928a2.667 2.667 0 1 0-2.666-4.618 2.667 2.667 0 0 0 2.667 4.618Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SettingsIcon
