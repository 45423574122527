export const demoGetAllNotifications = {
  totalNotifications: 15,
  pagesNumber: 1,
  notifications: [
    {
      info: {
        bucket: 'playbook-service-staging-segment-output-csv',
        userEmail: 'jon@magnify.io',
        filename:
          'b5c90b87-bc0a-4a50-b85f-bbfe665ea318/saved_journeys/draft/09742f7a-6bf0-43be-b1ae-fdb64d039420/5/2023-06-16T14:53:29.475Z.csv',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686927209643,
      priority: 'high',
      isDeleted: false,
      message:
        'The segment output requested by "jon@magnify.io" for "GS Survey Test 06161053" has been generated and you can download the CSV file.',
      id: '8329a554-0520-4e6c-8322-9795cb721d93',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 's3Download',
    },
    {
      info: {
        bucket: 'playbook-service-staging-segment-output-csv',
        userEmail: 'jon@magnify.io',
        filename:
          'b5c90b87-bc0a-4a50-b85f-bbfe665ea318/saved_journeys/draft/1360bfd5-9ba9-4bdb-9c8a-820fc858cc99/7/2023-06-16T13:50:02.048Z.csv',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686923402184,
      priority: 'high',
      isDeleted: false,
      message:
        'The segment output requested by "jon@magnify.io" for "Test Motion 06141333" has been generated and you can download the CSV file.',
      id: '2a7a6a42-a543-421c-bf6f-85678c1cc214',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 's3Download',
    },
    {
      info: {
        bucket: 'playbook-service-staging-segment-output-csv',
        userEmail: 'jon@magnify.io',
        filename:
          'b5c90b87-bc0a-4a50-b85f-bbfe665ea318/saved_journeys/draft/1360bfd5-9ba9-4bdb-9c8a-820fc858cc99/6/2023-06-16T13:38:50.542Z.csv',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686922730726,
      priority: 'high',
      isDeleted: false,
      message:
        'The segment output requested by "jon@magnify.io" for "Test Motion 06141333" has been generated and you can download the CSV file.',
      id: '53fef10f-4c6b-4885-92b7-f16893ba8341',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 's3Download',
    },
    {
      info: {
        userEmail: 'jon@magnify.io',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686837306037,
      priority: 'high',
      isDeleted: false,
      message:
        'An error occured while generating the segment output for Motion: "Test Motion Segmentation 06150948" requested by "jon@magnify.io". No account information found for exported segment.',
      id: '34470201-ee5e-4eed-ab83-fa7e48394293',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'error',
    },
    {
      info: {
        userEmail: 'jon@magnify.io',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686837193263,
      priority: 'high',
      isDeleted: false,
      message:
        'An error occured while generating the segment output for Motion: "Test Motion Segmentation 06150948" requested by "jon@magnify.io". No account information found for exported segment.',
      id: '12230ed0-cc1c-4d7f-ac5a-30ccd03518d3',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'error',
    },
    {
      info: {
        userEmail: 'jon@magnify.io',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686836995105,
      priority: 'high',
      isDeleted: false,
      message:
        'An error occured while generating the segment output for Motion: "Test Motion Segmentation 06150948" requested by "jon@magnify.io". No account information found for exported segment.',
      id: 'f07ae51b-aafc-4c89-bb3e-a15097150565',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'error',
    },
    {
      info: {
        userEmail: 'jon@magnify.io',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1686836957101,
      priority: 'high',
      isDeleted: false,
      message:
        'An error occured while generating the segment output for Motion: "" requested by "jon@magnify.io". No account information found for exported segment.',
      id: '33dd0d59-d772-4644-966e-178cae71e54d',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'error',
    },
    {
      info: {
        bucket: 'playbook-service-staging-segment-output-csv',
        userEmail: 'ria+magnifydemo@magnify.io',
        filename:
          'b5c90b87-bc0a-4a50-b85f-bbfe665ea318/saved_journeys/draft/fef2d462-e1c6-4915-9548-8f9059a4fa1e/2/2023-05-19T17:37:00.997Z.csv',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1684517821173,
      priority: 'high',
      isDeleted: false,
      message:
        'The segment output requested by "ria+magnifydemo@magnify.io" for "CLONE: Gainsight Testing" has been generated and you can download the CSV file.',
      id: 'f28b347a-4145-4e3b-a572-7e903e750d72',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 's3Download',
    },
    {
      info: {
        userEmail: 'josh+magnifydemo@magnify.io',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1684517790645,
      priority: 'high',
      isDeleted: false,
      message:
        'An error occured while generating the segment output for Motion: "Onboarding Motion" requested by "josh+magnifydemo@magnify.io". No account information found for exported segment.',
      id: '5a70442e-7c62-424b-99c1-69752597e275',
      isSolved: false,
      personsViewed: [
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'jon@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'error',
    },
    {
      info: {
        journeyId: 'fa7eaef7-306f-44fb-a6c3-3b6379f400a1',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1679583674900,
      priority: 'high',
      isDeleted: false,
      message: 'Motion "DRO Segment Test - Gainsight 4" failed to execute',
      id: '45375d42-c632-4c3a-a378-44d66e38badc',
      isSolved: false,
      personsViewed: [
        'jon@magnify.io',
        'don+magnifydemo@magnify.io',
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'journeyInfo',
    },
    {
      info: {
        journeyId: '83e58fcc-c82a-4527-b923-ad6657a45c02',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1679579579433,
      priority: 'high',
      isDeleted: false,
      message: 'Motion "CLONE: DRO Segment Test - Gainsight 3" failed to execute',
      id: 'b3a074f2-8e63-45f9-a9b9-3143a358fe26',
      isSolved: false,
      personsViewed: [
        'jon@magnify.io',
        'don+magnifydemo@magnify.io',
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'journeyInfo',
    },
    {
      info: {
        journeyId: '4f395804-818a-4a82-ae3c-6a5948b3d0f1',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1678397175570,
      priority: 'high',
      isDeleted: false,
      message: 'Motion "Email Motion: Talend Cloud" failed to execute',
      id: '5a8b7f14-cf66-4e11-868e-9c54a01cf11e',
      isSolved: false,
      personsViewed: [
        'shayan+magnifydemo@magnify.io',
        'jon@magnify.io',
        'don+magnifydemo@magnify.io',
        'ria+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'journeyInfo',
    },
    {
      info: {
        journeyId: '5cf28602-c827-4b20-b547-954efda586b3',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1677588801228,
      priority: 'high',
      isDeleted: false,
      message: 'Motion "CLONE: Reduce churn" failed to execute',
      id: '876d2f3a-2586-463f-a6a7-ce27aa2459c0',
      isSolved: false,
      personsViewed: [
        'paarth+magnifydemo@magnify.io',
        'ria+magnifydemo@magnify.io',
        'daniel-m+magnifydemo@magnify.io',
        'jon@magnify.io',
        'shayan+magnifydemo@magnify.io',
        'don+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'journeyInfo',
    },
    {
      info: {
        journeyId: '04faaed6-86a7-4710-80e3-3bed189f7013',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1677585846835,
      priority: 'high',
      isDeleted: false,
      message: 'Motion "Reduce churn" failed to execute',
      id: 'cfa77ed0-32dd-4db4-9e68-afcc50c9e251',
      isSolved: false,
      personsViewed: [
        'paarth+magnifydemo@magnify.io',
        'ria+magnifydemo@magnify.io',
        'daniel-m+magnifydemo@magnify.io',
        'jon@magnify.io',
        'shayan+magnifydemo@magnify.io',
        'don+magnifydemo@magnify.io',
        'josh+magnifydemo@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'journeyInfo',
    },
    {
      info: {
        journeyId: 'ca26e925-34f2-4609-9ed7-c65bb9a8585c',
      },
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1677494590449,
      priority: 'high',
      isDeleted: false,
      message: 'Motion "Customer Upsells " failed to execute',
      id: 'd08d27fe-f777-4983-9692-50d71ea07cd5',
      isSolved: false,
      personsViewed: [
        'josh+magnifydemo@magnify.io',
        'wenduo+magnifydemo@magnify.io',
        'paarth+magnifydemo@magnify.io',
        'ria+magnifydemo@magnify.io',
        'daniel-m+magnifydemo@magnify.io',
        'jon@magnify.io',
        'shayan+magnifydemo@magnify.io',
        'don+magnifydemo@magnify.io',
        'lex.schoner+demo@magnify.io',
        'stevenb+magnifydemo@magnify.io',
        'peter+magnifydemo@magnify.io',
      ],
      type: 'journeyInfo',
    },
  ],
}
