import type { ChartArea } from 'chart.js'

import type { UISpeedometerDriver } from 'models/insights'

const CHART_GREEN_HEX = '#CEFFC6'
const CHART_AMBER_HEX = '#FFF5BE'
const CHART_RED_HEX = '#EB2058'

export const createBackgroundColor = (ctx: CanvasRenderingContext2D, area: ChartArea, level: string) => {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)

  if (level === 'high') {
    gradient.addColorStop(0, CHART_RED_HEX)
    gradient.addColorStop(1, CHART_RED_HEX)
  } else if (level === 'medium') {
    gradient.addColorStop(0, CHART_AMBER_HEX)
    gradient.addColorStop(1, CHART_AMBER_HEX)
  } else {
    gradient.addColorStop(0, CHART_GREEN_HEX)
    gradient.addColorStop(1, CHART_GREEN_HEX)
  }

  return gradient
}

/** Chart.js uses PI values to calculate slice angles of the doughnut chart.
 * Our speedometer only uses 180 degrees of the doughnut chat
 * 180 degrees = PI, hence the conversion. */
export const getIndicatorRotationAngle = (endAngleValue: number, INDICATOR_STARTING_ANGLE_OFFSET: number) => {
  const indicatorRotationAngle = ((endAngleValue - Math.PI) / Math.PI) * 180
  return indicatorRotationAngle + INDICATOR_STARTING_ANGLE_OFFSET
}

/**
 * Calculates the initial rotation angle for the speedometer indicator based on sorted driver data.
 * @param {UISpeedometerDriver[]} sortedDrivers - An array of drivers sorted based on certain criteria.
 * @param {number} INDICATOR_STARTING_ANGLE_OFFSET - The offset for the starting angle of the indicator.
 * @returns {number} The initial rotation angle for the speedometer indicator.
 */
export const getInitialIndicatorRotationAngle = (
  sortedDrivers: UISpeedometerDriver[],
  INDICATOR_STARTING_ANGLE_OFFSET: number,
) => {
  const totalAccountCount = sortedDrivers.reduce((acc: number, driver) => acc + driver.account_count, 0)
  const sliceEndAngle = 180 / (totalAccountCount / sortedDrivers[0].account_count || 1)
  return sliceEndAngle + INDICATOR_STARTING_ANGLE_OFFSET
}

/**
 * Sorts an array of UISpeedometerDriver objects based on their levels high > medium > low .
 * @param {UISpeedometerDriver[]} drivers - An array of UISpeedometerDriver objects to be sorted.
 * @returns {UISpeedometerDriver[]} - The sorted array of drivers.
 */
export const sortDrivers = (drivers: UISpeedometerDriver[]) => {
  return drivers.sort((a, b) => {
    if (a.level === 'high') return -1
    if (a.level === 'medium' && b.level === 'low') return -1
    if (a.level === 'low' && b.level === 'medium') return 1
    return 0
  })
}

/**
 * Calculates the total sum of account counts based on the levels in the provided data.
 * @param {UISpeedometerDriver[]} data - An array of UISpeedometerDriver objects.
 * @returns {number} - The total sum of account counts.
 */
export const sumAccounts = (data: UISpeedometerDriver[]) => {
  const highLevel = data.find((item) => item.level === 'high')
  const mediumLevel = data.find((item) => item.level === 'medium')

  if (highLevel && mediumLevel) {
    // Both high and medium levels exist
    return highLevel.account_count + mediumLevel.account_count
  } else if (mediumLevel) {
    // Only medium level exists
    return mediumLevel.account_count
  } else if (highLevel) {
    // Only high level exists
    return highLevel.account_count
  } else {
    // Neither high nor medium levels exist
    return data[0].account_count
  }
}
