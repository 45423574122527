import { Layout, Menu } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import { observer } from 'mobx-react-lite'
import { Route } from 'react-router'
import { Link, Navigate, Routes } from 'react-router-dom'

import IconProfile from 'assets/images/profile.svg?react'
import IconBell from 'assets/images/settings_bell.svg?react'
import IconUsers from 'assets/images/users.svg?react'
import { Profile, Users, Notifications } from 'components/AccountSettings'
import { RoleEnum } from 'services/Utils/Role'
import useStore from 'store/useStore'

import type { FC } from 'react'
interface AccountSettingsPageProps {
  locationPathname: string
}

export const AccountSettingsPage: FC<AccountSettingsPageProps> = observer((props) => {
  const { locationPathname } = props
  let selectedKey
  const { userStore } = useStore()
  switch (locationPathname.split('/')[2]) {
    case 'profile':
      selectedKey = '1'
      break
    case 'users':
      selectedKey = '2'
      break
    case 'notifications':
      selectedKey = '3'
      break
    default:
      selectedKey = '1'
      break
  }

  const menuItems = () => {
    const items = [
      {
        key: '1',
        label: (
          <>
            <IconProfile />
            <Link to='profile' data-testid='profile'>
              {' '}
              Profile
            </Link>
          </>
        ),
      },
      {
        key: '2',
        label: (
          <>
            <IconUsers />
            <Link to='users' data-testid='users'>
              {' '}
              Users
            </Link>
          </>
        ),
      },
      {
        key: '3',
        label: (
          <>
            <IconBell />
            <Link to='notifications' data-testid='notifications'>
              {' '}
              Notifications
            </Link>
          </>
        ),
      },
    ]

    if (userStore.role === RoleEnum.User) {
      items.splice(1, 1)
    }
    return items
  }

  return (
    <Layout className='ant-layout-has-sider account-settings'>
      <Sider className='c-sider c-sider__sticky' width={265}>
        <span className='c-sider__header' data-testid='account-settings-sider'>
          Account Settings
        </span>
        <Menu
          mode='inline'
          defaultSelectedKeys={[selectedKey]}
          selectedKeys={[selectedKey]}
          inlineIndent={0}
          items={menuItems()}
        />
      </Sider>
      <Content>
        <Routes>
          <Route path='profile' element={<Profile />} />
          <Route path='users' element={<Users />} />
          <Route path='notifications' element={<Notifications />} />
          <Route path='*' element={<Navigate to='profile' />}></Route>
        </Routes>
      </Content>
    </Layout>
  )
})
AccountSettingsPage.displayName = 'AccountSettingsPage'
