import type { Action, ToolboxItems, Platform } from 'models/motion/motionBuilder.model'

export interface FilteredCategory {
  actions: Action[]
  name: string
  type: string
}

export const filterActions = (builderSettings: ToolboxItems, searchValue: string): ToolboxItems => {
  const filtereOptions: ToolboxItems = {
    inactiveActions: [],
    actions: [],
    rules: [],
  } as ToolboxItems
  for (const category in builderSettings) {
    const categorySources = (builderSettings as unknown as { [key: string]: Action[] | Platform[] })[category]
    // the option for actions are treated differently because they appear in a different way
    if (category !== 'actions') {
      const actions = categorySources as Action[]
      const passedCondition = actions.filter((action: Action) =>
        action.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
      if (passedCondition.length) {
        filtereOptions.rules = passedCondition
      }
    } else {
      filtereOptions.actions = []
      const platforms = categorySources as Platform[]

      platforms.forEach((platform) => {
        const actionsThatPassCondition = platform.actions.filter((action: Action) =>
          action.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
        const IsPlatformPassCondition = platform.name.toLowerCase().includes(searchValue.toLowerCase())
        let filteredCategory
        if (actionsThatPassCondition.length) {
          filteredCategory = { ...platform, actions: actionsThatPassCondition }
          filtereOptions.actions.push(filteredCategory)
        } else if (IsPlatformPassCondition) {
          filtereOptions.actions.push(platform)
        }
      })
    }
  }
  return filtereOptions
}
