import { Handle, Position } from 'react-flow-renderer'

import type { FC } from 'react'
import type { NodeProps } from 'react-flow-renderer'

export const End: FC<NodeProps> = ({ id, xPos, yPos }) => {
  return (
    <div className='c-node node-end' data-testid='node-end'>
      <div className='c-node__section'>
        <div className='c-node__text'>End</div>
      </div>
      <Handle type='target' position={Position.Top} id='a' />
    </div>
  )
}
