import { Alert, Form, Input, Modal } from 'antd'
import { useEffect, useState } from 'react'

import { Button } from 'components/common'
import { triggerSendTestMessage } from 'components/MotionBuilder/Utils/segmentBuilder.utils'

import type { ErrorMesage } from 'models'
import type { CreateActionFields, CreateActionsPayload } from 'models/motion/motionBuilder.model'
import type { SendTestMessagePayload } from 'models/motion.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

const getFieldObjectByKey = (payload: CreateActionsPayload | undefined, key: string) => {
  return payload?.fields?.find((field) => field.key === key)
}

interface SendTestMessageModalProps {
  isModalOpen: boolean
  configPanelPayload: CreateActionsPayload | undefined
  setIsModalOpen: (isOpen: boolean) => void
}

/**
 * The progress on this feature will be paused until we have data ingested
 * At this stage we don't know exactly what fields are the most valuable and required
 */

/**
 * Send test Message Modal
 * @param {SendTestMessageModalProps} props the attributes of the component.
 * @returns The component
 */
const SendTestMessageModal = ({ isModalOpen, configPanelPayload, setIsModalOpen }: SendTestMessageModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorMesage | null>(null)

  const configPanelFrom = getFieldObjectByKey(configPanelPayload, 'from')?.value
  const configPaneMessage =
    (getFieldObjectByKey(configPanelPayload, 'messageContent')?.value as CreateSlackMessagePayload)?.messageContent ??
    getFieldObjectByKey(configPanelPayload, 'messageContent')?.value
  // the defaultValues of the form are just provisory, for visual context
  const defaultValues = {
    from: (configPanelFrom as CreateActionFields)?.field ?? configPanelFrom,
    message: configPaneMessage,
  }

  useEffect(() => {
    form.setFieldValue('from', defaultValues.from)
  }, [defaultValues.from])

  useEffect(() => {
    form.setFieldValue('message', defaultValues.message)
  }, [defaultValues.message])

  const handleOk = () => {
    setLoading(true)
    form
      .validateFields()
      .then(async (values: SendTestMessagePayload) => {
        await triggerSendTestMessage(values.to, values.from, values.message)
        setLoading(false)
        form.resetFields()
        setIsModalOpen(false)
      })
      .catch(() => {
        setError({
          message: 'There was a problem sending this test message. Please try again later.',
          name: 'error',
          code: 'error',
        })
        setLoading(false)
      })
  }

  const handleCancel = () => {
    form.resetFields()
    setError(null)
    setIsModalOpen(false)
  }

  return (
    <Modal
      title='Send Test Message'
      width={656}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className='test-message-modal'
      forceRender
      footer={[
        <Button key='send-test-message-go-back-btn' text='Go back' type='secondary' onClickHandler={handleCancel} />,
        <Button
          key='send-test-message-submit-btn'
          text='Send Test Message'
          onClickHandler={handleOk}
          className='footer__button'
          disabled={loading}
        />,
      ]}>
      <p className='modal__text'>Would you like to Send a Test Message with these credentials? </p>

      <Form className='form' form={form} name='send-test-message-form' layout='vertical' initialValues={defaultValues}>
        <Form.Item name='to' label='To' className='to' rules={[{ required: true, message: '"To" is required' }]}>
          <Input />
        </Form.Item>
        <Form.Item name='from' label='From' rules={[{ required: true, message: '"From" is required' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name='message' label='Message' rules={[{ required: true, message: '"Message" is required' }]}>
          <Input disabled />
        </Form.Item>
      </Form>
      {error && <Alert message={error.message} type='error' />}
    </Modal>
  )
}
SendTestMessageModal.displayName = 'SendTestMessageModal'

export default SendTestMessageModal
