export interface CoreAPIErrorResponse {
  detail: string
  instance: string
  message: string
  status: number
  type: string
}

class CoreApiError extends Error {
  detail: string
  instance: string
  message: string
  status: number
  type: string

  constructor(coreApiResponse: CoreAPIErrorResponse) {
    super(coreApiResponse.message)
    this.name = this.constructor.name

    this.detail = coreApiResponse.detail
    this.instance = coreApiResponse.instance
    this.message = coreApiResponse.message
    this.status = coreApiResponse.status
    this.type = coreApiResponse.type
  }
}

export { CoreApiError }
