import { IconArrowDownRightRed, IconArrowUpRight } from 'components/common/Icons/Icons'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { DataFormatEnum } from 'models/insights'
import type { TenantActionTotalsStats } from 'models/observability.model'

interface StatsOverviewProps {
  observabilityTenantTotals?: TenantActionTotalsStats
  observabilityTenantPreviousTotals?: TenantActionTotalsStats
}

const getIcon = (value: number) => {
  if (value > 0) {
    return <IconArrowUpRight width={10} />
  } else {
    return <IconArrowDownRightRed width={10} />
  }
}

const StatsOverview = ({
  observabilityTenantTotals = {} as TenantActionTotalsStats,
  observabilityTenantPreviousTotals,
}: StatsOverviewProps) => {
  return (
    <div className='stats-overview' data-testid='stats-overview'>
      {Object.keys(observabilityTenantTotals || {}).map((key) => {
        const value = observabilityTenantTotals[key as keyof typeof observabilityTenantTotals] || 0
        const previous = observabilityTenantPreviousTotals?.[key as keyof typeof observabilityTenantTotals] ?? 0

        const currentPrevDiff = value - previous
        const isDiffferent = currentPrevDiff !== value

        return (
          <div key={key} className='stats-overview__item'>
            <div>
              <h6 data-testid='stats-item__heading'>{key}</h6>
              <div className='stats-overview__item--value'>
                <p data-testid='stats-overview__item--value'>
                  {formatNumber({ number: value, format: DataFormatEnum.Kmb, decimal: 1 })}
                </p>
                {isDiffferent && (
                  <>
                    <span className='difference--value' data-testid='difference-value'>
                      {formatNumber({ number: Math.abs(currentPrevDiff), format: DataFormatEnum.Kmb, decimal: 1 })}
                    </span>
                    {getIcon(currentPrevDiff)}
                  </>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
StatsOverview.displayName = 'StatsOverview'

export default StatsOverview
