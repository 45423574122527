import Chart from 'components/MotionDetails/MachineLearningMetric/Chart'

interface VolumeChartProps {
  title: string
  labels: string[]
  chartData: number[]
  chartColor?: 'purple' | 'blue'
}

const VolumeChart = ({ title, labels, chartData, chartColor }: VolumeChartProps) => {
  const yMax = Math.max(...chartData) * 1.3

  return (
    <div className='volume-chart'>
      <Chart
        labels={labels}
        data={chartData}
        title={title}
        isPercentage={false}
        shouldRoundNumbers={true}
        yMax={Number(yMax.toFixed(0))}
        yMin={0}
        chartColor={chartColor}
      />
    </div>
  )
}
VolumeChart.displayName = 'VolumeChart'

export default VolumeChart
