import { IconCaretRight, IconCheckMark } from 'components/common/Icons/Icons'
import { LogoService } from 'services/Utils/logo'
import { getNormalize } from 'services/Utils/parseString.utils'

import type { MetadataSearchItem } from 'models/metadata.model'
import type { MetadataField, MetadataSearchField } from 'models/motion/dynamicInput.model'
import type { CreateActionFields, Item, BreadcrumbInfo } from 'models/motion/motionBuilder.model'

interface SearchItemProps {
  item: MetadataSearchItem
  currentItem: Item | CreateActionFields
  breadCrumbItems: BreadcrumbInfo[]
  handleSelectField: (item: MetadataSearchField) => void
  handleSelectOption: (item: MetadataField) => void
}

const SearchViewList = ({ item, currentItem, handleSelectField, handleSelectOption }: SearchItemProps) => {
  const isField = item.type === 'field'
  const displayName = item.order[item.order.length - 1]?.magnifyDisplayName

  const searchItem: MetadataSearchField = {
    name: item.order[item.order.length - 1].name,
    key: item.order[item.order.length - 1]?.key,
    type: item.order[item.order.length - 1]?.type,
    platform: item.order[0]?.name.toLocaleLowerCase(),
    solutionInstanceId: item.order[0].solutionInstanceId,
    path: item.order
      .map((orderItem) => orderItem.magnifyDisplayName ?? orderItem.name)
      .splice(0, item.order.length - 1)
      .join('/'),
    object: item.order.length > 2 ? item.order[item.order.length - 2]?.key : item.order[item.order.length - 1]?.key,
    ...(displayName && {
      magnifyDisplayName: displayName,
    }),
  }

  const isSelected = (item: MetadataSearchField) => {
    if (typeof currentItem.value === 'object' && currentItem?.value && 'field' in currentItem?.value) {
      return item.name === currentItem?.value?.field
    }
    return false
  }

  const getItemMarkup = (searchItem: MetadataSearchField) =>
    isSelected(searchItem) ? (
      <IconCheckMark className='selected-data-field' data-testid='selected-data-field' />
    ) : (
      <span className='menu__item--label' data-testid='menu__item--label'>
        use
      </span>
    )

  return (
    <div
      className='menu__item search__item'
      data-testid='search__item'
      onClick={() => {
        if (isField) {
          handleSelectField(searchItem)
        } else {
          handleSelectOption(searchItem)
        }
      }}>
      <img
        key={searchItem.platform}
        src={LogoService.getIcon(getNormalize(searchItem.platform ?? ''))}
        alt={searchItem.platform}
      />

      <div className='search__info'>
        <div className='search__name' data-testid='search__name'>
          {searchItem.magnifyDisplayName ?? searchItem.name}
        </div>
        {item.type !== 'platform' && (
          <div className='search__path' data-testid='search__path'>
            <span title={searchItem.path}>{searchItem.path}</span>
          </div>
        )}
      </div>

      <div className='search__icon' data-testid='search__icon'>
        {isField ? getItemMarkup(searchItem) : <IconCaretRight />}
      </div>
    </div>
  )
}

export default SearchViewList
