import { Modal, Tabs } from 'antd'
import Card from 'antd/lib/card/Card'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Heading } from 'components/common'
import ExperimentResult from 'components/ExperimentDetails/components/ExperimentResult'
import AccountList from 'components/MotionDetails/AccountList'
import GoalMetric from 'components/MotionDetails/GoalMetric'
import MotionDetailsHeader from 'components/MotionDetails/Header'
import { LoadingGoalMetrics, LoadingHeader, LoadingMachineLearningMetrics } from 'components/MotionDetails/Loading'
import MachineLearningMetric from 'components/MotionDetails/MachineLearningMetric'
import OperationalStats from 'components/MotionDetails/OperationalStats'
import { getMachineLearningMetricsFromResponse } from 'store/reporting/reporting.store.utils'
import useStore from 'store/useStore'

import type { TabsProps } from 'antd'

import type { TenantInMotionReporting } from 'models/observability.model'

interface ExperimentDetailsProps {
  showDialog: boolean
  onCancel: () => void
  motions: { id: string; version: number }
}

// Temporary for demo purposes
const DEMO_OPERATIONAL_STATS_VARIANT_A = {
  PENDO: {
    PENDO_SEND_MESSAGE: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 1021,
      },
    },
  },
  SLACK: {
    SLACK_SEND_MESSAGE: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 1347,
      },
    },
  },
  ZENDESK: {
    ZENDESK_CREATE_TICKET: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 23,
      },
    },
  },
  MARKETO: {
    MARKETO_SEND_EMAIL: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 94,
      },
    },
  },
  GAINSIGHT: {
    GAINSIGHT_CREATE_CTA: {
      STATISTICS: {
        FAILED: 6,
        SUCCEEDED: 31,
      },
    },
  },
  SALESFORCE: {
    SALESFORCE_UPDATE_OPPORTUNITY: {
      STATISTICS: {
        FAILED: 1,
        SUCCEEDED: 8,
      },
    },
    SALESFORCE_CREATE_OPPORTUNITY: {
      STATISTICS: {
        FAILED: 28,
        SUCCEEDED: 17,
      },
    },
  },
  executionId: '176b8b04-8fcb-43d1-92dc-3a3f6db0b5cf',
  JOURNEY: {
    USERS: 12300,
    ACCOUNTS: 459,
  },
}

const DEMO_OPERATIONAL_STATS_VARIANT_B = {
  PENDO: {
    PENDO_SEND_MESSAGE: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 602,
      },
    },
  },
  SLACK: {
    SLACK_SEND_MESSAGE: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 359,
      },
    },
  },
  ZENDESK: {
    ZENDESK_CREATE_TICKET: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 15,
      },
    },
  },
  MARKETO: {
    MARKETO_SEND_EMAIL: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 10,
      },
    },
  },
  SALESFORCE: {
    SALESFORCE_UPDATE_OPPORTUNITY: {
      STATISTICS: {
        FAILED: 1,
        SUCCEEDED: 8,
      },
    },
    SALESFORCE_CREATE_OPPORTUNITY: {
      STATISTICS: {
        FAILED: 28,
        SUCCEEDED: 17,
      },
    },
  },
  executionId: '176b8b04-8fcb-43d1-92dc-3a3f6db0b5cf',
  JOURNEY: {
    USERS: 3600,
    ACCOUNTS: 156,
  },
}
const DEMO_MACHINE_LEARNING_METRICS = getMachineLearningMetricsFromResponse({
  metrics: {
    '2022-09-01 13:53:40.296475': 70.5,
    '2023-09-08 13:53:40.296475': 68.0,
    '2023-09-15 13:53:40.296475': 65.3,
    '2023-09-22 13:53:40.296475': 62.0,
    '2023-09-29 13:53:40.296475': 60.0,
    '2023-10-06 13:53:40.296475': 56.04,
    '2023-10-13 13:53:40.296475': 54.0,
    '2023-10-20 13:53:40.296475': 49.54,
    '2023-10-27 13:53:40.296475': 45.52,
    '2023-11-03 13:53:40.296475': 44.52,
  },
  title: 'Churn Probability',
})

const ExperimentDetails = observer(({ showDialog, onCancel, motions }: ExperimentDetailsProps) => {
  const { insightsStore, reportingStore } = useStore()
  const { loading, getMotion, getGoalMetrics, getMachineLearningMetrics, getAccountList, getOperationalStatistics } =
    reportingStore
  const { selectedDimension } = insightsStore
  const [loadingStats, setloadingStats] = useState(false)
  const {
    reportingStore: {
      data: { goalMetrics },
      dataTimeFrame: { goalMetricsTimeFrame, machineLearningMetricsTimeFrame },
      isGoalMetricsEmpty,
      isMachineLearningMetricsEmpty,
    },
  } = useStore()

  const navigate = useNavigate()

  const { id: playbookId, version } = motions

  useEffect(() => {
    if (playbookId && version) {
      getMotion({ playbookId, version: Number(version) })
        .then((motion) => {
          getOperationalStatistics({
            journeyId: playbookId,
            timestamp: motion?.stateHistory.EXECUTING,
          }).catch(console.error)
        })
        .catch(console.error)
      getGoalMetrics({ journeyId: playbookId, weeks: 10 }).catch(console.error)
      getMachineLearningMetrics({ journeyId: playbookId, weeks: 10 }).catch(console.error)
      getAccountList({
        journeyId: playbookId,
        version: version.toString(),
        dimension: selectedDimension,
      }).catch(console.error)
    }
  }, [motions])

  const handleMenuClick = (selectedTimeFrame: number) => {
    getGoalMetrics({ journeyId: playbookId ?? '', weeks: selectedTimeFrame }).catch(console.error)
  }

  const handleMLMenuClick = (selectedTimeFrame: number) => {
    getMachineLearningMetrics({ journeyId: playbookId ?? '', weeks: selectedTimeFrame }).catch(console.error)
  }

  const handleTabChange = () => {
    loadingStatsInterval()
  }

  const loadingStatsInterval = () => {
    setloadingStats(true)
    setTimeout(function () {
      setloadingStats((prev) => false)
    }, 500)
  }

  const handleClickViewList = () => {
    navigate('/motions/')
  }

  const handleClickViewMotion = () => navigate(`/motions/motion/${playbookId}/${version}`)

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Variant A',
      children: (
        <Card loading={loadingStats} bordered={false} className='stats'>
          <Heading variant='7' level='2'>
            Operational Stats
          </Heading>

          <OperationalStats
            operationalStatistics={DEMO_OPERATIONAL_STATS_VARIANT_A as unknown as TenantInMotionReporting}
            isOperationalStatisticsEmpty={false}
          />
        </Card>
      ),
    },
    {
      key: '2',
      label: 'Variant B',
      children: (
        <Card loading={loadingStats} bordered={false} className='stats'>
          <Heading variant='7' level='2'>
            Operational Stats
          </Heading>

          <OperationalStats
            operationalStatistics={DEMO_OPERATIONAL_STATS_VARIANT_B as unknown as TenantInMotionReporting}
            isOperationalStatisticsEmpty={false}
          />
        </Card>
      ),
    },
    {
      key: '3',
      label: 'Control',
      children: (
        <Card loading={loadingStats} bordered={false} className='stats'>
          <OperationalStats
            operationalStatistics={DEMO_OPERATIONAL_STATS_VARIANT_B as unknown as TenantInMotionReporting}
            isOperationalStatisticsEmpty={false}
          />
        </Card>
      ),
    },
  ]

  return (
    <Modal open={showDialog} className={'experiment-details'} footer={false}>
      <div className='view-motion__btn' onClick={handleClickViewList}></div>
      {loading.isMotionLoading ? (
        <LoadingHeader />
      ) : (
        <MotionDetailsHeader onClickClose={onCancel} onClickViewMotion={handleClickViewMotion} isExperiment={true} />
      )}
      <ExperimentResult />
      <Tabs defaultActiveKey='1' items={items} onChange={handleTabChange} />
      {loading.isGoalMetricsLoading ? (
        <LoadingGoalMetrics />
      ) : (
        <GoalMetric
          metrics={goalMetrics !== undefined ? [...goalMetrics, goalMetrics[0]] : undefined}
          timeFrame={goalMetricsTimeFrame}
          isEmpty={isGoalMetricsEmpty}
          onSelectionChange={handleMenuClick}
        />
      )}
      {loading.isMachineLearningMetricsLoading ? (
        <LoadingMachineLearningMetrics />
      ) : (
        <MachineLearningMetric
          metrics={DEMO_MACHINE_LEARNING_METRICS}
          timeFrame={machineLearningMetricsTimeFrame}
          isEmpty={isMachineLearningMetricsEmpty}
          onSelectionChange={handleMLMenuClick}
        />
      )}
      <AccountList journeyId={playbookId} journeyVersion={version.toString()} />
    </Modal>
  )
})
ExperimentDetails.displayName = 'ExperimentDetails'

export default ExperimentDetails
