import { Form } from 'antd'
import { observer } from 'mobx-react-lite'
import { type Dispatch, type SetStateAction } from 'react'

import AggregationsData from 'components/common/AggregationsData'
import RemoveAggregationModal from 'components/common/AggregationsData/RemoveAggregationModal'
import { ConfigPanelStatements } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelStatements/ConfigPanelStatements'

import type { ConfigPanelPayload } from 'models/motion/motionBuilder.model'

interface ConfigurationPanelProps {
  saveConfigData: () => Promise<void>
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

export const ConfigurationPanel = observer(({ saveConfigData, payload, setPayload }: ConfigurationPanelProps) => {
  return (
    <Form className='configuration-panel' onFinish={saveConfigData} scrollToFirstError={true}>
      <ConfigPanelStatements payload={payload} setPayload={setPayload} />
      <AggregationsData />
      <RemoveAggregationModal payload={payload} setPayload={setPayload} />
    </Form>
  )
})
ConfigurationPanel.displayName = 'ConfigurationPanel'
