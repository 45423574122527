import { memo } from 'react'
import { getMarkerEnd } from 'react-flow-renderer'

import { getLoopPath } from 'components/common/MotionThumbnail/Edges/EdgeLoop/EdgeLoopUtils'

import type { FC } from 'react'
import type { EdgeProps } from 'react-flow-renderer'

export const EdgeLoop: FC<EdgeProps> = memo(
  ({ id, sourceX, sourceY, targetX, targetY, arrowHeadType, markerEndId }) => {
    const horizontalLine = 50
    const path = getLoopPath({ sourceX, sourceY, targetX, targetY, horizontalLine })
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

    return <path id={id} className='react-flow__edge-path loop--edge' d={path} markerEnd={markerEnd} />
  },
)
