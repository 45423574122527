import { makeAutoObservable } from 'mobx'

import type { ChildStore } from 'store/StoreTypes'

import type { Node } from 'react-flow-renderer'

import type { NodeState } from 'models/motion.model'

export interface LoopActionState {
  text: string
  iconName: string
}
export type LoopTypeState = 'initial' | 'selecting'

export const DEFAULT_LOOP_ACTION: LoopActionState = { text: 'Choose an action from the canvas', iconName: '' }

export class LoopPanelStore implements ChildStore {
  loopAction: LoopActionState = DEFAULT_LOOP_ACTION
  isSelectingTarget: boolean = false
  showExitCondition: boolean = false
  loops = new Map<string, string>()
  currentSourceLoopId: string | null = null
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.loopAction = DEFAULT_LOOP_ACTION
    this.isSelectingTarget = false
    this.showExitCondition = false
    this.loops = new Map<string, string>()
    this.currentSourceLoopId = null
  }

  setLoop = (key: string, value?: string) => {
    this.loops.set(key, value ?? '')
  }

  setCurrentSourceLoopId = (id: string | null) => {
    this.currentSourceLoopId = id
  }

  setShowExitCondition = (exitCondition: boolean) => {
    this.showExitCondition = exitCondition
  }

  setIsSelectingTarget = (selecting: boolean) => {
    this.isSelectingTarget = selecting
  }

  setLoopAction = (loopAction: LoopActionState) => {
    this.loopAction = loopAction
  }

  setLoopToActionBasedOnCase = (state: LoopTypeState) => {
    switch (state) {
      case 'initial':
        this.setLoopAction({ text: '', iconName: '' })
        break
      case 'selecting':
        this.setLoopAction(DEFAULT_LOOP_ACTION)
        break
      default:
        break
    }
  }

  setLoopToActionBasedOnId = (nodes: Node<NodeState>[], targetId: string) => {
    const targetNode = nodes.find((node) => node.id === targetId)
    if (targetNode) {
      this.setLoopAction({ text: targetNode.data?.description ?? '', iconName: targetNode.data?.iconName ?? '' })
    } else {
      console.error('Failed to find targetNode in setLoopToActionBasedOnId:', targetId)
    }
  }
}
