import type { ActionsItems } from 'models/actions.model'
import { ShapeTypeEnum } from 'models/motion/motionBuilder.model'

const NO_NAME = 'No Name'

const getActionType = (name: string = NO_NAME) => {
  const actionName = name?.split(' ')

  return actionName[0].toLowerCase() + actionName[1]
}

const getActionData = (action: ActionsItems) => {
  return {
    action: action.type,
    actionId: action.id,
    name: action.actionName || NO_NAME,
    actionLevel: action.actionLevel,
    actionType: getActionType(action.actionName),
    iconName: action.iconName ?? getActionType(action.actionName),
    type: 'action',
    shape: ShapeTypeEnum.Square,
    object: action.metadataObject,
    platform: action.platform,
    solutionInstanceId: action.solutionInstanceId,
  }
}

export { getActionData }
