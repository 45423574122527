const MIN_DIGITS = 0
const MAX_DIGITS = 2

/**
 * From float number with a lot of digits eg 5299.779296875 turnes into 5,299.78 adding the host default language number formatting
 * @param value - value that will be formated
 * @returns - new format of value
 */
export const beautifyFloat = (value: number, maximumFractionDigits = MAX_DIGITS) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: MIN_DIGITS,
    maximumFractionDigits,
  })
}
