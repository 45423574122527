import { useEffect, useMemo, useRef } from 'react'

/**
 * Creates a debounced function that delays invoking the provided function until after `wait` milliseconds have elapsed since the last time the debounced function was invoked.
 * Typically used to run an expensive or async function after user interaction.
 *
 * @template T The type of the function to debounce.
 * @param {T} func The function to debounce.
 * @param {number} [wait=250] The number of milliseconds to delay.
 * @returns {(...args: Parameters<T>) => void} Returns the new debounced function.
 *
 * @example
 * // Usage with a function that takes one string parameter
 * const log = (message: string) => console.log(message);
 * const debouncedLog = debounce(log, 300);
 * debouncedLog('Hello, world!');
 * @see {@link https://gist.github.com/rendall/79a8559ad1b5a022a7923f160f7c429b}
 */
export const debounce = <T extends (...args: any[]) => void>(func: T, wait = 250) => {
  let debounceTimeout: number | null = null
  return (...args: Parameters<T>): void => {
    if (debounceTimeout) {
      window.clearTimeout(debounceTimeout)
    }
    debounceTimeout = window.setTimeout(() => {
      func(...args)
    }, wait)
  }
}

/**
 * Debounce hook.
 * @template T The type of the function to debounce.
 * @param {T} callback The function to debounce.
 * @param {number} [wait=250] The number of milliseconds to delay.
 * @returns The debounced function.
 * @see {@link https://www.developerway.com/posts/debouncing-in-react}
 * @example
 * const DebounceWithUseCallbackAndState = () => {
 *   const [value, setValue] = useState("initial");
 *   const onChange = () => {
 *     debug("State value:", value);
 *   };
 *   const debouncedOnChange = useDebounce(onChange);
 *   return (
 *     <input
 *       onChange={(e) => {
 *         debouncedOnChange();
 *         setValue(e.target.value);
 *       }}
 *       value={value}
 *     />
 *   );
 * };
 */
const useDebounce = <T extends (...args: any[]) => any>(
  callback: T,
  wait = 250,
): ((...args: Parameters<T>) => ReturnType<T> | void) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo((...args) => {
    const func = (...args: Parameters<T>) => {
      ref.current?.(...args)
    }
    return debounce(func, wait)
  }, [])

  return debouncedCallback
}

export default useDebounce
