import { Tag } from 'antd'

import { IconFlag } from 'components/common/Icons/Icons'

interface MotionMetricCellProps {
  text: string
  label: string
  className?: string
}

const MotionMetricCell = ({ text, label, className }: MotionMetricCellProps) => {
  const color = label === 'Significant' ? 'blue' : 'gray'
  return (
    <div className={`motion-metric-cell ${className}`}>
      <IconFlag />
      <span>{text}</span>
      <Tag className={`tag tag--${color}`}>
        <span>{label}</span>
      </Tag>
    </div>
  )
}
MotionMetricCell.displayName = 'MotionMetricCell'
export default MotionMetricCell
