import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { SupportFormValues } from 'models/support.model'

export class HelpCenterStore implements ChildStore {
  apiError: CoreAPIErrorResponse | null = null

  /** The Support Request form modal configuration. */
  supportModal: Record<string, boolean> = {
    isOpen: false,
  }

  /** The Support Request form data. */
  currentSupportTicket: Record<string, string> = {
    email: '',
    name: '',
    topic: '',
    severity: '',
    subject: '',
    description: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.apiError = null

    this.supportModal = {
      isOpen: false,
    }

    this.currentSupportTicket = {
      email: '',
      name: '',
      topic: '',
      severity: '',
      subject: '',
      description: '',
    }
  }

  setSupportModal = (props: Record<string, boolean>) => {
    this.supportModal = props
  }

  onChangeField = (field: string, value: string) => {
    this.currentSupportTicket[field] = value
  }

  post = async (user: SupportFormValues): Promise<any> => {
    try {
      await API.support.post(user)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
      throw error
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
