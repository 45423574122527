import { NumberFormat } from 'services/Utils'

interface RevenueCellProps {
  amount: number
  change?: number
  className?: string
}

/**
 * Return the amount and change in parenthesis.
 * e.g. 278.28 (23%)
 * if change is negative then style it red and if positive then green
 * @param {number} amount - the amount to in US $ to display in the cell
 * @param {number | undefined} change - the value as a % to be shown alongside the amount in parenthesis.
 */

const RevenueCell = ({ amount, change, className }: RevenueCellProps) => {
  return (
    <span className={`cell cell-value ${className}`} data-testid='cell-value'>
      $ {NumberFormat.beautifyFloat(amount, 1)} <small>(</small>
      <span
        data-testid='cell-change'
        className={
          typeof change === 'number' && change !== 0 ? `cell--${change > 0 ? 'positive' : 'negative'}` : 'cell--neutral'
        }>
        <small>{typeof change === 'number' ? `${NumberFormat.beautifyFloat(change * 100, 1)} %` : 'N/A'}</small>
      </span>
      <small>)</small>
    </span>
  )
}

export default RevenueCell
