import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import IconLoader from 'assets/icons/insightsDetails/loader.svg?react'
import { Heading } from 'components/common/Heading/Heading'
import useStore from 'store/useStore'

const InsightsLoadingModal = observer(() => {
  const { insightsStore } = useStore()

  const { isRecalculating, setIsRecalculating } = insightsStore

  const handleCancel = () => {
    setIsRecalculating(false)
  }

  return (
    <Modal
      open={isRecalculating}
      footer={false}
      width='668px'
      maskClosable={true}
      style={{ textAlign: 'center' }}
      onCancel={handleCancel}
      onOk={handleCancel}
      className='recalculate-modal'>
      <Heading level='1' variant='1'>
        Recalculating information with new target
      </Heading>

      <IconLoader className='loading-icon'></IconLoader>
    </Modal>
  )
})

InsightsLoadingModal.displayName = 'InsightsLoadingModal'

export default InsightsLoadingModal
