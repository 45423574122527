import axios from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetEmailStatsReporting, demoGetReportingMotionReporting } from 'api/mockResponses/demo/reporting.mock'
import {
  accountsListMock,
  goalMetricsValueMock,
  machineDistributionMock,
  emailStatsMock,
} from 'api/mockResponses/reporting.mock'
import { getBaseUrl } from 'api/utils'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import { LoggerService } from 'services/LogService/LogService'
import { DEFAULT_ACCOUNT_LIST } from 'store/reporting/reporting.store.utils'

import type { MotionIdentifiers } from 'models/motion.model'
import {
  AccountListKeyEnum,
  type AccountListApiResponse,
  type EmailStatistics,
  type EmailStatisticsApiResponse,
  type GoalMetricsApiResponse,
  type MachineLearningMetricsApiResponse,
  type MetricsPayload,
  type OperationalStatistics,
  type OperationalStatisticsPayload,
  type ReportingMotion,
  AccountListTypeEnum,
} from 'models/reporting.model'

const BASE_ERROR_MESSAGE = `Could not fetch reporting`

export const getReportingMotion = async ({ playbookId, version }: MotionIdentifiers): Promise<ReportingMotion> => {
  try {
    const {
      data: { data },
    } = await axios.get<{ data: ReportingMotion }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${playbookId}/${version}`,
      {
        demoData: demoGetReportingMotionReporting,
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get reporting Motion error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Motion')
    }
  }
}

export const getOperationalStatistics = async ({
  journeyId,
  timestamp,
}: OperationalStatisticsPayload): Promise<OperationalStatistics> => {
  try {
    if (!timestamp) {
      throw new Error(`Timestamp: ${timestamp} is not valid. ISO 8601 format is required`)
    }
    const { data } = await axios.get<OperationalStatistics>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/statistics/${journeyId}/${timestamp}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: `${BASE_ERROR_MESSAGE} operational statistics!`, error })
    return {} as OperationalStatistics
  }
}

/**
 * Fetch the consolidated list of metrics for a given Motion.
 * @param {object} props The properties to pass to the API.
 * @param {string} props.journeyId The Motion ID to fetch metrics for.
 * @param {number} props.weeks The number of weeks to fetch metrics for.
 * @returns {GoalMetricsApiResponse} The API response.
 */
export const getGoalMetrics = async ({ journeyId, weeks }: MetricsPayload): Promise<GoalMetricsApiResponse> => {
  try {
    if (Demo.mockApiIsEnabled() || Sandbox.isEnabled()) {
      return Promise.resolve(goalMetricsValueMock)
    } else {
      return Promise.resolve({
        journeyId,
        metrics: [],
        weeks,
      })
    }
  } catch (error: unknown) {
    LoggerService.error({ message: `${BASE_ERROR_MESSAGE} goal metrics!`, error })
    return {} as GoalMetricsApiResponse
  }
}

export const machineLearningMetrics = async ({
  journeyId,
  weeks,
}: MetricsPayload): Promise<MachineLearningMetricsApiResponse> => {
  try {
    if (Demo.mockApiIsEnabled() || Sandbox.isEnabled()) {
      return Promise.resolve(machineDistributionMock)
    } else {
      return Promise.resolve({
        metrics: {},
        title: '',
      })
    }
  } catch (error: unknown) {
    LoggerService.error({ message: `${BASE_ERROR_MESSAGE} machine distribution!`, error })
    return { title: '', metrics: {} } as MachineLearningMetricsApiResponse
  }
}

export const getAccountList = async (
  journeyId: string,
  limit: number,
  offset: number,
  search: string = '',
): Promise<AccountListApiResponse> => {
  try {
    if (Demo.mockApiIsEnabled()) {
      return Promise.resolve(accountsListMock)
    } else {
      return Promise.resolve({
        columns: [
          {
            key: AccountListKeyEnum.Account,
            title: 'Name',
            type: AccountListTypeEnum.String,
          },
          {
            key: AccountListKeyEnum.AccountID,
            title: 'ID',
            type: AccountListTypeEnum.String,
          },
          {
            key: AccountListKeyEnum.ParticipatedSince,
            title: 'Participated Since',
            type: AccountListTypeEnum.String,
          },
        ],
        page: 1,
        rows: [],
        total: 0,
        limit: 0,
        offset: 0,
        search: null,
      })
    }
  } catch (error: unknown) {
    LoggerService.error({ message: `${BASE_ERROR_MESSAGE} accounts list!`, error })
    return DEFAULT_ACCOUNT_LIST
  }
}

/**
 * Fetch the email stats for a given Motion.
 * @param {object} props The properties to pass to the API.
 * @param {string} props.journeyId The Motion ID to fetch email stats for.
 * @returns {EmailStatistics[]} The API response.
 */
export const getEmailStats = async ({ journeyId }: { journeyId: string }): Promise<EmailStatistics[]> => {
  try {
    if (Demo.mockApiIsEnabled() || Sandbox.isEnabled()) {
      return emailStatsMock.data
    } else {
      const { data } = await axios.get<EmailStatisticsApiResponse>(
        `${getBaseUrl('CORE_API')}/v1/core/email-templates/statistics/journeyId/${journeyId}`,
        {
          demoData: demoGetEmailStatsReporting,
        } as DemoAxiosRequestConfig,
      )
      return data.data
    }
  } catch (error: unknown) {
    LoggerService.error({ message: `${BASE_ERROR_MESSAGE} email stats!`, error })
    return [] as EmailStatistics[]
  }
}
