import { Input } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useContext, useState } from 'react'

import { IconPenUnderlined } from 'components/common/Icons/Icons'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

export const SegmentDescription = observer(() => {
  const { segmentBuilderData, setSegmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { description } = segmentBuilderData

  const { motionStore } = useStore()
  const [editable, setEditable] = useState(false)

  const textareaProps = {
    autoFocus: true,
    rows: 3,
    defaultValue: description,
    maxLength: 256,
    onFocus: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      const val = e.target.value
      e.target.value = ''
      e.target.value = val
    },
    onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      const description = e?.currentTarget?.value
      if (description) {
        setSegmentBuilderData((prev) => ({
          ...prev,
          description,
        }))
      }
    },
  }

  return (
    <div
      className={`segment-description__container ${editable ? 'active' : 'inactive'}`}
      onBlur={() => setEditable(false)}>
      <span className='segment-description__container-title'>DESCRIPTION</span>
      <div
        className={classNames('segment-description__container-body', {
          'segment-description__container-body--disabled': motionStore.isSegmentBuilderEditDisabled,
        })}>
        {editable ? <Input.TextArea {...textareaProps} data-testid='edit-description-text' /> : <p>{description}</p>}
        {!editable && (
          <IconPenUnderlined
            onClick={() => setEditable(!motionStore.isSegmentBuilderEditDisabled)}
            data-testid='edit-description'
            className={classNames('segment-description__container-icon', {
              'segment-description__container-icon--disabled': motionStore.isSegmentBuilderEditDisabled,
            })}
          />
        )}
      </div>
    </div>
  )
})
SegmentDescription.displayName = 'SegmentDescription'
