import { useEffect } from 'react'

import { displayErrorNotification } from 'services/ApiErrorNotifications'
import type { ActionsStore } from 'store/actions/actions.store'
import type { EmailTemplatesStore } from 'store/emailTemplates/emailTemplates.store'
import type { FileAttachmentsStore } from 'store/fileAttachments/fileAttachments.store'
import type { HelpCenterStore } from 'store/helpCenter.store'
import type { InsightsStore } from 'store/insights/insights.store'
import type { IntegrationsStore } from 'store/integrations/integrations.store'
import type { MetadataStore } from 'store/metadata/metadata.store'
import type { DynamicInputStore } from 'store/motion/dynamicInput/dynamicInput.store'
import type { MotionStore } from 'store/motion.store'
import type { NotificationStore } from 'store/notifications.store'
import type { ReportingStore } from 'store/reporting/reporting.store'
import type { UserStore } from 'store/user.store'

type Store =
  | ActionsStore
  | DynamicInputStore
  | EmailTemplatesStore
  | FileAttachmentsStore
  | HelpCenterStore
  | InsightsStore
  | IntegrationsStore
  | MotionStore
  | NotificationStore
  | ReportingStore
  | UserStore

export const useDisplayErrorNotification = (store: Store) => {
  useEffect(() => {
    if (store.apiError) {
      displayErrorNotification(store.apiError)
      // make sure we clear the error after displaying it
      // this is to prevent the error from being displayed again
      store.setApiError(null)
    }
  }, [store.apiError])
}

export const useMetadataDisplayErrorNotification = (metadataStore: MetadataStore) => {
  useEffect(() => {
    if (metadataStore.apiErrors) {
      metadataStore.apiErrors.forEach((err) => {
        if (!err.seen) {
          displayErrorNotification(err.coreApiError.error)
          metadataStore.setApiErrors(err.coreApiError, true)
        }
      })
    }
  }, [])
}
