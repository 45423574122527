import type { TenantActionTotals, TenantInMotionReportingTotals, TenantMotionTotals } from 'models/observability.model'

export const demoGetObservabilityTenantActionTotals: TenantActionTotals = {
  totals: {
    actions: 214100000,
    users: 2400000,
    accounts: 3200,
  },
  integrations: {
    magnify: {
      total: 567900,
      percentageTotal: '38',
      actions: {
        'Send Email': {
          total: 567900,
          percentageTotal: '38',
        },
      },
    },
    gainsight: {
      total: 345700,
      percentageTotal: '22',
      actions: {
        'Create Timeline Event': {
          total: 345700,
          percentageTotal: '22',
        },
      },
    },
    zendesk: {
      total: 234600,
      percentageTotal: '20',
      actions: {
        'Create Ticket': {
          total: 234600,
          percentageTotal: '17',
        },
        'Update Ticket': {
          total: 3680,
          percentageTotal: '3',
        },
      },
    },
    pendo: {
      total: 222300,
      percentageTotal: '14',
      actions: {
        'Launch Guide': {
          total: 222300,
          percentageTotal: '14',
        },
      },
    },
    slack: {
      total: 12400,
      percentageTotal: '6',
      actions: {
        'Send Message': {
          total: 12400,
          percentageTotal: '6',
        },
      },
    },
  },
  graphDataPoints: {
    daily: [
      {
        total: 40,
        date: 1706659200000,
      },
      {
        total: 0,
        date: 1706745600000,
      },
      {
        total: 0,
        date: 1706832000000,
      },
      {
        total: 0,
        date: 1706918400000,
      },
      {
        total: 0,
        date: 1707004800000,
      },
      {
        total: 2,
        date: 1707091200000,
      },
      {
        total: 0,
        date: 1707177600000,
      },
      {
        total: 18,
        date: 1707264000000,
      },
      {
        total: 4,
        date: 1707350400000,
      },
      {
        total: 22,
        date: 1707436800000,
      },
      {
        total: 0,
        date: 1707523200000,
      },
      {
        total: 0,
        date: 1707609600000,
      },
      {
        total: 8,
        date: 1707696000000,
      },
      {
        total: 0,
        date: 1707782400000,
      },
      {
        total: 0,
        date: 1707868800000,
      },
      {
        total: 0,
        date: 1707955200000,
      },
      {
        total: 9,
        date: 1708041600000,
      },
      {
        total: 0,
        date: 1708128000000,
      },
      {
        total: 0,
        date: 1708214400000,
      },
      {
        total: 5,
        date: 1708300800000,
      },
      {
        total: 45,
        date: 1708387200000,
      },
      {
        total: 0,
        date: 1708473600000,
      },
      {
        total: 1,
        date: 1708560000000,
      },
      {
        total: 4,
        date: 1708646400000,
      },
      {
        total: 0,
        date: 1708732800000,
      },
      {
        total: 0,
        date: 1708819200000,
      },
      {
        total: 84,
        date: 1708905600000,
      },
      {
        total: 3,
        date: 1708992000000,
      },
      {
        total: 0,
        date: 1709078400000,
      },
      {
        total: 0,
        date: 1709164800000,
      },
      {
        total: 36,
        date: 1709251200000,
      },
    ],
    weekly: [
      {
        total: 40,
        date: 1706486400000,
      },
      {
        total: 46,
        date: 1707091200000,
      },
      {
        total: 17,
        date: 1707696000000,
      },
      {
        total: 55,
        date: 1708300800000,
      },
      {
        total: 123,
        date: 1708905600000,
      },
    ],
    quarterly: [
      {
        total: 281,
        date: 1704067200000,
      },
    ],
    yearly: [
      {
        total: 281,
        date: 1704067200000,
      },
    ],
  },
}

export const demoGetObservabilityTenantActionTotalsRange: TenantActionTotals = {
  totals: {
    actions: 3200000,
    users: 153200,
    accounts: 768,
  },
  previousTotals: {
    actions: 3272000,
    users: 148200,
    accounts: 760,
  },

  integrations: {
    magnify: {
      total: 46892,
      percentageTotal: '38',
      actions: {
        'Send Email': {
          total: 46892,
          percentageTotal: '38',
        },
      },
    },
    gainsight: {
      total: 27148,
      percentageTotal: '22',
      actions: {
        'Create Timeline Event': {
          total: 27148,
          percentageTotal: '22',
        },
      },
    },
    zendesk: {
      total: 24680,
      percentageTotal: '20',
      actions: {
        'Create Ticket': {
          total: 21000,
          percentageTotal: '17',
        },
        'Update Ticket': {
          total: 3680,
          percentageTotal: '3',
        },
      },
    },
    pendo: {
      total: 17276,
      percentageTotal: '14',
      actions: {
        'Launch Guide': {
          total: 17276,
          percentageTotal: '14',
        },
      },
    },
    slack: {
      total: 7404,
      percentageTotal: '6',
      actions: {
        'Send Message': {
          total: 7404,
          percentageTotal: '6',
        },
      },
    },
  },
  graphDataPoints: {
    daily: [
      {
        total: 132541,
        date: 1706659200000,
      },
      {
        total: 0,
        date: 1706745600000,
      },
      {
        total: 0,
        date: 1706832000000,
      },
      {
        total: 0,
        date: 1706918400000,
      },
      {
        total: 0,
        date: 1707004800000,
      },
      {
        total: 149034,
        date: 1707091200000,
      },
      {
        total: 0,
        date: 1707177600000,
      },
      {
        total: 18,
        date: 1707264000000,
      },
      {
        total: 4,
        date: 1707350400000,
      },
      {
        total: 134626,
        date: 1707436800000,
      },
      {
        total: 0,
        date: 1707523200000,
      },
      {
        total: 0,
        date: 1707609600000,
      },
      {
        total: 8,
        date: 1707696000000,
      },
      {
        total: 112755,
        date: 1707782400000,
      },
      {
        total: 0,
        date: 1707868800000,
      },
      {
        total: 0,
        date: 1707955200000,
      },
      {
        total: 9,
        date: 1708041600000,
      },
      {
        total: 57889,
        date: 1708128000000,
      },
      {
        total: 0,
        date: 1708214400000,
      },
      {
        total: 5,
        date: 1708300800000,
      },
      {
        total: 3,
        date: 1708387200000,
      },
      {
        total: 51721,
        date: 1708473600000,
      },
      {
        total: 1,
        date: 1708560000000,
      },
      {
        total: 4,
        date: 1708646400000,
      },
      {
        total: 0,
        date: 1708732800000,
      },
      {
        total: 96781,
        date: 1708819200000,
      },
      {
        total: 84,
        date: 1708905600000,
      },
      {
        total: 71051,
        date: 1709251200000,
      },
    ],
    weekly: [
      {
        total: 149032,
        date: 1708128000000,
      },
      {
        total: 134626,
        date: 1708473600000,
      },
      {
        total: 112731,
        date: 1708819200000,
      },
      {
        total: 57881,
        date: 1709251200000,
      },
    ],
    quarterly: [
      {
        total: 281,
        date: 1704067200000,
      },
    ],
    yearly: [
      {
        total: 281,
        date: 1704067200000,
      },
    ],
  },
}

export const demoGetObservabilityTenantMotionTotals: TenantMotionTotals = {
  tenantTotals: {
    participantTotals: {
      ENTERED: 27846,
      ACTIVE: 36552,
      COMPLETED: 153690,
    },
    graphDataPoints: {
      daily: [
        {
          total: 85901,
          date: 1706659200000,
        },
        {
          total: 0,
          date: 1706745600000,
        },
        {
          total: 0,
          date: 1706832000000,
        },
        {
          total: 0,
          date: 1706918400000,
        },
        {
          total: 0,
          date: 1707004800000,
        },
        {
          total: 77809,
          date: 1707091200000,
        },
        {
          total: 0,
          date: 1707177600000,
        },
        {
          total: 18,
          date: 1707264000000,
        },
        {
          total: 4,
          date: 1707350400000,
        },
        {
          total: 59879,
          date: 1707436800000,
        },
        {
          total: 0,
          date: 1707523200000,
        },
        {
          total: 0,
          date: 1707609600000,
        },
        {
          total: 8,
          date: 1707696000000,
        },
        {
          total: 54990,
          date: 1707782400000,
        },
        {
          total: 0,
          date: 1707868800000,
        },
        {
          total: 0,
          date: 1707955200000,
        },
        {
          total: 9,
          date: 1708041600000,
        },
        {
          total: 36923,
          date: 1708128000000,
        },
        {
          total: 0,
          date: 1708214400000,
        },
        {
          total: 5,
          date: 1708300800000,
        },
        {
          total: 3,
          date: 1708387200000,
        },
        {
          total: 27994,
          date: 1708473600000,
        },
        {
          total: 1,
          date: 1708560000000,
        },
        {
          total: 4,
          date: 1708646400000,
        },
        {
          total: 0,
          date: 1708732800000,
        },
        {
          total: 25778,
          date: 1708819200000,
        },
        {
          total: 84,
          date: 1708905600000,
        },
        {
          total: 35421,
          date: 1709251200000,
        },
      ],
      weekly: [
        {
          total: 36923,
          date: 1708128000000,
        },
        {
          total: 27994,
          date: 1708473600000,
        },
        {
          total: 25778,
          date: 1708819200000,
        },
        {
          total: 35421,
          date: 1709251200000,
        },
      ],
      quarterly: [
        {
          total: 4,
          date: 1704067200000,
        },
      ],
      yearly: [
        {
          total: 4,
          date: 1704067200000,
        },
      ],
    },
  },
  topJourneys: [
    {
      journeyId: '63d2b542-f062-43ff-8920-d2be889a512d',
      journeyName: 'Increase Feature A Usage',
      totals: {
        ENTERED: 11533,
        ACTIVE: 11533,
        COMPLETED: 11533,
      },
    },
    {
      journeyId: 'd2913f78-bc4f-48dc-9554-8f110f4613b0',
      journeyName: 'Launch Announcement: Feature B',
      totals: {
        ENTERED: 3300,
        ACTIVE: 20300,
        COMPLETED: 4300,
      },
    },
    {
      journeyId: 'e1f1be5b-bc4c-4669-9480-03429bee4fd4',
      journeyName: 'Accounts Renewing within 6 months',
      totals: {
        ENTERED: 1700.5,
        ACTIVE: 19990,
        COMPLETED: 3000.5,
      },
    },
    {
      journeyId: '2122629f-709a-4a16-9c35-ecae9c953418',
      journeyName: 'Low Usage Users',
      totals: {
        ENTERED: 8900,
        ACTIVE: 7980,
        COMPLETED: 3020,
      },
    },
    {
      journeyId: 'ca80fb06-0dd8-411c-8059-6544f29fb5b1',
      journeyName: 'Onboarding',
      totals: {
        ENTERED: 6680,
        ACTIVE: 620,
        COMPLETED: 4960,
      },
    },
    {
      journeyId: '226c1354-91e2-48d8-bf52-0894146dd607',
      journeyName: 'Pete - Execution Status - v2',
      totals: {
        ENTERED: 4,
        ACTIVE: 4,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '577b91b8-d5d0-4daa-af63-d14ed5fc1957',
      journeyName: 'Pete - Execution Status - v1',
      totals: {
        ENTERED: 4,
        ACTIVE: 4,
        COMPLETED: 0,
      },
    },
    {
      journeyId: 'b7c6a358-0623-419b-b66f-694a671c49d5',
      journeyName: 'Pete - Execution Status - v3',
      totals: {
        ENTERED: 4,
        ACTIVE: 4,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '360a0fed-792c-4184-ab9f-e91a9aa00424',
      journeyName: 'CLONE: Dynamic inputs test',
      totals: {
        ENTERED: 3,
        ACTIVE: 3,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '2052b4bf-2fe7-41de-be2d-560bddb8a45e',
      journeyName: 'IG Test - Salesforce - Update Opportunity',
      totals: {
        ENTERED: 2,
        ACTIVE: 2,
        COMPLETED: 0,
      },
    },
  ],
}

export const emptyMotionsTotal: TenantMotionTotals = {
  tenantTotals: {
    participantTotals: {
      ENTERED: 0,
      ACTIVE: 0,
      COMPLETED: 0,
    },
    graphDataPoints: {
      daily: [
        {
          total: 4,
          date: 1709683200000,
        },
      ],
      weekly: [
        {
          total: 0,
          date: 1709510400000,
        },
      ],
      quarterly: [
        {
          total: 0,
          date: 1704067200000,
        },
      ],
      yearly: [
        {
          total: 0,
          date: 1704067200000,
        },
      ],
    },
  },
  topJourneys: [
    {
      journeyId: '63d2b542-f062-43ff-8920-d2be889a512d',
      journeyName: 'Dynamic inputs test',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: 'd2913f78-bc4f-48dc-9554-8f110f4613b0',
      journeyName: 'CLONE: Test State Machine Creation',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: 'e1f1be5b-bc4c-4669-9480-03429bee4fd4',
      journeyName: 'SM - Magnify Send Email w/ Update',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '2122629f-709a-4a16-9c35-ecae9c953418',
      journeyName: 'CLONE: Angela Test',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: 'ca80fb06-0dd8-411c-8059-6544f29fb5b1',
      journeyName: 'Pete - Execution Status - v4',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '226c1354-91e2-48d8-bf52-0894146dd607',
      journeyName: 'Pete - Execution Status - v2',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '577b91b8-d5d0-4daa-af63-d14ed5fc1957',
      journeyName: 'Pete - Execution Status - v1',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: 'b7c6a358-0623-419b-b66f-694a671c49d5',
      journeyName: 'Pete - Execution Status - v3',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '360a0fed-792c-4184-ab9f-e91a9aa00424',
      journeyName: 'CLONE: Dynamic inputs test',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
    {
      journeyId: '2052b4bf-2fe7-41de-be2d-560bddb8a45e',
      journeyName: 'IG Test - Salesforce - Update Opportunity',
      totals: {
        ENTERED: 0,
        ACTIVE: 0,
        COMPLETED: 0,
      },
    },
  ],
}

export const demoGetObservabilityInMotionReportingTotals: {
  [key: string]: TenantInMotionReportingTotals
} = {
  '3161a7db-92e3-43af-9f0f-97d8f6f4e1b6': {
    nodes: [
      {
        id: 'segment1',
        total: 27400,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 27400,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'triggerEvent584732690288',
        total: 27400,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 27400,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'timeDelay602644717162',
        total: 27400,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 26300,
            percentageTotal: '96',
          },
          {
            status: 'ACTIVE',
            total: 1100,
            percentageTotal: '4',
          },
        ],
      },
      {
        id: 'triggerEvent814560792971',
        total: 26300,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 26300,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'ifElse615143814040',
        total: 26284,
        statusTotals: [
          {
            status: 'YES',
            total: 18200,
            percentageTotal: '69',
          },
          {
            status: 'NO',
            total: 8000,
            percentageTotal: '30',
          },
          {
            status: 'ERROR',
            total: 84,
            percentageTotal: '1',
          },
        ],
      },
      {
        id: 'triggerEvent809017933089',
        total: 18200,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 18200,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'timeDelay137539969123',
        total: 26200,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 22500,
            percentageTotal: '86',
          },
          {
            status: 'ACTIVE',
            total: 3700,
            percentageTotal: '14',
          },
        ],
      },
      {
        id: 'merge966515920853',
        total: 26200,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 26200,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'triggerEvent662029071632',
        total: 26200,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 22500,
            percentageTotal: '86',
          },
          {
            status: 'ACTIVE',
            total: 3700,
            percentageTotal: '14',
          },
        ],
      },
      {
        id: 'loop880325097032',
        total: 22500,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 22500,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'createCta350189859510',
        total: 8,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 8,
            percentageTotal: '100',
          },
        ],
      },
      {
        id: 'triggerEvent989016907386',
        total: 8,
        statusTotals: [
          {
            status: 'COMPLETED',
            total: 8,
            percentageTotal: '100',
          },
        ],
      },
    ],
  },
}
