import type { MetadataSearchItem } from 'models/metadata.model'
import { FieldTypeEnum } from 'models/metadata.model'
import type { MetadataField } from 'models/motion/dynamicInput.model'
import type { CreateActionFields } from 'models/motion/motionBuilder.model'

export const fieldTypes = [
  [
    FieldTypeEnum.Int,
    FieldTypeEnum.Integer,
    FieldTypeEnum.Number,
    FieldTypeEnum.Currency,
    FieldTypeEnum.Percent,
    FieldTypeEnum.Double,
    FieldTypeEnum.Decimal,
  ] as string[],
  [
    FieldTypeEnum.Url,
    FieldTypeEnum.Id,
    FieldTypeEnum.Address,
    FieldTypeEnum.String,
    FieldTypeEnum.Phone,
    FieldTypeEnum.Text,
    FieldTypeEnum.Reference,
    FieldTypeEnum.Regexp,
    FieldTypeEnum.Textarea,
    FieldTypeEnum.Email,
  ] as string[],
  [FieldTypeEnum.Date, FieldTypeEnum.Datetime, FieldTypeEnum.Datepicker] as string[],
  [FieldTypeEnum.Picklist, FieldTypeEnum.Collection] as string[],
  [FieldTypeEnum.Dropdown, FieldTypeEnum.Select] as string[],
  [FieldTypeEnum.Boolean] as string[],
]

export const checkTypeEquality = (itemType: string | undefined, type: string) => {
  const categoryTypes = fieldTypes.filter((types) => itemType && types.includes(itemType.toLowerCase()))

  return categoryTypes[0]?.includes(type?.toLowerCase()) || false
}

export const filterByType = (metadataList: MetadataField[], filterByType: string | undefined) => {
  // TODO: This is a temporary fix to handle the case where we want to return all the fields that are
  // eligible for dynamic input for the Slack - From field present in the Post To Channel and Send Message action
  if (filterByType === 'select') {
    // eslint-disable-next-line no-param-reassign
    filterByType = ''
  }
  if (filterByType) {
    return metadataList.filter((metadata) => !metadata?.type || checkTypeEquality(metadata.type, filterByType))
  }

  return metadataList
}

export const filterByTypeOnSearch = (metadataList: MetadataSearchItem[], filterByType: string) => {
  if (filterByType) {
    return metadataList.filter(
      (metadata) =>
        metadata.type !== 'field' ||
        (metadata.type === 'field' && checkTypeEquality(metadata.order[metadata.order.length - 1].type, filterByType)),
    )
  }

  return metadataList
}

export const isDyanmicField = (item: CreateActionFields) => {
  return !!(item.value as CreateActionFields)?.platform || item.onlyDynamicInput
}
