import { LinkOutlined } from '@ant-design/icons'
import { Dropdown, Table, Button as AntBtn, message } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import EllipsisVertical from 'assets/images/ellipsis-vertical.svg?react'
import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import SupportModal from 'components/SupportModal'
import { ConnectionStatus } from 'pages/Integrations/ConnectionStatus/ConnectionStatus'
import { getLatestConnection, getIconUrl } from 'pages/Integrations/utils'
import useStore from 'store/useStore'

import type { MenuProps, TableProps } from 'antd'

import { DataFormatEnum } from 'models/insights'
import type { Integration, IntegrationConnectionStatus } from 'models/Integration.model'

interface IntegrationTableDataType {
  key: string
  connection: {
    connectionId: string
    displayName: string
    category: string
    userOAuthLink: string | undefined
  }
  status: string
  numOfConnections: string
  lastIngested: number
  actions: string
}

export const ConnectionsTable = () => {
  const { integrationsStore, helpCenterStore } = useStore()
  const { integrations, isLoading } = integrationsStore

  const handleCopyToClipboard = async (userOAuthLink: string | undefined) => {
    if (!userOAuthLink) {
      message.error('No OAuth link found')
      return
    }

    try {
      await navigator.clipboard.writeText(userOAuthLink ?? '')
      message.success('OAuth link copied to clipboard!')
    } catch (error: unknown) {
      message.error('Failed to copy OAuth link to clipboard')
    }
  }

  const items: MenuProps['items'] = [
    {
      label: <p>Edit</p>,
      key: '0',
      onClick: () => helpCenterStore.setSupportModal({ isOpen: true }),
    },
  ]

  const columns: TableProps<IntegrationTableDataType>['columns'] = [
    {
      title: 'Connection',
      dataIndex: 'connection',
      key: 'connection',
      render: (connection: { connectionId: string; displayName: string; category: string }) => (
        <div className='connections-table__connection'>
          <DynamicImage iconUrl={getIconUrl(connection.connectionId)} iconAlt={connection.displayName} />
          <div className='connections-table__connection__info'>
            <p className='connections-table__connection__info__text'>{connection.displayName}</p>
            <p className='connections-table__connection__info__text--secondary'>{connection.category}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <ConnectionStatus text={status} status={status as IntegrationConnectionStatus} />,
    },
    {
      title: 'Connections',
      dataIndex: 'numOfConnections',
      key: 'numOfConnections',
      render: (numOfConnections: string) => <p className='connections-table__text'>{numOfConnections}</p>,
    },
    {
      title: 'Last Ingested',
      dataIndex: 'lastIngested',
      key: 'lastIngested',
      render: (lastIngested: number | undefined) => {
        if (!lastIngested) return '-'
        return <p className='connections-table__text'>{dayjs(lastIngested).format('D[-]MMM[-]YY h:mm A')}</p>
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (actionsTotal: string) => {
        if (actionsTotal === '-') return <p className='connections-table__text'>{actionsTotal}</p>

        return (
          <p className='connections-table__text'>
            {formatNumber({
              number: actionsTotal,
              format: DataFormatEnum.CurrencyKMB,
              decimal: 1,
            }).slice(1)}
          </p>
        )
      },
    },
    {
      title: '',
      key: 'actionBtn',
      render: (_: any, data: IntegrationTableDataType) => {
        const userOAuthLink = data?.connection?.userOAuthLink
        const userOAuthDropdownItem = {
          label: (
            <span className='connections-table__oauth-link'>
              <LinkOutlined />
              <p>Copy OAuth link</p>
            </span>
          ),
          key: 'oauth-link',
          onClick: () => handleCopyToClipboard(userOAuthLink),
        }

        return (
          <Dropdown
            placement='bottomRight'
            trigger={['click']}
            menu={{
              items: userOAuthLink ? items.concat(userOAuthDropdownItem) : items,
            }}>
            <AntBtn>
              <EllipsisVertical />
            </AntBtn>
          </Dropdown>
        )
      },
    },
  ]

  const locale = {
    emptyText: 'No connections found',
  }

  const getTableData = (integrations: Integration[]) => {
    return integrations.reduce((acc: IntegrationTableDataType[], integration: Integration) => {
      const status = integration.connections?.some((connection) => connection.status === 'Error')
        ? 'Error'
        : integration.connections?.some((connection) => connection.status === 'Processing')
          ? 'Processing'
          : 'Live'

      if (!integration.connections || integration.connections.length < 1) return acc
      const data = {
        key: integration.id,
        connection: {
          connectionId: integration.id,
          displayName: integration.displayName,
          category: integration.category,
          // Getting OAuth link from the first connection, as the table does not differentiate
          // between multiple connections for the same platform
          userOAuthLink: integration.connections[0].userOauthLink,
        },
        status: status,
        numOfConnections: integration?.connections?.length.toString() || '0',
        lastIngested: getLatestConnection(integration.connections).lastIngestedDate,
        actions: integration?.actions?.total.toString() || '-',
      }
      return [...acc, data]
    }, [])
  }

  const tableData = useMemo(() => getTableData(integrations), [integrations])

  return (
    <div className='connections-table'>
      <Table locale={locale} columns={columns} dataSource={tableData} pagination={false} loading={isLoading} />
      <SupportModal description={'Please contact us for help with editing this connection.'} />
    </div>
  )
}
