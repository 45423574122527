import type { Elements } from 'react-flow-renderer'

export function mergeArraysWithoutDuples(initialElements: Elements, updatedNodes: Elements): Elements {
  const merged = [...initialElements]
  for (let i = 0; i < merged.length; i++) {
    for (let j = 0; j < updatedNodes.length; j++) {
      if (merged[i].id === updatedNodes[j].id) {
        merged.splice(i, 1, updatedNodes[j])
        break
      }
    }
  }
  return merged
}
