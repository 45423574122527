import { Row, Select, Table } from 'antd'
import { toJS } from 'mobx'
import { useEffect, useMemo, useRef, useState } from 'react'

import { Button, Heading } from 'components/common'
import { getColumnSearchProps } from 'pages/Motions/InternalOperationalStats/components/common'
import { columnSorter, exportToCSV } from 'pages/Motions/InternalOperationalStats/components/utils'
import { DateService } from 'services/Utils/date'

import type { InputRef } from 'antd'
import type { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface'

import type {
  InternalMotionExecutionOperationalStats,
  MotionExecutionStatuses,
  MotionExecutionStatusesItem,
} from 'models/motion.model'

interface ExecutionStatusProps {
  executionOperationalStats: InternalMotionExecutionOperationalStats | null
}

const getItemsByExecutionId = (motionExecutionStatuses: MotionExecutionStatuses[], id: string) => {
  const executionStatus = motionExecutionStatuses.find((status) => status[id] !== undefined)

  return executionStatus ? executionStatus[id].Items : []
}

const ExecutionStatus = ({ executionOperationalStats }: ExecutionStatusProps) => {
  const hasMultiExecutions =
    executionOperationalStats?.journeyExecutionStatuses &&
    executionOperationalStats?.journeyExecutionStatuses.length > 1
  const searchInputExecutionStatuses = useRef<InputRef>(null)

  const [executionsStatus, setExecutionsStatus] = useState<MotionExecutionStatusesItem[]>([])
  const [currentlySelectedExecutionId, setCurrentlySelectedExecutionId] = useState<null | string>(null)

  useEffect(() => {
    if (executionOperationalStats !== null) {
      const executionIds = executionOperationalStats?.journeyExecutionStatuses.map(
        (motionExecutionStatus) => Object.keys(motionExecutionStatus)[0],
      )

      const currentExecutionId = currentlySelectedExecutionId ?? executionIds[0]
      setCurrentlySelectedExecutionId(currentExecutionId)
      const items = getItemsByExecutionId(executionOperationalStats?.journeyExecutionStatuses, currentExecutionId) ?? []
      setExecutionsStatus(items)
    }
  }, [executionOperationalStats, currentlySelectedExecutionId])

  const handleReset = (clearFilters: () => void, confirm: (params: FilterConfirmProps) => void) => {
    clearFilters()
    confirm({ closeDropdown: false })
  }

  const columnsExecutionStatus = useMemo(
    (): ColumnType<MotionExecutionStatusesItem>[] => [
      {
        title: 'Instance ID',
        dataIndex: 'instanceId',
        key: 'instanceId',
        sorter: (a, b) => columnSorter(a, b, 'instanceId'),
        ...getColumnSearchProps('instanceId', searchInputExecutionStatuses, handleReset),
      },
      {
        title: 'Account ID',
        dataIndex: 'accountId',
        key: 'accountId',
        sorter: (a, b) => columnSorter(a, b, 'accountId'),
        ...getColumnSearchProps('accountId', searchInputExecutionStatuses, handleReset),
      },
      {
        title: 'User ID',
        dataIndex: 'userId',
        key: 'userId',
        sorter: (a, b) => columnSorter(a, b, 'userId'),
        ...getColumnSearchProps('userId', searchInputExecutionStatuses, handleReset),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => columnSorter(a, b, 'status'),
        ...getColumnSearchProps('status', searchInputExecutionStatuses, handleReset),
      },
      {
        title: 'Action ID',
        dataIndex: 'actionId',
        key: 'actionId',
        sorter: (a, b) => columnSorter(a, b, 'timestamp'),
        ...getColumnSearchProps('actionId', searchInputExecutionStatuses, handleReset),
      },
      {
        title: '🕑 Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        sorter: (a, b) => columnSorter(a, b, 'timestamp'),
        render: (_: unknown, record) =>
          `${DateService.parseDateTimestamp(record.timestamp, {
            showTime: true,
          })}`,
      },
    ],
    [],
  )

  const handleChangeExecutionStatus = (value: string) => {
    setCurrentlySelectedExecutionId(value)
  }

  const orderedColumns = ['instanceId', 'accountId', 'userId', 'status', 'actionId', 'timestamp']
  const handleExportOnClickHandler = () => {
    const executionStatusFieldValues = executionsStatus.map((status) => {
      return Object.entries(status)
        .map(([key, value]) => {
          if (orderedColumns.includes(key)) {
            if (value === '' || value === null || value === undefined) {
              return { [key]: '' }
            }

            return { [key]: value as string }
          } else {
            return null
          }
        })
        .filter((datapoint) => datapoint !== null)
    })

    const flattenedArray = executionStatusFieldValues.map((value) => {
      return value
        .sort((a, b) => {
          return (
            orderedColumns.findIndex((key) => key in (a ?? {})) - orderedColumns.findIndex((key) => key in (b ?? {}))
          )
        })
        .map((individualProperty) => Object.values(individualProperty ?? {}) as [string, string])
    })

    exportToCSV(toJS([orderedColumns, ...flattenedArray]), 'ExecutionStatus')
  }

  return (
    <section className='motion-details-header'>
      <div className='internal-operational-stats-heading-container'>
        <Heading variant='7' level='2'>
          Motion Execution Statuses
        </Heading>

        <Button
          text='Export Contents to CSV'
          onClickHandler={handleExportOnClickHandler}
          disabled={executionsStatus.length === 0}
        />
      </div>

      {hasMultiExecutions && (
        <Row>
          <Select
            value={currentlySelectedExecutionId}
            className='execution-select'
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            onChange={handleChangeExecutionStatus}
            options={executionOperationalStats?.journeyExecutions.map((executionDetails) => {
              return {
                value: executionDetails.executionId,
                label: `${executionDetails.executionId} 🕑 ${DateService.parseDateTimestamp(
                  executionDetails.timestamp,
                  {
                    showTime: true,
                  },
                )}`,
              }
            })}
          />
        </Row>
      )}
      <Table dataSource={executionsStatus} columns={columnsExecutionStatus} rowKey='timestamp' />
    </section>
  )
}

export default ExecutionStatus
