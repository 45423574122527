import { getEdgeCenter } from 'react-flow-renderer'

interface LoopPath {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  horizontalLine: number
}
export function getLoopPath(options: LoopPath) {
  const { sourceX, sourceY, targetX, targetY, horizontalLine } = options
  const verticalLine = (sourceY - targetY) / 2
  const centerY = getEdgeCenter({ sourceX, sourceY, targetX, targetY })[1]
  let firstCornerPath = null
  let secondCornerPath = null

  if (Math.round(sourceX) <= Math.round(targetX)) {
    firstCornerPath =
      sourceY <= targetY
        ? bottomLeftCorner(sourceX, centerY, horizontalLine, verticalLine)
        : topLeftCorner(sourceX, centerY, horizontalLine, verticalLine)
    secondCornerPath =
      sourceY <= targetY
        ? rightTopCorner(targetX, centerY, horizontalLine, verticalLine)
        : rightBottomCorner(targetX, centerY, horizontalLine, verticalLine)
  } else {
    console.error('left arrows are not supported')
  }

  const path = `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`
  return path
}

// These are some helper methods for drawing the round corners
// The name indicates the direction of the path. "bottomLeftCorner" goes
// from bottom to the left
// horizontal is the width of the horizontal line of the loop edge
// vertical is the height of the vertical line of the loop edge
const bottomLeftCorner = (x: number, y: number, horizontal: number, vertical: number): string => {
  return `L ${x},${y + vertical}Q ${x + horizontal},${y + vertical} ${x + horizontal},${y + vertical}`
}

const rightBottomCorner = (x: number, y: number, horizontal: number, vertical: number): string => {
  return `L ${x + horizontal},${y + vertical}Q ${x + horizontal},${y} ${x + horizontal},${y - vertical}`
}

const topLeftCorner = (x: number, y: number, horizontal: number, vertical: number): string => {
  return `L ${x},${y + vertical}Q ${x + horizontal},${y + vertical} ${x + horizontal},${y + vertical}`
}

const rightTopCorner = (x: number, y: number, horizontal: number, vertical: number): string => {
  return `L ${x + horizontal},${y + vertical}Q ${x + horizontal},${y} ${x + horizontal},${y - vertical}`
}
