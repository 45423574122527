import { useMemo } from 'react'

import { getInsightHeading } from 'components/Insights/components/InsightRow/utils'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { MlMetricCategoryEnum } from 'models/account.model'
import type { InsightDriverSegmentV2, InsightDriverV2 } from 'models/insights'
import { DataFormatEnum, InsightDriverTypeEnum } from 'models/insights'

interface InsightContentHeadingProps {
  insight: InsightDriverV2
  insightType: MlMetricCategoryEnum
  isRevenueQuantified?: boolean
  insightSegment: InsightDriverSegmentV2
}

const InsightContentHeading = ({
  insight,
  insightType,
  isRevenueQuantified,
  insightSegment,
}: InsightContentHeadingProps) => {
  const { account_count } = insightSegment
  const { improve_from_text, improve_to_text } = insightSegment?.propensity ?? {}
  const { elift_improve_from, elift_improve_by } = insightSegment?.revenue ?? {}

  const insightTextPreffix = insightType === MlMetricCategoryEnum.Churn ? 'Save' : 'Grow'

  const insightHeading = useMemo(() => {
    return `${getInsightHeading(insightType)} from `
  }, [insightType])

  if (elift_improve_by === undefined) {
    // display the deprecate way
    return (
      <h3 data-testid='insight-row-insight-content-heading' className='insights-content-heading'>
        {insightHeading}
        <span className='insights-content-heading--bold'>{improve_from_text} </span>
        to <span className='insights-content-heading--bold'>{improve_to_text} </span>
        of <span className='insights-content-heading--bold'>{account_count.toLocaleString()} </span>
        accounts <span className='insights-content-heading--light'>by improving</span>
      </h3>
    )
  } else if (isRevenueQuantified === false || insight.driver_type === InsightDriverTypeEnum.Metric) {
    // the text when can't be calculated the impact
    const arrSuffix =
      insightType === MlMetricCategoryEnum.Churn
        ? 'At-Risk'
        : insightType.charAt(0).toUpperCase() + insightType.slice(1)

    return (
      <h3 data-testid='insight-row-insight-content-heading' className='insights-content-heading'>
        Address <span className='insights-content-heading--bold'>{account_count.toLocaleString()} </span>
        accounts with{' '}
        <span className='insights-content-heading--bold'>
          {formatNumber({ number: elift_improve_from, format: DataFormatEnum.CurrencyKMB, decimal: 1 })} ARR {arrSuffix}
        </span>{' '}
        by impacting:
      </h3>
    )
  }

  // default view new way
  return (
    <h3 data-testid='insight-row-insight-content-heading' className='insights-content-heading'>
      {`${insightTextPreffix} `}
      <span className='insights-content-heading--bold'>
        {formatNumber({ number: elift_improve_by, format: DataFormatEnum.CurrencyKMB, decimal: 1 })} in ARR{' '}
      </span>
      in potential {insightType} from{' '}
      <span className='insights-content-heading--bold'>{account_count.toLocaleString()} </span>
      accounts by impacting:
    </h3>
  )
}

InsightContentHeading.displayName = 'InsightContentHeading'

export default InsightContentHeading
