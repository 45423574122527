import { CaretDownFilled } from '@ant-design/icons'
import { Empty, Select } from 'antd'

import { Heading } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import Chart from 'components/MotionDetails/MachineLearningMetric/Chart'

import type { MachineLearningMetrics } from 'models/reporting.model'

interface MachineLearningMetricProps {
  metrics?: MachineLearningMetrics
  timeFrame: number
  isEmpty: boolean
  onSelectionChange: (selectedTimeFrame: number) => void
}

const MachineLearningMetric = ({ metrics, timeFrame, isEmpty, onSelectionChange }: MachineLearningMetricProps) => {
  return (
    <section className='machine-learning-metric machine-learning-metric-container' id='machineLearningMetrics'>
      <div className='machine-learning-metric-heading'>
        <div className='machine-learning-metric-heading__wrapper'>
          <Heading variant='7' level='2'>
            {`Predictive Insight ${metrics?.title && `(Average ${metrics.title.toLowerCase()} trend)`}`}
          </Heading>
          <MockDataCallout />
        </div>

        {!isEmpty && (
          <Select
            suffixIcon={<CaretDownFilled />}
            labelInValue={false}
            defaultValue={timeFrame}
            style={{ width: 120 }}
            onChange={onSelectionChange}
            className='machine-learning-metric-heading-select'
            data-testid='machine-learning-metric-heading-select'>
            <Select.Option value={10}>10 weeks</Select.Option>
            <Select.Option value={20}>20 weeks</Select.Option>
          </Select>
        )}
      </div>
      {isEmpty ? <Empty /> : metrics && <Chart labels={metrics.labels} data={metrics.data} title={metrics.title} />}
    </section>
  )
}
MachineLearningMetric.displayName = 'MachineLearningMetric'

export default MachineLearningMetric
