import React from 'react'

import { DYNAMIC_INPUT, Tag } from 'components/common'
import { displayPayloadGroupsInline } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/CriteriaInlineText/CriteriaInlineText.utils'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'

import type { CreateActionFields, PayloadData } from 'models/motion/motionBuilder.model'

interface CriteriaInlineTextProps {
  payload: PayloadData
}

const CriteriaInlineText = ({ payload }: CriteriaInlineTextProps) => {
  const data = displayPayloadGroupsInline(payload)

  const getTextInDynamicInputValue = (value: string) => value.split(DYNAMIC_INPUT)

  return (
    <div className='display-inline'>
      <span className='display-inline__area' data-testid='display-inline__area'>
        {data.map((item, index: number) => {
          if (typeof item === 'string') {
            return (
              <span key={`${item}-${index}`} className='area area--groupOperator'>
                {item}
              </span>
            )
          } else if (item.bracket) {
            return (
              <span key={`bracket-${index}`} className={`area area--bracket ${item.open ? 'open' : 'closed'}`}>
                {item.bracket}
              </span>
            )
          } else {
            return (
              <React.Fragment key={`item-${index}`}>
                <span key={`field-${index}`} className='area area--field'>
                  {item.field}
                </span>
                <span key={`operator-${index}`} className='area area--operator'>
                  {item.operator}
                </span>
                {item.value && (item.value as CreateActionFields)?.platform ? (
                  <span key={`value-${index}`} className='area area--value'>
                    {getTextInDynamicInputValue((item.value as CreateActionFields)?.value as string)?.[0] || ''}
                    <Tag
                      icon={getMenuItemIcon({
                        name: (item.value as CreateActionFields).platform as string,
                        entityType: 'platform',
                      })}
                      text={(item.value as CreateActionFields)?.field as string}
                      title={`${(item.value as CreateActionFields)?.object}/${(item.value as CreateActionFields)?.field}`}
                      disabled
                    />
                    {getTextInDynamicInputValue((item.value as CreateActionFields)?.value as string)?.[1] || ''}
                  </span>
                ) : (
                  <span key={`value-${index}`} className='area area--value'>
                    {item.value?.toString() || 'NO_VALUE'}
                  </span>
                )}
              </React.Fragment>
            )
          }
        })}
      </span>
    </div>
  )
}
CriteriaInlineText.displayName = 'CriteriaInlineText'

export default CriteriaInlineText
