import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { useStoreState } from 'react-flow-renderer'

import { Button, IconPlus, IconTrash } from 'components/common'
import SelectTarget from 'components/common/SelectTarget'
import { unmarkTargetNodes } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import { getMarkedStatusMergeNodes, unsetMerge } from 'services/Utils/merge/MergeAction.utils'
import { clone } from 'services/Utils/misc'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'
import type { Node } from 'react-flow-renderer'

import type { ConfigPanelPayload, MergePayload, SegmentBuilderData } from 'models/motion/motionBuilder.model'

const initialMergeTarget = {
  isActive: false,
}

const initialPayload: MergePayload = {
  targets: [],
}

interface MergePanelProps {
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const MergePanel = observer(({ setPayload }: MergePanelProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const currentNodeId = segmentBuilderData.nodeId
  const nodes: Node<SegmentBuilderData>[] = useStoreState((state) => state.nodes) as Node<SegmentBuilderData>[]
  const edges = useStoreState((state) => state.edges)
  const { setElements } = segmentBuilderData

  const { mergePanelStore, motionStore } = useStore()
  const { merges, setMerge, setIsSelectingTarget, isSelectingTarget } = mergePanelStore
  const currentMergeTargets = merges.get(currentNodeId)

  useEffect(() => {
    // init merge node
    if (Object.keys(segmentBuilderData.payload).length && !currentMergeTargets) {
      setPayload({ ...initialPayload, ...clone(segmentBuilderData.payload) })
    } else {
      setPayload({ ...clone(segmentBuilderData.payload), ...initialPayload })
    }

    if (!currentMergeTargets?.length) {
      // init merge store
      const dslTargets = clone(segmentBuilderData.payload)?.targets?.map((targetId: string) => ({
        ...initialMergeTarget,
        nodeId: targetId,
      }))
      if (segmentBuilderData.payload?.targets?.length) {
        return setMerge(currentNodeId, dslTargets)
      }
      setMerge(currentNodeId, [initialMergeTarget])
    }
  }, [])

  useEffect(() => {
    if (!currentMergeTargets?.length && !segmentBuilderData.payload?.targets?.length) {
      setMerge(currentNodeId, [initialMergeTarget])
    }
  }, [currentMergeTargets?.length])

  if (!currentMergeTargets) return null

  const displayTrashButton = currentMergeTargets.length > 1 && !isSelectingTarget

  const handleChooseAction = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    e.preventDefault()

    mergePanelStore.setCurrentSourceMergeId(currentNodeId)
    const thisAction = { ...currentMergeTargets[index] }
    thisAction.isActive = !thisAction.isActive

    if (thisAction.isActive) {
      thisAction.actionText = 'Choose a branch end point'
    } else {
      thisAction.actionText = ''
    }

    mergePanelStore.setMerge(
      currentNodeId,
      currentMergeTargets.map((target, targetIndex) => (targetIndex === index ? thisAction : target)),
    )
    const currentNode = nodes.find((element) => element.id === currentNodeId)
    if (!currentNode) {
      return
    }

    // Display valid/invalid targets
    const markedNodes = getMarkedStatusMergeNodes({
      elements: [...nodes, ...edges],
      mergeId: currentNodeId,
    })

    setIsSelectingTarget(!isSelectingTarget)

    // toggle potential targets
    if (!isSelectingTarget) {
      setElements([...markedNodes, ...edges])
    } else {
      const cleanElements = unmarkTargetNodes(nodes)
      setElements([...cleanElements, ...edges])
    }
  }

  const handleRemoveMergeTarget = (e: React.MouseEvent<HTMLOrSVGElement>, index: number) => {
    e.preventDefault()
    if (currentMergeTargets) {
      const nodeIdToRemoveMerge = currentMergeTargets[index].nodeId

      if (nodeIdToRemoveMerge) {
        // cleanup elements state and merge payload
        const elmenentsAfterRemovedMerge = unsetMerge([...nodes, ...edges], nodeIdToRemoveMerge)
        setElements(elmenentsAfterRemovedMerge)
        setPayload((prevPayload) => ({
          ...prevPayload,
          targets: prevPayload.targets.filter((targetId) => targetId !== nodeIdToRemoveMerge),
        }))

        // cleanup store state
        mergePanelStore.setMerge(
          currentNodeId,
          currentMergeTargets.map((target) => {
            if (target.nodeId === nodeIdToRemoveMerge) {
              delete target.nodeId
              delete target.actionText
            }
            return target
          }),
        )
      }
    }
  }

  const handleAddMergeField = () => {
    setMerge(currentNodeId, [...currentMergeTargets, { ...initialMergeTarget }])
  }

  const handleRemoveMergeField = (e: React.MouseEvent<HTMLOrSVGElement>, index: number) => {
    handleRemoveMergeTarget(e, index)
    setMerge(
      currentNodeId,
      currentMergeTargets.filter((_, targetIndex) => targetIndex !== index),
    )
  }

  const getNodeById = (id?: string) => {
    const targetNode = nodes.find((node) => node.id === id)
    return targetNode
  }

  return (
    <Layout className='merge-panel'>
      <span className='select-target__title'>Merge from</span>
      {currentMergeTargets?.map((target, index) => {
        const node = getNodeById(target.nodeId)
        const actionText = node?.data?.description || node?.data?.name || target.actionText || ''
        return (
          <div key={index} className='merge-selection'>
            <SelectTarget
              isActive={target.isActive}
              hasTarget={!!target.nodeId}
              iconName={node?.data?.iconName ?? node?.data?.action}
              actionText={actionText}
              onClickHandler={(e) => {
                handleChooseAction(e, index)
              }}
              onClickRemoveHandler={(e) => {
                handleRemoveMergeTarget(e, index)
              }}
              testIdAction='merge-target'
              testIdContainer='merge-container'
              isDisabled={motionStore.isSegmentBuilderEditDisabled}></SelectTarget>

            <div className='btn-placeholder'>
              {displayTrashButton && (
                <Button
                  text=''
                  type='secondary'
                  link
                  testId='delete-target'
                  icon={{ element: <IconTrash /> }}
                  onClickHandler={(e) => {
                    handleRemoveMergeField(e, index)
                  }}
                />
              )}
            </div>
          </div>
        )
      })}

      <div className='add-branch'>
        <Button
          link
          size={'L'}
          type='primary'
          text='Add another branch'
          testId='add-branch'
          onClickHandler={handleAddMergeField}
          icon={{ element: <IconPlus /> }}
          disabled={motionStore.isSegmentBuilderEditDisabled}></Button>
      </div>
    </Layout>
  )
})

export default MergePanel
