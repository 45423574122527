import { InputNumber } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { IconArrowTop, IconCheckMark, IconTarget } from 'components/common/Icons/Icons'
import * as utils from 'pages/Motions/MotionGoalsModal/index.utils'
import useStore from 'store/useStore'

import type { GoalType } from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

const MotionGoals = observer(() => {
  const { motionGoalsStore } = useStore()
  const {
    motionGoal,
    setGoalProbability,
    setGoalType,
    setEmptyGoal,
    currentMotionGoals: { currState },
  } = motionGoalsStore
  const [activeGoal, setActiveGoal] = useState<number | null>(null)

  const isMotionStateInDraft = currState === MotionStateEnum.Draft

  useEffect(() => {
    const goalIndex = utils.goals.findIndex((goal) => goal.type === motionGoal.type)

    setActiveGoal(goalIndex === -1 ? null : goalIndex)
  }, [motionGoal])

  const handleGoalClick = (index: number) => {
    if (activeGoal === index) {
      setActiveGoal(null)
      setEmptyGoal()
    } else {
      setActiveGoal(index)
      setGoalType(utils.goals[index].type as GoalType)
    }
  }

  return (
    <div className='track-goal' data-testid='track-goal'>
      <label>{utils.motionGoalsMock.goalsTitle}</label>
      <div className='track-goal__container'>
        {utils.goals.map((goal, index) => (
          <span
            key={goal.label}
            data-testid='goal-container'
            className={classNames('goal-container', {
              'goal-container--active': index === activeGoal,
              disabled: !isMotionStateInDraft,
            })}
            onClick={() => isMotionStateInDraft && handleGoalClick(index)}>
            <label>{goal.label}</label>
            <span className='goal-container--checkbox'>{index === activeGoal && <IconCheckMark />}</span>
          </span>
        ))}
      </div>

      <div
        className={classNames('track-goal__target', { 'track-goal__target--empty': activeGoal === null })}
        data-testid='track-goal__target'>
        {(activeGoal || activeGoal === 0) && (
          <>
            <IconTarget /> Target: <span className='track-goal__target--name'>{utils.goals[activeGoal].label}</span>{' '}
            {utils.goals[activeGoal].goal} to be
            <InputNumber
              key='goal-probability'
              className='track-goal__target--input'
              controls={{
                upIcon: <IconArrowTop data-testid='inputUpIcon' />,
                downIcon: <IconArrowTop data-testid='inputDownIcon' />,
              }}
              min={0}
              max={100}
              step={5}
              precision={0}
              bordered={false}
              value={motionGoal?.value || 0}
              formatter={(value) => `${value}%`}
              onChange={setGoalProbability}
              disabled={!isMotionStateInDraft}
            />
          </>
        )}
      </div>
    </div>
  )
})
MotionGoals.displayName = 'MotionGoals'

export default MotionGoals
