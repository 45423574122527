import { Popover } from 'antd'
import classNames from 'classnames'

import Logo from 'components/common/Logo'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import { emptyTenantMotionTotals, sortByTopActions } from 'components/MotionObservability/utils'

import { DataFormatEnum } from 'models/insights'
import type { TenantActionTotalsIntegrations } from 'models/observability.model'

interface TopActionsProps {
  integrations: TenantActionTotalsIntegrations
  hasAnyActionsRun: boolean
}

const TopActions = ({ integrations, hasAnyActionsRun }: TopActionsProps) => {
  const topActions = sortByTopActions(hasAnyActionsRun ? integrations : emptyTenantMotionTotals.integrations)

  return (
    <div className='top-actions' data-testid='top-actions'>
      <div className='top-actions__header'>
        <h6>Top Actions</h6>
      </div>

      <div className='top-actions__content'>
        <div
          className={classNames('top-actions__chart', {
            [`top-actions__chart--empty`]: !hasAnyActionsRun,
          })}>
          {topActions.map((topAction, index) => {
            const maxActionTotal = topActions[0].total
            const barWidth = hasAnyActionsRun ? (topAction.total / maxActionTotal) * 100 : 100

            return (
              <Popover
                key={topAction.name}
                overlayClassName='motion-observability__popover'
                placement='bottom'
                open={hasAnyActionsRun ? undefined : false}
                title={
                  <div className='motion-observability__popover__header'>
                    <div
                      className={classNames('motion-observability__popover__header__fill', {
                        [`bg-primary-${index + 1}`]: true,
                      })}
                    />
                    <h6>{topAction.platform}</h6>
                  </div>
                }
                content={
                  <div className='motion-observability__popover__content' style={{ marginLeft: '20px' }}>
                    <p>
                      {topAction.name}:{' '}
                      {formatNumber({ number: topAction.total, format: DataFormatEnum.Kmb, decimal: 1 })}
                    </p>
                  </div>
                }>
                <div key={topAction.name} className='top-actions__chart__item'>
                  {hasAnyActionsRun && (
                    <div className='top-actions__chart__item__heading'>
                      <div className='top-actions__chart__item__heading__left'>
                        <Logo platformName={topAction.platform} bordered={false} />
                        <p>{topAction.name}</p>
                      </div>
                      <div className='top-actions__chart__item__heading__right'>
                        <p>
                          {formatNumber({
                            number: topAction.total,
                            format: DataFormatEnum.Kmb,
                            decimal: 1,
                          })}
                        </p>
                      </div>
                    </div>
                  )}

                  <div
                    className={classNames('top-actions__chart__item__bar', {
                      [`top-actions__chart__item__bar--empty`]: !hasAnyActionsRun,
                    })}
                    style={{ width: `${barWidth}%` }}>
                    <div
                      data-testid='top-actions-bar'
                      className={classNames('top-actions__chart__item__bar__fill', {
                        [`bg-primary-${index + 1}`]: true,
                        [`bg-empty`]: !hasAnyActionsRun,
                      })}></div>
                  </div>
                </div>
              </Popover>
            )
          })}
        </div>
      </div>
    </div>
  )
}
TopActions.displayName = 'TopActions'

export default TopActions
