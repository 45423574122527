import type { Level } from 'models/dashboard.model'
import { type Impact } from 'models/insights'

/**
  By convention established with the ML,
  Subsegment prediction bucket of the target.  0, 1, and 2 indicate “Low”, “Medium” and “High” correspondingly.
  But there are cases when the style needs to be reversed, cases like churn when low is a good thing
*/
export const calculateClassName = (value: Level | string, impact: Impact | undefined, columnKey: string) => {
  switch (value) {
    case 0:
    case 'negative':
    case 'low':
      return `cell--low ${impact ? `impact-${impact}` : ''} ${columnKey}`
    case 1:
    case 'medium':
      return `cell--medium ${impact ? `impact-${impact}` : ''} ${columnKey}`
    case 2:
    case 'positive':
    case 'high':
      return `cell--high ${impact ? `impact-${impact}` : ''} ${columnKey}`
    default:
      return ''
  }
}

export const getPredictionValue = (value: Level | string) => {
  switch (value) {
    case 0:
    case 'low':
      return 'Low'
    case 1:
    case 'medium':
      return 'Medium'
    case 2:
    case 'high':
      return 'High'

    default:
      return 'N/A'
  }
}
