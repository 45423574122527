import { createContext } from 'react'

import type { ReactNode } from 'react'

import type { SegmentBuilderData } from 'models/motion/motionBuilder.model'
import type { ABExperimentSegmentSizes, ABExperimentVariant } from 'models/motion.model'

import type { Elements } from 'react-flow-renderer/dist/types'

export interface SegmentBuilderStore {
  elements: Elements
  setElements: React.Dispatch<React.SetStateAction<Elements>>
  setElementsWithOptimizedPositions: (elements: Elements) => void
  segmentBuilderData: SegmentBuilderData
  setSegmentBuilderData: React.Dispatch<React.SetStateAction<SegmentBuilderData>>
  experimentElements: Elements
  setExperimentElements: React.Dispatch<React.SetStateAction<Elements>>
  isABSplitScreenEnabled: () => boolean
  isABExperimentModeEnabled: boolean
  isEditingVariantA: boolean
  isEditingVariantB: boolean
  selectedExperimentSegementSizes: ABExperimentSegmentSizes
  setShowExperimentModal: React.Dispatch<React.SetStateAction<boolean>>
  editExperimentVariant: (variant: ABExperimentVariant) => void
  reset: boolean
  setReset: React.Dispatch<React.SetStateAction<boolean>>
}

export interface SegmentBuilderProviderProps {
  store: SegmentBuilderStore
  children: ReactNode
}

export const SegmentBuilderContext = createContext<SegmentBuilderStore>({} as SegmentBuilderStore)

export const SegmentBuilderProvider = ({ store, children }: SegmentBuilderProviderProps) => {
  return <SegmentBuilderContext.Provider value={store}>{children}</SegmentBuilderContext.Provider>
}
