import { getConnectedEdges, getIncomers, getOutgoers, isNode } from 'react-flow-renderer'

import { mergeArraysWithoutDuples } from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils.common'
import {
  getLoopEdgeId,
  loopArrowEdge,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import {
  getChildNodes,
  getElementById,
  getSiblingsYAxisNodes,
  sortNodeByPositionY,
} from 'components/MotionTarget/motionTarget.utils'
import { generateEndNodeEdgePair } from 'services/Utils/dslConversion/dslToReactFlow/dslToReactFlow.utils'
import { unsetMerge } from 'services/Utils/merge/MergeAction.utils'
import { clone } from 'services/Utils/misc'
import { getElementsMergeValidated, elementsSeparator } from 'services/Utils/motionHelpers/motionHelpers.utils'

import type { Edge, Elements, Node } from 'react-flow-renderer'

import type { ActionsItems } from 'models/actions.model'
import type { Action, ConfigPanelPayload, Groups, SegmentBuilderData } from 'models/motion/motionBuilder.model'
import type { NodePayload } from 'models/motion.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'
import type {
  SegmentReportingItems,
  TenantInMotionReportingTotals,
  TenantInMotionReportingTotalsNode,
} from 'models/observability.model'

export function getElementsAfterDeleteNode(nodeId: string, nodes: Node[], allEdges: Edge[]): Elements {
  // Get the node that needs to be removed
  const node: Node<SegmentBuilderData> = nodes.find((node) => node.id === nodeId) as Node<SegmentBuilderData>

  if (!node) {
    return [...nodes, ...allEdges]
  }

  if (isBranchNode(node)) {
    const elementsAfterBranchRemove = getElementsAfterRemoveBranch({
      nodes,
      currentNode: node,
      allEdges,
    })

    return getElementsMergeValidated(elementsAfterBranchRemove)
  }

  if (isConnectedLoopNode(node)) {
    return getElementsAfterRemoveLoop({
      currentNode: node,
      nodes,
      allEdges,
    })
  }

  if (isLoopTarget(node.data as SegmentBuilderData)) {
    return getElementsAfterDeleteTarget({
      nodes,
      currentNode: node,
      allEdges,
    })
  }

  if (isNodeMerged({ edges: allEdges, nodes, currentNode: node })) {
    const targetEdge = allEdges.find((edge) => edge.source === nodeId && edge?.type !== NodeTypeEnum.Loop)
    const sourceEdge = allEdges.find((edge) => edge.target === nodeId && edge?.type !== NodeTypeEnum.Loop)
    const targetNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => targetEdge?.target === node.id)
    const sourceNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => sourceEdge?.source === node.id)
    if (targetEdge && targetNode && sourceEdge && sourceNode) {
      if (sourceNode.data!.type === NodeTypeEnum.Branch && sourceNode.position.x !== node.position.x) {
        /* If the source node of the deleted node has type branch
         Unset the node which is merged */
        const updatedElements = unsetMerge([...nodes, ...allEdges], nodeId)
        const elements = elementsSeparator<
          SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
          { edgeLabel: string; edgeType: NodeTypeEnum }
        >(updatedElements)

        return [
          ...getNodesAfterDeleteNode(updatedElements, nodeId),
          ...getEdgesAfterDeleteNode(nodeId, elements.nodes, elements.edges),
        ]
      }
    }
  }

  const finalNodes = getNodesAfterDeleteNode([...nodes, ...allEdges], nodeId)
  const finalEdges = getEdgesAfterDeleteNode(nodeId, nodes, allEdges)

  return [...finalNodes, ...finalEdges]
}

interface DeleteNode {
  nodes: Node[]
  allEdges: Edge[]
  currentNode: Node<SegmentBuilderData>
}

export function getElementsAfterDeleteTarget({ currentNode, nodes, allEdges }: DeleteNode) {
  const loop: Node<SegmentBuilderData> = getElementById(
    nodes,
    currentNode.data!.loopSourceId,
  ) as Node<SegmentBuilderData>
  const loopPositionY = loop.position.y
  const targetPositionY = currentNode.position.y
  const isWhileDoLoop = loopPositionY < targetPositionY
  const nodesBetween = getNodesBetweenTwoIds({
    elements: [...nodes, ...allEdges],
    sourceId: currentNode.data!.loopSourceId as string,
    targetId: currentNode.id,
    isWhileDoLoop,
  })
  let nextTarget: Node<SegmentBuilderData> | undefined
  const targetAlternatives = [...nodesBetween].sort(sortNodeByPositionY)

  if (isWhileDoLoop) {
    nextTarget = targetAlternatives.pop()
  } else {
    nextTarget = targetAlternatives[0]
  }

  if (!nextTarget) {
    // If there aren't elements between source and target
    const elements = getElementsAfterDeleteNextTarget({
      currentNode: currentNode,
      allEdges,
      nodes,
    })

    return elements
  }

  const sourceId = currentNode.data!.loopSourceId as string
  const finalEdges = getEdgesAfterDeleteNode(currentNode.id, nodes, allEdges)
  const loopEdgesWithoutRemovedLoop = finalEdges.filter((edge) => edge.id !== getLoopEdgeId(sourceId, currentNode.id))
  const nodesWithoutRemoved = getNodesAfterDeleteNode([...nodes, ...allEdges], currentNode.id)
  const newLoopEdge = loopArrowEdge(loop.id, nextTarget.id)

  nextTarget.data!.loopSourceId = sourceId
  loop.data!.targetNodeId = nextTarget.id

  return [...nodesWithoutRemoved, ...loopEdgesWithoutRemovedLoop, newLoopEdge]
}

export function getElementsAfterDeleteNextTarget({ currentNode, allEdges, nodes }: DeleteNode) {
  const finalEdges = getEdgesAfterDeleteNode(currentNode.id, nodes, allEdges)
  const nodesWithoutRemoved = getNodesAfterDeleteNode([...nodes, ...allEdges], currentNode.id)
  const finalEdgesWithoutLoop = finalEdges.filter(
    (edge) => edge.id !== getLoopEdgeId(currentNode.data!.loopSourceId as string, currentNode.id),
  )
  const loopNode: Node<SegmentBuilderData> = getElementById(
    nodesWithoutRemoved,
    currentNode.data!.loopSourceId,
  ) as Node<SegmentBuilderData>
  // clear loop target node id
  delete loopNode?.data!.targetNodeId
  return [...nodesWithoutRemoved, ...finalEdgesWithoutLoop]
}

export function getElementsAfterRemoveLoop({ currentNode, nodes, allEdges }: DeleteNode) {
  const nodesWithoutRemoved = getNodesAfterDeleteNode([...nodes, ...allEdges], currentNode.id)
  const remainEdges = getEdgesAfterDeleteNode(currentNode.id, nodes, allEdges)
  const targetNodeId = currentNode.data!.targetNodeId
  const finalEdgesWithoutLoop = remainEdges.filter(
    (edge) => edge.id !== getLoopEdgeId(currentNode.id, targetNodeId as string),
  )
  const targetNode: Node<SegmentBuilderData> = getElementById(
    nodesWithoutRemoved,
    targetNodeId,
  ) as Node<SegmentBuilderData>
  // clear target source loop id
  delete targetNode?.data!.loopSourceId
  return [...nodesWithoutRemoved, ...finalEdgesWithoutLoop]
}

export function getElementsAfterRemoveBranch({ currentNode, nodes, allEdges }: DeleteNode) {
  // branch delete logic
  const node = nodes.find((node) => node.id === currentNode.id)
  if (!node) {
    return [...nodes, ...allEdges]
  }

  const { edges } = elementsSeparator<
    SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >([...nodes, ...allEdges])
  const elemenentsWithoutLoops = [...nodes, ...edges]
  const nodesWithoutRemoved = nodes.filter((node) => node.id !== currentNode.id)

  const childOfRemovedEl = getChildNodes(node, elemenentsWithoutLoops).sort((a, b) => a.position.y - b.position.y)

  const foundMergeChildOfBranch = childOfRemovedEl.find(
    (node: Node<SegmentBuilderData>) =>
      node.data!.type === NodeTypeEnum.Merge && node.position.x < currentNode.position.x,
  )

  let childOfMerge: Node[] = []
  if (foundMergeChildOfBranch) {
    // getting the child of a parent branch that is merge returns as child element the merge that is connected and its child nodes
    // the child of merges should be excluded from the list of removed elements
    childOfMerge = getChildNodes(foundMergeChildOfBranch, elemenentsWithoutLoops)
  }

  const { parentNode } = getSiblingsYAxisNodes(node, elemenentsWithoutLoops)

  const upperNodes = nodesWithoutRemoved.filter((node) => {
    const isChild = childOfRemovedEl.some((childNode) => {
      /* child node position needs to be equal or greather than
     in order to prevent removing merged nodes wich are on the left side of branch position */
      return (
        childNode.id === node.id && childNode.position.x >= currentNode.position.x && !childOfMerge.includes(childNode)
      )
    })
    return !isChild
  })

  const nodesToRemove = childOfRemovedEl.filter((node) => !upperNodes.includes(node))

  const edgesToRemove = getConnectedEdges([node], allEdges)
  nodesToRemove.forEach((node) => {
    const targetEdge = getConnectedEdges([node], allEdges)
    if (targetEdge) {
      edgesToRemove.push(...targetEdge)
    }
  })

  const edgesWithoutMergedNodes: Edge[] = []
  const outsideMergedNodes: Node[] = []
  const endEdgesAndNodes = [
    ...generateEndNodeEdgePair({
      position: parentNode.position,
      sourceId: parentNode.id,
      isNoBranch: parentNode.position.x < currentNode.position.x,
    }),
  ]

  edgesToRemove.forEach((edgeToRemove) => {
    const targetNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === edgeToRemove.target)
    const sourceNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === edgeToRemove.source)

    if (targetNode && sourceNode) {
      if (targetNode.position.x >= currentNode.position.x) {
        edgesWithoutMergedNodes.push(edgeToRemove)

        if (targetNode.data!.type === NodeTypeEnum.Merge) {
          if (targetNode.data?.payload?.targets?.length) {
            for (const target of targetNode.data.payload.targets) {
              const linkedNode = upperNodes.find((upperNode) => upperNode.id === target)

              if (linkedNode && outsideMergedNodes.indexOf(linkedNode) === -1) {
                outsideMergedNodes.push(linkedNode)
              }
            }
          }
        }
      } else if (sourceNode.position.x > targetNode.position.x) {
        const mergedNodeOutsideBranch = upperNodes.find((upperNode) => upperNode.id === sourceNode.id)
        // check if same merge node within branch deleted has other nodes merged from outside
        if (!mergedNodeOutsideBranch) {
          const targetIndex = targetNode.data?.payload?.targets?.indexOf(sourceNode.id) ?? -1
          if (targetIndex > -1) {
            targetNode.data!.payload.targets.splice(targetIndex, 1)
          }
          edgesWithoutMergedNodes.push(edgeToRemove)
        }
      }
    }
  })
  outsideMergedNodes.forEach((mergedNodeOutsideIf) => {
    endEdgesAndNodes.push(
      ...generateEndNodeEdgePair({ position: mergedNodeOutsideIf.position, sourceId: mergedNodeOutsideIf.id }),
    )
  })

  const edgesWithoutRemoved = allEdges.filter((val) => !edgesWithoutMergedNodes.includes(val))

  const updatedNodes = mergeArraysWithoutDuples(upperNodes, nodesWithoutRemoved)

  const finalResult = [...updatedNodes, ...edgesWithoutRemoved, ...endEdgesAndNodes]

  return finalResult
}

interface NodesBetweenTwoIdsProps {
  elements: Elements
  sourceId: string
  targetId: string
  isWhileDoLoop: boolean
}
export function getNodesBetweenTwoIds({
  elements,
  sourceId,
  targetId,
  isWhileDoLoop,
}: NodesBetweenTwoIdsProps): Node[] {
  const { edges, nodes } = elementsSeparator<
    SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const sourceNode = elements.find((node) => node.id === sourceId && isNode(node)) as Node
  const targetNode = elements.find((node) => node.id === targetId && isNode(node)) as Node

  const nodesBetween: Node[] = []

  const traverse = (traverseNodes: Node[], isWhileDoLoop: boolean) => {
    for (const node of traverseNodes) {
      if (node.id !== targetNode.id) {
        nodesBetween.push(node)
        if (isWhileDoLoop) {
          traverse(getOutgoers(node, [...edges, ...nodes]), isWhileDoLoop)
        } else {
          traverse(getIncomers(node, [...edges, ...nodes]), isWhileDoLoop)
        }
      }
    }
  }

  if (isWhileDoLoop) {
    traverse(getOutgoers(sourceNode, [...edges, ...nodes]), isWhileDoLoop)
  } else {
    traverse(getIncomers(sourceNode, [...edges, ...nodes]), isWhileDoLoop)
  }

  return nodesBetween
}

export function getNodesAfterDeleteNode(allElements: Elements, removedNodeId: string): Node[] {
  // Get the node that needs to be removed
  const { edges, nodes } = elementsSeparator<
    SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(allElements)
  const removedNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === removedNodeId)

  if (!removedNode) {
    return nodes
  }

  const parentNodeEdge: Edge<{ edgeType: NodeTypeEnum }> | undefined = edges.find(
    (edge: Edge<{ edgeType: NodeTypeEnum }>) =>
      edge.target === removedNodeId && edge.data!.edgeType !== NodeTypeEnum.Merge,
  )
  let removedNodeChildEdge: Edge<{ edgeType: NodeTypeEnum }> | undefined
  if (removedNode?.data?.type === NodeTypeEnum.Branch) {
    removedNodeChildEdge = edges.find(
      (edge: Edge<{ edgeType: NodeTypeEnum }>) =>
        edge.source === removedNode.id && edge.data!.edgeType === NodeTypeEnum.Merge,
    )
  } else {
    removedNodeChildEdge = edges.find((edge) => edge.source === removedNode.id)
  }

  const childNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === removedNodeChildEdge?.target)
  const parentNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === parentNodeEdge?.source)
  if (!parentNode) {
    return nodes
  }

  const nodesWithoutRemoved: Node[] = nodes.filter((node) => node.id !== removedNodeId)

  const removedElementIsMergeTarget =
    childNode?.data?.type === NodeTypeEnum.Merge && childNode?.position.x !== removedNode?.position.x

  if (removedElementIsMergeTarget) {
    // deleted node target of merge logic
    const targetNodeIndex = childNode?.data?.payload?.targets?.indexOf(removedNodeId) as number
    childNode.data!.payload.targets[targetNodeIndex] = parentNode?.id
  }

  return nodesWithoutRemoved
}

export function getEdgesAfterDeleteNode(removedNodeId: string, nodes: Node[], allEdges: Edge[]) {
  const removedNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === removedNodeId)
  if (!removedNode) {
    return allEdges
  }

  const { edges, loopEdges } = elementsSeparator<
    SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >([...nodes, ...clone(allEdges)])
  const elements = [...nodes, ...edges]

  const { childNode }: { childNode: Node<SegmentBuilderData> | undefined } = getSiblingsYAxisNodes(
    removedNode,
    elements,
  )
  // Get the edges to remove

  if (childNode.data?.type === NodeTypeEnum.Merge) {
    const edgeToRemove = edges.find((edge) => edge.target === removedNode.id)
    const edgeToUpdate = edges.find((edge) => edge.source === removedNode.id)
    const parentNode = nodes.find((node) => node.id === edgeToRemove?.source)

    if (edgeToRemove && edgeToUpdate && parentNode) {
      const edgesWithoutRemoved = edges.filter((edge) => edgeToRemove.id !== edge.id)
      edgeToUpdate.source = `${parentNode.id}`
      edgeToUpdate.id = `e${parentNode.id}-${childNode.id}`

      const finalEdges = [...edgesWithoutRemoved, ...loopEdges]
      return finalEdges
    }
  } else {
    const edgeToRemove = edges.find((edge) => edge.source === removedNode.id)
    const edgeToUpdate = edges.find((edge) => edge.target === removedNode.id)

    if (edgeToRemove && edgeToUpdate) {
      const edgesWithoutRemoved = edges.filter((edge) => edgeToRemove.id !== edge.id)
      edgeToUpdate.target = `${childNode.id}`
      edgeToUpdate.id = `e${edgeToUpdate.source}-${childNode.id}`
      const finalEdges = [...edgesWithoutRemoved, ...loopEdges]
      return finalEdges
    }
  }

  return allEdges
}

export function isBranchNode(node: Node<SegmentBuilderData>) {
  return node.data?.type === NodeTypeEnum.Branch
}
export function isConnectedLoopNode(node: Node<SegmentBuilderData>) {
  return !!(node.data?.type === NodeTypeEnum.Loop && node.data?.targetNodeId)
}

export function isLoopTarget(nodeData: SegmentBuilderData) {
  return !!nodeData?.loopSourceId
}

export function isNoBranch(node: Node<{ data: { edgeType: NodeTypeEnum; edgeLabel: string } }>) {
  return node?.data?.data?.edgeLabel === BranchLabelEnum.No
}

function isNodeMerged({ edges, nodes, currentNode }: { edges: Edge[]; nodes: Node[]; currentNode: Node }) {
  const targetEdge = edges.find((edge) => edge.source === currentNode.id)
  const targetNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => targetEdge?.target === node.id)

  if (targetNode?.data?.type === NodeTypeEnum.Merge && currentNode.position.x !== targetNode.position.x) {
    return true
  }

  return false
}

export function isElementDeleted(remainedElements: Elements, panelNodeId: string) {
  return !remainedElements.some((element) => element.id === panelNodeId)
}

export const getPlatformsToBeDisplayed = (nodePayload: ConfigPanelPayload): string[] => {
  const platforms: string[] = []

  const searchInGroup = (group: ConfigPanelPayload | Groups): void => {
    if (group.groups) {
      for (const element of group.groups) {
        if ('groups' in element) {
          return searchInGroup(element as unknown as Groups)
        } else if (!platforms.includes(element.platform)) {
          platforms.push(element.platform)
        }
      }
    }
  }

  if (nodePayload?.groups?.length) {
    searchInGroup(nodePayload)
  }

  if (nodePayload?.exitCondition?.groups?.length) {
    // add the platforms icon for loops
    searchInGroup(nodePayload?.exitCondition as ConfigPanelPayload)
  }

  return platforms
}

export const getActionPlatform = (data: SegmentBuilderData, actions: Action[] | ActionsItems[] | undefined) => {
  if (data.platform) return [data.platform]

  const response = actions?.find((action: Action | ActionsItems) => {
    if (action.actionName?.toLowerCase() === data.name.toLowerCase()) {
      return action
    }
    return false
  })

  if (response) {
    return [response.platform]
  }

  return []
}

export const getSegmentReportingData = (
  actionType: string,
  type: string,
  nodeId: string,
  tenantInMotionReportingTotals: TenantInMotionReportingTotals | null,
) => {
  if (!tenantInMotionReportingTotals) {
    return {}
  }

  let segmentId = ''
  if (actionType) {
    segmentId = actionType + nodeId
  } else {
    segmentId = type + nodeId
  }

  const segmentReportingNode = tenantInMotionReportingTotals.nodes.filter(
    (node: TenantInMotionReportingTotalsNode) => node.id === segmentId,
  )

  const segmentReportingData: SegmentReportingItems = {}

  if (segmentReportingNode.length > 0) {
    segmentReportingNode[0].statusTotals.forEach((statusTotal) => {
      segmentReportingData[statusTotal.status.toLowerCase()] = {
        total: statusTotal.total,
        percentageTotal: statusTotal.percentageTotal,
        segmentColor: getSegmentColor(segmentReportingNode[0].id, statusTotal.status.toLowerCase()),
      }
    })
  }

  return segmentReportingData
}

export const getSegmentColor = (segmentId: string, status: string) => {
  const destructiveStatuses = ['error', 'failed']
  if (destructiveStatuses.includes(status)) return 'destructive'

  const blue1Statuses = ['completed', 'yes']
  const secondaryPaletteSegmentId = 'ifelse'

  const segmentColorShade = blue1Statuses.includes(status) ? '1' : '2'
  const palette = segmentId.toLowerCase().startsWith(secondaryPaletteSegmentId) ? 'secondary' : 'primary'

  return `${palette}-${segmentColorShade}`
}
