const info = ({ message, data }: { message: string; data: any }) => {
  console.log(`${message} : `, data)
}

const error = ({ message, error }: { message: string; error: any }) => {
  console.error(`${message} : `, error)
}

export const LoggerService = {
  info,
  error,
}
