import { Skeleton } from 'antd'
import dayjs from 'dayjs'

import EmptyStateImage from 'assets/images/motion-list-observability-empty-graph.svg?react'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import HorizontalStackedChart from 'components/MotionObservability/components/HorizontalStackedChart'
import StatsSummary from 'components/MotionObservability/components/StatsSummary'
import VolumeChart from 'components/MotionObservability/components/VolumeChart'
import { getAverageActionsPerAccount, sortIntegrations } from 'components/MotionObservability/utils'

import { DataFormatEnum } from 'models/insights'
import type { TenantActionTotals } from 'models/observability.model'

interface CompletedActionsCardProps {
  tenantMotionsActionTotals: TenantActionTotals | null
  isLoading: boolean
}

const CompletedActionsCard = ({ tenantMotionsActionTotals, isLoading }: CompletedActionsCardProps) => {
  if (isLoading) {
    return (
      <div className='motion-observability__card' data-testid='completed-actions-card-loading'>
        <Skeleton active />
      </div>
    )
  }

  const sortedIntegrations = tenantMotionsActionTotals ? sortIntegrations(tenantMotionsActionTotals.integrations) : {}
  const hasAnyActionsRun = tenantMotionsActionTotals ? tenantMotionsActionTotals?.totals?.actions > 0 : false

  const chartData = tenantMotionsActionTotals?.graphDataPoints?.weekly.map((point) => point.total).reverse() || []
  const labels =
    tenantMotionsActionTotals?.graphDataPoints?.weekly.map(({ date }) => {
      return dayjs(date).format('MMM DD')
    }) || []

  return (
    <div className='motion-observability__card' data-testid='completed-actions-card'>
      <div className='motion-observability__card__content'>
        <HorizontalStackedChart
          title='Actions by Integration'
          items={sortedIntegrations}
          hasAnyData={hasAnyActionsRun}
        />
        <StatsSummary
          summaryItems={[
            {
              title: 'Average Actions per Account',
              value: getAverageActionsPerAccount(
                tenantMotionsActionTotals?.totals?.actions ?? 0,
                tenantMotionsActionTotals?.totals?.accounts ?? 0,
              ),
            },
            {
              title: 'Total Accounts',
              value: tenantMotionsActionTotals?.totals?.accounts ?? 0,
            },
          ]}
          hasAnyActionsRun={hasAnyActionsRun}
        />
        <div className='motion-observability__card__content__stat-total'>
          <h5>Completed Actions</h5>
          {hasAnyActionsRun ? (
            <h5>
              {formatNumber({
                number: tenantMotionsActionTotals?.totals.actions ?? 0,
                format: DataFormatEnum.Kmb,
                decimal: 1,
              })}
            </h5>
          ) : (
            <div className='motion-observability__card__value--empty' />
          )}
        </div>
        {hasAnyActionsRun ? (
          <VolumeChart title='Actions' labels={labels} chartData={chartData} chartColor='blue' />
        ) : (
          <EmptyStateImage />
        )}
      </div>
    </div>
  )
}
CompletedActionsCard.displayName = 'CompletedActionsCard'

export default CompletedActionsCard
