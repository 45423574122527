import ImageBriefcasePlus from 'assets/icons/briefcase_plus.svg'
import IconBriefcasePlus from 'assets/icons/briefcase_plus.svg?react'
import ImageCalendarPlus from 'assets/icons/calendar_plus.svg'
import IconCalendarPlus from 'assets/icons/calendar_plus.svg?react'
import ImageCrownPlus from 'assets/icons/crown_plus.svg'
import IconCrownPlus from 'assets/icons/crown_plus.svg?react'
import ImageCrownSpanner from 'assets/icons/crown_spanner.svg'
import IconCrownSpanner from 'assets/icons/crown_spanner.svg?react'
import ImageCtaCreated from 'assets/icons/cta_created.svg'
import IconCtaCreated from 'assets/icons/cta_created.svg?react'
import ImageMail from 'assets/icons/mail.svg'
import IconMail from 'assets/icons/mail.svg?react'
import ImageMessageBubble from 'assets/icons/messageBubble.svg'
import IconMessageBubble from 'assets/icons/messageBubble.svg?react'
import ImageCreateFiles from 'assets/icons/motionBuilder/actionsIcon/create_files.svg'
import IconCreateFiles from 'assets/icons/motionBuilder/actionsIcon/create_files.svg?react'
import IconLightBulb from 'assets/icons/motionBuilder/actionsIcon/light_bulb.svg?react'
import ImageBox from 'assets/icons/segmentBuilder/box.svg'
import IconBox from 'assets/icons/segmentBuilder/box.svg?react'
import ImageStars from 'assets/icons/stars.svg'
import IconStars from 'assets/icons/stars.svg?react'
import ImageUserPlus from 'assets/icons/user_plus.svg'
import IconUserPlus from 'assets/icons/user_plus.svg?react'
import ImageUserSpanner from 'assets/icons/user_spanner.svg'
import IconUserSpanner from 'assets/icons/user_spanner.svg?react'
import ImageUsers from 'assets/icons/users.svg'
import IconUsers from 'assets/icons/users.svg?react'
import ImageChorusai from 'assets/images/chorusai.svg'
import IconChorusai from 'assets/images/chorusai.svg?react'
import ImageGainsight from 'assets/images/gainsight.svg'
import IconGainsight from 'assets/images/gainsight.svg?react'
import ImageGenericEmail from 'assets/images/generic-email.svg'
import IconGenericEmail from 'assets/images/generic-email.svg?react'
import ImageGoogleAnalytics from 'assets/images/google_analytics.svg'
import IconGoogleAnalytics from 'assets/images/google_analytics.svg?react'
import ImageHubspot from 'assets/images/hubspot.svg'
import IconHubspot from 'assets/images/hubspot.svg?react'
import ImageIntercom from 'assets/images/intercom.svg'
import IconIntercom from 'assets/images/intercom.svg?react'
import ImageMagnify from 'assets/images/magnify-logomark-blue.svg'
import IconMagnify from 'assets/images/magnify-logomark-blue.svg?react'
import ImageMarketo from 'assets/images/marketo.svg'
import IconMarketo from 'assets/images/marketo.svg?react'
import ImagePendo from 'assets/images/pendo.svg'
import IconPendo from 'assets/images/pendo.svg?react'
import ImagePokemon from 'assets/images/pokemon-catalog.svg'
import IconPokemon from 'assets/images/pokemon-catalog.svg?react'
import ImageSalesforce from 'assets/images/salesforce.svg'
import IconSalesforce from 'assets/images/salesforce.svg?react'
import ImageSendGrid from 'assets/images/sendgrid.svg'
import IconSendGrid from 'assets/images/sendgrid.svg?react'
import ImageSftp from 'assets/images/sftp-bulk.svg'
import IconSftp from 'assets/images/sftp-bulk.svg?react'
import ImageSkilljar from 'assets/images/skilljar.svg'
import IconSkilljar from 'assets/images/skilljar.svg?react'
import ImageSlack from 'assets/images/slack.svg'
import IconSlack from 'assets/images/slack.svg?react'
import ImageSnowflake from 'assets/images/snowflake.svg'
import IconSnowflake from 'assets/images/snowflake.svg?react'
import ImageWalkMe from 'assets/images/walkme.svg'
import IconWalkMe from 'assets/images/walkme.svg?react'
import ImageZendesk from 'assets/images/zendesk.svg'
import IconZendesk from 'assets/images/zendesk.svg?react'
import ImageZuara from 'assets/images/zuara.svg'
import IconZuara from 'assets/images/zuara.svg?react'
import { OPERATIONAL_STATISTICS_METRICS } from 'components/MotionDetails/OperationalStats/utils'

function sortImages(name: string, svg?: boolean) {
  switch (name.toLowerCase()) {
    case 'salesforce':
      return svg ? <IconSalesforce /> : ImageSalesforce
    case 'sftp-bulk':
      return svg ? <IconSftp /> : ImageSftp
    case 'pokemon-catalog':
      return svg ? <IconPokemon /> : ImagePokemon
    case 'marketo':
      return svg ? <IconMarketo /> : ImageMarketo
    case 'pendo':
      return svg ? <IconPendo /> : ImagePendo
    case 'intercom':
      return svg ? <IconIntercom /> : ImageIntercom
    case 'gainsight':
      return svg ? <IconGainsight /> : ImageGainsight
    case 'skilljar':
      return svg ? <IconSkilljar /> : ImageSkilljar
    case 'zendesk':
      return svg ? <IconZendesk /> : ImageZendesk
    case 'walkme':
      return svg ? <IconWalkMe /> : ImageWalkMe
    case 'zuara':
      return svg ? <IconZuara /> : ImageZuara
    case 'google_analytics':
      return svg ? <IconGoogleAnalytics /> : ImageGoogleAnalytics
    case 'slack':
      return svg ? <IconSlack /> : ImageSlack
    case 'snowflake':
      return svg ? <IconSnowflake /> : ImageSnowflake
    case 'sendgrid':
      return svg ? <IconSendGrid /> : ImageSendGrid
    case 'magnify':
    case 'magnifyinsights':
    case 'magnify-insights':
      return svg ? <IconMagnify /> : ImageMagnify
    case 'hubspot':
      return svg ? <IconHubspot /> : ImageHubspot
    case 'chorusai':
      return svg ? <IconChorusai /> : ImageChorusai
    case 'email':
      return svg ? <IconGenericEmail /> : ImageGenericEmail
    default:
      // default icon
      return svg ? <IconBox /> : ImageBox
  }
}

function metricIcons(name: string, svg?: boolean) {
  switch (name) {
    case OPERATIONAL_STATISTICS_METRICS.JOURNEY:
      return svg ? <IconUserPlus /> : ImageUserPlus
    case 'users':
      return svg ? <IconUsers className='users' /> : ImageUsers
    case OPERATIONAL_STATISTICS_METRICS.HUBSPOT_SEND_EMAIL:
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL:
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_SEND_EMAIL:
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_OPEN_EMAIL:
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_CLICKED_LINK_EMAIL:
      return svg ? <IconMail /> : ImageMail
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_OPPORTUNITY:
    case OPERATIONAL_STATISTICS_METRICS.INTERCOM_CREATE_UPDATE_COMPANY:
      return svg ? <IconCrownPlus /> : ImageCrownPlus
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_UPDATE_OPPORTUNITY:
      return svg ? <IconCrownSpanner /> : ImageCrownSpanner
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_UPDATE_ACCOUNT:
      return svg ? <IconUserSpanner /> : ImageUserSpanner
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_CASE:
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_TASK:
      return svg ? <IconBriefcasePlus /> : ImageBriefcasePlus
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_CREATE_CTA:
      return svg ? <IconCtaCreated /> : ImageCtaCreated
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_CREATE_TIMELINE_EVENT:
      return svg ? <IconCalendarPlus /> : ImageCalendarPlus
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_TRIGGER_EVENT:
      return svg ? <IconStars /> : ImageStars
    case OPERATIONAL_STATISTICS_METRICS.SLACK_POST_TO_CHANNEL:
    case OPERATIONAL_STATISTICS_METRICS.SLACK_SEND_MESSAGE:
    case OPERATIONAL_STATISTICS_METRICS.PENDO_SEND_MESSAGE:
      return svg ? <IconMessageBubble /> : ImageMessageBubble
    case OPERATIONAL_STATISTICS_METRICS.PENDO_LAUNCH_GUIDE:
      return svg ? <IconLightBulb /> : IconLightBulb
    case OPERATIONAL_STATISTICS_METRICS.INTERCOM_CREATE_TICKET:
    case OPERATIONAL_STATISTICS_METRICS.ZENDESK_CREATE_TICKET:
      return svg ? <IconCreateFiles /> : ImageCreateFiles

    default:
      return svg ? <IconBox /> : ImageBox
  }
}

const getIcon = (name: string): string => {
  return sortImages(name) as string
}

const getSVGIcon = (name: string) => {
  return sortImages(name, true)
}

const getMetricIcon = (name: string) => {
  return metricIcons(name, true)
}

export const LogoService = {
  getIcon,
  getSVGIcon,
  getMetricIcon,
}
