import { Popover } from 'antd'
import classNames from 'classnames'
import { useMemo } from 'react'

import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { DataFormatEnum } from 'models/insights'
import type {
  ParticipantsByMotionStateData,
  SegmentReportingItem,
  TenantActionTotalsIntegrations,
} from 'models/observability.model'

interface HorizontalStackedChartProps {
  title?: string
  items: TenantActionTotalsIntegrations | ParticipantsByMotionStateData
  hasAnyData: boolean
  showLegend?: boolean
  barHeight?: 'sm' | 'default' | 'lg'
  className?: string
}

const HorizontalStackedChart = ({
  title,
  items,
  hasAnyData,
  showLegend = true,
  barHeight = 'default',
  className = '',
}: HorizontalStackedChartProps) => {
  const legend = useMemo(() => {
    if (!showLegend) return null
    return Object.entries(items).map(([key, value]: [string, SegmentReportingItem], index) => {
      const isOverFive = index > 4
      return (
        <div key={`legend-${key}-${index}`} className='horizontal-stacked-chart__legend__label'>
          <span
            className={classNames('horizontal-stacked-chart__legend__label__dot', {
              [`bg-secondary-${index + 1}`]: isOverFive && hasAnyData,
              [`bg-primary-${index + 1}`]: !isOverFive && hasAnyData,
              [`bg-${value.segmentColor}`]: value.segmentColor,
              'bg-empty': !hasAnyData,
            })}></span>
          {key}
        </div>
      )
    })
  }, [items, showLegend])

  const chart = useMemo(() => {
    return Object.entries(items).map(([key, value]: [string, SegmentReportingItem], index) => {
      const isOverFive = index > 4
      return (
        <Popover
          key={`chart-${key}-${index}`}
          overlayClassName='motion-observability__popover'
          placement='bottom'
          title={
            <div className='motion-observability__popover__header'>
              <div
                className={classNames('motion-observability__popover__header__fill', {
                  [`bg-secondary-${index + 1}`]: isOverFive,
                  [`bg-primary-${index + 1}`]: !isOverFive,
                  [`bg-${value.segmentColor}`]: value.segmentColor,
                })}
              />
              <h6>{key}</h6>
            </div>
          }
          content={
            <div className='motion-observability__popover__content' style={{ marginLeft: '20px' }}>
              <p>
                {formatNumber({ number: value.total, format: DataFormatEnum.Kmb, decimal: 1 })} (
                {Number(value.percentageTotal).toFixed(0)}%)
              </p>
            </div>
          }>
          <div
            className={classNames('horizontal-stacked-chart__bar__fill', {
              [`bg-secondary-${index + 1}`]: isOverFive,
              [`bg-primary-${index + 1}`]: !isOverFive,
              [`bg-${value.segmentColor}`]: value.segmentColor,
              [`horizontal-stacked-chart__bar__fill--${barHeight}`]: barHeight,
            })}
            style={{
              width: `${value.percentageTotal}%`,
            }}
          />
        </Popover>
      )
    })
  }, [items])

  return (
    <div
      className={classNames({
        'horizontal-stacked-chart': true,
        [className]: className,
      })}
      data-testid='horizontal-stacked-chart'>
      {title && (
        <div className='horizontal-stacked-chart__header'>
          <h6>
            {title} <span>(%)</span>
          </h6>
        </div>
      )}
      <div className='horizontal-stacked-chart__content'>
        {showLegend && <div className='horizontal-stacked-chart__legend'>{legend}</div>}
        <div className='horizontal-stacked-chart__bar'>
          {!hasAnyData ? <div className={'horizontal-stacked-chart__bar__fill--empty bg-empty'} /> : chart}
        </div>
      </div>
    </div>
  )
}
HorizontalStackedChart.displayName = 'HorizontalStackedChart'

export default HorizontalStackedChart
