import { observer } from 'mobx-react-lite'

import { Button } from 'components/common'
import { IconPlusCircle } from 'components/common/Icons/Icons'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import { PerceptionsIcon } from 'components/Navigation/NavigationIcons'

import type { Item } from 'models/motion/motionBuilder.model'

const MotionMetrics = observer(() => {
  return (
    <div className='track-metrics' data-testid='track-metrics'>
      <div className='track-metrics-flow-wrapper'>
        <div className='track-metrics-flow-dots'>
          <svg width='16' height='265' viewBox='0 0 16 265' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='8' cy='8' r='8' fill='#312EE0' />
            <circle cx='8' cy='257' r='8' fill='#767676' />
            <line x1='9' y1='14' x2='8.99999' y2='249' stroke='#312EE0' stroke-width='2' />
          </svg>
        </div>
        <div className='track-metrics-flow-content'>
          <label>Define segment criteria</label>
          <table className='track-metrics__table' data-testid='track-metrics__table'>
            <thead>
              <tr>
                <th>
                  <span>Aggregation</span>
                </th>
                <th>
                  <span>Select a data field</span>
                </th>
                <th>
                  <span>Criteria</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr data-testid='track-metrics__table--row'>
                <td className='data-field' data-testid='timespan' style={{ width: '168px' }}>
                  <FieldInput
                    item={{ type: 'select', value: 'Sum' } as Item}
                    range={false}
                    isAction={true}
                    changeFieldValue={(value) => {}}
                  />
                </td>

                <td className='data-field' data-testid='data-field'>
                  <div
                    className='fake-field'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '56px',
                      padding: '14px 18px',
                      width: '336px',
                      textAlign: 'left',
                      border: '1px solid #dbdad8',
                      borderRadius: '2px',
                      outline: '0',
                      transition: 'all 0.3s linear',
                    }}>
                    <PerceptionsIcon />
                    &nbsp;&nbsp;Budget Concerns
                  </div>
                </td>

                <td className='target ' data-testid='target'>
                  <div className='segment-builder__criteria'>
                    <div className='group-container' style={{ height: '56px' }}>
                      <div className='group-container__item-container'>
                        <div
                          className='group-container__item-container__item'
                          style={{
                            height: '56px',
                            marginTop: '0',
                            boxShadow: 'none',
                            borderColor: '#dadce0',
                          }}>
                          <div className='group-container__item-container__item__select-input-range'>
                            <span className='ant-input-group ant-input-group-compact criteria-input-group dynamic-field'>
                              <div
                                className='ant-select ant-select-lg ant-select-single ant-select-show-arrow'
                                data-testid='select-operator'>
                                <div className='ant-select-selector'>
                                  <span
                                    className='ant-select-selection-item'
                                    title='Contains'
                                    style={{ fontSize: '14px' }}>
                                    greater than
                                  </span>
                                </div>
                                <span className='ant-select-arrow' unselectable='on' aria-hidden='true'>
                                  <svg
                                    width='12'
                                    height='8'
                                    viewBox='0 0 12 8'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                      d='M6.8991 7.22923C6.50442 7.64749 5.83915 7.64749 5.44447 7.22923L0.685882 2.18631C0.0840002 1.54846 0.536207 0.5 1.4132 0.5L10.9304 0.500001C11.8074 0.500001 12.2596 1.54846 11.6577 2.18631L6.8991 7.22923Z'
                                      fill='#CDD0D8'></path>
                                  </svg>
                                </span>
                              </div>
                              <div className='dynamic-field-input borderless' data-testid='dynamic-field-input'>
                                <div
                                  className='editable-area'
                                  data-testid='editable-area'
                                  style={{ textAlign: 'left' }}>
                                  3
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <Button
            text='Add criteria'
            icon={{ element: <IconPlusCircle /> }}
            link
            testId='add-metric'
            onClickHandler={() => {}}
          />

          <div
            style={{
              color: '#767676',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              paddingTop: '93px',
            }}>
            <span>Define metrics to track the motion performance</span>
          </div>
        </div>
      </div>
    </div>
  )
})
MotionMetrics.displayName = 'MotionMetrics'

export default MotionMetrics
