import { Alert } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { IconInfo } from 'components/common/Icons/Icons'
import CompletedActionsCard from 'components/MotionObservability/MotionListObservability/CompletedActionsCard'
import CompletedUsersCard from 'components/MotionObservability/MotionListObservability/CompletedUsersCard'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import useStore from 'store/useStore'

const MotionListObservability = observer(() => {
  const { observabilityStore } = useStore()
  const {
    fetchTenantMotionActionPastWeeks,
    fetchTenantMotionActionTotals,
    fetchTenantMotionRangeTotals,
    isLoadingFetchTenantMotionActionPastWeeks,
    isLoadingFetchTenantMotionActionTotals,
    isLoadingFetchTenantMotionRangeTotals,
    tenantMotionActionPastWeeks,
    tenantMotionRangeTotals,
  } = observabilityStore
  const currentDate = dayjs()
  const fourWeeksAgo = currentDate.subtract(4, 'week').valueOf()
  const defaultFourWeeks = { dateFrom: fourWeeksAgo, dateTo: currentDate.valueOf() }

  useEffect(() => {
    fetchTenantMotionActionTotals().catch(console.error)
    fetchTenantMotionActionPastWeeks(defaultFourWeeks).catch(console.error)
    fetchTenantMotionRangeTotals(defaultFourWeeks).catch(console.error)
  }, [Sandbox.isEnabled(), Demo.mockApiIsEnabled()])

  const firsttenantMotionActionPastWeeks = Object.keys(tenantMotionActionPastWeeks ?? {})
  const tenantMotionActionPastWeeksData = tenantMotionActionPastWeeks?.[firsttenantMotionActionPastWeeks[0]] ?? null

  // TODO: Might have to skip this step if not demo as real data returns without top level object
  const firsttenantMotionRangeTotals = Object.keys(tenantMotionRangeTotals ?? {})
  const tenanttenantMotionRangeTotalsData = tenantMotionRangeTotals?.[firsttenantMotionRangeTotals[0]] ?? null

  // Used to toggle showing the empty state banner, but not while loading.
  const isDataLoading =
    isLoadingFetchTenantMotionActionTotals ||
    isLoadingFetchTenantMotionActionPastWeeks ||
    isLoadingFetchTenantMotionRangeTotals
  const emptyTenantMotionData =
    (tenanttenantMotionRangeTotalsData?.tenantTotals?.participantTotals?.ENTERED ?? 0) === 0 &&
    (tenantMotionActionPastWeeksData?.totals?.actions ?? 0) === 0
  const displayEmptyState = emptyTenantMotionData && !isDataLoading

  return (
    <section className='motion-observability'>
      {displayEmptyState && (
        <Alert
          className='motion-observability-alert'
          data-testid='motion-observability-alert'
          message={
            <>
              Track the customer impact of your Motions. Need help creating your first one? See our guide here{' '}
              <a
                href='https://magnifyio.zendesk.com/hc/en-us/articles/17125471855515-motions'
                target='_blank'
                rel='external noreferrer'>
                here
              </a>
              .
            </>
          }
          icon={<IconInfo />}
          type='info'
          showIcon
        />
      )}
      <div className={`motion-observability__wrapper`}>
        <CompletedActionsCard
          tenantMotionsActionTotals={tenantMotionActionPastWeeksData}
          isLoading={isLoadingFetchTenantMotionActionPastWeeks}
        />
        <CompletedUsersCard
          tenantMotionRangeTotals={tenanttenantMotionRangeTotalsData}
          isLoading={isLoadingFetchTenantMotionActionPastWeeks}
        />
      </div>
    </section>
  )
})
MotionListObservability.displayName = 'MotionListObservability'

export default MotionListObservability
