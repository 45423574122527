import { Form, Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { isJsonString } from 'utils/type-helpers'

import { Button, DYNAMIC_INPUT, PAYLOAD_STRUCTURE_KEY } from 'components/common'
import { checkTypeEquality } from 'components/common/DataSourceContainer/index.utils'
import SingleInputField from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/SingleInputField'
import {
  actionLabels,
  filterDefaultFields,
  parsePayloadFields,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/SpecialActions/TriggerEvent/utils'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type {
  ConfigPanelPayload,
  ValueType,
  CreateActionFields,
  payloadStructureError,
  Item,
} from 'models/motion/motionBuilder.model'
import { ActionTypeEnum } from 'models/motion/motionBuilder.model'

interface TriggerEventProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const TriggerEvent = observer(({ payload, setPayload }: TriggerEventProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const [payloadStructureError, setPayloadStructureError] = useState<payloadStructureError>('none')
  // Handles enabling & disabling the Payload Structure textarea.
  const [disablePayloadStructureInput, setDisablePayloadStructureInput] = useState(false)
  const { dynamicInputStore, motionStore } = useStore()
  const { dynamicInputPosition } = dynamicInputStore

  useEffect(() => {
    // Check to see if we need to disable the Payload Structure textarea.
    if (payload?.fields?.length) {
      const fieldStructure = payload.fields.find((field) => field.key === PAYLOAD_STRUCTURE_KEY)
      if (fieldStructure?.value && isJsonString(fieldStructure?.value as string)) {
        const parsedPayload = parsePayloadFields(
          JSON.parse(fieldStructure?.value as string) as {
            [key: string]: string
          },
        )
        if (parsedPayload?.length) {
          setDisablePayloadStructureInput(true)
        }
      }
    }
  }, [])

  const changeFieldValue = (
    value:
      | boolean
      | number
      | number[]
      | string
      | string[]
      | moment.Moment
      | moment.Moment[]
      | CreateActionFields
      | null,
    fieldKey: string | undefined,
  ) => {
    setPayload((payload) => {
      const fieldIndex = payload.fields.findIndex((field) => field.key === fieldKey)

      if (fieldIndex > -1) {
        payload.fields[fieldIndex].value = value
        payload.fields[fieldIndex].isDynamicInput =
          !!(payload.fields[fieldIndex].value as CreateActionFields)?.platform ||
          !!(value as CreateActionFields)?.platform
      }

      return { ...payload }
    })
  }

  const addDynamicValue = (value: ValueType, fieldKey: string | undefined) => {
    const fieldIndex = payload.fields.findIndex((field) => field.key === fieldKey)

    setPayload((prevPayload) => {
      const updatedPayload = { ...prevPayload }
      const field = { ...updatedPayload.fields[fieldIndex] }
      const currentValue = (field.value as CreateActionFields)?.value
      const currentPlatform = (field.value as CreateActionFields)?.platform
      const fieldType = field.type

      const newValue = {
        ...(value as object),
        ...(currentValue && { value: currentValue }),
        ...(currentPlatform &&
          currentValue &&
          !(currentValue as string).includes(DYNAMIC_INPUT) &&
          checkTypeEquality(fieldType, 'string') && {
            value: (currentValue as string) + DYNAMIC_INPUT,
          }),
        ...(!currentPlatform &&
          checkTypeEquality(fieldType, 'string') &&
          (value as CreateActionFields).platform && {
            value: addDynamicInputOnCaretPosition(field),
          }),
        ...(!checkTypeEquality(fieldType, 'string') &&
          (value as CreateActionFields).platform && {
            value: DYNAMIC_INPUT,
          }),
      }

      field.value = newValue as CreateActionFields
      field.isDynamicInput = !!currentPlatform || !!(value as CreateActionFields).platform
      updatedPayload.fields[fieldIndex] = field

      return updatedPayload
    })
  }

  const addDynamicInputOnCaretPosition = (item: Item | CreateActionFields): string => {
    if (
      item.value &&
      typeof item.value === 'string' &&
      item.key === dynamicInputPosition?.key &&
      (dynamicInputPosition?.position || dynamicInputPosition?.position === 0)
    ) {
      return (
        item.value.slice(0, dynamicInputPosition?.position) +
        DYNAMIC_INPUT +
        item.value.slice(dynamicInputPosition?.position)
      )
    }
    return (item.value as string) || '' + DYNAMIC_INPUT
  }

  const removeTag = (fieldKey: string | undefined) => {
    const fieldIndex = payload.fields.findIndex((field) => field.key === fieldKey)

    setPayload((prevPayload) => {
      const updatedPayload = { ...prevPayload }
      const field = { ...updatedPayload.fields[fieldIndex] }

      if ((field.value as CreateActionFields)?.value) {
        field.value = ((field.value as CreateActionFields).value as string).replace(DYNAMIC_INPUT, '')
        field.isDynamicInput = false
        updatedPayload.fields[fieldIndex] = field
      }

      return updatedPayload
    })
  }

  /** Converts the Payload Structure JSON to input fields. */
  const loadFieldStructure = () => {
    const fieldStructure = payload.fields.find((field) => field.key === PAYLOAD_STRUCTURE_KEY)

    if (!fieldStructure?.value) {
      setPayloadStructureError('empty')
      return
    }

    if (fieldStructure?.value && isJsonString(fieldStructure?.value)) {
      const parsedPayload = parsePayloadFields(JSON.parse(fieldStructure.value) as Record<string, string>)
      if (parsedPayload?.length) {
        const defaultFields = filterDefaultFields(payload.fields, true)

        // We add the payload fields here so they appear in the UI, but they will also appear in the payload but are not required to be there.
        setPayload((prev) => {
          return { ...prev, fields: [...defaultFields, ...parsedPayload] } as ConfigPanelPayload
        })
        setPayloadStructureError('none')
        setDisablePayloadStructureInput(true)
      } else {
        setPayloadStructureError('invalid')
        resetCustomFields()
      }
    } else {
      setPayloadStructureError('invalid')
      resetCustomFields()
    }
  }

  const resetCustomFields = () => {
    const defaultFields = filterDefaultFields(payload.fields, true)

    setPayload({ fields: defaultFields } as ConfigPanelPayload)
  }

  return (
    <Layout className='action-container'>
      <div className='list'>
        <div className='action-input-fields' data-testid='action-input-fields'>
          <div className='subtitle' data-testid='subtitle'>
            {getMenuItemIcon({ entityType: 'platform', name: segmentBuilderData.platform }, true)}
            <span>
              {segmentBuilderData.platform} {segmentBuilderData.name} Inputs
            </span>
          </div>

          <div className='action-inputs' data-testid='action-inputs'>
            {filterDefaultFields(payload?.fields, true).map((metadata: CreateActionFields) => {
              return (
                <Form.Item
                  key={metadata.name}
                  colon={false}
                  name={metadata.name}
                  label={metadata.name}
                  data-testid='form-item'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={metadata.name}>
                  <FieldInput
                    item={metadata}
                    range={false}
                    isAction={true}
                    {...(metadata.key === PAYLOAD_STRUCTURE_KEY && { disabled: disablePayloadStructureInput })}
                    changeFieldValue={changeFieldValue}
                  />
                </Form.Item>
              )
            })}
          </div>

          {disablePayloadStructureInput ? (
            <Button
              text={actionLabels.edit}
              size='L'
              type='secondary'
              testId='edit-btn'
              onClickHandler={() => {
                setDisablePayloadStructureInput(false)
              }}
              disabled={motionStore.isSegmentBuilderEditDisabled}
            />
          ) : (
            <Button
              text={actionLabels.load}
              size='L'
              testId='load-btn'
              onClickHandler={loadFieldStructure}
              disabled={motionStore.isSegmentBuilderEditDisabled}
            />
          )}

          {payloadStructureError !== 'none' && (
            <div className='error-message' data-testid='error-message'>
              {actionLabels.payloadErrorMessages[payloadStructureError]}
            </div>
          )}

          <div className='action-inputs' data-testid='action-inputs'>
            {filterDefaultFields(payload?.fields, false).map((metadata: CreateActionFields, index: number) => {
              metadata.platform = segmentBuilderData.platform
              metadata.object = segmentBuilderData.object
              metadata.field = metadata.key

              if (metadata.type === 'picklist') {
                metadata.onlyDynamicInput = true
              }

              return (
                <SingleInputField
                  key={`${metadata.key}-${index}`}
                  index={index}
                  item={metadata}
                  actionType={ActionTypeEnum.Create}
                  removeTag={removeTag}
                  addDynamicValue={addDynamicValue}
                  changeFieldValue={changeFieldValue}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
})
TriggerEvent.displayName = 'TriggerEvent'

export default TriggerEvent
