import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Divider } from 'antd'
import { useNavigate } from 'react-router-dom'

import { Heading, IconUser } from 'components/common'

interface AccountInformationProps {
  name: string
  numberOfUsers: number
}

const AccountInformation = ({ name, numberOfUsers }: AccountInformationProps) => {
  const navigate = useNavigate()

  return (
    <div className='account-information-container'>
      <div className='account-information-title-container' data-testid='account-information-title-container'>
        <div
          className='account-information-heading-container'
          data-testid='account-information-title-container-heading'>
          <Heading variant='2' level='2' className='account-information-title-container-heading'>
            {name}
          </Heading>
          {numberOfUsers > 0 ? (
            <div
              className='account-information-total-user-container'
              data-testid='account-information-total-user-container'>
              <IconUser />
              {numberOfUsers}
            </div>
          ) : null}
        </div>

        <CloseButton type='ghost' shape='circle' onClick={() => navigate(`/dashboard`)}>
          <CloseOutlined />
        </CloseButton>
      </div>

      <Divider />
    </div>
  )
}

export default AccountInformation
