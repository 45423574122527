import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Form, Popover, Button as CloseButton, Tooltip } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { DataSourceContainer, IconBolt, STRING_TYPE } from 'components/common'
import { checkTypeEquality, isDyanmicField } from 'components/common/DataSourceContainer/index.utils'
import EditWarningModal from 'components/EmailTemplates/modals/EditWarningModal'
import DynamicFieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/DynamicFieldInput'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import { allowDynamicInput } from 'components/MotionBuilder/Utils/serviceUtils'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import EmailTemplateModal from 'pages/Motions/EmailTemplateModal'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'

import type { MetadataField } from 'models/motion/dynamicInput.model'
import type { CreateActionFields } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum } from 'models/motion/motionBuilder.model'
import { MotionStateEnum } from 'models/motion.model'

interface SingleInputFieldProps {
  index: number
  item: CreateActionFields
  actionType: ActionTypeEnum
  removeTag: (fieldKey: string | undefined) => void
  addDynamicValue: (item: MetadataField, fieldKey: string | undefined) => void
  changeFieldValue: (
    item: boolean | number | number[] | string | string[] | moment.Moment | moment.Moment[] | CreateActionFields | null,
    fieldKey?: string,
    index?: number,
  ) => void
  disabled?: boolean
  payloadFields?: CreateActionFields[]
}

const SingleInputField = observer(
  ({
    index,
    item,
    actionType,
    removeTag,
    addDynamicValue,
    changeFieldValue,
    disabled = false,
    payloadFields,
  }: SingleInputFieldProps) => {
    const { dynamicInputStore, motionStore } = useStore()
    const { fetchDynamicInputsMetadata, resetSearch } = dynamicInputStore
    const { currentMotion } = motionStore
    const [displayDynamicFieldPopover, setDisplayDynamicFieldPopover] = useState<boolean>(false)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState(false)

    useDisplayErrorNotification(dynamicInputStore)

    // Return null early if the action is a slack action and the input is a hidden input
    if (item.platform === 'slack' && item.type === 'hidden') {
      return null
    }

    const enableDynamicInput = allowDynamicInput(item) && !motionStore.isSegmentBuilderEditDisabled

    const openDynamicFieldPopup = (value: boolean) => {
      if (!displayDynamicFieldPopover) {
        fetchDynamicInputsMetadata(item).catch(console.error)
      }
      setDisplayDynamicFieldPopover(value)
    }

    const onOpenChange = (value: boolean) => {
      if (!displayDynamicFieldPopover && item.onlyDynamicInput) {
        fetchDynamicInputsMetadata(item).catch(console.error)
      }
      setTimeout(() => {
        if (displayDynamicFieldPopover || item.onlyDynamicInput) {
          setDisplayDynamicFieldPopover(value)
        }
      }, 1)

      resetSearch()
    }

    const handleDynamicBtnClick = () => {
      openDynamicFieldPopup(true)
    }

    const handleEditTemplate = () => {
      setIsEditEmailModalOpen(false)
      setIsEdit(true)
      setShowEmailModal(true)
    }

    const handleCloseEmailTemplateModal = () => {
      setIsEdit(false)
      setShowEmailModal(false)
    }

    const items: MenuProps['items'] = [
      {
        label: 'Remove Template',
        key: 'remove-template',
        className: 'dropdown__item',
        onClick: () => {
          changeFieldValue('', 'templateId')
          changeFieldValue('', 'templateVersion')
          changeFieldValue('', 'template')
        },
      },
    ]

    const isTemplateName = item.name === 'Email' && item.platform === 'magnify'

    return (
      <Popover
        key={item.key}
        trigger='click'
        open={!disabled && displayDynamicFieldPopover}
        placement='bottomRight'
        overlayClassName='data-field__popover dynamic-field__popover'
        showArrow={false}
        onOpenChange={onOpenChange}
        content={
          <DataSourceContainer
            currentItem={item}
            handleSelectDataField={(field) => {
              addDynamicValue(field, item.key)
              openDynamicFieldPopup(false)
            }}
          />
        }>
        <Form.Item
          colon={false}
          name={['user-', `${item.key}-${index}`]}
          label={!isTemplateName && item.name}
          initialValue={item.name}
          className={classNames({
            'dynamic-field-popover--active': displayDynamicFieldPopover,
            'dynamic-field': enableDynamicInput,
            'only-dynamic-field': item.onlyDynamicInput,
            [`type-${item.type}`]: true,
          })}
          validateStatus={item.error ? 'error' : ''}
          hasFeedback={item.error}
          rules={[
            {
              required: item.required && actionType !== ActionTypeEnum.Update,
              message: 'This field is required',
              whitespace: true,
            },
          ]}>
          <Tooltip placement='top' title={item.tooltip} data-testid='tooltip'>
            <>
              {isTemplateName && (
                <>
                  <div className='template-label'>
                    <p className='template-label__text'>
                      {item.name} <span className='template-label__text--required'>*</span>{' '}
                      {item.value && (
                        <span className='template-label__text--link' onClick={() => setIsEditEmailModalOpen(true)}>
                          (Edit)
                        </span>
                      )}
                    </p>
                    {item.value && currentMotion?.currState !== MotionStateEnum.Executing && (
                      <Dropdown
                        overlayClassName='motions-table-row-actions'
                        menu={{ items }}
                        trigger={['click']}
                        placement='bottomRight'
                        disabled={motionStore.isSegmentBuilderEditDisabled}>
                        <CloseButton
                          className='motions-table-row-actions-button'
                          disabled={motionStore.isSegmentBuilderEditDisabled}>
                          <EllipsisOutlined className='dropdown__icon' style={{ fontSize: '18px' }} />
                        </CloseButton>
                      </Dropdown>
                    )}
                  </div>
                  <EmailTemplateModal
                    open={showEmailModal}
                    onCancel={() => handleCloseEmailTemplateModal()}
                    onConfirm={() => handleCloseEmailTemplateModal()}
                    selectedTemplate={item.value as string}
                    changeFieldValue={changeFieldValue}
                    isInActionFlow
                    initializeEdit={{
                      isEdit,
                      templateId: (payloadFields?.find((field) => field.key === 'templateId')?.value as string) || null,
                      templateVersion:
                        (payloadFields?.find((field) => field.key === 'templateVersion')?.value as number) || null,
                    }}
                  />
                  <EditWarningModal
                    isModalOpen={isEditEmailModalOpen}
                    actionConfirm={handleEditTemplate}
                    setIsModalOpen={setIsEditEmailModalOpen}
                  />
                </>
              )}

              {isDyanmicField(item) || checkTypeEquality(item.type, STRING_TYPE) ? (
                <DynamicFieldInput
                  item={item}
                  borderless={false}
                  openPopover={openDynamicFieldPopup}
                  removeTag={() => removeTag(item.key)}
                  changeFieldValue={changeFieldValue}
                />
              ) : (
                <FieldInput
                  item={item}
                  range={false}
                  isAction={true}
                  changeFieldValue={changeFieldValue}
                  disabled={disabled || motionStore.isSegmentBuilderEditDisabled}
                  payloadFields={payloadFields}
                />
              )}

              {enableDynamicInput && (
                <div
                  className={classNames('dynamic-field-popup-btn', {
                    'dynamic-field-popup-btn--active': displayDynamicFieldPopover,
                    'dynamic-field-popup-btn--disabled': motionStore.isSegmentBuilderEditDisabled,
                  })}
                  data-testid='dynamic-field-popup-btn'
                  onClick={handleDynamicBtnClick}>
                  <IconBolt />
                </div>
              )}
            </>
          </Tooltip>
        </Form.Item>
      </Popover>
    )
  },
)
SingleInputField.displayName = 'SingleInputField'

export default SingleInputField
