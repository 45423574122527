/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Form, Modal } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Heading } from 'components/common'
import { IconClose } from 'components/common/Icons/Icons'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import * as utils from 'pages/Motions/MotionGoalsModal/index.utils'
import MotionMetrics from 'pages/Perceptions/MotionGoalsModal/MotionMetrics'
import useStore from 'store/useStore'

import type { Item } from 'models/motion/motionBuilder.model'

const MotionGoalsModal = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { motionStore, motionGoalsStore } = useStore()
  const [titleError, setTitleError] = useState<boolean>(false)

  useEffect(() => {
    const loadedMotion = !location.pathname.includes('new') && location.pathname.includes('motions/motion')
    /* Set Motion goals if there is an existing Motion loaded */
    if (loadedMotion && motionStore.currentMotion) {
      motionGoalsStore.setCurrentMotionGoals({
        title: motionStore.currentMotion.title,
        description: motionStore.currentMotion.description,
        goal: motionStore.currentMotion.goal,
        metrics: motionStore.currentMotion.metrics,
        currState: motionStore.currentMotion.currState,
      })
    } else if (!location.pathname.includes('motions/motion')) {
      /* If the user is navigating from an existing Motion to other route, empty the Motion goals */
      motionGoalsStore.setMotionGoals({ ...utils.emptyMotionGoals })
      motionGoalsStore.setCurrentMotionGoals({ ...utils.emptyMotionGoals })
    }

    motionGoalsStore.setMotionGoalsModal({ isOpen: false, isEditing: false })
  }, [motionStore.currentMotion, location])

  useEffect(() => {
    setTitleError(false)
  }, [motionGoalsStore.motionGoalsModal.isOpen])

  const handleContinue = () => {
    if (!motionGoalsStore.motionTitle) {
      setTitleError(true)
      return
    }

    motionGoalsStore.setCurrentMotionGoals({
      title: motionGoalsStore.motionTitle,
      description: motionGoalsStore.motionDescription,
      goal: motionGoalsStore.motionGoal,
      metrics: motionGoalsStore.motionMetrics.filter((metric) => metric.field && metric.platform && metric.object),
      currState: motionGoalsStore.motionCurrState,
    })
    handleCloseModal()

    const isNewMotion = !motionGoalsStore.motionGoalsModal.isEditing && !location.pathname.includes('motions/motion')

    if (isNewMotion) {
      if (motionGoalsStore.motionGoalsModal.insightId) {
        navigate(`/motions/motion/new?insightUuid=${motionGoalsStore.motionGoalsModal.insightId}`)
      } else {
        navigate('/motions/motion/new', { state: { to: location.pathname } })
      }
    }
  }

  const handleCloseModal = () => {
    motionGoalsStore.setMotionGoalsModal({ isOpen: false, isEditing: false })
  }

  const onChangeTitle = (value: string) => {
    setTitleError(!!!value)

    motionGoalsStore.setMotionTitle(value)
  }

  const onChangeDescription = (description: string) => {
    motionGoalsStore.setMotionDescription(description)
  }

  return (
    <Modal
      key='modal__motion-goals'
      open={motionGoalsStore.motionGoalsModal.isOpen}
      closable={false}
      centered
      width={1080}
      className='modal__form modal__motion-goals'
      footer={[
        <React.Fragment key='modal__form--footer modal__motion-goals--footer'>
          <Button text='Cancel' type='secondary' size='XL' onClickHandler={handleCloseModal} testId='cancel-btn' />

          <Button
            text={motionGoalsStore.motionGoalsModal.isEditing ? 'Save' : 'Continue'}
            size='XL'
            className='m-l-10'
            onClickHandler={handleContinue}
            testId='continue-btn'
          />
        </React.Fragment>,
      ]}>
      <div className='modal__form--header modal__motion-goals--header' data-testid='modal__motion-goals--header'>
        <Heading level='1' variant='2'>
          {motionGoalsStore.motionGoalsModal.isEditing
            ? utils.motionGoalsMock.modalTitleOnEdit
            : utils.motionGoalsMock.modalTitle}
        </Heading>

        <div className='header--buttons' data-testid='header--buttons'>
          <IconClose onClick={handleCloseModal} className='btn__close' data-testid='modal-close' />
        </div>
      </div>

      <Form className='modal__form--body modal__motion-goals--body' data-testid='modal__motion-goals--body'>
        <div className='motion-inputs'>
          <Form.Item
            data-testid='motion-name'
            colon={false}
            label={utils.motionGoalsMock.motionNameLabel}
            name={utils.motionGoalsMock.motionNameLabel}
            className={classNames({ 'required-item': titleError })}
            rules={[
              {
                required: true,
              },
            ]}>
            <FieldInput
              item={{ type: 'string', value: motionGoalsStore.motionTitle } as Item}
              range={false}
              isAction={true}
              placeholder={utils.motionGoalsMock.motionNamePlaceholder}
              changeFieldValue={onChangeTitle as any}
            />
          </Form.Item>
          <Form.Item
            data-testid='motion-description'
            colon={false}
            label={utils.motionGoalsMock.motionDescriptionLabel}>
            <FieldInput
              item={{ type: 'string', value: motionGoalsStore.motionDescription } as Item}
              range={false}
              isAction={true}
              placeholder={utils.motionGoalsMock.motionDescriptionPlaceholder}
              changeFieldValue={onChangeDescription as any}
            />
          </Form.Item>
        </div>
        {titleError && (
          <label className='error-message' data-testid='error-message'>
            {utils.motionGoalsMock.errorMessage}
          </label>
        )}

        <MotionMetrics />
      </Form>
    </Modal>
  )
})
MotionGoalsModal.displayName = 'MotionGoalsModal'

export default MotionGoalsModal
