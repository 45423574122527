export const clone = <T>(obj: T): T => {
  const output: T = JSON.parse(JSON.stringify(obj)) as T
  return output
}

export const ordinalSuffix = (value: number) => {
  const moduloTen = value % 10,
    moduloHundred = value % 100
  if (moduloTen === 1 && moduloHundred !== 11) {
    return value + 'st'
  }
  if (moduloTen === 2 && moduloHundred !== 12) {
    return value + 'nd'
  }
  if (moduloTen === 3 && moduloHundred !== 13) {
    return value + 'rd'
  }
  return value + 'th'
}
