import { makeAutoObservable } from 'mobx'

import type { ChildStore } from 'store/StoreTypes'

import type { Elements, FlowElement } from 'react-flow-renderer'

import type { MergePayload } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

export interface MergeTarget {
  isActive: boolean
  nodeId?: string
  actionText?: string
  type?: string
  payload?: MergePayload
}

export class MergePanelStore implements ChildStore {
  isSelectingTarget: boolean = false
  merges = new Map<string, MergeTarget[]>([])
  currentSourceMergeId: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.isSelectingTarget = false
    this.merges = new Map<string, MergeTarget[]>([])
    this.currentSourceMergeId = null
  }

  setMerge = (key: string, value: MergeTarget[]) => {
    this.merges.set(key, value)
  }

  setCurrentSourceMergeId = (id: string | null) => {
    this.currentSourceMergeId = id
  }

  setIsSelectingTarget = (selecting: boolean) => {
    this.isSelectingTarget = selecting
  }

  setMergeBasedOnElements = (elements: Elements) => {
    elements
      .filter((element: FlowElement<MergeTarget>) => element?.data?.type === NodeTypeEnum.Merge)
      .forEach((mergedNode: FlowElement<MergeTarget>) => {
        if (mergedNode.data?.payload?.targets) {
          this.setMerge(
            mergedNode.id,
            mergedNode.data?.payload?.targets?.map(
              (target: string) => ({ nodeId: target, isActive: false }) as MergeTarget,
            ),
          )
        } else {
          console.error('Unexpected setMergeBasedOnElements data type:', mergedNode)
        }
      })
  }
}
