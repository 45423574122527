import { getAccountsRanges } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import type { AccountRowValue, DriverSegmentEnum, SegmentV3 } from 'models/insights'

interface InsightRiskTabV2Props {
  segmentTitle: DriverSegmentEnum
  segmentValues: SegmentV3
  onClick: (title: DriverSegmentEnum) => void
  isSelected: boolean
  selectedAccountsRows: AccountRowValue[]
  allAccountsRows: AccountRowValue[]
  isCustomSegmentSelected?: boolean
}

const InsightRiskTabV2 = ({
  segmentTitle,
  segmentValues,
  onClick,
  isSelected = true,
  selectedAccountsRows,
  allAccountsRows,
  isCustomSegmentSelected,
}: InsightRiskTabV2Props) => {
  return (
    <div
      className={isSelected ? 'insight-risk-tab-container-selected' : 'insight-risk-tab-container'}
      data-testid={isSelected ? 'insight-risk-tab-container-selected' : 'insight-risk-tab-container'}
      onClick={() => onClick(segmentTitle)}>
      <div className='insight-risk-tab-content'>
        <div data-testid='insight-details-risk-tab-heading' className='insight-risk-tab-content-heading'>
          <span className={`insight__status insight__status--${segmentTitle}`}></span>
          {segmentValues.title}
        </div>

        <div className='stats'>
          <div className='insight-risk-tab-content-probability' data-testid='insight-risk-tab-content-probability'>
            {segmentValues.driver.title}
            {' : '}
            {getAccountsRanges({
              source: {
                title: segmentValues.driver.title,
                column: segmentValues.driver.column,
                aggregator: segmentValues.driver.min_metric.aggregator,
                format: segmentValues.driver.min_metric.format,
                decimal: segmentValues.driver.min_metric.decimal,
                segment: segmentTitle,
              },
              allTableRows: allAccountsRows,
              selectedAccountsRows: isCustomSegmentSelected ? [] : selectedAccountsRows,
            })}
            {' to '}
            {getAccountsRanges({
              source: {
                title: segmentValues.driver.title,
                column: segmentValues.driver.column,
                aggregator: segmentValues.driver.max_metric.aggregator,
                format: segmentValues.driver.max_metric.format,
                decimal: segmentValues.driver.max_metric.decimal,
                segment: segmentTitle,
              },
              allTableRows: allAccountsRows,
              selectedAccountsRows: isCustomSegmentSelected ? [] : selectedAccountsRows,
            })}
          </div>

          <span className='stats-account'>
            <span
              data-testid='insight-details-risk-tab-account-count'
              className='insight-risk-tab-content-accounts-count'>
              {getAccountsRanges({
                source: {
                  title: segmentValues.num_accounts.title,
                  column: segmentValues.num_accounts.column,
                  aggregator: segmentValues.num_accounts.aggregator,
                  format: segmentValues.num_accounts.format,
                  decimal: segmentValues.num_accounts.decimal,
                  segment: segmentTitle,
                },
                allTableRows: allAccountsRows,
                selectedAccountsRows: isCustomSegmentSelected ? [] : selectedAccountsRows,
              })}
            </span>{' '}
            <span className='insight-risk-tab-content-accounts-text'>Accounts</span>
          </span>

          <span className='stats-income' data-testid='stats-income'>
            {' '}
            |{' '}
            {`${getAccountsRanges({
              source: {
                title: segmentValues.revenue_elift_improve_from.title,
                column: segmentValues.revenue_elift_improve_from.column,
                aggregator: segmentValues.revenue_elift_improve_from.aggregator,
                format: segmentValues.revenue_elift_improve_from.format,
                decimal: segmentValues.revenue_elift_improve_from.decimal,
                segment: segmentTitle,
              },
              allTableRows: allAccountsRows,
              selectedAccountsRows: isCustomSegmentSelected ? [] : selectedAccountsRows,
            })} ${segmentValues.revenue_elift_improve_from.title}`}
          </span>
        </div>
      </div>
    </div>
  )
}
InsightRiskTabV2.displayName = 'InsightRiskTabV2'

export default InsightRiskTabV2
