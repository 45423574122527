import type { FC } from 'react'

interface DynamicImageProps {
  iconUrl: string
  iconAlt: string
}

export const DynamicImage: FC<DynamicImageProps> = (props: DynamicImageProps) => {
  const { iconUrl, iconAlt } = props
  return <img src={iconUrl} alt={iconAlt} className='dynamic-image' />
}
