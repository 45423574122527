import type * as moment from 'moment'

import type { CreateSlackMessagePayload } from 'models/slack-messages'

export type PartialWithRequiredKeys<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>

interface PlainObject {
  hasOwnProperty<K extends string>(key: K): this is Record<K, unknown>

  // Object.hasOwn() is intended as a replacement for Object.hasOwnProperty(). See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwn
  hasOwn<K extends string>(key: K): this is Record<K, unknown>
}

export function isPlainObject(value: unknown): value is PlainObject {
  return !!value && typeof value === 'object' && !Array.isArray(value)
}

export function isMomentObject(value: unknown): value is moment.Moment {
  return !!value && typeof value === 'object' && '_isAMomentObject' in value
}

export function isSlackActionObject(value: unknown): value is CreateSlackMessagePayload {
  return !!value && typeof value === 'object' && 'messageContent' in value && 'dynamicInputs' in value
}

/**
 * Verify that a string is a valid JSON payload.
 * @param {string} value The string to verify.
 * @returns {boolean} True if the string is a valid JSON payload, false otherwise.
 */
export const isJsonString = (value: unknown): value is string => {
  if (typeof value !== 'string') {
    return false
  }
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}
