import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Alert, Col, Row, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import EmptyImage from 'assets/images/insight-drivers-empty-state.svg?react'
import { Button } from 'components/common'
import { IconChevronRight, IconErrorInsights, IconInfo } from 'components/common/Icons/Icons'
import InsightRow from 'components/Insights/components/InsightRow'
import InsightsDropdown from 'components/Insights/components/InsightsDropdown'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import useStore from 'store/useStore'

export const Insights = observer(() => {
  const insightsContainerRef = useRef<HTMLDivElement>(null)
  const [displayMoreInsights, setDisplayMoreInsights] = useState(false)

  const { insightsStore } = useStore()
  const {
    apiErrorAllInsights,
    getDimensions,
    getAllInsights,
    isLoadingAllInsights,
    selectedDimension,
    selectedTarget,
    targetOptions,
    setSelectedTarget,
    setSelectedDimension,
    dimensions,
    insights,
  } = insightsStore

  useEffect(() => {
    getDimensions().catch(console.error)
    getAllInsights({ dimension: selectedDimension }).catch(console.error)
  }, [selectedDimension, Demo.mockApiIsEnabled(), Sandbox.isEnabled()])

  const handleSelectedTargetClick = useCallback(
    (driverTitle: string) => {
      setDisplayMoreInsights(false)
      setSelectedTarget(driverTitle)
    },
    [setSelectedTarget],
  )

  const handleSelectedDimensionClick = useCallback(
    (dimension: string) => {
      setDisplayMoreInsights(false)
      setSelectedDimension(dimension)
    },
    [setSelectedDimension],
  )

  const insightList = useMemo(() => {
    if (isLoadingAllInsights || !selectedTarget || !selectedTarget.short_name || selectedTarget.drivers.length < 0) {
      return null
    }

    const insightsToShow = displayMoreInsights ? selectedTarget.drivers : [...selectedTarget.drivers].splice(0, 3)
    if (!insightsToShow) {
      return null
    }

    return insightsToShow.map((insight) => (
      <InsightRow
        selectedTarget={selectedTarget.short_name!}
        insight={insight}
        key={`${selectedTarget?.short_name}-${insight.driver_id}-${selectedDimension}`}
        insightImpactLevel={selectedTarget.impact}
        isRevenueQuantified={insights?.is_revenue_quantified}
      />
    ))
  }, [isLoadingAllInsights, displayMoreInsights, insights, selectedTarget, selectedDimension])

  if (isLoadingAllInsights) {
    return (
      <div data-testid='insights-skeleton-loading'>
        <Skeleton className='insights-skeleton-container' />
      </div>
    )
  }

  const dimensionOptions = Object.keys(dimensions || {})

  return (
    <div className='insights'>
      <div data-testid='insights-drivers-heading' className='insights-drivers-heading'>
        {dimensionOptions.length > 1 ? (
          <>
            <InsightsDropdown
              key='dimension-dropdown'
              capitalize
              targetOptions={dimensionOptions}
              selectedTarget={selectedDimension}
              handleSelectedTargetClick={handleSelectedDimensionClick}
            />
            <div className='insights-drivers-heading-chevron'>
              <IconChevronRight />
            </div>
          </>
        ) : null}
        Top drivers for customer
        {targetOptions.length ? (
          <InsightsDropdown
            key='driver-dropdown'
            targetOptions={targetOptions}
            selectedTarget={selectedTarget?.short_name}
            handleSelectedTargetClick={handleSelectedTargetClick}
          />
        ) : null}
      </div>
      <div data-testid='selected-driver-insights' ref={insightsContainerRef}>
        {insightList}

        {/* The request is successful but there are no insights */}
        {((!selectedTarget && !apiErrorAllInsights) || selectedTarget?.drivers?.length === 0) && (
          <>
            <Alert
              className='insights-alert'
              data-testid='insights-alert'
              message={
                <>
                  Magnify Insights identifies the most impactful drivers of churn, expansion, and conversion. Learn more
                  about Magnify Insights{' '}
                  <a
                    href='https://magnifyio.zendesk.com/hc/en-us/articles/24156651605915-Magnify-Insights'
                    target='_blank'
                    rel='external noreferrer'>
                    here
                  </a>
                  .
                </>
              }
              icon={<IconInfo />}
              type='info'
              showIcon
            />
            <Row className='insights-empty-container' data-testid='insights-empty-container'>
              <Col>
                <EmptyImage />
              </Col>
            </Row>
          </>
        )}

        {/* The request failed, and there are no selected targets */}
        {!selectedTarget && apiErrorAllInsights && (
          <Row
            className='insights-empty-container'
            justify='center'
            style={{ minHeight: insightsContainerRef.current?.clientHeight || 300 }}
            data-testid='insights-error-container'>
            <IconErrorInsights />
            <div data-testid='insights-empty-text-container' className='insights-empty-text-container'>
              Something went wrong, please check your integration status or contact service team.
            </div>
          </Row>
        )}

        {selectedTarget && selectedTarget.drivers.length > 3 && (
          <Row justify='center'>
            <Button
              text={displayMoreInsights ? 'View less' : 'View more'}
              link
              size='L'
              icon={{ element: displayMoreInsights ? <UpOutlined /> : <DownOutlined />, position: 'right' }}
              onClickHandler={() => setDisplayMoreInsights(!displayMoreInsights)}
              className='m-t-16'
              testId='view-more'
            />
          </Row>
        )}
      </div>
    </div>
  )
})
Insights.displayName = 'Insights'
