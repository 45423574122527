import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { getActionData } from 'store/actions/actions.store.utils'
import type { ChildStore } from 'store/StoreTypes'

import type { ActionsItems } from 'models/actions.model'
import type { MetadataPlatform } from 'models/metadata.model'
import { ShapeTypeEnum, type Platform, type ToolboxItems } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

const initToolbox = {
  rules: [
    {
      name: 'If / Else',
      description: 'If / Else',
      type: NodeTypeEnum.Branch,
      iconName: 'ifElse',
      shape: ShapeTypeEnum.Rhomb,
    },
    {
      name: 'Wait for trigger',
      description: 'Wait for trigger',
      type: NodeTypeEnum.WaitForTrigger,
      iconName: 'waitForTrigger',
      shape: ShapeTypeEnum.Rhomb,
    },
    {
      name: 'Time delay',
      description: 'Time delay',
      type: NodeTypeEnum.TimeDelay,
      iconName: 'timeDelay',
      shape: ShapeTypeEnum.Rhomb,
    },
    {
      name: 'Loop',
      description: 'Loop',
      type: NodeTypeEnum.Loop,
      iconName: 'loop',
      shape: ShapeTypeEnum.Rhomb,
    },
    {
      name: 'Merge',
      description: 'Merge',
      type: NodeTypeEnum.Merge,
      iconName: 'merge',
      shape: ShapeTypeEnum.Rhomb,
    },
  ],
  actions: [],
  inactiveActions: [],
}

const defaultActions = [
  {
    iconName: 'lightBulb',
    actionName: 'Launch Guide',
    metadataObject: 'Guide',
    platform: 'pendo',
    isHidden: false,
    id: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'triggerEvent',
    actionName: 'Trigger Event',
    metadataObject: 'Trigger_Event',
    platform: 'gainsight',
    actionLevel: 'user',
    id: '333b9438-ce6f-426a-88d3-e7180907ca2c',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'createCrown',
    actionName: 'Create Opportunity',
    metadataObject: 'Opportunity',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '98d26a16-34b3-4eb3-b5ca-de8d6b6bdd5b',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'updateCrown',
    actionName: 'Update Opportunity',
    metadataObject: 'Opportunity',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '95104dac-cd7b-4ba0-90f5-535d0238cf51',
    type: 'update',
    solutionInstanceId: '1',
  },
  {
    iconName: 'createFiles',
    actionName: 'Create Ticket',
    metadataObject: 'Ticket',
    platform: 'zendesk',
    actionLevel: 'account',
    id: '9b724756-0f99-498d-abfd-9f440f629eb6',
    type: 'create',
  },
  {
    iconName: 'createCustomObject',
    actionName: 'Create Case',
    metadataObject: 'Case',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '6ed6ebf8-f2c4-4c5c-883b-7faf163dd882',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'updateUser',
    actionName: 'Update Account',
    metadataObject: 'Account',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '103eee45-2d0e-4928-a1ca-e4e6bfe88a8f',
    type: 'update',
    solutionInstanceId: '1',
  },
  {
    iconName: 'createCustomObject',
    actionName: 'Create CTA',
    metadataObject: 'Call_To_Action',
    platform: 'gainsight',
    actionLevel: 'user',
    id: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'sendEmail',
    actionName: 'Send Email',
    metadataObject: 'Campaign',
    platform: 'marketo',
    actionLevel: 'user',
    isHidden: false,
    id: '776b77df-da11-4a84-abe8-37955da11b00',
    type: 'create',
  },
  {
    iconName: 'sendEmail',
    actionName: 'Send Email',
    metadataObject: 'Email',
    platform: 'magnify',
    actionLevel: 'user',
    id: '7a01af85-1d1b-4f0e-932b-ee9ca1f6c38d',
    type: 'create',
  },
  {
    iconName: 'updateFiles',
    actionName: 'Update Ticket',
    metadataObject: 'Ticket',
    platform: 'zendesk',
    actionLevel: 'account',
    id: '770017f9-cca5-4713-9d15-b680f4f23c0c',
    type: 'update',
  },
  {
    iconName: 'createNote',
    actionName: 'Add Ticket Comment',
    metadataObject: 'Ticket.Comment',
    platform: 'zendesk',
    actionLevel: 'account',
    id: '717ecb6d-38ef-446c-9cd2-a50ce1329584',
    type: 'update',
  },
  {
    iconName: 'createCalendar',
    actionName: 'Create Timeline Event',
    metadataObject: 'Activity_Timeline',
    platform: 'gainsight',
    actionLevel: 'account',
    id: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'sendMessage',
    actionName: 'Send Message',
    metadataObject: 'Message',
    platform: 'slack',
    actionLevel: 'user',
    isHidden: false,
    id: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
    type: 'create',
  },
  {
    iconName: 'sendMessage',
    actionName: 'Post to Channel',
    metadataObject: 'Channel',
    platform: 'slack',
    actionLevel: 'user',
    isHidden: false,
    id: '4306b7e2-e4d0-4f2a-b93c-9da858a4e349',
    type: 'create',
  },
  {
    iconName: 'createCustomObject',
    actionName: 'Create Request',
    metadataObject: 'Request',
    platform: 'zendesk',
    actionLevel: 'account',
    id: 'b5299e49-5ad8-443b-b027-236c8d205edd',
    type: 'create',
  },
  {
    iconName: 'createBriefcase',
    actionName: 'Create Task',
    metadataObject: 'Task',
    platform: 'salesforce',
    actionLevel: 'account',
    id: 'baeb3b22-91c9-4295-a84f-568824316e7b',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'createFiles',
    actionName: 'Create Ticket',
    metadataObject: 'Tickets',
    platform: 'intercom',
    actionLevel: 'user',
    isHidden: false,
    id: 'eb3e0b4a-c2e4-46e2-aa88-bd4724a95dfe',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'createCrown',
    actionName: 'Create or Update Company',
    metadataObject: 'Companies',
    platform: 'intercom',
    actionLevel: 'account',
    isHidden: false,
    id: '1ed44390-336d-4388-b6ea-f75526265b6f',
    type: 'create',
    solutionInstanceId: '1',
  },
  {
    iconName: 'sendEmail',
    actionName: 'Send Transactional Email',
    metadataObject: 'Send_Transactional_Email',
    platform: 'hubspot',
    actionLevel: 'user',
    isHidden: false,
    id: '7d8f029e-8d83-4490-950b-72c2da7a5e39',
    type: 'create',
    solutionInstanceId: '1',
  },
]

export class ActionsStore implements ChildStore {
  isLoading = true
  actions: ActionsItems[] = []
  filteredToolboxItems: ToolboxItems = this.toolboxItems

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.isLoading = true
    this.actions = []
    this.filteredToolboxItems = this.toolboxItems
    this.apiError = null
  }

  get toolboxItems(): ToolboxItems {
    const platformMap = new Map<string, Platform>()

    // Create a set of active actions so we can filter out inactive actions
    const activeActionsSet = new Set(this.actions.map(({ actionName, platform }) => `${actionName}:${platform}`))

    // Filter out active actions from the default actions
    const inactiveActions = defaultActions.filter(
      ({ actionName, platform }) => !activeActionsSet.has(`${actionName}:${platform}`),
    )

    // Create a map of platforms to actions
    for (const action of this.actions) {
      if (!platformMap.has(action.platform)) {
        platformMap.set(action.platform, {
          name: action.platform,
          type: 'platform',
          actions: [],
        })
      }
      platformMap.get(action.platform)?.actions?.push(getActionData(action))
    }

    // Create a map of platforms to inactive actions
    const inactivePlatformMap = new Map<string, Platform>()
    for (const action of inactiveActions) {
      if (!inactivePlatformMap.has(action.platform)) {
        inactivePlatformMap.set(action.platform, {
          name: action.platform,
          type: 'platform',
          actions: [],
        })
      }
      inactivePlatformMap.get(action.platform)?.actions?.push(getActionData(action as ActionsItems))
    }

    return {
      ...initToolbox,
      actions: Array.from(platformMap.values()),
      inactiveActions: Array.from(inactivePlatformMap.values()),
    }
  }

  fetchActions = async (): Promise<void> => {
    this.isLoading = true
    try {
      const [actions, platforms] = await Promise.all([
        API.actions.getActions(),
        /* --- TO BE REMOVED AFTER SOLUTION INSTANCE ID WILL BE VALID ---*/
        API.metadata.getPlatforms(),
        /* --------------------------------------------------------------*/
      ])

      runInAction(() => {
        this.setActions(actions, platforms)
        this.filteredToolboxItems = this.toolboxItems
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  setActions = (
    items: ActionsItems[],
    result: {
      data: MetadataPlatform[]
    },
  ) => {
    /* --- TO BE REMOVED AFTER SOLUTION INSTANCE ID WILL BE VALID ---*/
    items.forEach((item) => {
      const data = result.data.find((platform) => item.platform === platform.name)
      if (data) {
        item.solutionInstanceId = data.connections[0].solutionInstanceId
      }
    })
    /* --------------------------------------------------------------*/

    this.actions = items
  }

  setFilteredToolboxItems = (result: ToolboxItems) => {
    this.filteredToolboxItems = result
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
