import { Layout, Row, Skeleton } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import mockGraph from 'assets/images/mocks/graph.png'
import { Button, IconErrorInsights } from 'components/common'
import { sortSegmentRiskLevel } from 'components/common/Utils/insights'
import Banner from 'components/Insights/components/InsightsDetails/components/Banner'
import CustomSegment from 'components/Insights/components/InsightsDetails/components/CustomSegment'
import InsightImpact from 'components/Insights/components/InsightsDetails/components/InsightImpact'
import InsightRiskTab from 'components/Insights/components/InsightsDetails/components/InsightRiskTab'
import InsightsLoadingModal from 'components/Insights/components/InsightsDetails/components/InsightsLoadingModal'
import SegmentAccountTable from 'components/Insights/components/InsightsDetails/components/SegmentAccountsTable'
import { buildSegmentHeading, getDefaultLevel } from 'components/Insights/components/InsightsDetails/index.utils'
import useDemoFeature from 'hooks/useDemoFeature'
import MotionGoalsModal from 'pages/Motions/MotionGoalsModal'
import useStore from 'store/useStore'

import type { Params } from 'react-router-dom'

import type { InsightV2, SegmentV3 } from 'models/insights'
import { DriverSegmentEnum, InsightDriverTypeEnum } from 'models/insights'

const InsightsDetails = observer(() => {
  const { insightsStore } = useStore()
  const {
    apiError,
    accountData,
    currentInsight,
    isLoading,
    isLoadingAccount,
    selectedAccounts,
    selectedCustomSegmentAccounts,
    selectedDimension,
    dimensions,
  } = insightsStore
  const { isMockApiEnabled } = useDemoFeature()

  const insight = currentInsight as InsightV2
  const { target }: Readonly<Params<string>> = useParams()
  const { driverId }: Readonly<Params<string>> = useParams()

  const [selectedRiskTabs, setSelectedRiskTabs] = useState<Array<DriverSegmentEnum>>([])
  const [segmentRange, setSegmentRange] = useState<{ min: undefined | number; max: undefined | number }>({
    min: undefined,
    max: undefined,
  })
  const isCustomSegmentSelected = selectedRiskTabs.includes(DriverSegmentEnum.Custom)

  useEffect(() => {
    if (target && driverId) {
      insightsStore.getDimensions().catch(console.error)
      insightsStore.fetchInsight({ dimension: selectedDimension, target, id: driverId }).catch(console.error)
      insightsStore.fetchAccount({ dimension: selectedDimension, target, id: driverId }).catch(console.error)
    }
  }, [target, driverId])

  // Set the default selected risk tab, depending on what impact the insight is
  useEffect(() => {
    if (!!insight) {
      const defaultSelectedRiskTab = getDefaultLevel(Object.keys(insight.predefined_segments))
      setSelectedRiskTabs([defaultSelectedRiskTab])
    }
  }, [insight])

  const handleInsightRiskTabClick = (segmentRiskLevel: DriverSegmentEnum) => {
    if (selectedRiskTabs.includes(segmentRiskLevel)) {
      setSelectedRiskTabs(selectedRiskTabs.filter((t) => t !== segmentRiskLevel))
    } else if (segmentRiskLevel === DriverSegmentEnum.Custom) {
      setSelectedRiskTabs([segmentRiskLevel])
    } else {
      setSelectedRiskTabs([...selectedRiskTabs.filter((t) => t !== DriverSegmentEnum.Custom), segmentRiskLevel])
    }
  }

  if (isLoading || !insight) {
    return (
      <Layout className='container'>
        <Content className='content'>
          <Skeleton active></Skeleton>
        </Content>
      </Layout>
    )
  }

  if (!isLoading && !insight && apiError) {
    return (
      <Layout className='container'>
        <Content className='content insights-details-not-found'>
          <IconErrorInsights />
          <div data-testid='insights-empty-text-container' className='insights-empty-text-container'>
            Insight not found, please check your integration status or contact service team.
          </div>
          <Link to='/dashboard'>
            <Button className='insights-details-not-found-button' text='Back to Dashboard' />
          </Link>
        </Content>
      </Layout>
    )
  }

  return (
    <Layout className='container'>
      <Content className='content insights-details'>
        <Banner
          driverId={driverId}
          insight={insight}
          selectedDimension={selectedDimension}
          showDimension={Object.keys(dimensions || {}).length > 1}
        />

        <section className='insights-details__body'>
          {insight.aggregations?.length && (
            <InsightImpact
              aggregations={insight.aggregations}
              isEmptyState={
                insight?.is_revenue_quantified === false && insight?.driver_type === InsightDriverTypeEnum.Insight
              }
              targetValue={insight.metric_value}
            />
          )}

          <hr />

          <div
            className='insights-details-risk-segment-container'
            data-testid='insights-details-risk-segment-container'>
            <Row className='segment__header'>
              <div className='segment__heading'>{buildSegmentHeading(target)}</div>
            </Row>

            <div className='segment__body'>
              {Object.keys(insight.predefined_segments ?? [])
                .sort(sortSegmentRiskLevel)
                .map((segment) => (
                  <InsightRiskTab
                    key={segment}
                    segmentTitle={segment as DriverSegmentEnum}
                    segmentValues={insight?.predefined_segments?.[segment] ?? ({} as SegmentV3)}
                    onClick={handleInsightRiskTabClick}
                    isSelected={selectedRiskTabs.includes(segment as DriverSegmentEnum)}
                    selectedAccountsRows={selectedAccounts}
                    allAccountsRows={accountData.tableRows}
                    isCustomSegmentSelected={isCustomSegmentSelected}
                  />
                ))}
            </div>

            <CustomSegment
              onClick={handleInsightRiskTabClick}
              segmentTitle={DriverSegmentEnum.Custom}
              segmentValues={
                insight?.predefined_segments?.[Object.keys(insight?.predefined_segments)[0]] ?? ({} as SegmentV3)
              }
              isSelected={selectedRiskTabs.includes(DriverSegmentEnum.Custom)}
              allAccountsRows={accountData.tableRows}
              selectedAccountsRows={selectedCustomSegmentAccounts}
              segmentRange={segmentRange}
              setSegmentRange={setSegmentRange}
            />
          </div>

          {isMockApiEnabled && (
            <div className='insights-details-histograph-container' data-testid='insights-details-histograph-container'>
              <div className='insights-details-graph-container'>
                <img src={mockGraph} alt='Graph' className='mock-image' />
              </div>
            </div>
          )}

          <hr />

          {isLoadingAccount ? (
            <Skeleton active></Skeleton>
          ) : (
            <SegmentAccountTable filterBy={selectedRiskTabs} isCustomSegmentSelected={isCustomSegmentSelected} />
          )}
        </section>

        <MotionGoalsModal />
        <InsightsLoadingModal />
      </Content>
    </Layout>
  )
})
InsightsDetails.displayName = 'InsightsDetails'

export default InsightsDetails
