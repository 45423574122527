import { Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import StatsOverview from 'components/MotionObservability/ActiveMotionsReporting/components/StatsOverview'
import TopActions from 'components/MotionObservability/ActiveMotionsReporting/components/TopActions'
import HorizontalStackedChart from 'components/MotionObservability/components/HorizontalStackedChart'
import { sortIntegrations } from 'components/MotionObservability/utils'
import useStore from 'store/useStore'

const AllTimeCard = observer(() => {
  const { observabilityStore } = useStore()
  const { isLoadingFetchTenantMotionActionTotals, tenantMotionsActionTotals } = observabilityStore

  if (isLoadingFetchTenantMotionActionTotals) {
    return (
      <div className='motion-observability__card' data-testid='all-time-card-loading'>
        <Skeleton active />
      </div>
    )
  }

  const sortedIntegrations = sortIntegrations(tenantMotionsActionTotals?.integrations)
  const hasAnyActionsRun =
    (tenantMotionsActionTotals?.totals?.actions || 0) > 0 && Object.keys(sortedIntegrations).length > 0

  return (
    <div className='motion-observability__card' data-testid='all-time-card'>
      <div className='motion-observability__card__header'>
        <h5>All-Time</h5>
      </div>
      <div className='motion-observability__card__content'>
        <StatsOverview observabilityTenantTotals={tenantMotionsActionTotals?.totals} />
        <HorizontalStackedChart
          title='Actions by Integration'
          items={sortedIntegrations}
          hasAnyData={hasAnyActionsRun}
        />
        <TopActions integrations={sortedIntegrations} hasAnyActionsRun={hasAnyActionsRun} />
      </div>
      <div className='motion-observability__card__footer'>
        <Link className='button button-secondary' to='/motions' data-testid='view-all-motions'>
          View Motions
        </Link>
      </div>
    </div>
  )
})
AllTimeCard.displayName = 'AllTimeCard'

export default AllTimeCard
