import { isEdge, isNode } from 'react-flow-renderer'

import { getElementById } from 'components/MotionTarget/motionTarget.utils'
import { getOnlyLetters, toCamelCase } from 'services/Utils/parseString.utils'

import type { Edge, Node, Elements, FlowElement } from 'react-flow-renderer'

import type { CreateActionFields, AggregationData, SegmentBuilderData } from 'models/motion/motionBuilder.model'
import type { ActionFieldsPayload, Dsl, NodeState, RecursiveTraverse } from 'models/motion.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'

interface ReactFlowToDSLProps {
  elements: Elements
  aggregations?: AggregationData[]
}

export function reactFlowToDSL({ elements, aggregations = [] }: ReactFlowToDSLProps): Dsl {
  const startAt: FlowElement<SegmentBuilderData> | undefined = elements.find(
    (el: FlowElement<SegmentBuilderData>) => el.data!.isInitial === true,
  )
  // initial state
  const states = {}
  // send elements without loops edges
  const nodes = getNodes<SegmentBuilderData>(elements)
  const edges = getEdges<{ edgeLabel: string; edgeType: NodeTypeEnum }>(elements)
  const edgesWithoutLoopEdges = edges.filter((edge) => {
    return edge.type !== NodeTypeEnum.Loop
  })

  recursiveTraverse({
    elements: [...nodes, ...edgesWithoutLoopEdges],
    nodeId: startAt?.id,
    states,
  })

  const result = {
    ...(aggregations?.length && { aggregations }),
    startAt: `${toCamelCase(startAt?.data?.name || '')}${startAt?.id}` || '',
    states: states,
  }

  return result
}

function recursiveTraverse({ elements, nodeId, states, prevNodeKey, edgeLabel }: RecursiveTraverse) {
  if (!nodeId) {
    return
  }

  const nodes = getNodes<SegmentBuilderData>(elements)
  const edges: Edge<{ edgeLabel: string; edgeType: NodeTypeEnum }>[] = getEdges(elements)

  // the current node
  const currentNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === nodeId)
  if (!currentNode) {
    return
  }
  // the edge that start from the current node
  const edgesOfNode: Edge<{ edgeLabel: string; edgeType: NodeTypeEnum }>[] = edges.filter(
    (edge) => edge.source === currentNode?.id,
  )

  const isEndNode = !edgesOfNode.length
  const isBasicNode = edgesOfNode.length === 1
  const isBranchNode = edgesOfNode.length > 1
  const currentNodeName = toCamelCase(currentNode?.data?.name || '') as NodeTypeEnum
  const currentNodeId = currentNode?.id
  const currentNodeKey = `${currentNodeName}${currentNodeId}` as NodeTypeEnum
  const targetNode: FlowElement<SegmentBuilderData> | undefined = currentNode.data?.targetNodeId
    ? getElementById(elements, currentNode.data?.targetNodeId)
    : undefined

  const currentObject = generateNodeInfo(currentNodeName, currentNode, toCamelCase(targetNode?.data?.name || ''))

  states[currentNodeKey] = currentObject as NodeState
  const isYesBranch = edgeLabel === BranchLabelEnum.Yes
  const isNoBranch = edgeLabel && edgeLabel === BranchLabelEnum.No

  // prevNodeKey doesnt exist only for the first iteration
  if (prevNodeKey) {
    // if there is a previous node add to it the current node as next
    states[prevNodeKey].next = currentNodeKey
  }
  // default of a branch (yes)
  if (prevNodeKey && isYesBranch) {
    // if there is a previous node and the index is 0 it means it's on the yes branch
    states[prevNodeKey].default = currentNodeKey
  }

  if (prevNodeKey && isNoBranch) {
    // next of a branch (no)
    states[prevNodeKey].choices = [
      {
        next: currentNodeKey,
      },
    ]

    states[prevNodeKey].type = prevNodeKey === NodeTypeEnum.Segment ? NodeTypeEnum.Segment : getOnlyLetters(prevNodeKey)
    // it's a choice type we don't need next
    delete states[prevNodeKey].next
  }

  if (isBranchNode) {
    for (const edge of edgesOfNode) {
      recursiveTraverse({
        elements,
        nodeId: edge.target,
        states,
        prevNodeKey: currentNodeKey,
        edgeLabel: edge.data?.edgeLabel as BranchLabelEnum,
      })
    }
    if (currentNodeKey) {
      delete states[currentNodeKey].next
    }
  }

  if (isBasicNode) {
    recursiveTraverse({
      elements,
      nodeId: edgesOfNode[0].target,
      states,
      prevNodeKey: currentNodeKey,
    })
  }

  if (isEndNode && prevNodeKey) {
    if (!states[prevNodeKey].default) {
      // if the parent node is branch and doesn't have a default it means it's an end
      states[prevNodeKey].end = true
      delete states[prevNodeKey].next
    }
    if (isYesBranch) {
      // if the current node is end this should be reflected into parent elment dsl
      states[prevNodeKey].end = true
      delete states[prevNodeKey].default
    }
    if (isNoBranch && states[prevNodeKey].choices?.length) {
      // for no branch the end should be added into the choice array
      const choiceState = states[prevNodeKey].choices
      if (choiceState) {
        choiceState[0].end = true
        delete choiceState[0].next
      }
    }
    delete states[currentNodeKey]
  }

  if (
    prevNodeKey &&
    states[prevNodeKey].type === NodeTypeEnum.Branch &&
    states[prevNodeKey].default &&
    states[prevNodeKey].end
  ) {
    delete states[prevNodeKey].end
  }
}

function generateNodeInfo(nodeName: NodeTypeEnum, node: Node<SegmentBuilderData>, targetNodeName?: string) {
  const data = {
    // Here you can update the DSL data based on the react flow node data
    type: nodeName === NodeTypeEnum.Segment ? NodeTypeEnum.Segment : node?.data?.type || nodeName,
    ...(node?.data?.payload && { payload: modifyPayload(node) }),
    ...(node?.data?.description && { description: node?.data.description }),
    ...(node?.position && { position: node?.position }),
    ...(nodeName === NodeTypeEnum.Segment &&
      node?.data?.excludedUserIds && { excludedUserIds: node?.data?.excludedUserIds }),
    // if it's loop move copy from payload the targetNodeId on main lvl
    // TODO: add special treat case for action items
    ...(node.data?.type === NodeTypeEnum.Loop &&
      node?.data.targetNodeId && {
        targetNodeId: `${targetNodeName}${node.data.targetNodeId}`,
      }),
    ...(node?.data?.type === NodeTypeEnum.Action && {
      actionId: node.data.actionId,
      actionLevel: node.data.actionLevel,
      action: node.data.action,
      fakeAction: node.data.fakeAction ?? false,
      platform: node.data.platform,
      object: node.data.object,
      ...(node.data.solutionInstanceId && { solutionInstanceId: node.data.solutionInstanceId }),
      ...(node.data?.magnifyDisplayName && { magnifyDisplayName: node.data.magnifyDisplayName }),
      ...(node.data?.iconName && { iconName: node.data.iconName }),
    }),
  }

  return data
}

const getNodes = <T>(elements: Elements): Node<T>[] => elements.filter((el) => isNode(el)) as Node<T>[]

const getEdges = <T>(elements: Elements): Edge<T>[] => elements.filter((el) => isEdge(el)) as Edge<T>[]

export interface Payload {
  fields?: Record<string, any>
}
export function modifyPayload(node: Node<SegmentBuilderData>): Payload {
  const payload: Payload = { ...node?.data!.payload }

  if (node?.data?.type === NodeTypeEnum.Action) {
    const fields = generateActionFields(node)

    if (fields.length) {
      payload.fields = fields
    }

    if (payload?.fields && !payload?.fields?.length) {
      delete payload.fields
    }
  }

  return payload
}

export const generateActionFields = (node: Node<SegmentBuilderData>) => {
  const fields: ActionFieldsPayload[] = []

  const oldFields: CreateActionFields[] = node?.data?.payload.fields || []
  for (const field of oldFields) {
    if (field.value || typeof field.value === 'boolean') {
      fields.push({
        key: field.key,
        isDynamicInput: field.isDynamicInput,
        type: field.type,
        value: field.value as string,
        ...(field.displayValue && { displayValue: field.displayValue }),
        ...(field.defaultValue && { defaultValue: field.defaultValue }),
      })
    }
  }

  return fields
}
