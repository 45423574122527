import { Table } from 'antd'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import EmptyState from 'components/AccountDetails/EmptyState'
import { getParticipatedMotionsData } from 'components/AccountDetails/ParticipatedMotions/utils'
import { Heading } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import Sandbox from 'configs/sandbox'

import type { ColumnsType } from 'antd/lib/table'

import type { ParticipatedMotion, ParticipatedMotionsTable } from 'models/account.model'

interface ParticipatedMotionsProps {
  participatedMotions: ParticipatedMotion[] | null
  participatedMotionsData?: ParticipatedMotionsTable[]
  participatedMotionsColumns?: ColumnsType<ParticipatedMotionsTable>
  isParticipatedMotionsDataAvailable?: boolean
}

const ParticipatedMotions = ({ participatedMotions, participatedMotionsColumns }: ParticipatedMotionsProps) => {
  const navigate = useNavigate()
  const isParticipatedMotionsAvailable = participatedMotions && participatedMotions.length > 0

  const redirectToMotion = useCallback(
    (journeyId, version) => navigate(`/motions/motion/${journeyId}/${version}`),
    [navigate],
  )

  if (!isParticipatedMotionsAvailable) {
    return <EmptyState title='Participated Motions' message='No Motions are running yet for this account' />
  }

  return (
    <div className='participated-motions__container' data-testid='participated-motions__container'>
      <div className='participated-motions__header'>
        <Heading variant='2' level='2'>
          Participated Motions
        </Heading>
        <MockDataCallout />
      </div>

      <div className={classNames('participated-motions__table', { 'disable-link': Sandbox.isEnabled() })}>
        <Table
          dataSource={getParticipatedMotionsData(participatedMotions)}
          columns={participatedMotionsColumns}
          pagination={{ position: ['bottomRight'] }}
          rowClassName='participated-motions__table-row'
          onRow={(record, rowIndex) => {
            return {
              onClick: () =>
                redirectToMotion(
                  participatedMotions[rowIndex as number].journeyId,
                  participatedMotions[rowIndex as number].journeyVersion,
                ),
            }
          }}
        />
      </div>
    </div>
  )
}

export default ParticipatedMotions
