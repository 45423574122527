import { makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { FileAttachment } from 'models/file-attachments.model'

/** Limit file size to 5MB to match the server. */
export const FILE_SIZE_LIMIT = 5 * 1024 * 1024

export class FileAttachmentsStore implements ChildStore {
  loading = {
    isFileLoading: false,
    isListLoading: false,
  }
  data = {
    list: [] as FileAttachment[],
  }
  modal = {
    isOpen: false,
  }

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
    })
  }

  reset = () => {
    this.loading = {
      isFileLoading: false,
      isListLoading: false,
    }
    this.data = {
      list: [] as FileAttachment[],
    }
    this.modal = {
      isOpen: false,
    }
    this.apiError = null
  }

  /**
   * Upload a new file.
   * @param {FormData} formData The payload to upload a new file.
   * @returns {Promise<string>} The uploaded file ID.
   */
  uploadFile = async (formData: FormData) => {
    this.loading.isFileLoading = true
    try {
      const { id } = await API.fileAttachments.upload(formData)
      return id
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isFileLoading = false
      })
    }
  }

  /**
   * Get the file attachment list.
   * @returns {Promise<FileAttachment[] | undefined>} Sets the list of file attachments in the store.
   */
  getFileList = async (): Promise<FileAttachment[] | undefined> => {
    this.loading.isListLoading = true
    try {
      const { result } = await API.fileAttachments.getAll()
      runInAction(() => {
        this.data.list = result
      })
      return result
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isListLoading = false
      })
    }
  }

  /**
   * Delete (soft delete) a file specified by an ID.
   * @param {string} id The ID of the file to delete.
   * @returns {Promise<string | undefined>} The status of the delete operation.
   */
  deleteFile = async (id: string): Promise<string | undefined> => {
    this.loading.isFileLoading = true
    try {
      const { status } = await API.fileAttachments.deleteFile(id)
      return status
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isFileLoading = false
      })
    }
  }

  setModal = (isOpen: boolean) => {
    runInAction(() => {
      this.modal.isOpen = isOpen
    })
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
