import { InputNumber, Popover } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import isEqual from 'utils/isEqual'

import { Button } from 'components/common'
import DynamicDataField from 'components/common/DynamicDataField'
import { IconTrash, IconPlusCircle, IconArrowTop } from 'components/common/Icons/Icons'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import * as utils from 'pages/Motions/MotionGoalsModal/index.utils'
import useStore from 'store/useStore'

import type { Item } from 'models/motion/motionBuilder.model'
import { MotionStateEnum } from 'models/motion.model'

const MotionMetrics = observer(() => {
  const { motionGoalsStore } = useStore()
  const {
    motionMetrics,
    dataFieldIndex,
    motionGoalsModal,
    currentMotionGoals: { currState },
    setMotionMetrics,
    addMetric,
    removeMetric,
    onChangeAggregation,
    onChangeTrend,
    onChangeTimespan,
    onChangeTarget,
    onChangeDataField,
    setDataFieldIndex,
  } = motionGoalsStore

  const isMotionStateInDraft = currState === MotionStateEnum.Draft

  useEffect(() => {
    if (!motionMetrics.length) {
      setMotionMetrics([
        {
          ...utils.emptyMetric,
          key: 0,
        },
      ])
    }
  }, [motionGoalsModal.isOpen])

  const isPopoverOpen = (value: boolean, index: number) => {
    if (value) {
      setDataFieldIndex(index)
    } else {
      setDataFieldIndex(-1)
    }
  }

  const isTrashIconVisible = () => {
    if (motionMetrics.length === 1 && isEqual(motionMetrics[0], utils.emptyMetric)) {
      return false
    }

    return true
  }

  const hadDifferentTypePreviously = ({
    current,
    previous,
  }: {
    current: string | undefined
    previous: string | undefined
  }) => {
    return previous !== current
  }

  return (
    <div className='track-metrics' data-testid='track-metrics'>
      <label>{utils.motionGoalsMock.metricsTitle}</label>

      <table className='track-metrics__table' data-testid='track-metrics__table'>
        <thead>
          <tr>
            <th>
              <span>{utils.motionGoalsMock.dataFieldLabel}</span>
            </th>
            <th>
              <span>{utils.motionGoalsMock.aggregationLabel}</span>
            </th>
            <th>
              <span>{utils.motionGoalsMock.timespanLabel}</span>
            </th>
            <th>
              <span>{utils.motionGoalsMock.trendLabel}</span>
            </th>
            <th>
              <span>{utils.motionGoalsMock.setTargetLabel}</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {motionMetrics.map((metric, index) => (
            <tr key={metric.key} data-testid='track-metrics__table--row'>
              <td className='data-field' data-testid='data-field'>
                <Popover
                  trigger='click'
                  placement='bottom'
                  overlayClassName='data-field__popover'
                  onOpenChange={(value: boolean) => {
                    isPopoverOpen(value, index)
                  }}
                  {...(!isMotionStateInDraft ? { open: false } : { open: dataFieldIndex === index })}
                  className={classNames({ 'data-field--active': dataFieldIndex === index })}
                  content={
                    <DynamicDataField
                      handleSelectDataField={(item) => {
                        isPopoverOpen(false, index)
                        onChangeDataField({
                          item,
                          hasDifferentType: hadDifferentTypePreviously({ current: item.type, previous: metric.type }),
                          index,
                        })
                      }}
                    />
                  }>
                  <div
                    className={classNames('data-field__container', { disabled: !isMotionStateInDraft })}
                    data-testid='data-field__container'>
                    <span className='data-field__container--platform'>
                      {!!metric.platform && getMenuItemIcon({ entityType: 'platform', name: metric.platform })}
                    </span>
                    {!!metric.object && !!metric.name && (
                      <span className='data-field__container--name'>
                        {metric.magnifyDisplayName ?? metric.object}/{metric.name}
                      </span>
                    )}
                  </div>
                </Popover>
              </td>

              <td className='aggregation' data-testid='aggregation'>
                <FieldInput
                  item={{ type: 'select', value: metric.aggregation } as Item}
                  range={false}
                  isAction={true}
                  disabled={!metric.platform || !isMotionStateInDraft}
                  defaultOptions={utils.getAggregationOptions(metric.type)}
                  changeFieldValue={(value) => onChangeAggregation(value as string, index)}
                />
              </td>

              <td className='timespan' data-testid='timespan'>
                <FieldInput
                  item={{ type: 'select', value: metric.timespan } as Item}
                  range={false}
                  isAction={true}
                  disabled={!isMotionStateInDraft}
                  defaultOptions={utils.timespanOptions}
                  changeFieldValue={(value) => onChangeTimespan(value as string, index)}
                />
              </td>

              <td className='trend' data-testid='trend'>
                <FieldInput
                  item={{ type: 'select', value: metric.trend } as Item}
                  range={false}
                  isAction={true}
                  disabled={!isMotionStateInDraft}
                  defaultOptions={utils.trendOptions}
                  changeFieldValue={(value) => onChangeTrend(value as string, index)}
                />
              </td>

              <td className='target' data-testid='target'>
                <InputNumber
                  key='metric-target'
                  className='track-goal__target--input'
                  controls={{
                    upIcon: <IconArrowTop data-testid='inputUpIcon' />,
                    downIcon: <IconArrowTop data-testid='inputDownIcon' />,
                  }}
                  min={0}
                  precision={0}
                  bordered={false}
                  value={metric.target}
                  onChange={(value) => onChangeTarget(value, index)}
                  disabled={!isMotionStateInDraft}
                />
              </td>

              <td className='delete-metric'>
                <div>
                  {isTrashIconVisible() && (
                    <Button
                      text=''
                      type='secondary'
                      link
                      testId='delete-metric'
                      icon={{ element: <IconTrash /> }}
                      onClickHandler={() => removeMetric(index)}
                      disabled={!isMotionStateInDraft}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {motionMetrics.length < utils.motionGoalsMock.maxMetrics && (
        <Button
          text='Add metric'
          icon={{ element: <IconPlusCircle /> }}
          link
          testId='add-metric'
          onClickHandler={() => addMetric(utils.emptyMetric)}
          disabled={!isMotionStateInDraft}
        />
      )}
    </div>
  )
})
MotionMetrics.displayName = 'MotionMetrics'

export default MotionMetrics
