import { CaretDownFilled } from '@ant-design/icons'
import { Select, Carousel, Empty } from 'antd'
import { useWindowSize } from 'react-use'

import { Card, Heading } from 'components/common'
import Arrow from 'components/common/Arrow'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import Chart from 'components/MotionDetails/GoalMetric/Chart'
import { slidesToShow } from 'components/MotionDetails/GoalMetric/utils'

import type { GoalMetrics } from 'models/reporting.model'

interface GoalMetricProps {
  timeFrame: number
  metrics?: GoalMetrics[]
  isEmpty: boolean
  onSelectionChange: (selectedTimeFrame: number) => void
}

const GoalMetric = ({ timeFrame, metrics, isEmpty, onSelectionChange }: GoalMetricProps) => {
  const size = useWindowSize()

  return (
    <section className='goal-metric goal-metric-container' id='goalMetrics'>
      <div className='goal-metric-heading'>
        <div className='goal-metric-heading__wrapper'>
          <Heading variant='7' level='2'>
            Goal Metrics
          </Heading>
          <MockDataCallout />
        </div>

        {!isEmpty && (
          <Select
            suffixIcon={<CaretDownFilled />}
            labelInValue={false}
            defaultValue={timeFrame}
            style={{ width: 120 }}
            onChange={onSelectionChange}
            className='goal-metric-heading-select'
            data-testid='goal-metric-heading-select'>
            <Select.Option value={10}>10 weeks</Select.Option>
            <Select.Option value={20}>20 weeks</Select.Option>
          </Select>
        )}
      </div>
      {isEmpty ? (
        <Empty />
      ) : (
        <Carousel
          className='goal-metric-carousel'
          arrows
          adaptiveHeight={true}
          variableWidth={true}
          slidesToShow={slidesToShow(size.width)}
          swipeToSlide={true}
          dots={false}
          infinite={false}
          nextArrow={<Arrow />}
          prevArrow={<Arrow />}>
          {metrics?.map((goalMetricData, index: number) => {
            return (
              <Card variant='2' className='goal-metric-card' key={index}>
                <Chart
                  labels={goalMetricData.labels}
                  data={goalMetricData.data}
                  title={goalMetricData.title}
                  annotation={goalMetricData.annotation}
                  visualization={goalMetricData.visualization}
                />
              </Card>
            )
          })}
        </Carousel>
      )}
    </section>
  )
}
GoalMetric.displayName = 'GoalMetric'

export default GoalMetric
