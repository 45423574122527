import { Layout, Row, Col } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'

import askForSupportIcon from 'assets/icons/helpCenter/ask-for-support.svg'
import learningCenterIcon from 'assets/icons/helpCenter/learning-center.png'
import { Heading } from 'components/common'
import SupportModal from 'components/SupportModal'
import useStore from 'store/useStore'

const HelpCenter = () => {
  const { helpCenterStore } = useStore()

  return (
    <main className='bg-secondary ant-layout-has-sider help-center'>
      <Layout className='container'>
        <Content className='content'>
          <SupportModal />
          <Content>
            <Row>
              <Heading variant='1' level='1'>
                Help Center
              </Heading>
            </Row>
            <Row>
              <p>You can visit our documents to find the user guide or submit a support request.</p>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row'>
                <button
                  className='help-center-button'
                  type='button'
                  data-testid='help-center-button-learning-center'
                  onClick={() => window.open(import.meta.env.VITE_HELP_DOCS_URL, '_blank')}>
                  <div className='icon-container'>
                    <img src={learningCenterIcon} alt='Learning Center' width={48} height={64} />
                  </div>
                  <Heading variant='6' level='2'>
                    Learning Center
                  </Heading>
                  <p>We have summarized many FAQs in our documentation.</p>
                </button>
              </Col>
              <Col className='gutter-row'>
                <button
                  className='help-center-button'
                  type='button'
                  data-testid='help-center-button-ask-for-support'
                  onClick={() => helpCenterStore.setSupportModal({ isOpen: true })}>
                  <div className='icon-container'>
                    <img src={askForSupportIcon} alt='Ask for Support' width={80} height={84} />
                  </div>
                  <Heading variant='6' level='2'>
                    Ask for Support
                  </Heading>
                  <p>Submit a support request, we will get back as soon as possible.</p>
                </button>
              </Col>
            </Row>
          </Content>
        </Content>
      </Layout>
    </main>
  )
}
HelpCenter.displayName = 'HelpCenter'

export default observer(HelpCenter)
