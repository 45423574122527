import type { MetadataTypes } from 'models/metadata.model'
import type { PayloadData } from 'models/motion/motionBuilder.model'
import { RelationOperator } from 'models/motion.model'

// eslint-disable-next-line no-template-curly-in-string
export const DYNAMIC_INPUT = '${dynamicInput}'
export const TEXT_NODE_TYPE = 3
export const DIV_NODE_TYPE = 1
export const STRING_TYPE = 'string'
export const PAYLOAD_STRUCTURE_KEY = 'Payload Structure'
export const DATETIME_TYPE = 'DateTime'
export const EMPTY_GROUPS_PAYLOAD: PayloadData = { groups: [], relationOperator: RelationOperator.OR }
export const skipSnowflakePlatformRequest = (options?: MetadataTypes) =>
  options?.platform !== 'snowflake' || (options?.platform === 'snowflake' && options?.object)
