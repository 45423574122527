import { Modal } from 'antd'

import { Button, Heading } from 'components/common'
import { IconClose } from 'components/common/Icons/Icons'

interface ISegmentExportModal {
  isVisable: boolean
  handleCancelModal: () => void
  handleSegmentExport: () => void
  isLoading?: boolean
}

export const SegmentExportModal = ({
  isVisable,
  handleCancelModal,
  handleSegmentExport,
  isLoading,
}: ISegmentExportModal) => {
  return (
    <Modal
      open={isVisable}
      onCancel={handleCancelModal}
      centered
      width={668}
      className='modal__remove'
      footer={[
        <Button key='cancel' text='Cancel' type='secondary' testId='cancel-btn' onClickHandler={handleCancelModal} />,
        <Button
          key='submit'
          text='Export'
          testId='export-btn'
          className='m-l-10'
          onClickHandler={handleSegmentExport}
          loading={isLoading}
        />,
      ]}>
      <div className='modal__remove--heading'>
        <Heading level='1' variant='3' className='bold'>
          Export segment data to CSV
        </Heading>

        <IconClose onClick={handleCancelModal} className='btn__close' data-testid='modal-close' />
      </div>

      <div className='message'>
        <span className='message__text' role='alert' data-testid='modal-message'>
          You are about to export the data of this segment to a CSV. Are you sure you want to continue?
        </span>
      </div>
    </Modal>
  )
}
SegmentExportModal.displayName = 'SegmentExportModal'
