import { SearchOutlined } from '@ant-design/icons'
import { Input, Space } from 'antd'

import Button from 'components/common/Button'

import type { InputRef } from 'antd'
import type { ColumnType } from 'antd/lib/table'
import type { FilterConfirmProps } from 'antd/lib/table/interface'

export const getColumnSearchProps = <T,>(
  dataIndex: keyof T,
  searchInputExecutionStatuses: React.RefObject<InputRef>,
  handleReset: (
    clearFilters: (param?: FilterConfirmProps) => void,
    confirm: (param?: FilterConfirmProps) => void,
  ) => void,
): ColumnType<T> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInputExecutionStatuses}
        placeholder='Search'
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />

      <Space>
        <Button
          type='primary'
          size='M'
          text='Search'
          onClickHandler={() => confirm()}
          icon={{ element: <SearchOutlined />, position: 'left' }}
        />

        <Button size='M' text='Reset' onClickHandler={() => clearFilters && handleReset(clearFilters, confirm)} />

        <Button
          type='secondary'
          size='M'
          text='close'
          onClickHandler={() => {
            close()
          }}
        />
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#303ef5' : undefined }} />,
  onFilter: (value, record) =>
    (record[dataIndex] as string)
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInputExecutionStatuses.current?.select(), 100)
    }
  },
  render: (text: string) => text,
})
