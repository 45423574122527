import { RefreshSegmentOptions } from 'models/motion.model'

export const cronDaysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const motionExecutionModalCadenceSelectionClass = 'motion-execution-modal-cadence-container-selection'

export const defaultRefreshSegmentIncludeOptions = [
  { label: RefreshSegmentOptions.ACCOUNTS, value: RefreshSegmentOptions.ACCOUNTS },
  { label: RefreshSegmentOptions.USERS, value: RefreshSegmentOptions.USERS },
]

export const refreshSegmentDisabledOption = [
  { label: RefreshSegmentOptions.ACCOUNTS, value: RefreshSegmentOptions.ACCOUNTS },
  { label: RefreshSegmentOptions.USERS, value: RefreshSegmentOptions.USERS, disabled: true },
]
