import { Table } from 'antd'
import { useContext } from 'react'

import type { ChartData, TableColumn, TableData } from 'models/account.model'

interface TabContentProps {
  id: number
  chartData: ChartData
  tableColumns: TableColumn[]
  tableData: TableData[]
  TabContext: React.Context<{
    activeTab: number
    setTab: (tabId: number) => void
  }>
}

const TabContent = ({ id, tableColumns, tableData, TabContext }: TabContentProps) => {
  const { activeTab } = useContext(TabContext)

  if (id !== activeTab) return null

  return (
    <div className='ml-metrics-tab-content-container'>
      <div className='ml-metrics-tab-content-table'>
        <Table dataSource={tableData} columns={tableColumns} pagination={false} />
      </div>
    </div>
  )
}
TabContent.displayName = 'TabContent'

export default TabContent
