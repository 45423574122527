import { useState } from 'react'

import { INITIAL_EXPERIMENT_SEGMENT_SIZES } from 'components/MotionTarget/MotionTargetSettings'

import type { ABExperimentSegement, ABExperimentSegmentSizes, ABExperimentVariant } from 'models/motion.model'

/**
 * This provides state and functions for managing an A/B experiment.
 * @returns {Object} An object containing the state and functions for the A/B experiment.
 */
const useABExperiment = () => {
  const [showExperimentModal, setShowExperimentModal] = useState<boolean>(false)
  const [isABExperimentModeEnabled, setIsABExperimentModeEnabled] = useState(false)
  const [isEditingVariantA, setIsEditingVariantA] = useState(false)
  const [isEditingVariantB, setIsEditingVariantB] = useState(false)
  const [selectedExperimentSegementSizes, setSelectedExperimentSegementSizes] = useState(
    INITIAL_EXPERIMENT_SEGMENT_SIZES,
  )

  /**
   * Returns true if the user is editing either variant A or variant B of the experiment, false otherwise.
   * @returns {boolean} A boolean value indicating if the user is editing an experiment variant.
   */
  const isEditingExperiment = () => {
    return isEditingVariantA || isEditingVariantB
  }

  /**
   * Returns true if the user has enabled the A/B experiment mode and is not editing any variant, false otherwise.
   * @returns {boolean} A boolean value indicating if the user has enabled the A/B split screen mode.
   */
  const isABSplitScreenEnabled = () => {
    return isABExperimentModeEnabled && !isEditingExperiment()
  }

  /**
   * Sets the editing state for the given experiment variant to true and the other variant to false.
   * @param {ABExperimentVariant} variant The experiment variant to edit, either 'a' or 'b'.
   */
  const editExperimentVariant = (variant: ABExperimentVariant) => {
    setIsEditingVariantA(variant === 'a')
    setIsEditingVariantB(variant === 'b')
  }

  /**
   * Sets the editing state for both experiment variants to false.
   */
  const disableEditingExperiment = () => {
    setIsEditingVariantA(false)
    setIsEditingVariantB(false)
  }

  /**
   * Updates the selected segment sizes for the experiment variants and control group with the given value and key.
   * @param {number} value The percentage value of the segment size, between 0 and 1.
   * @param {ABExperimentSegement} key The segment key, either 'Variant A', 'Variant B', or 'Control'.
   */
  const handleExperimentSegementSizeChange = (value: number, key: ABExperimentSegement) => {
    setSelectedExperimentSegementSizes({
      ...selectedExperimentSegementSizes,
      [key]: value,
    })
  }

  /**
   * Initialises the selected segment sizes for the experiment variants and control group with the given segment sizes object or the initial values if not provided.
   * @param {ABExperimentSegmentSizes} [segmentSizes=INITIAL_EXPERIMENT_SEGMENT_SIZES] The segment sizes object containing the percentage values for each segment, between 0 and 1.
   */
  const initExperimentSegmentSizes = (segmentSizes: ABExperimentSegmentSizes = INITIAL_EXPERIMENT_SEGMENT_SIZES) => {
    setSelectedExperimentSegementSizes({
      'Variant A': segmentSizes?.['Variant A'],
      'Variant B': segmentSizes?.['Variant B'],
      Control: segmentSizes?.['Control'],
    })
  }

  /**
   * Returns the total percentage of the selected segment sizes, rounded to the nearest integer.
   * @returns {string} A string representing the total percentage of the selected segment sizes, with a '%' sign appended.
   */
  const getSelectedSegmentSizeTotal = (): string => {
    const total: number = Object.values(selectedExperimentSegementSizes).reduce(
      (acc: number, value: number) => acc + value,
      0,
    ) as number
    return (total * 100).toFixed(0)
  }

  return {
    showExperimentModal,
    setShowExperimentModal,
    isABExperimentModeEnabled,
    setIsABExperimentModeEnabled,
    isEditingVariantA,
    isEditingVariantB,
    isABSplitScreenEnabled,
    isEditingExperiment,
    editExperimentVariant,
    disableEditingExperiment,
    selectedExperimentSegementSizes,
    handleExperimentSegementSizeChange,
    initExperimentSegmentSizes,
    getSelectedSegmentSizeTotal,
  }
}

export default useABExperiment
