/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { message, Row, Select, Skeleton, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import { DynamicCell, Heading, Search } from 'components/common'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'
import useStore from 'store/useStore'

import type { SortOrder } from 'antd/es/table/interface'
import type { ColumnProps } from 'antd/lib/table'

import type { RevenueStats } from 'models/dashboard.model'
import type { AccountForecastTableRow, TableColumn } from 'models/insights'
import { DataFormatEnum, TableColumnTypeEnum } from 'models/insights'

function getRandomInt(min = 0, max = 1) {
  // eslint-disable-next-line no-param-reassign
  ;[min, max] = [Math.ceil(min), Math.floor(max)]
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Generate Ant Design table columns from data.
 * @see https://ant.design/components/table/#column
 */
export function getTableColumns(
  data: TableColumn[] = [],
  defaultSortKey: string = '',
  defaultSortDirection: SortOrder = 'ascend',
): ColumnProps<AccountForecastTableRow>[] {
  return data.map((column) => {
    const output: ColumnProps<AccountForecastTableRow> = {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      render: (_: any, record: AccountForecastTableRow) => {
        const warning = getRandomInt(0, 5)
        record.totalUsers = { value: getRandomInt(10, 999) }
        record.customerWarnings = { value: warning, segment: warning > 0 ? 'low' : '' }
        record.potentialChampions = { value: getRandomInt(0, 15) }
        record.productFeedback = { value: getRandomInt(0, 30) }
        // Keep ARR down below 1,000,000
        if ((record.revenue as number) >= 1000000) {
          record.revenue = (record.revenue as number) / 100
        }
        return (
          <div className='table__row'>
            <DynamicCell column={column} record={record} />
          </div>
        )
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (a: AccountForecastTableRow, b: AccountForecastTableRow) => {
        switch (column.type) {
          case TableColumnTypeEnum.String:
            return sortAlphabetically((a[column.key].value as string) ?? '', (b[column.key].value as string) ?? '')

          case TableColumnTypeEnum.Number:
          case TableColumnTypeEnum.ImpactPercentage:
          case TableColumnTypeEnum.Percentage:
            let firstValue = a[column.key] ? a[column.key].value : 0
            let secondValue = b[column.key] ? b[column.key].value : 0

            // Check for percentage values that are strings and parse out the value, and check for NaN.
            if (typeof firstValue === 'string') {
              firstValue = parseInt(firstValue, 10)
              if (isNaN(firstValue)) {
                firstValue = 0
              }
            }
            if (typeof secondValue === 'string') {
              secondValue = parseInt(secondValue, 10)
              if (isNaN(secondValue)) {
                secondValue = 0
              }
            }

            return sorterNumbers(firstValue as number, secondValue as number)

          case TableColumnTypeEnum.RevenueForecast:
            return sorterNumbers(
              (a[column.key].value as RevenueStats)?.change ?? null,
              (b[column.key].value as RevenueStats)?.change ?? null,
            )

          default:
            return sorterNumbers((a[column.key].value as number) ?? 0, (b[column.key].value as number) ?? 0)
        }
      },
    }
    if (column.key === defaultSortKey) {
      output.defaultSortOrder = defaultSortDirection
    }
    return output
  })
}

const AccountsTable = observer(() => {
  const { insightsStore } = useStore()
  const { accountForecast, fetchAccountForecast, isLoadingAccountForecast } = insightsStore

  const [selectedRenewal, setSelectedRenewal] = useState('')
  const [selectedForecast, setSelectedForecast] = useState('')
  const [accountsTableSource, setAccountsTableSource] = useState<AccountForecastTableRow[]>(
    accountForecast?.table_rows || [],
  )

  const [searchInput, setSearchInput] = useState<string>('')
  const accountTableColumns = useMemo(
    () =>
      getTableColumns(
        [
          {
            key: 'account_name',
            title: 'Account',
            type: TableColumnTypeEnum.String,
            format: DataFormatEnum.Text,
            nullable: false,
          },
          {
            key: 'contract_end_date',
            title: 'Renewal Date',
            type: TableColumnTypeEnum.String,
            format: DataFormatEnum.Text,
            nullable: true,
          },
          {
            key: 'totalUsers',
            title: 'Total Users',
            type: TableColumnTypeEnum.Number,
            format: DataFormatEnum.Comma,
            decimal: 0,
            nullable: false,
          },
          {
            key: 'customerWarnings',
            title: 'Customer Warnings',
            type: TableColumnTypeEnum.Number,
            format: DataFormatEnum.Comma,
            decimal: 0,
            nullable: false,
          },
          {
            key: 'potentialChampions',
            title: 'Potential Champions',
            type: TableColumnTypeEnum.Number,
            format: DataFormatEnum.Comma,
            decimal: 0,
            nullable: false,
          },
          {
            key: 'productFeedback',
            title: 'Product Feedback',
            type: TableColumnTypeEnum.Number,
            format: DataFormatEnum.Comma,
            decimal: 0,
            nullable: false,
          },
          {
            key: 'revenue',
            title: 'Forecasted ARR',
            type: TableColumnTypeEnum.Number,
            format: DataFormatEnum.CurrencyKMB,
            decimal: 1,
            nullable: false,
          },
        ],
        'contract_end_date',
      ),
    [accountForecast?.table_columns],
  )

  // Initial fetch of the account table data.
  useEffect(() => {
    fetchAccountForecast({ page: 1, pageSize: 10, sortKey: 'contractEndDate', sortDirection: 'asc' }).catch((error) => {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    })
  }, [])

  // Helper Function to filter the data as needed
  const updateFilters = (searchString = '') => {
    setAccountsTableSource(
      accountForecast?.table_rows.filter((row) => {
        const searchCondition = searchString
          ? `${row.account_name.value}`.toLowerCase().includes(searchString.toLowerCase())
          : true
        const renewalCondition = selectedRenewal ? row.contract_end_period.value === selectedRenewal : true
        const forecastCondition = row.revenue_period.value === selectedForecast

        return searchCondition && renewalCondition && forecastCondition
      }) || [],
    )
  }

  // Initial load of account data based on the filter.
  useEffect(() => {
    setSelectedForecast(accountForecast?.filter_values?.revenue_period[0] || '')
    updateFilters()
  }, [accountForecast?.table_rows])

  // When updating the filters, change the data
  useEffect(() => {
    updateFilters()
  }, [selectedRenewal, selectedForecast])

  // Handle search inputs
  const handleOnSearch = (value: string) => {
    updateFilters(value)
  }

  // Handle closing the search
  const handleClose = () => {
    updateFilters()
    setSearchInput('')
  }

  // Build the filters for the table based on the fetched data
  // `selectedForecast` is included as a dependency because
  // the defaultValue isn't shown when it is not included
  const tableFilters = useMemo(() => {
    if (!accountForecast?.filter_columns || !accountForecast?.filter_values) {
      return null
    }
    return (
      <>
        <Select
          data-testid='renewal-dropdown'
          defaultValue=''
          style={{ width: 210 }}
          onChange={(value) => {
            setSelectedRenewal(value)
          }}>
          <Select.Option value='' key='rp-all'>
            Renewal Date: <strong>All</strong>
          </Select.Option>
          {accountForecast?.filter_values.contract_end_period.map((period) => (
            <Select.Option value={period} key={`cep-${period}`}>
              Renewal Date: <strong>{period}</strong>
            </Select.Option>
          ))}
        </Select>
      </>
    )
  }, [accountForecast?.filter_columns, accountForecast?.filter_values, selectedForecast])

  return (
    <div className='account-forecast-container' data-testid='accounts-container'>
      <Row justify='space-between'>
        <Heading level='1' variant='1'>
          Accounts
        </Heading>
        <div className='header-right'>
          {tableFilters}
          <Search
            placeholder='Search...'
            onClose={handleClose}
            searchType={['onEnter', 'onType']}
            onFilter={handleOnSearch}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            large
          />
        </div>
      </Row>
      {isLoadingAccountForecast && <Skeleton active />}
      {!isLoadingAccountForecast && (
        <Table
          data-testid='account-forecast-table'
          rowKey={(record) => `${record.account_id}-${record.contract_end_period.value}-${record.revenue_period.value}`}
          rowClassName={'cursor-pointer'}
          dataSource={accountsTableSource}
          columns={accountTableColumns}
          sortDirections={['ascend', 'descend']}
          pagination={{ hideOnSinglePage: true }}
          showSorterTooltip={false}
        />
      )}
    </div>
  )
})
AccountsTable.displayName = 'AccountsTable'

export default AccountsTable
