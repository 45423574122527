import { useEffect, useRef, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useDeepCompareEffect } from 'react-use'

import SpeedometerIndicator from 'components/Insights/components/InsightRow/components/InsightSpedometer/icon'
import {
  createBackgroundColor,
  getIndicatorRotationAngle,
  getInitialIndicatorRotationAngle,
  sortDrivers,
} from 'components/Insights/components/InsightRow/components/InsightSpedometer/utils'
import { getDefaultLevel } from 'components/Insights/components/InsightsDetails/index.utils'

import type { ActiveElement, ChartData, ChartEvent } from 'chart.js'

import type { InsightDriverSegmentV2, UISpeedometerDriver } from 'models/insights'

import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

interface InsightsSpeedometerProps {
  insightDriverSegment: { [key: string]: InsightDriverSegmentV2 }
  setDisplayedContentIndex: (index: number) => void
}

const InsightsSpeedometer = ({ insightDriverSegment, setDisplayedContentIndex }: InsightsSpeedometerProps) => {
  const INDICATOR_STARTING_ANGLE_OFFSET = -90
  const [indicatorRotoationAngle, setIndicatorRotoationAngle] = useState<number>(INDICATOR_STARTING_ANGLE_OFFSET)
  const chartRef: React.ForwardedRef<ChartJSOrUndefined<'doughnut'>> = useRef(null)
  const [chartData, setChartData] = useState<ChartData<'doughnut'>>({
    labels: [],
    datasets: [],
  })
  const [accountCount, setAccountCount] = useState(0)

  const transformedDrivers = Object.entries(insightDriverSegment).map((ids) => ({
    level: ids[0],
    ...ids[1],
  }))
  const sortedDrivers = sortDrivers(transformedDrivers)

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      tableUnderChart: {
        display: false,
      },
    },
    onClick: (e: ChartEvent, elements: ActiveElement[]) => {
      if (!elements.length) return
      const { endAngle } = elements[0].element as unknown as { endAngle: number }
      const driverIndex = elements[0].index ?? 0
      setDisplayedContentIndex(driverIndex)
      setAccountCount(sortedDrivers[driverIndex].account_count)
      setIndicatorRotoationAngle(getIndicatorRotationAngle(endAngle, INDICATOR_STARTING_ANGLE_OFFSET))
    },
  }

  // Ensure the chart updates when we change dimensions
  useDeepCompareEffect(() => {
    const defaultLevel = getDefaultLevel(Object.keys(insightDriverSegment))
    setAccountCount(insightDriverSegment[defaultLevel].account_count)
    setIndicatorRotoationAngle(getInitialIndicatorRotationAngle(sortedDrivers, INDICATOR_STARTING_ANGLE_OFFSET))
  }, [insightDriverSegment])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const data = {
      labels: sortedDrivers.map((driver: UISpeedometerDriver) => driver.level),
      datasets: [
        {
          label: 'Level',
          data: sortedDrivers.map((driver: UISpeedometerDriver) => driver.account_count),
          backgroundColor: sortedDrivers.map((driver: UISpeedometerDriver) =>
            createBackgroundColor(chart.ctx, chart.chartArea, driver.level),
          ),
          borderWidth: 0,
          hoverOffset: 10,
          hoverBorderWidth: 0,
          circumference: 180,
          rotation: 270,
          weight: 0.2,
        },
      ],
    }

    setChartData(data)
    const defaultLevel = getDefaultLevel(Object.keys(insightDriverSegment))
    setAccountCount(insightDriverSegment[defaultLevel].account_count)

    if (INDICATOR_STARTING_ANGLE_OFFSET !== indicatorRotoationAngle) return
    setIndicatorRotoationAngle(getInitialIndicatorRotationAngle(sortedDrivers, INDICATOR_STARTING_ANGLE_OFFSET))
  }, [chartRef])

  // if drivers is empty don't show anything
  if (!insightDriverSegment) return null

  const transformStyle = {
    transform: `rotate(${indicatorRotoationAngle}deg)`,
    WebkitTransform: `rotate(${indicatorRotoationAngle}deg)`,
    MozTransform: `rotate(${indicatorRotoationAngle}deg)`,
    OTransform: `rotate(${indicatorRotoationAngle}deg)`,
  }

  return (
    <div data-testid='insight-row-insight-spedometer-wrapper' className='insights-speedometer'>
      <Doughnut ref={chartRef} data={chartData} options={options} />
      <SpeedometerIndicator style={transformStyle} className='insights-speedometer__indicator' />
      <div className='insights-speedometer__account-count' data-testid='insights-speedometer__account-count'>
        <span className='count'>{accountCount}</span>
        Accounts
      </div>
    </div>
  )
}
InsightsSpeedometer.displayName = 'InsightsSpeedometer'

export default InsightsSpeedometer
