import { useFlags } from 'launchdarkly-react-client-sdk'

import { Callout } from 'components/common'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'

const MockDataCallout = () => {
  const { sandbox } = useFlags<LaunchDarklyFeatureFlags>()

  if (!sandbox) return null

  return (
    <Callout variant='1' tooltipText='This module is mocked for all Sandbox Motions'>
      MOCK DATA
    </Callout>
  )
}
MockDataCallout.displayName = 'MockDataCallout'

export default MockDataCallout
