import { Alert } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { Heading } from 'components/common'
import { IconInfo } from 'components/common/Icons/Icons'
import AllTimeCard from 'components/MotionObservability/ActiveMotionsReporting/AllTimeCard'
import PastWeeksCard from 'components/MotionObservability/ActiveMotionsReporting/PastWeeksCard'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import useStore from 'store/useStore'

const ActiveMotionsReporting = observer(() => {
  const { observabilityStore } = useStore()
  const {
    fetchTenantMotionActionPastWeeks,
    fetchTenantMotionActionTotals,
    fetchTenantMotionRangeTotals,
    isLoadingFetchTenantMotionActionPastWeeks,
    isLoadingFetchTenantMotionActionTotals,
    isLoadingFetchTenantMotionRangeTotals,
    tenantMotionsActionTotals,
  } = observabilityStore

  const currentDate = dayjs()
  // Subtract 4 weeks
  const fourWeeksAgo = currentDate.subtract(4, 'week').valueOf()
  const defaultFourWeeks = { dateFrom: fourWeeksAgo, dateTo: currentDate.valueOf() }

  useEffect(() => {
    fetchTenantMotionActionTotals().catch(console.error)
    fetchTenantMotionActionPastWeeks(defaultFourWeeks).catch(console.error)
    fetchTenantMotionRangeTotals(defaultFourWeeks).catch(console.error)
  }, [Sandbox.isEnabled(), Demo.mockApiIsEnabled()])

  const isDataLoading =
    isLoadingFetchTenantMotionActionTotals ||
    isLoadingFetchTenantMotionActionPastWeeks ||
    isLoadingFetchTenantMotionRangeTotals
  const emptyTenantMotionData = (tenantMotionsActionTotals?.totals?.actions ?? 0) === 0
  const displayEmptyState = emptyTenantMotionData && !isDataLoading

  return (
    <section className='motion-observability'>
      <Heading level='1' variant='1'>
        Motions
      </Heading>
      {displayEmptyState && (
        <Alert
          className='motion-observability-alert'
          data-testid='motion-observability-alert'
          message={
            <>
              Track the customer impact of your Motions. Need help creating your first one? See our guide here{' '}
              <a
                href='https://magnifyio.zendesk.com/hc/en-us/articles/17125471855515-motions'
                target='_blank'
                rel='external noreferrer'>
                here
              </a>
              .
            </>
          }
          icon={<IconInfo />}
          type='info'
          showIcon
        />
      )}
      <div className='motion-observability__wrapper'>
        <AllTimeCard />
        <PastWeeksCard pastWeeksOption={defaultFourWeeks} />
      </div>
    </section>
  )
})
ActiveMotionsReporting.displayName = 'ActiveMotionsReporting'

export default ActiveMotionsReporting
