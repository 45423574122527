import type { ABExperimentVariant } from 'models/motion.model'

interface EditVariantLabelProps {
  variant: ABExperimentVariant
}

const EditVariantLabel = ({ variant }: EditVariantLabelProps) => {
  return (
    <div className='edit-variant-label'>
      <span className='edit-variant-label__dot'></span>
      <p className='edit-variant-label__text'>Editing variant {variant.toUpperCase()}</p>
    </div>
  )
}

export default EditVariantLabel
