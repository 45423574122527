import { Alert, Col, Radio, Row, Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import EmptyStateImage from 'assets/images/revenue-retention-empty-state.svg?react'
import { Heading } from 'components/common'
import { IconInfo } from 'components/common/Icons/Icons'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import { LogoMark } from 'components/Navigation/NavigationIcons'
import { baseChartOptions, transformPayload, transformRevenueData } from 'components/RevenueRetentionForecast/utils'
import useStore from 'store/useStore'

import type { ChartData } from 'chart.js'
import type { Context } from 'chartjs-plugin-datalabels'
import type { ReactElement } from 'react'

import type { RevenueTrend } from 'models/insights'

import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

const RevenueRetentionForecast = observer(() => {
  const { insightsStore } = useStore()
  const { fetchRevenueRetentionForecast, revenueRetentionForecast, isLoadingRevenueRetentionForecast, dimensions } =
    insightsStore

  // Chart
  const chartRef: React.ForwardedRef<ChartJSOrUndefined<'bar'>> = useRef(null)
  const [chartData, setChartData] = useState<{
    labels: string[]
    datasets: {
      label: string
      data: number[]
      backgroundColor: string
      order: number
      barThickness: number
      tooltip: string[]
    }[]
  }>({
    labels: [],
    datasets: [],
  })
  const [chartMode, setChartMode] = useState<'value' | 'percent'>('value')
  const [chartOptions, setChartOptions] = useState({})
  const [chartDropdown, setChartDropdown] = useState<{ value: string; label: ReactElement<any, any> }[]>([])
  const [dimensionOptions, setDimensionOptions] = useState<{ value: string; label: JSX.Element }[]>([
    {
      label: (
        <span>
          <strong>Overall</strong>
        </span>
      ),
      value: 'overall',
    },
  ])
  const [currentForecast, setCurrentForecast] = useState<RevenueTrend>({} as RevenueTrend)
  const [currentDimension, setCurrentDimension] = useState('overall')

  // Initialize chart with default values
  useEffect(() => {
    // Don't do anything until the chart is rendered on the page
    const chart = chartRef.current
    if (!chart) {
      return
    }

    // Data hasn't loaded yet, don't do anything
    if (!revenueRetentionForecast) {
      return
    }

    // Build the dropdown options for highlighting a forecast
    const forecasts = revenueRetentionForecast?.revenue_trend?.filter((trend) => trend.type === 'forecast') ?? []
    const dropdownOptions = forecasts.map(({ label }, index) => ({
      value: `${index}`,
      label: (
        <>
          Forecast: <strong>{label}</strong>
        </>
      ),
    }))
    setChartDropdown(dropdownOptions)
    if (Object.keys(currentForecast).length === 0 && forecasts[0]) {
      setCurrentForecast(forecasts[0])
    }

    // Build the dropdown options for dimension
    const dimensionKeys = Object.keys(dimensions || {})
    if (dimensionKeys.length > 0) {
      const newDimensionOptions = dimensionKeys.map((dimension: string) => ({
        label: (
          <span>
            <strong>{dimension[0].toUpperCase() + dimension.substring(1)}</strong>
          </span>
        ),
        value: dimension,
      }))
      setDimensionOptions(newDimensionOptions)
    }

    // Assuming the payload variable contains your provided JSON data
    const result = transformPayload(revenueRetentionForecast, chartMode)
    const tableData = transformRevenueData(revenueRetentionForecast, chartMode)
    const forecastCount = revenueRetentionForecast?.revenue_trend?.filter(({ type }) => type === 'forecast').length ?? 0
    const dataColumnsCount = (revenueRetentionForecast?.revenue_trend?.length ?? 0) - forecastCount
    setChartData(result)
    // We need to update the chart options to include the table data and change out the axis title.
    setChartOptions({
      ...baseChartOptions,
      plugins: {
        ...baseChartOptions.plugins,
        datalabels: {
          ...baseChartOptions.plugins.datalabels,
          formatter: (value: number, context: Context) => {
            // TODO Is it possible to get the height of the bar and check that instead? Doesn't seem so.
            if (chartMode === 'value') {
              // If the value is less than 4, don't show it because the bar is too small
              if (Math.abs(value) <= 4) {
                return ''
              }
              return value.toFixed(1)
            }
            // If the value is less than 9, don't show it because the bar is too small
            if (Math.abs(value) <= 9) {
              return ''
            }
            return `${Math.trunc(value)}%`
          },
        },
        tableUnderChart: {
          ...baseChartOptions.plugins.tableUnderChart,
          datasets: tableData,
          format:
            chartMode === 'value'
              ? (value: number) => `$${value}`
              : (value: number, datasetIndex: number) => {
                  if (datasetIndex === 0) {
                    return `${Math.trunc(value)}%`
                  } else {
                    return `$${value}`
                  }
                },
          forecastCount,
          dataColumnsCount,
        },
      },
      scales: {
        ...baseChartOptions.scales,
        y: {
          ...baseChartOptions.scales.y,
          title: {
            ...baseChartOptions.scales.y.title,
            text: chartMode === 'value' ? 'ARR ($M)' : 'ARR (%)',
          },
        },
      },
    })
  }, [chartRef, chartMode, revenueRetentionForecast, dimensions])

  // When the chart first renders, resize it to fix any DPI issues.
  useEffect(() => {
    const chartInstance = chartRef.current
    if (chartInstance) {
      chartInstance.resize()
    }
  }, [chartRef.current])

  useEffect(() => {
    fetchRevenueRetentionForecast({ dimension: currentDimension }).catch(console.error)
  }, [currentDimension])

  // Compute the values for the currentForecast
  const nrrValue = formatNumber({
    number: currentForecast?.past_revenue?.['value']?.value || 0,
    format: currentForecast?.past_revenue?.['value']?.format,
    decimal: currentForecast?.past_revenue?.['value']?.decimal,
  })
  const nrrPercent = formatNumber({
    number: currentForecast?.past_revenue?.['percent']?.value || 0,
    format: currentForecast?.past_revenue?.['percent']?.format,
    decimal: currentForecast?.past_revenue?.['percent']?.decimal,
  })
  const nrr = chartMode === 'value' ? `${nrrValue} (${nrrPercent})` : `${nrrPercent} (${nrrValue})`
  // Starting ARR should always be dollars
  const startingArr = formatNumber({
    number: currentForecast?.future_revenue?.value?.value || 0,
    format: currentForecast?.future_revenue?.value?.format,
    decimal: currentForecast?.future_revenue?.value?.decimal,
  })
  const renewal = formatNumber({
    number: currentForecast?.events?.[1]?.[chartMode]?.value || 0,
    format: currentForecast?.events?.[1]?.[chartMode]?.format,
    decimal: currentForecast?.events?.[1]?.[chartMode]?.decimal,
  })
  const expansion = formatNumber({
    number: currentForecast?.events?.[0]?.[chartMode]?.value || 0,
    format: currentForecast?.events?.[0]?.[chartMode]?.format,
    decimal: currentForecast?.events?.[0]?.[chartMode]?.decimal,
  })
  const churn = formatNumber({
    number: currentForecast?.events?.[2]?.[chartMode]?.value || 0,
    format: currentForecast?.events?.[2]?.[chartMode]?.format,
    decimal: currentForecast?.events?.[2]?.[chartMode]?.decimal,
  })

  // Empty State
  const showEmptyState =
    !isLoadingRevenueRetentionForecast &&
    (!revenueRetentionForecast || Object.keys(revenueRetentionForecast).length === 0)

  if (showEmptyState) {
    return (
      <section className='forecast' data-testid='revenue-retention-forecast'>
        <div className='heading-wrapper'>
          <Heading level='1' variant='1'>
            Revenue Retention Forecast
          </Heading>
        </div>
        <Alert
          className='forecast-alert'
          data-testid='forecast-alert'
          message={
            <>
              Use Magnify's revenue forecast to identify high risk accounts. Learn more about our Revenue Retention
              Forecast{' '}
              <a
                href='https://magnifyio.zendesk.com/hc/en-us/articles/24155452133659-Revenue-Retention-Forecast'
                target='_blank'
                rel='external noreferrer'>
                here
              </a>
              .
            </>
          }
          icon={<IconInfo />}
          type='info'
          showIcon
        />
        <Row justify='center' align='middle' className='forecast-content'>
          <Col>
            <EmptyStateImage />
          </Col>
        </Row>
      </section>
    )
  }

  return (
    <Spin size='large' spinning={isLoadingRevenueRetentionForecast}>
      <section className='forecast' data-testid='revenue-retention-forecast'>
        <div className='heading-wrapper'>
          <Heading level='1' variant='1'>
            Revenue Retention Forecast
          </Heading>
          <div className='forecast-controls'>
            <Select
              defaultValue={dimensionOptions[0] as unknown as string}
              style={{ width: 200 }}
              onChange={(e: string) => {
                setCurrentDimension(e)
              }}
              options={dimensionOptions}
            />
            <Select
              defaultValue='0'
              style={{ width: 194 }}
              onChange={(e) => {
                setCurrentForecast(
                  revenueRetentionForecast?.revenue_trend
                    .filter((trend) => trend.type === 'forecast')
                    .at(Number.parseInt(e, 10)) || ({} as RevenueTrend),
                )
              }}
              options={chartDropdown}
            />
            <Radio.Group
              defaultValue='value'
              size='large'
              onChange={(event) => setChartMode(event?.target?.value as 'value' | 'percent')}>
              <Radio.Button value='value'>$</Radio.Button>
              <Radio.Button value='percent'>%</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <Row justify='center' align='middle' className='forecast-content'>
          <Col className='forecast-data-col'>
            <div className='forecast-cta'>
              <div className='forecast-cta-top'>
                <div className='forecast-cta-top-left'>
                  <LogoMark />
                  <div className='forecast-top-text'>Forecast</div>
                </div>
              </div>
              <div className='forecast-cta-nrr-title'>Net Revenue Retention</div>
              <div className='forecast-cta-nrr'>{nrr}</div>
              <div className='forecast-cta-starting-arr-title'>Starting ARR</div>
              <div className='forecast-cta-starting-arr'>{startingArr}</div>
              <div className='forecast-legend'>
                <div className='forecast-legend-item'>
                  <div className='forecast-legend-left'>
                    <div className='forecast-legend-color' style={{ backgroundColor: '#6C84F4' }} />
                    <div className='forecast-legend-text'>Renewal</div>
                  </div>
                  <div className='forecast-legend-value'>{renewal}</div>
                </div>
                <div className='forecast-legend-item'>
                  <div className='forecast-legend-left'>
                    <div className='forecast-legend-color' style={{ backgroundColor: '#2E4FEF' }} />
                    <div className='forecast-legend-text'>Expansion</div>
                  </div>
                  <div className='forecast-legend-value'>+{expansion}</div>
                </div>
                <div className='forecast-legend-item'>
                  <div className='forecast-legend-left'>
                    <div className='forecast-legend-color' style={{ backgroundColor: '#F14062' }} />
                    <div className='forecast-legend-text'>Churn</div>
                  </div>
                  <div className='forecast-legend-value'>{churn}</div>
                </div>
              </div>
            </div>
          </Col>
          <Col className='forecast-chart-col'>
            <h2>Net Revenue Retention: Renewal, Expansion, Churn</h2>
            <div className='chart-container'>
              <Bar
                data-testid='canvas'
                ref={chartRef}
                data={chartData as unknown as ChartData<'bar'>}
                options={chartOptions}
              />
            </div>
          </Col>
        </Row>
      </section>
    </Spin>
  )
})
RevenueRetentionForecast.displayName = 'RevenueRetentionForecast'

export default RevenueRetentionForecast
