import { observer } from 'mobx-react-lite'

import CalculatedDataItem from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/CalculatedData/Item'
import useStore from 'store/useStore'

import type { DragEvent } from 'react'

import { FieldTypeEnum } from 'models/metadata.model'
import type { SourceData, AggregationData } from 'models/motion/motionBuilder.model'

interface CalculatedDataProps {
  handleDragStart?: (e: DragEvent<HTMLDivElement>, item: SourceData) => void
  handleDragEnd?: (e: DragEvent<HTMLDivElement>, item?: SourceData, position?: any) => void
}

export const CalculatedData = observer(({ handleDragStart, handleDragEnd }: CalculatedDataProps) => {
  const { aggregationsDataStore } = useStore()
  const { aggregationsList } = aggregationsDataStore

  const onDragStart = (e: DragEvent<HTMLDivElement>, item: AggregationData) => {
    const { platform, object, field } = item

    const options = {
      ...(platform && { platform: platform }),
      ...(object && { object: object }),
      key: field,
      type: FieldTypeEnum.Number,
      isDynamicInput: false,
    }

    const dataSource = {
      ...options,
      ...item,
    }

    handleDragStart?.(e, dataSource)
  }

  return (
    <div className='segment-sidebar__calculated-data' data-testid='segment-sidebar__calculated-data'>
      <div className='menu' data-testid='menu'>
        {aggregationsList.map((item: AggregationData, index: number) => (
          <CalculatedDataItem
            key={`${item.aggregationUuid}`}
            item={item}
            index={index}
            onDragStart={onDragStart}
            handleDragEnd={handleDragEnd}
          />
        ))}
      </div>
    </div>
  )
})
