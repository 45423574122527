import type { MlMetricCategory } from 'models/account.model'
import { MlMetricCategoryEnum } from 'models/account.model'
import { DriverSegmentEnum } from 'models/insights'

/**
 * Builds a heading based on the target metric category.
 *
 * @param {string | undefined} target - The target metric category as a string.
 * @returns {string} The generated heading based on the target category.
 */
export const buildSegmentHeading = (target: string | undefined) => {
  switch (target as MlMetricCategory) {
    case MlMetricCategoryEnum.Churn:
      return 'Churn Driver Segments'
    case MlMetricCategoryEnum.Conversion:
      return 'Conversion Driver Segments'
    case MlMetricCategoryEnum.Expansion:
      return 'Expansion Driver Segments'
    default:
      return ''
  }
}

/**
 * Get the default risk level based on impact and available levels.
 *
 * @param {string[]} levels - An array of risk levels ('low', 'medium', 'high').
 * @returns {Exclude<MLMetricRiskLevel, null>} The default risk level ('low', 'medium', or 'high').
 *
 * The order of segments will be ['high', 'medium', 'low'] (in this order)
 *
 */
export const getDefaultLevel = (levels: string[]): DriverSegmentEnum => {
  if (levels.includes(DriverSegmentEnum.High)) {
    return DriverSegmentEnum.High
  } else if (levels.includes(DriverSegmentEnum.Medium)) {
    return DriverSegmentEnum.Medium
  }

  return DriverSegmentEnum.Low
}

export const isNotNullish = (value: any): boolean => {
  return value !== null && value !== undefined
}
