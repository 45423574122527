import { Button } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useContext, useMemo } from 'react'
import { Handle, Position, useStoreState } from 'react-flow-renderer'

import { IconTrash, IconNodeTarget } from 'components/common/Icons/Icons'
import SegmentReporting from 'components/common/MotionThumbnail/Nodes/Segment/SegmentReporting/SegmentReporting'
import {
  getPlatformsToBeDisplayed,
  getActionPlatform,
  getSegmentReportingData,
} from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils'
import {
  getLoopEdgeId,
  loopArrowEdge,
  unmarkTargetNodes,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import { preProcessNode } from 'components/MotionBuilder/Utils/processNodes'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import useDemoFeature from 'hooks/useDemoFeature'
import { BuilderIcon } from 'services/Utils/BuilderIcon'
import { getMergeUIElements } from 'services/Utils/merge/MergeAction.utils'
import { clone } from 'services/Utils/misc'
import { getMarkedEdges, isLoopEdge } from 'services/Utils/motionHelpers/motionHelpers.utils'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Node, NodeProps } from 'react-flow-renderer'

import type { SegmentBuilderData } from 'models/motion/motionBuilder.model'
import { HeighlightNodeStatusEnum } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

export const Segment = observer(({ data, isConnectable, id }: NodeProps<SegmentBuilderData>) => {
  const { loopPanelStore, motionStore, actionsStore, mergePanelStore, observabilityStore } = useStore()
  const { currentSourceLoopId, setLoop, loops, setCurrentSourceLoopId, setIsSelectingTarget } = loopPanelStore
  const { onShowDrawer, setElements, payload } = data
  const { isMockApiEnabled } = useDemoFeature()

  const { setSegmentBuilderData } = useContext(SegmentBuilderContext)

  const nodes: Node<SegmentBuilderData>[] = useStoreState((state) => state.nodes) as Node<SegmentBuilderData>[]
  const edges = useStoreState((state) => state.edges)
  const nodeStatus = data.status
  const selectedNode = nodes.find((node) => node.id === id)
  const nodesWithoutSelectedOne = nodes.filter((node) => node.id !== id)

  const platforms =
    data.type === NodeTypeEnum.Action
      ? getActionPlatform(data, clone(actionsStore?.actions))
      : getPlatformsToBeDisplayed(payload)
  const loopTargetId = loops.get(id)
  const isFocused = motionStore.focusedNode === id
  const isAlreadyAnEdgeForThisNode =
    currentSourceLoopId && edges.find((edge) => edge.id === getLoopEdgeId(currentSourceLoopId, id) && isLoopEdge(edge))

  let platformLogoMargin = 0
  const defaultLogoMargin = 28

  const getDescriptionTopMargin = () => {
    const descriptionLength = data?.description?.length || 0
    let defaultTopMargin = 12

    if (descriptionLength > 48 && descriptionLength < 96) defaultTopMargin = -2
    if (descriptionLength > 95) defaultTopMargin = -14

    return defaultTopMargin
  }

  const handleNodeMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (currentSourceLoopId && !isAlreadyAnEdgeForThisNode) {
      // add the loop arrow/edge
      const loopEdge = loopArrowEdge(currentSourceLoopId, id)
      setElements([...nodes, ...edges, loopEdge])
    }
  }

  const handleNodeMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!loopTargetId && isAlreadyAnEdgeForThisNode) {
      const edgesWithoutLoopOne = edges.filter((edge) => {
        return !(edge.id === getLoopEdgeId(currentSourceLoopId, id) && isLoopEdge(edge))
      })

      setElements([...nodes, ...edgesWithoutLoopOne])
    }
  }

  // TODO: Remove this provisory MAGPROD-978
  const handleClickOnMockSegment = () => {
    motionStore.setFocusedNode(id)
    // add a flag to know when needs to be displayed the special segment
    data.isHiddenSegment = true
    onShowDrawer(data)
  }
  const handleClickNode = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    // Regular Node
    if (!currentSourceLoopId && !mergePanelStore.isSelectingTarget) {
      if (data.isHiddenSegment) {
        // remove the flag for the special segment if is set
        delete data.isHiddenSegment
      }

      motionStore.setFocusedNode(id)

      // Preprocess the node data to be displayed in the config panel.
      const processedData = await preProcessNode(data)

      onShowDrawer(processedData)
      return
    }

    // it was clicked in context of loop selection
    if (currentSourceLoopId && isAlreadyAnEdgeForThisNode) {
      const sourceNode: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === currentSourceLoopId)

      if (selectedNode && selectedNode.data && sourceNode && sourceNode.data && nodesWithoutSelectedOne?.length) {
        const selectedNodeData = selectedNode.data
        delete selectedNodeData?.status

        selectedNodeData.loopSourceId = currentSourceLoopId
        sourceNode.data.targetNodeId = id
        const targetNode = { ...selectedNode, data: selectedNodeData }

        setLoop(currentSourceLoopId, id)
        setCurrentSourceLoopId(null)
        setIsSelectingTarget(false)
        const elmentsWithLoopConfig = [...unmarkTargetNodes(nodesWithoutSelectedOne), targetNode, ...edges]

        // Mark the chain of edges from target to source restricted by loop conditions
        const elementsWithMarkedEdges = getMarkedEdges({
          elements: elmentsWithLoopConfig,
          nodeSourceId: currentSourceLoopId,
          nodeTargetId: id,
          elementsType: [NodeTypeEnum.Loop, NodeTypeEnum.Branch, NodeTypeEnum.Merge],
        })

        // Update the SegmentBuilderData with the selected targetNodeId
        setSegmentBuilderData((prev) => {
          return { ...prev, targetNodeId: id }
        })

        setElements(elementsWithMarkedEdges)
      }
      return
    }

    // Scenario when a merge is selected
    if (mergePanelStore.currentSourceMergeId && mergePanelStore.isSelectingTarget) {
      if (mergePanelStore.currentSourceMergeId === id) {
        // don't do anything when is clicked on the opened merge
        return
      }

      const currentMergeTargets = mergePanelStore.merges.get(mergePanelStore.currentSourceMergeId)
      if (currentMergeTargets) {
        mergePanelStore.setMerge(
          mergePanelStore.currentSourceMergeId,
          currentMergeTargets.map((target) => (target.isActive ? { ...target, isActive: false, nodeId: id } : target)),
        )
      }
      mergePanelStore.setIsSelectingTarget(false)

      // unmark the target elements
      const cleanNodes = unmarkTargetNodes(nodes)
      const cleanElements = [...cleanNodes, ...edges]
      setElements(cleanElements)

      // mark the selected element
      if (selectedNode) {
        const selectedNodeData = selectedNode.data
        delete selectedNodeData?.status

        const elementsWithoutMerge = cleanElements.filter((element) => element.id !== id)
        const mergeElements = getMergeUIElements({
          elements: [...elementsWithoutMerge, selectedNode],
          sourceId: mergePanelStore.currentSourceMergeId,
          targetId: id,
        })

        setElements([...mergeElements])
      }
    }
  }

  const nodeAttributes =
    nodeStatus === HeighlightNodeStatusEnum.Valid
      ? {
          onMouseOver: handleNodeMouseOver,
          onMouseLeave: handleNodeMouseLeave,
        }
      : undefined

  const handleDeleteNode = () => {
    if (selectedNode) {
      motionStore.setDisplayRemoveModal(true)
      motionStore.setCurrentNode(selectedNode)
    }
  }

  const platformItems = useMemo(() => {
    if (!platforms || platforms?.length <= 0) {
      return null
    }
    return platforms.map((platform: string = '', index: number) => (
      <span
        key={index}
        style={{
          zIndex: platforms.length - index,
          marginLeft: index === 0 ? 0 : (platformLogoMargin += defaultLogoMargin),
        }}
        className='logo'>
        {getMenuItemIcon({ entityType: 'platform', name: platform }, true)}
      </span>
    ))
  }, [platforms])

  return (
    <div
      className={classNames('c-node', `c-${data.shape}`, `node-${data?.status ?? 'no-status'}`, { active: isFocused })}
      data-testid={`node-${id}`}>
      <div
        className={`c-node__node-left target--${data?.status ?? 'no-status'}`}
        style={{ top: getDescriptionTopMargin() }}
        onClick={handleClickNode}>
        <div className='c-node__node-left__platforms'>{platformItems}</div>
        <div className='c-node__node-left__wrapper'>
          {motionStore.isInMotionReportingEnabled ? (
            <SegmentReporting
              title={data?.description}
              items={getSegmentReportingData(
                data?.actionType,
                data?.type,
                data?.nodeId,
                observabilityStore.tenantInMotionReportingTotals,
              )}
            />
          ) : (
            <p className='c-node__node-left__text'>{data.description}</p>
          )}
        </div>
      </div>
      <div
        className={classNames('target--default', `target--${data?.status ?? 'no-status'}`, { target: !!nodeStatus })}
        {...nodeAttributes}
        data-testid={`node-${data.type}`}>
        {data?.status && <IconNodeTarget className='target__icon' />}
        <div className='c-node__section' onClick={handleClickNode}>
          <div className='c-node__content'>
            <div className='c-node__icon'>
              <BuilderIcon
                name={`${data.iconName || data.type}`}
                options={{ width: 46, height: 46, draggable: false }}
              />
            </div>
          </div>
        </div>
      </div>
      {isMockApiEnabled && data.nodeId === '1' && <div className='hidden-btn' onClick={handleClickOnMockSegment}></div>}

      {/* display the remove button only when it's not slecting loop action */}
      {!nodeStatus && !motionStore.isInMotionReportingEnabled ? (
        <Button
          className='delete-node'
          icon={<IconTrash />}
          onClick={handleDeleteNode}
          data-testid='delete-node'></Button>
      ) : undefined}
      <Handle type='source' position={Position.Bottom} id='s-bottom' isConnectable={isConnectable} />
      <Handle type='source' position={Position.Right} id='s-right' isConnectable={isConnectable} />
      <Handle type='target' position={Position.Left} id='t-left' isConnectable={isConnectable} />
      <Handle type='target' position={Position.Top} id='t-top' isConnectable={isConnectable} />
      <Handle type='target' position={Position.Right} id='t-right' isConnectable={isConnectable} />
    </div>
  )
})
Segment.displayName = 'Segment'
