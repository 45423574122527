import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { Position, getEdgeCenter, getMarkerEnd, getSmoothStepPath, useStoreState } from 'react-flow-renderer'

import { getElementById } from 'components/MotionTarget/motionTarget.utils'
import useStore from 'store/useStore'

import type { DragEvent } from 'react'
import type { EdgeProps, FlowElement, Node } from 'react-flow-renderer'

import type { BuilderAction, DroppedAction, SegmentBuilderData } from 'models/motion/motionBuilder.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'

const EdgeSegment = observer(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    arrowHeadType,
    markerEndId,
    target,
    data,
    source,
  }: EdgeProps<{ disabledEdgeFor: string[]; edgeType: NodeTypeEnum; edgeLabel: string }>) => {
    const { motionStore } = useStore()
    const {
      draggedToolBoxElement,
      dragOverElement,
      setDragOverElement,
      setDroppedElement,
      wasUp,
      setGoUp,
      setWasUp,
      setGoDown,
    } = motionStore

    const nodes: Node<SegmentBuilderData>[] = useStoreState((state) => state.nodes) as Node<SegmentBuilderData>[]

    const dropZoneRef = useRef<HTMLDivElement>(null)
    const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition })
    const isEdgeDisabled = data?.disabledEdgeFor?.includes(draggedToolBoxElement?.type ?? '')

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
    const displayBorders = motionStore.displayEdgeDrops && !isEdgeDisabled ? 'edge--bordered' : ''

    const BIG_PLACEHOLDER = 200 //px
    const DEFAULT_PLACEHOLDER = 20 //px
    const NODE_HEIGHT = 100 //px
    const EDGE_PADDING = 4 //px
    const sourceNode: FlowElement<{ type: string }> | undefined = getElementById(nodes, source)
    const isBranch = sourceNode?.data?.type === NodeTypeEnum.Branch

    const placehoder = {
      width: displayBorders ? BIG_PLACEHOLDER : DEFAULT_PLACEHOLDER,
      height: displayBorders ? BIG_PLACEHOLDER : DEFAULT_PLACEHOLDER,
    }

    const [, edgeCenterY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
    })

    const edgeShape = draggedToolBoxElement?.shape
    const xPosition = targetX - BIG_PLACEHOLDER / 2

    const isMergeEdge = data?.edgeType === NodeTypeEnum.Merge

    // TODO: find a better way to define the Y position, for merge isn't working perfectly
    // the goal is no matter how long in the distance between source y and target y, the + sign should be below the source Y with a specific space and should be resposive taking into consideration the source y
    const yPosition =
      sourcePosition !== Position.Right
        ? isMergeEdge
          ? edgeCenterY - 110 * (targetY / sourceY) // 110 is really a magic numer
          : edgeCenterY - BIG_PLACEHOLDER / 2
        : edgeCenterY - NODE_HEIGHT * 0.75 + EDGE_PADDING

    // fixedYPosition is used by the yes/no label
    const fixedYPosition = sourcePosition !== Position.Right ? sourceY : sourceY + NODE_HEIGHT / 2 - EDGE_PADDING

    // taking into consideration merges, the edge x should be based on the source x
    const fixedXPosition = !isMergeEdge ? xPosition : sourceX - BIG_PLACEHOLDER / 2

    const onEdgeDrop = (event: DragEvent, id: string) => {
      event.preventDefault()

      const eventDataTransfer = event.dataTransfer.getData('application/json')
      if (!eventDataTransfer || isEdgeDisabled) {
        return
      }

      const elementsWithZoom: HTMLCollectionOf<Element> = document.getElementsByClassName('c-zoom')
      for (let index = 0; index < elementsWithZoom.length; index++) {
        const element = elementsWithZoom.item(index)
        // remove the zoom class
        element!.className = element!.className.replaceAll(' c-zoom', '')
      }
      setGoUp(false)
      setGoDown(false)
      setWasUp(false)
      setDroppedElement({
        data: { data, ...(JSON.parse(eventDataTransfer) as Partial<BuilderAction>) },
        edgeToDrop: {
          id,
          source,
          target,
        },
      } as DroppedAction)
    }

    const onEdgeDragLeave = (event: DragEvent) => {
      if (isEdgeDisabled) {
        return
      }

      event.preventDefault()

      const elementsWithZoom: HTMLCollectionOf<Element> = document.getElementsByClassName('c-zoom')
      for (let index = 0; index < elementsWithZoom.length; index++) {
        const element = elementsWithZoom.item(index)
        // remove the zoom class
        element!.className = element!.className.replaceAll(' c-zoom', '')
      }

      if (wasUp) {
        setGoUp(true)
        setGoDown(false)
        setWasUp(false)
      }
    }

    const onEdgeDragOver = (event: DragEvent) => {
      if (isEdgeDisabled) {
        return
      }
      event.preventDefault()

      if (dragOverElement?.id !== id) {
        setDragOverElement({ id, source, target })
      }

      const isFromAction = event.dataTransfer.types.includes('application/reactflow')
      // don't to nothing if the dragged element isn't from the accepted sources
      if (!isFromAction) {
        return
      }

      event.dataTransfer.dropEffect = 'copy'

      // Set addElement true only if it's dragged over a button
      if (dropZoneRef.current?.className.length) {
        if (!dropZoneRef.current.className.includes('c-zoom')) {
          // add the zoom class
          dropZoneRef.current.classList.add('c-zoom')
        }

        if (!wasUp) {
          setGoUp(false)
          setGoDown(true)
          setWasUp(true)
        }
      }
    }

    return (
      <>
        <path id={id} className='react-flow__edge-path' d={edgePath} markerEnd={markerEnd} />
        {isBranch && (
          <foreignObject width={33} height={20} x={fixedXPosition + 110} y={fixedYPosition}>
            <div
              className={classNames('label-type', {
                'label-yes': data?.edgeLabel === BranchLabelEnum.Yes,
                'label-no': data?.edgeLabel !== BranchLabelEnum.Yes,
              })}>
              <span className='label'>{data?.edgeLabel}</span>
            </div>
          </foreignObject>
        )}
        <foreignObject
          width={placehoder.width}
          height={placehoder.height}
          x={displayBorders ? fixedXPosition : fixedXPosition + 90}
          y={displayBorders ? yPosition : yPosition + 90}
          requiredExtensions='http://www.w3.org/1999/xhtml'>
          <div
            className='placeholder--area'
            data-testid={`placeholder--area`}
            ref={dropZoneRef}
            onDrop={(event) => onEdgeDrop(event, id)}
            onDragOver={onEdgeDragOver}
            onDragLeave={onEdgeDragLeave}>
            <div className={`edge__content ${displayBorders} edge__content--${edgeShape}`}>
              <div className='edge__button'>
                <span className='plus-sign'></span>
                <span className='plus-sign'></span>
                {/* TODO:REMOVE COMMENT once it's done the merge feature */}
                {/* {isEdgeDisabled && <>-X-</>} */}
              </div>
            </div>
          </div>
        </foreignObject>
        {/* TODO:REMOVE COMMENT once it's done the merge feature */}
        {/* <foreignObject
          width={400}
          height={100}
          x={displayBorders ? xPosition : xPosition + 90}
          y={displayBorders ? yPosition : yPosition + 90}>
          <div style={{ position: 'absolute' }}>{id}</div>
        </foreignObject> */}
      </>
    )
  },
)

export default EdgeSegment
