import EmptyState from 'components/AccountDetails/EmptyState'
import { Heading, IconArrowUpPositiveGreen, IconArrowDownNegativeRed } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import Chart from 'components/MotionDetails/MachineLearningMetric/Chart'
import { getMaxYAxisValue } from 'components/MotionDetails/MachineLearningMetric/Chart/helpers/options'

import type { Revenues } from 'models/account.model'
import { DataFormatEnum } from 'models/insights'

interface RevenueForcastingProps {
  data: Revenues
}

const RevenueForcasting = ({ data }: RevenueForcastingProps) => {
  const isRevenueDataAvailable = Object.keys(data).length > 0 && data.trend.length > 0
  const formatLabel = (label: string) => label.toUpperCase().replaceAll('-', ' ')

  if (!isRevenueDataAvailable) {
    return <EmptyState title='Revenue Forecasting' message='No Forecasting available for this account' />
  }

  // Chart data
  const labels = data.trend.map((item) => {
    const label = formatLabel(item.label)
    return item.forecast ? `${label} (forecasted)` : label
  })
  const chartData = data.trend.map((item) => item.value)
  const yMax = getMaxYAxisValue(chartData, 0.3)

  // Prepare the lift percentage and labels
  const liftPercentage = (data.lift.percent * 100).toFixed(0)

  // Calculate the delta value of the from -> to quarters
  const fromValue = data.trend.find((item) => item.label === data.lift.from)?.value ?? 0
  const toValue = data.trend.find((item) => item.label === data.lift.to)?.value ?? 0
  const deltaValue = toValue - fromValue

  return (
    <div className='revenue-forcasting-container' data-testid='revenue-forcasting-container'>
      <div className='revenue-forcasting-info-section'>
        <div className='revenue-forcasting-header'>
          <Heading variant='2' level='2'>
            Revenue Forecast
          </Heading>
          <MockDataCallout />
        </div>

        <p>For the forecasted timeframe, Magnify predicts Annual Recurring Revenue (ARR) will change by</p>
        <div className='revenue-forcasting-info-section-highlight-metric'>
          <div className='revenue-forcasting-info-section-highlight-metric-wrapper'>
            {parseFloat(liftPercentage) >= 0 ? <IconArrowUpPositiveGreen /> : <IconArrowDownNegativeRed />}
            <span className='revenue-forcasting-info-section-highlight-metric-percentile'>
              {deltaValue > 0 ? '+' : ''}
              {formatNumber({
                number: deltaValue,
                format: DataFormatEnum.CurrencyKMB,
                decimal: 0,
              })}{' '}
              ({Math.abs(parseFloat(liftPercentage))}%)
            </span>
          </div>
        </div>
      </div>
      <div className='revenue-forcasting-chart-section-container'>
        <Chart
          labels={labels}
          data={chartData}
          title={'ARR'}
          isPercentage={false}
          shouldFormatKMB
          yMax={yMax}
          yMin={0}
        />
      </div>
    </div>
  )
}
RevenueForcasting.displayName = 'RevenueForcasting'

export default RevenueForcasting
