import { IconAccounts, IconUser } from 'components/common/Icons/Icons'

interface MotionOutputProps {
  users: number
  accounts: number
}

const MotionOutput = ({ users, accounts }: MotionOutputProps) => {
  return (
    <div className='motion-output' data-testid='motion-output'>
      <div className='motion-output__item item--left'>
        <IconAccounts />
        <span data-testid='accounts' className='value'>
          {accounts}
        </span>
      </div>
      <div className='motion-output__item item--right'>
        <IconUser />
        <span data-testid='users' className='value'>
          {users}
        </span>
      </div>
    </div>
  )
}
export default MotionOutput
