/**
 * Handler for paste that filters out HTML and keeps the raw text.
 * This is desgiend to work everywhere including `contentEditable` elements,
 * which unfortunately only works correctly with `document.execCommand`.
 */
export const onPasteReturnRawText: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
  // Prevent the original paste
  event.preventDefault()
  // Extract the raw text from the event data (prevents inserting HTML)
  const text = event.clipboardData.getData('text/plain').trim()
  // Inject the HTML like the original paste
  document.execCommand('insertText', false, text)
}
