import { toJS } from 'mobx'
import { ArrowHeadType, isNode } from 'react-flow-renderer'

import { mergeArraysWithoutDuples } from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils.common'
import { loopArrowEdge } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import {
  getFragmentX,
  getFragmentY,
  getInitialY,
  getInitialX,
  getElementById,
} from 'components/MotionTarget/motionTarget.utils'
import { getRandomId } from 'services/Utils/dslConversion'
import { clone } from 'services/Utils/misc'
import {
  elementsSeparator,
  getChainOfEdges,
  getElementsMergeValidated,
  getLoopSourcePair,
  markDisableEdges,
} from 'services/Utils/motionHelpers/motionHelpers.utils'
import {
  camelCaseToNormalCase,
  camelToSnakeCase,
  getOnlyLetters,
  getOnlyNumbers,
  toCamelCase,
} from 'services/Utils/parseString.utils'

import type { Edge, Elements, FlowElement, Node, XYPosition } from 'react-flow-renderer'

import type { ActionTypeEnum, SegmentBuilderData } from 'models/motion/motionBuilder.model'
import { ShapeTypeEnum } from 'models/motion/motionBuilder.model'
import type { Dsl, EdgeOption, NodePayload, NodeState, RecursiveModel } from 'models/motion.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'

let treeObject: Record<string, FlowElement<any>> = {}
let countSeens: { [key: string]: number } = {}

export function dslToReactFlow(dsl: Dsl): Elements {
  const stateName = dsl.startAt

  recursiveTraverse({ flow: dsl, stateName })
  const treeStructure = { ...treeObject }
  treeObject = {}
  countSeens = {}
  const uIElements: FlowElement<any>[] = Object.values(treeStructure)
  const elements = addMergeEdge(clone(uIElements))
  const uIElementsWithLoops = addLoopSources(elements)
  const finalElements = getElementsMergeValidated(uIElementsWithLoops)
  return finalElements
}

/**
 * Return a new JSON object in React flow Node format
 * @param {any} stateElement - current state object.
 * @param {string} stateName - current state object name.
 * @param {number} level - the level of element used for vertical aligment.
 * @param {string} parentStateName - the name of the parent state.
 * @param {string} positionX - the position on x axis used for horizontal aligment.
 */
function newNode(
  stateElement: SegmentBuilderData,
  stateName: string,
  parentStateName: string,
  isNoBranch: boolean | undefined,
): Node<SegmentBuilderData> {
  // there are 2 type of nodes, normal nodes and end nodes
  // normal nodes will have the id of the state name
  // end nodes will have the name of parent node with end suffix

  const isIfElse = (getOnlyLetters(stateName) as NodeTypeEnum) === NodeTypeEnum.Branch
  const node: Node<Partial<SegmentBuilderData> & NodeState> = {
    id: `${getOnlyNumbers(stateName)}`,
    type: NodeTypeEnum.Segment,
    data: {
      // Here you can update the react flow node data based on DSL (endpoint response)
      type: stateElement.type,
      name: `${isIfElse ? 'If / Else' : camelCaseToNormalCase(getOnlyLetters(stateName))}`,
      ...(stateElement.payload && { payload: stateElement.payload }),
      ...(getOnlyLetters(stateName) === 'End' && { isFinal: true }),
      ...(getOnlyLetters(stateName) !== 'End' && { shape: getShapeOfElement(stateElement.type) }),
      data: { edgeLabel: `${isNoBranch ? BranchLabelEnum.No : BranchLabelEnum.Yes}` },
      iconName: `${stateElement.iconName ?? toCamelCase(camelToSnakeCase(getOnlyLetters(stateName)))}`,
      ...(stateElement.description && { description: stateElement.description }),
      ...(stateElement.type === NodeTypeEnum.Action && {
        actionType: `${toCamelCase(camelToSnakeCase(getOnlyLetters(stateName)))}` as ActionTypeEnum,
        actionId: stateElement.actionId,
        actionLevel: stateElement.actionLevel,
        action: stateElement.action,
        fakeAction: stateElement.fakeAction ?? false,
        platform: stateElement.platform,
        object: stateElement.object,
        solutionInstanceId: stateElement.solutionInstanceId,
      }),
    },
    // Add X and Y position based on the backend
    position: {
      x: (stateElement as NodeState).position?.x as number,
      y: (stateElement as NodeState).position?.y as number,
    },
  }

  node.data!.nodeId = node.id

  // Move back the targetNodeId into node payload (only loops can have)
  if (stateElement.type === NodeTypeEnum.Loop && stateElement.targetNodeId) {
    // remove the string part from the targetNode
    node.data!.targetNodeId = stateElement.targetNodeId.replace(/\D/g, '')
  }

  return node as Node<SegmentBuilderData>
}

function edge(options: EdgeOption): Edge {
  const { stateName, parentStateName, end, isNoBranch } = options
  const newEdge: Edge = {
    id: `e${getOnlyNumbers(parentStateName)}-${getOnlyNumbers(stateName)}`,
    source: end ? `${getOnlyNumbers(stateName)}` : `${getOnlyNumbers(parentStateName)}`,
    target: end ? `${getOnlyNumbers(parentStateName)}` : `${getOnlyNumbers(stateName)}`,
    type: 'custom',
    data: { edgeLabel: `${isNoBranch ? BranchLabelEnum.No : BranchLabelEnum.Yes}` },
    sourceHandle: isNoBranch ? 's-right' : 's-bottom',
    targetHandle: 't-top',
    arrowHeadType: ArrowHeadType.Arrow,
  }

  return newEdge
}

function recursiveTraverse(option: RecursiveModel) {
  const { flow, stateName, parentStateName, isNoBranch } = option
  // flow - JSON in ASL format https://states-language.net/spec.html
  // stateName - current state name
  const currentState = flow.states[stateName]
  const isBranch = currentState?.type === 'ifElse'

  if (!parentStateName) {
    // the initial state doesn't have a parent state and requires a specific treat
    // Here you can update the react flow Segment node data based on DSL (endpoint response)
    const segmentNode = {
      id: '1',
      type: NodeTypeEnum.Segment,
      data: {
        isInitial: true,
        ...(currentState.payload && { payload: toJS(currentState.payload) }),
        ...(currentState.description && { description: currentState.description }),
        ...(currentState.excludedUserIds && { excludedUserIds: currentState.excludedUserIds }),
        name: 'Segment',
        shape: 'circle',
        iconName: 'segment',
        nodeId: '1',
        type: NodeTypeEnum.Segment,
      },
      position: {
        x: currentState?.position?.x || getInitialX(),
        y: currentState?.position?.y || getInitialY(),
      },
    }

    treeObject[stateName] = segmentNode

    if (currentState.end) {
      // the initial view when only the segment is configured
      const randomNo = getRandomId()

      const endNode = {
        id: `${randomNo}`,
        type: 'end',
        data: { isFinal: true, name: 'End', description: 'End' },
        position: { x: segmentNode.position.x, y: segmentNode.position.y + getFragmentY() },
      }

      const newEdge: Edge = {
        id: `e1-${randomNo}`,
        source: `1`,
        target: `${randomNo}`,
        type: 'custom',
        data: { edgeLabel: `yes` },
        sourceHandle: 's-bottom',
        targetHandle: 't-top',
        arrowHeadType: ArrowHeadType.Arrow,
      }

      treeObject['end_if_Else' + randomNo] = endNode
      treeObject[newEdge.id] = newEdge
    }
  } else {
    const node = newNode(currentState as SegmentBuilderData, stateName, parentStateName, isNoBranch)
    const newEdge = edge({ stateName, parentStateName, isNoBranch })

    if (node.data?.type === NodeTypeEnum.Loop && node.data.targetNodeId) {
      const loopEdge = loopArrowEdge(node.id, node.data.targetNodeId)
      treeObject[loopEdge.id] = loopEdge
    }
    treeObject[stateName] = node
    treeObject[newEdge.id] = newEdge

    if (currentState.end && !isBranch) {
      Object.values(treeObject).forEach((element) => {
        if (isNode(element)) {
          const key = JSON.stringify(element.position)
          countSeens[key] = (countSeens[key] || 0) + 1
        }
      })

      // When is a merge, it goe through the same node multiple times
      // we have to prevent creating multiple end nodes
      if (countSeens[JSON.stringify(node.position)] === 1) {
        // The case for basic elements that ends
        const parentElementPosition = (treeObject[stateName] as Node<Partial<SegmentBuilderData> & NodeState>).position
        generateEndNodeEdgePair({ position: parentElementPosition, sourceId: getOnlyNumbers(stateName), treeObject })
      }
    }
  }

  const nextStateName = currentState?.next ? currentState?.next : currentState.default
  if (nextStateName) {
    recursiveTraverse({ flow, stateName: nextStateName, parentStateName: stateName })
  }

  if (isBranch) {
    const parentElementPosition = (treeObject[stateName] as Node<Partial<SegmentBuilderData> & NodeState>).position

    const isNoBranch = currentState.choices?.[0]?.end
    const endPosition = {
      x: isNoBranch ? parentElementPosition.x + getFragmentX() : parentElementPosition.x,
      y: parentElementPosition.y + getFragmentY(),
    }
    const key = JSON.stringify(endPosition)
    countSeens[key] = (countSeens[key] || 0) + 1
    // prevent duplicate end nodes
    const isFirstTimeEnd = countSeens[key] === 1

    if (currentState.choices?.[0]?.next) {
      recursiveTraverse({
        flow,
        stateName: currentState.choices[0]?.next,
        parentStateName: stateName,
        isNoBranch: true,
      })
    }
    if (currentState.choices?.[0]?.end && isFirstTimeEnd) {
      // is branch and has an END node on the no branch
      // create end based on the parent x incredemented of the parent y increment

      generateEndNodeEdgePair({
        position: parentElementPosition,
        sourceId: getOnlyNumbers(stateName),
        treeObject,
        isNoBranch: true,
      })
    }
    if (!currentState.default && isFirstTimeEnd) {
      // is branch and has an END node on the yes branch
      // create end based on the x of the parent y increment

      generateEndNodeEdgePair({ position: parentElementPosition, sourceId: getOnlyNumbers(stateName), treeObject })
    }
  }
}

interface EndNodeEdgePairProps {
  position: XYPosition
  sourceId: string
  treeObject?: Record<string, FlowElement<any>>
  isNoBranch?: boolean
}

export function generateEndNodeEdgePair({ position, sourceId, treeObject, isNoBranch }: EndNodeEdgePairProps) {
  const randomNo = getRandomId()
  const endNode = {
    id: `${randomNo}`,
    type: 'end',
    data: { isFinal: true, name: 'End', description: 'End' },
    // if is on YES branch increment only the Y position
    // if is on NO branch increment both axis
    position: {
      x: isNoBranch ? position.x + getFragmentX() : position.x,
      y: position.y + getFragmentY(),
    },
  }

  const endEdge: Edge = {
    id: `e${sourceId}-${randomNo}`,
    source: `${sourceId}`,
    target: `${randomNo}`,
    type: 'custom',
    data: { edgeLabel: isNoBranch ? BranchLabelEnum.No : BranchLabelEnum.Yes },
    sourceHandle: isNoBranch ? 's-right' : 's-bottom',
    targetHandle: 't-top',
    arrowHeadType: ArrowHeadType.Arrow,
  }

  if (treeObject) {
    treeObject['end_if_Else' + randomNo] = endNode
    treeObject[endEdge.id] = endEdge
  }

  return [endNode, endEdge]
}

function getShapeOfElement(value: NodeTypeEnum): ShapeTypeEnum {
  switch (value) {
    case NodeTypeEnum.Segment:
      // Targeting
      return ShapeTypeEnum.Circle
    case NodeTypeEnum.Branch:
    case NodeTypeEnum.WaitForTrigger:
    case NodeTypeEnum.TimeDelay:
    case NodeTypeEnum.Loop:
    case NodeTypeEnum.Merge:
      // Rules
      return ShapeTypeEnum.Rhomb
    default:
      // Actions
      return ShapeTypeEnum.Square
  }
}

function addLoopSources(elements: Elements): Elements {
  // adds the helper attribute 'loopSourceId' used by loops functionality
  const loops = elements.filter(
    (element: FlowElement<{ type: NodeTypeEnum }>) => element.data?.type === NodeTypeEnum.Loop,
  )
  if (!loops.length) {
    return elements
  }

  const loopSourcePair = getLoopSourcePair(loops)

  const chainOfEdges: Edge[] = []
  // To these edges^ will be added a label to restrict dropping loops and if/elses on them
  loopSourcePair.forEach((pair) => {
    chainOfEdges.push(...getChainOfEdges({ elements, nodeSourceId: pair.loop, nodeTargetId: pair.loopTarget }))
  })

  const markedEdges = markDisableEdges({
    edges: chainOfEdges,
    elementsType: [NodeTypeEnum.Loop, NodeTypeEnum.Branch, NodeTypeEnum.Merge],
  })

  const copyElements = clone(elements)
  loopSourcePair.forEach((loopSource) => {
    copyElements.forEach((element: FlowElement<{ loopSourceId: string }>) => {
      if (loopSource?.loopTarget === element.id) {
        element.data!.loopSourceId = loopSource.loop
      }
    })
  })

  const updatedElements = mergeArraysWithoutDuples(copyElements, markedEdges)

  return updatedElements
}

/**
 * Function that adds the merge associate edge
 * @param elements The array of nodes and edges
 * @returns The array of elements with the special merge edge/arrow
 */

export const addMergeEdge = (elements: Elements): Elements => {
  const { edges, nodes } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const pairs = getMergeTargetSourcePairs([...edges, ...nodes])

  pairs.forEach((pair) => {
    // the edges that points to the pair id should have the target a new merge element
    // the new merge element needs to point to the pair id

    const targetNode = nodes.find((node) => node.id === pair.id)
    if (!targetNode) {
      return elements
    }

    pair.targets.forEach((targetId) => {
      const foundEdgeSource = edges.find((edge) => edge.source === targetId)
      const foundEdgeSourceNode = nodes.find((node) => node.id === targetId)

      if (foundEdgeSource && foundEdgeSourceNode) {
        //special style for merge edge
        foundEdgeSource.data!.edgeType = NodeTypeEnum.Merge
        foundEdgeSource.data!.edgeLabel = BranchLabelEnum.Yes
        foundEdgeSource.targetHandle = 't-right'
      }
    })
  })

  return elements
}

/**
 * Function that returns the pair of merge source-target from an array of elements
 * @param elements The array of nodes and edges
 * @returns An array of merge id and targets ids if any
 */
export const getMergeTargetSourcePairs = (elements: Elements) => {
  const { edges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const mergeTargetIds: string[] = []
  const tempIds: string[] = []
  interface TargetSourcePair {
    id: string
    targets: string[]
  }
  const mergeTargetSourcePair: TargetSourcePair[] = []

  edges.forEach((edge) => {
    if (tempIds.includes(edge.target) && !mergeTargetIds.includes(edge.target)) {
      mergeTargetIds.push(edge.target)
    } else {
      tempIds.push(edge.target)
    }
  })

  mergeTargetIds.forEach((mergeId) => {
    const mergePair: TargetSourcePair = {
      id: mergeId,
      targets: [],
    }
    const mergeNode = getElementById(elements, mergeId) as Node
    const targets = edges.filter((edge) => edge.target === mergeId).map((edge) => edge.source)
    const targetsNodes = targets
      .map((targetId) => getElementById(elements, targetId) as Node)
      .filter((node) => node.position.x !== mergeNode.position.x)

    mergePair.targets = targetsNodes.map((node) => node.id)

    mergeTargetSourcePair.push(mergePair)
  })

  return mergeTargetSourcePair
}
