import { Layout } from 'antd'
import { useContext } from 'react'

import {
  TimeDelayPanel,
  DataSourcePanel,
  LoopPanel,
  MergePanel,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes'
import CreatePanel from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/Create'
import { TriggerEvent } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/SpecialActions'
import UpdatePanel from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/Update'
import { handleSpecialCase } from 'components/MotionBuilder/Utils/segmentBuilder.utils'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'

import type { ConfigPanelStatementsProps } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum, ActionEnum } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

export const ConfigPanelStatements = ({ payload, setPayload }: ConfigPanelStatementsProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)

  let type: NodeTypeEnum | ActionTypeEnum | ActionEnum = segmentBuilderData.action ?? segmentBuilderData.type
  const specialCase = handleSpecialCase(segmentBuilderData.actionType, segmentBuilderData.action)

  if (specialCase) {
    type = specialCase
  }

  switch (type) {
    case NodeTypeEnum.Branch:
    case NodeTypeEnum.Segment:
    case NodeTypeEnum.WaitForTrigger:
      return <DataSourcePanel payload={payload} setPayload={setPayload} key={segmentBuilderData.nodeId} />
    case NodeTypeEnum.TimeDelay:
      return <TimeDelayPanel payload={payload} setPayload={setPayload} key={segmentBuilderData.nodeId} />
    case NodeTypeEnum.Loop:
      return <LoopPanel payload={payload} setPayload={setPayload} key={segmentBuilderData.nodeId} />
    case ActionEnum.Create:
      return <CreatePanel payload={payload} setPayload={setPayload} key={segmentBuilderData.nodeId} />
    case ActionEnum.Update:
      return <UpdatePanel payload={payload} setPayload={setPayload} key={segmentBuilderData.nodeId} />
    case ActionTypeEnum.TriggerEvent:
      return <TriggerEvent payload={payload} setPayload={setPayload} key={segmentBuilderData.nodeId} />
    case NodeTypeEnum.Merge:
      return <MergePanel setPayload={setPayload} key={segmentBuilderData.nodeId} />
    default:
      return <Layout></Layout>
  }
}
ConfigPanelStatements.displayName = 'ConfigPanelStatements'
