import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import { sortAlphabetically } from 'services/Utils/Sorters'

export const columnSorter = <T>(a: T, b: T, key: keyof T): number => {
  return sortAlphabetically(String(a[key]), String(b[key]))
}

const fileType = 'charset=UTF-8'
const fileExtension = '.csv'

/**
 * Creates an excel spreadsheet with the target data
 *
 * @param data 2d array of the data
 * @param fileName Name of the file to be exported
 */
export const exportToCSV = (data: unknown[][], fileName: string) => {
  // Provisions sheet based on a 2D-array
  const workSheet = XLSX.utils.aoa_to_sheet(data)
  const csv = XLSX.utils.sheet_to_csv(workSheet)

  const fileData = new Blob([csv], { type: fileType })
  FileSaver.saveAs(fileData, fileName + fileExtension)
}
