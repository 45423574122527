import { Input, Popover, Select } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { DropdownArrowDown, IconBolt, DataSourceContainer, DYNAMIC_INPUT, STRING_TYPE } from 'components/common'
import { checkTypeEquality } from 'components/common/DataSourceContainer/index.utils'
import DynamicFieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/DynamicFieldInput'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import {
  getElementSelectOptions,
  getMenuItemIcon,
  allowDynamicInput,
} from 'components/MotionBuilder/Utils/serviceUtils'
import useInputType from 'components/MotionBuilder/Utils/useInputType'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type {
  ConfigPanelPayload,
  CreateActionFields,
  Item,
  KeyValueField,
  PayloadData,
  SelectOptions,
  ValueType,
} from 'models/motion/motionBuilder.model'
import { InputOperatorEnum } from 'models/motion/motionBuilder.model'

export interface CriteriaInputProps {
  indexes: {
    group: number
    item: number
  }
  payload: PayloadData
  forceDisableDynamicInput?: boolean
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const CriteriaInput = observer(
  ({ indexes, payload, forceDisableDynamicInput = false, setPayload }: CriteriaInputProps) => {
    const { dynamicInputStore, motionStore } = useStore()
    const { dynamicInputPosition, fetchDynamicInputsMetadata, resetSearch } = dynamicInputStore
    const item = payload.groups[indexes.group].groups[indexes.item]
    const selectOptions = getElementSelectOptions(item.type)
    const [inputIsRange, setInputIsRange] = useState(item.operator === InputOperatorEnum.Between)
    const [focusOnInput, setFocusOnInput] = useState('')
    const [displayDynamicFieldPopover, setDisplayDynamicFieldPopover] = useState<boolean>(false)

    const isDynamicField = item.onlyDynamicInput || item.isDynamicInput
    // We want to explicitly disable dynamic inputs for fields that are datetimes and the user selects either `within` operator
    const enableDynamicInput =
      allowDynamicInput(item) &&
      !forceDisableDynamicInput &&
      item.operator !== InputOperatorEnum.WithinNext &&
      item.operator !== InputOperatorEnum.WithinLast &&
      item.operator !== InputOperatorEnum.NoneOf &&
      item.operator !== InputOperatorEnum.AnyOf &&
      item.operator !== InputOperatorEnum.ContainsOneOf &&
      item.operator !== InputOperatorEnum.NotContainsAnyOf

    useDisplayErrorNotification(dynamicInputStore)

    const { isNullishInput } = useInputType(item)

    const changeInputConditions = (e: InputOperatorEnum) => {
      // only regenerate inputs if the input type has changed
      if (!inputIsRange && e === InputOperatorEnum.Between) {
        setInputIsRange(true)
      } else if (inputIsRange && e !== InputOperatorEnum.Between) {
        setInputIsRange(false)
      }

      setPayload((payload) => {
        payload.groups[indexes.group].groups[indexes.item].operator = e

        // Clear the value whenever the user switches to a within operator
        if (e === InputOperatorEnum.WithinNext || e === InputOperatorEnum.WithinLast) {
          payload.groups[indexes.group].groups[indexes.item].value = null
        } else if (e === InputOperatorEnum.Between) {
          // If we switch to a between operator, the value is expected to be an array.
          payload.groups[indexes.group].groups[indexes.item].value = [0, 0]
        }

        return { ...payload }
      })

      setFocusOnInput(e)
    }

    const changeFieldValue = (
      value:
        | boolean
        | number
        | number[]
        | string
        | string[]
        | moment.Moment
        | moment.Moment[]
        | CreateActionFields
        | null,
      fieldKey?: string | number,
      index?: number,
    ) => {
      setPayload((payload) => {
        // If the field is a range like Between, we need to update the value in the correct index.
        if (typeof index === 'number' && Array.isArray(payload.groups[indexes.group].groups[indexes.item].value)) {
          if (index === 0) {
            payload.groups[indexes.group].groups[indexes.item].value = [
              value as number,
              (payload.groups[indexes.group].groups[indexes.item].value as number[])?.[1],
            ]
          } else {
            payload.groups[indexes.group].groups[indexes.item].value = [
              (payload.groups[indexes.group].groups[indexes.item].value as number[])?.[0],
              value as number,
            ]
          }
        } else {
          payload.groups[indexes.group].groups[indexes.item].value = value as string
        }

        payload.groups[indexes.group].groups[indexes.item].isDynamicInput =
          !!(payload.groups[indexes.group].groups[indexes.item].value as CreateActionFields)?.platform ||
          !!(value as CreateActionFields)?.platform

        return { ...payload }
      })
    }

    const addDynamicValue = (value: ValueType) => {
      setPayload((payload) => {
        const groupItem = payload.groups[indexes.group].groups[indexes.item]
        const currentValue = (groupItem.value as CreateActionFields)?.value
        const currentPlatform = (groupItem.value as CreateActionFields)?.platform
        const itemType = groupItem.type

        const newValue = {
          ...(value as object),
          ...(currentValue && { value: currentValue }),
          ...(currentPlatform &&
            currentValue &&
            !(currentValue as string).includes(DYNAMIC_INPUT) &&
            checkTypeEquality(itemType, STRING_TYPE) && {
              value: Array.isArray(currentValue) ? DYNAMIC_INPUT : (currentValue as string) + DYNAMIC_INPUT,
            }),
          ...(!currentPlatform &&
            checkTypeEquality(itemType, STRING_TYPE) &&
            (value as CreateActionFields).platform && {
              value: addDynamicInputOnCaretPosition(groupItem),
            }),
          ...(!checkTypeEquality(itemType, STRING_TYPE) &&
            (value as CreateActionFields).platform && {
              value: DYNAMIC_INPUT,
            }),
        }

        groupItem.value = newValue as CreateActionFields
        groupItem.isDynamicInput = !!currentPlatform || !!(value as CreateActionFields).platform

        return { ...payload }
      })
    }

    const addDynamicInputOnCaretPosition = (item: Item): string => {
      if (
        item.value &&
        typeof item.value === 'string' &&
        item.key === dynamicInputPosition?.key &&
        (dynamicInputPosition?.position || dynamicInputPosition?.position === 0)
      ) {
        return (
          item.value.slice(0, dynamicInputPosition?.position) +
          DYNAMIC_INPUT +
          item.value.slice(dynamicInputPosition?.position)
        )
      }
      return (item.value as string) || '' + DYNAMIC_INPUT
    }

    function getLabelOption(selectOptions: SelectOptions[], type: string) {
      if (!type) {
        return selectOptions[0]?.label
      }
      const defaultItem = selectOptions.filter((item) => item.value === type)
      return defaultItem[0].label || selectOptions[0]?.label
    }

    const addDefaultOperator = () => {
      if (!item.operator)
        setPayload((payload) => {
          payload.groups[indexes.group].groups[indexes.item].operator = (selectOptions[0]?.value ||
            '') as InputOperatorEnum

          return payload
        })
    }

    const openDynamicFieldPopup = (value: boolean) => {
      if (!displayDynamicFieldPopover) {
        fetchDynamicInputsMetadata(item).catch(console.error)
      }
      setDisplayDynamicFieldPopover(value)
    }

    const onOpenChange = (value: boolean) => {
      if (!displayDynamicFieldPopover) {
        fetchDynamicInputsMetadata(item).catch(console.error)
      }
      setTimeout(() => {
        if (displayDynamicFieldPopover || item.onlyDynamicInput) {
          setDisplayDynamicFieldPopover(value)
        }
      }, 1)

      resetSearch()
    }

    const removeTag = () => {
      setPayload((payload) => {
        const groupItem = payload.groups[indexes.group].groups[indexes.item]
        const valueField = groupItem?.value as KeyValueField
        if (valueField?.value) {
          payload.groups[indexes.group].groups[indexes.item].value = (valueField.value as string).replace(
            DYNAMIC_INPUT,
            '',
          )
          payload.groups[indexes.group].groups[indexes.item].isDynamicInput = false
        }

        return { ...payload }
      })
    }

    const handleDynamicBtnClick = () => {
      openDynamicFieldPopup(true)
    }

    return (
      <div key={`${indexes.group}-${indexes.item}`} draggable={false}>
        <div className='group-container__item-container__item-title' data-testid='item-title' draggable={false}>
          <span title={`${item.magnifyDisplayName ?? item.object} / ${item.field}`}>
            {getMenuItemIcon({ entityType: 'platform', name: item.platform }, true)}
            {item.magnifyDisplayName ?? item.object} / {item.field}
          </span>
        </div>
        <div
          className={classNames('group-container__item-container__item', {
            'group-container__item-container__item--active': displayDynamicFieldPopover,
          })}
          data-testid='group-container__item-container__item'>
          <div className='group-container__item-container__item__select-input-range'>
            <Popover
              trigger='click'
              open={displayDynamicFieldPopover}
              placement='bottomRight'
              overlayClassName='data-field__popover dynamic-field__popover'
              showArrow={false}
              onOpenChange={onOpenChange}
              content={<DataSourceContainer currentItem={item} handleSelectDataField={addDynamicValue} />}>
              <Input.Group
                compact
                className={classNames('criteria-input-group', {
                  'dynamic-field': enableDynamicInput,
                  'is-nullish': isNullishInput,
                  'criteria-input-group--disabled': motionStore.isSegmentBuilderEditDisabled,
                })}>
                <Select
                  size='large'
                  popupClassName='group-container__item-container__item__select-input-range__dropdown'
                  disabled={motionStore.isSegmentBuilderEditDisabled}
                  data-testid='select-operator'
                  suffixIcon={<DropdownArrowDown />}
                  defaultValue={getLabelOption(selectOptions, item.operator) as InputOperatorEnum}
                  autoFocus={!item.operator}
                  defaultOpen={!item.operator}
                  value={item.operator}
                  onChange={changeInputConditions}
                  onBlur={() => addDefaultOperator()}>
                  {selectOptions.map((option, index) => (
                    <Select.Option key={`${index}-${option.value}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>

                {isDynamicField || checkTypeEquality(item.type, STRING_TYPE) ? (
                  <DynamicFieldInput
                    item={item}
                    borderless={true}
                    openPopover={openDynamicFieldPopup}
                    removeTag={removeTag}
                    changeFieldValue={changeFieldValue}
                  />
                ) : (
                  <FieldInput
                    key={`${indexes.group}-${indexes.item}-input`}
                    autoFocus={!!focusOnInput}
                    item={item}
                    range={inputIsRange}
                    setFocusOnInput={setFocusOnInput}
                    changeFieldValue={changeFieldValue}
                    disabled={isNullishInput || motionStore.isSegmentBuilderEditDisabled}
                  />
                )}
                {enableDynamicInput && !isNullishInput && !motionStore.isSegmentBuilderEditDisabled && (
                  <div
                    className={classNames('dynamic-field-popup-btn', {
                      'dynamic-field-popup-btn--active': displayDynamicFieldPopover,
                      'dynamic-field-popup-btn--disabled': motionStore.isSegmentBuilderEditDisabled,
                    })}
                    data-testid='dynamic-field-popup-btn'
                    onClick={handleDynamicBtnClick}>
                    <IconBolt />
                  </div>
                )}
              </Input.Group>
            </Popover>
          </div>
        </div>
      </div>
    )
  },
)
CriteriaInput.displayName = 'CriteriaInput'

export default CriteriaInput
