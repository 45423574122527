import { Input } from 'antd'

import { IconSearch } from 'components/common/Icons/Icons'

interface SidebarSearchProps {
  prefix?: JSX.Element
  placeholder?: string
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const SidebarSearch = ({ prefix, placeholder, onChangeHandler }: SidebarSearchProps) => {
  const icon = prefix || <IconSearch />

  return <Input placeholder={placeholder || 'search'} prefix={icon} onChange={onChangeHandler} data-testid='search' />
}
SidebarSearch.displayName = 'SidebarSearch'
