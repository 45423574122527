import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Row, Table } from 'antd'
import { useMemo, useRef } from 'react'

import { Heading } from 'components/common/Heading/Heading'
import { getColumnSearchProps } from 'pages/Motions/InternalOperationalStats/components/common'
import { columnSorter } from 'pages/Motions/InternalOperationalStats/components/utils'
import { DateService } from 'services/Utils/date'

import type { InputRef } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import type { FilterConfirmProps } from 'antd/lib/table/interface'

interface Executions {
  completedTimestamp: string
  version: number
  csvPath: string
  executionId: string
  tenantId: string
  timestamp: string
  stateMachineArn: string
  journeyId: string
  includePreviousAccounts: boolean
  segmentUrl: string
  includePreviousUsers: boolean
}

interface MotionExecutionsProps {
  motionExecutions: Executions[]
  id: string
}

const COLOR_GREEN = '#007c15'

const MotionExecutions = ({ motionExecutions, id }: MotionExecutionsProps) => {
  const searchInputExecutionStatuses = useRef<InputRef>(null)
  const hasMultiplePages = motionExecutions?.length > 5

  const handleReset = (clearFilters: () => void, confirm: (params: FilterConfirmProps) => void) => {
    clearFilters()
    confirm({ closeDropdown: false })
  }

  const executionColumns = useMemo(
    (): ColumnsType<Executions> => [
      {
        title: 'Execution ID',
        dataIndex: 'executionId',
        key: 'executionId',
        ...(hasMultiplePages && getColumnSearchProps('executionId', searchInputExecutionStatuses, handleReset)),
        sorter: (a, b) => columnSorter(a, b, 'executionId'),
      },
      {
        title: 'Include Previous Accounts',
        dataIndex: 'includePreviousAccounts',
        key: 'includePreviousAccounts',
        align: 'center',
        ...(hasMultiplePages &&
          getColumnSearchProps('includePreviousAccounts', searchInputExecutionStatuses, handleReset)),
        sorter: (a, b) => columnSorter(a, b, 'includePreviousAccounts'),
        render: (_, record) =>
          record.includePreviousAccounts ? <CheckOutlined style={{ color: COLOR_GREEN }} /> : <CloseOutlined />,
      },
      {
        title: 'Include Previous Users',
        dataIndex: 'includePreviousUsers',
        key: 'includePreviousUsers',
        align: 'center',
        ...(hasMultiplePages &&
          getColumnSearchProps('includePreviousUsers', searchInputExecutionStatuses, handleReset)),
        sorter: (a, b) => columnSorter(a, b, 'includePreviousUsers'),
        render: (_, record) =>
          record.includePreviousUsers ? <CheckOutlined style={{ color: COLOR_GREEN }} /> : <CloseOutlined />,
      },
      {
        title: '🕑 Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        sorter: (a, b) => columnSorter(a, b, 'timestamp'),
        render: (_, record) =>
          `${DateService.parseDateTimestamp(record.timestamp, {
            showTime: true,
          })}`,
      },
      {
        title: '🕒 Completed Timestamp',
        dataIndex: 'completedTimestamp',
        key: 'completedTimestamp',
        sorter: (a, b) => columnSorter(a, b, 'completedTimestamp'),
        render: (_, record) =>
          `${DateService.parseDateTimestamp(record.completedTimestamp, {
            showTime: true,
          })}`,
      },
    ],
    [],
  )

  return (
    <section className='motion-details-header'>
      <Heading variant='7' level='2'>
        Motion Executions
      </Heading>
      <Row>
        <span className='m-r-5'>Motion ID</span>
        <b>{id}</b>
      </Row>

      <Table
        dataSource={motionExecutions}
        columns={executionColumns}
        rowKey='timestamp'
        pagination={{ position: hasMultiplePages ? ['bottomRight'] : [] }}
      />
    </section>
  )
}
MotionExecutions.displayName = 'MotionExecutions'

export default MotionExecutions
