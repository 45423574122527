import dayjs from 'dayjs'

import { formatParticipatedSince } from 'components/AccountDetails/ParticipatedMotions/utils'
import colors from 'components/MotionDetails/GoalMetric/Chart/helpers/chart-colors.module.scss'
import { LoggerService } from 'services/LogService/LogService'

import type { TenantInsightAccounts } from 'models/insights'
import type { Account } from 'models/observability.model'
import type {
  AccountList,
  AccountListApiResponse,
  AccountListRow,
  GoalMetric,
  GoalMetrics,
  GoalMetricsApiResponse,
  GoalMetricsData,
  MachineLearningMetrics,
  MachineLearningMetricsApiResponse,
} from 'models/reporting.model'
import { AccountListKeyEnum, AccountListTypeEnum, ChartLegendEnum } from 'models/reporting.model'

import { ChartTypeEnum } from 'components/MotionDetails/GoalMetric/Chart/helpers/types'

export interface QuartileDataset {
  [ChartLegendEnum.FirstQuartile]: number[]
  [ChartLegendEnum.SecondQuartile]: number[]
  [ChartLegendEnum.ThirdQuartile]: number[]
  [ChartLegendEnum.FourthQuartile]: number[]
}

export const DEFAULT_PAGE_SIZE = 10

export const DEFAULT_EMPTY_ACCOUNT_LIST = {
  columns: [],
  limit: 0,
  offset: 0,
  rows: [],
  search: null,
  total: 0,
}

export const DEFAULT_ACCOUNT_LIST: AccountListApiResponse = {
  columns: [
    {
      key: AccountListKeyEnum.Account,
      title: 'Name',
      type: AccountListTypeEnum.String,
    },
    {
      key: AccountListKeyEnum.AccountID,
      title: 'ID',
      type: AccountListTypeEnum.String,
    },
    {
      key: AccountListKeyEnum.ParticipatedSince,
      title: 'Participated Since',
      type: AccountListTypeEnum.String,
    },
  ],
  page: 1,
  rows: [],
  total: 0,
  limit: 0,
  offset: 0,
  search: null,
}

/** Defines the length of the quartile dataset format. */
const QUARTILE_DATASET_LENGTH = 5

/**
 * Convert a list of `YYYY-MM-DD` date strings to a list of formatted labels.
 * @param {string[]} dates The dates to convert to labels.
 * @returns {string[]} The converted labels.
 */
export const extractLabels = (dates: string[]): string[] => {
  if (!dates.length) return []
  let currentMonth = dayjs(dates[0]).format('MMM')
  return dates.map((date: string, index) => {
    const dayJs = dayjs(date)
    if (index === 0) {
      return dayJs.format('MMM DD')
    }
    const month = dayJs.format('MMM')
    // New month, update the current month and return the label with the month included
    if (month !== currentMonth) {
      currentMonth = month
      return dayJs.format('MMM DD')
    }
    return dayJs.format('DD')
  })
}

/**
 * Convert a quartile metric to a Chart.js data format.
 * @param {GoalMetric} metric
 * @returns {GoalMetrics} The converted quartile metric.
 */
export const processQuartileMetrics = (metric: GoalMetric): GoalMetrics => {
  if (!metric.data?.quartiles || !metric.data?.averages) {
    throw new Error('Quartiles or averages data is missing!')
  }
  const averages = Object.values(metric.data.averages)
  const quartiles: number[][] = Object.values(metric.data.quartiles)
  const labels = extractLabels(Object.keys(metric.data.quartiles))

  // Ensure that the quartiles and averages arrays have the same length.
  if (labels.length !== averages.length) {
    throw new Error('Quartiles and averages response length mismatch!')
  }

  /**
    Split the quartiles into a dataset format.
    ```
    let dataset = [1,2,3,4,5]
    let format = {
      '1st quartile': [1, 2],
      '2nd quartile': [2, 3],
      '3rd quartile': [3, 4],
      '4th quartile': [4, 5],
    }
    ```
  */
  const quartileDataset = quartiles.map((dataset: number[]): QuartileDataset => {
    if (dataset.length !== QUARTILE_DATASET_LENGTH) {
      throw new Error("Quartile dataset's length is incorrect!")
    }
    return {
      [ChartLegendEnum.FirstQuartile]: [dataset[0], dataset[1]],
      [ChartLegendEnum.SecondQuartile]: [dataset[1], dataset[2]],
      [ChartLegendEnum.ThirdQuartile]: [dataset[2], dataset[3]],
      [ChartLegendEnum.FourthQuartile]: [dataset[3], dataset[4]],
    }
  })

  const quartileDatasetGrouped = {
    [ChartLegendEnum.FirstQuartile]: colors.firstQuartile,
    [ChartLegendEnum.SecondQuartile]: colors.secondQuartile,
    [ChartLegendEnum.ThirdQuartile]: colors.thirdQuartile,
    [ChartLegendEnum.FourthQuartile]: colors.fourthQuartile,
  }

  const data: GoalMetricsData[] = Object.values(ChartLegendEnum).map((legend: ChartLegendEnum) => {
    if (legend === ChartLegendEnum.Average)
      return {
        label: legend,
        data: averages,
        type: ChartTypeEnum.Line,
        backgroundColor: colors.average,
        pointBorderColor: colors.average,
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointRadius: 3,
        borderWidth: 3,
        borderColor: colors.average,
        pointStyle: 'circle',
      }
    return {
      label: legend,
      type: ChartTypeEnum.Bar,
      pointStyle: 'rect',
      borderColor: 'white',
      borderWidth: 1,
      data: quartileDataset.map((dataset: QuartileDataset) => dataset[legend]),
      backgroundColor: quartileDatasetGrouped[legend],
    } as GoalMetricsData
  })

  // Move average to the first index so it appears above the quartiles
  const averageIndex = data.findIndex((d) => d.label === ChartLegendEnum.Average)
  if (averageIndex !== -1) {
    const [average] = data.splice(averageIndex, 1)
    data.unshift(average)
  }

  return {
    labels,
    title: metric.title,
    annotation: metric.annotation,
    data,
    visualization: metric.visualization,
  }
}

/**
 * Convert a barChart metric to a Chart.js data format.
 * @param {GoalMetric} metric
 * @returns The converted bar chart metric.
 */
export const processBarChartMetrics = (metric: GoalMetric): GoalMetrics => {
  if (!metric.data?.count) {
    throw new Error('Count data is missing!')
  }
  const labels = extractLabels(Object.keys(metric.data.count))

  return {
    annotation: metric.annotation,
    data: [
      {
        backgroundColor: colors.average,
        borderColor: colors.average,
        borderWidth: 1,
        label: 'Count',
        data: Object.values(metric.data.count),
      },
    ],
    label: ChartLegendEnum.Average,
    labels,
    title: metric.title,
    type: ChartTypeEnum.Bar,
    visualization: metric.visualization,
  }
}

/**
 * Process a GoalMetrics API response into a format that can be used by Chart.js.
 * @param {GoalMetricsApiResponse} goalMetrics The API response to process.
 * @returns {GoalMetrics[]} An array of GoalMetrics converted to chart formats that can be used by Chart.js.
 */
export const getGoalMetricsFromResponse = (goalMetrics: GoalMetricsApiResponse): GoalMetrics[] => {
  try {
    return goalMetrics.metrics.map((metric: GoalMetric) => {
      if (metric.visualization === 'quartile') {
        return processQuartileMetrics(metric)
      }
      if (metric.visualization === 'barChart') {
        return processBarChartMetrics(metric)
      }

      throw new Error(`Unknown GoalMetric type: '${metric.visualization}'`)
    })
  } catch (error: unknown) {
    if (error instanceof Error) {
      LoggerService.error({ message: error.message, error })
    }
    return []
  }
}

export const getMachineLearningMetricsFromResponse = (
  apiResponse: MachineLearningMetricsApiResponse,
): MachineLearningMetrics => {
  const { title = '', metrics = {} } = apiResponse
  const labels = extractLabels(Object.keys(metrics))
  const data = Object.values(metrics)
  return {
    title,
    labels,
    data,
  }
}

/**
 * Map an API response to the store in a paged format.
 * @param {AccountListApiResponse} response The API response to add to the store
 * @param {number} limit The number of items to return
 * @param {number} offset The offset to start from
 * @param {AccountList} storedAccounts Existing pages in the store
 * @returns {AccountList} The updated store with the new page added.
 */
export const mapApiResponseToStore = (
  response: AccountListApiResponse,
  limit: number,
  offset: number,
  storedAccounts: AccountList,
  lastKey?: string | null,
  lastKeyHistory?: string[] | null,
): AccountList => {
  const page = Math.ceil((offset + (response.rows?.length ?? limit)) / DEFAULT_PAGE_SIZE)
  return {
    columns: response.columns,
    data: [
      {
        page,
        items: response.rows,
      },
    ],
    total: response.total,
    lastKey,
    lastKeyHistory,
  }
}

/**
 * Combines the list of accounts returned from the reporting API with Acccounts returned from the Participated Motions DynamoDB table.
 * It also adds missing columns to the account list returned from participated Motions.
 * @param {AccountListApiResponse} currentAccountsList Account list returned by reporting API.
 * @param {TenantInsightAccounts} accountInsights Account insights for given tenant.
 * @param {Account[]} accounts Accounts recived from opensearch.
 * @returns {AccountListApiResponse} The combined account list.
 */
export const combineAccountLists = (
  currentAccountsList: AccountListApiResponse,
  accountInsights: TenantInsightAccounts | null,
  accounts: Account[],
) => {
  const reportingAccountsIds = Array.isArray(currentAccountsList.rows)
    ? currentAccountsList.rows.map(({ id }) => id)
    : []
  const missingAccountsListColumns = Array.isArray(currentAccountsList.columns)
    ? currentAccountsList.columns
        .filter((column) => {
          if (column.type === AccountListTypeEnum.Composite) {
            return false
          }
          return true
        })
        .map((column) => ({ [column.key]: 'N/A' }))
    : []

  const accountsToAdd = accounts.reduce((result: AccountListRow[], account) => {
    const motionAccountId = account.accountId
    if (!reportingAccountsIds.includes(motionAccountId)) {
      const accountName = accountInsights?.accounts?.[motionAccountId]?.account_name ?? account.accountName
      const userCount = accountInsights?.accounts?.[motionAccountId]?.user_count ?? 0

      result.push({
        // Adds missing columns to object from current account list
        ...missingAccountsListColumns.reduce((acc, column) => ({ ...acc, ...column }), {}),
        id: motionAccountId,
        account: accountName,
        totalUsers: userCount,
        participatedSince: formatParticipatedSince(account.timestamp),
        csManager: '',
      } as AccountListRow)
    }
    return result
  }, [] as AccountListRow[])

  return {
    ...currentAccountsList,
    rows: currentAccountsList?.rows?.concat(accountsToAdd) ?? accountsToAdd,
    total: currentAccountsList.total + accountsToAdd.length,
  }
}
