/* eslint-disable no-template-curly-in-string */
export const demoGetAllMotions = {
  data: [
    {
      isActive: true,
      version: 5,
      stateHistory: {
        DRAFT: '2023-09-05T14:35:57.852Z',
      },
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'gainsight',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      lastUpdatedAt: 1693924557852,
      createdBy: 'peter+magnifydemo@magnify.io',
      playbookId: '3161a7db-92e3-43af-9f0f-97d8f6f4e1b6',
      currState: 'EXECUTING',
      title: 'Tech Touch Motion',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 768,
            users: 153700,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 4500,
            COMPLETED: 149200,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 3200000,
          },
        },
      },
    },
    {
      isActive: false,
      version: 6,
      stateHistory: {
        DRAFT: '2024-05-01T14:05:26.676Z',
      },
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'intercom',
        },
        {
          platform: 'slack',
        },
        {
          platform: 'zendesk',
        },
        {
          platform: 'hubspot',
        },
        {
          platform: 'magnify',
        },
      ],
      tenantId: '5b699535-bb99-4654-a796-a141f7a9f6d4',
      lastUpdatedAt: 1714572326676,
      createdBy: 'carolyn.eames@magnify.io',
      playbookId: 'dd8f70fb-ce5d-47cd-93f3-7ecf6be142fa',
      currState: 'DRAFT',
      title: 'Low Feature Adoption Motion',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 0,
            users: 0,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 0,
            COMPLETED: 0,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 0,
          },
        },
      },
    },
    {
      isActive: true,
      version: 2,
      stateHistory: {
        DRAFT: '2023-07-25T13:43:29.954Z',
      },
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'slack',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      lastUpdatedAt: 1690292609954,
      createdBy: 'jon@magnify.io',
      playbookId: 'dbd9b833-8bb7-4651-8ff3-12b5f7c4f4cf',
      currState: 'DRAFT',
      title: 'Re-engage Customer',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 0,
            users: 0,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 0,
            COMPLETED: 0,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 0,
          },
        },
      },
    },
    {
      version: 11,
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'slack',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      lastUpdatedAt: 1689967643019,
      createdBy: 'josh+magnifydemo@magnify.io',
      playbookId: 'a71299a6-5862-47eb-8201-7ec1060c7309',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-07-21T19:27:23.019Z',
      },
      segmentTotals: [
        {
          totalAccount: 10,
          timestamp: '2023-08-31T09:48:53.065Z',
          totalUsers: 20,
        },
      ],
      currState: 'DRAFT',
      title: 'Onboarding Motion',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 0,
            users: 0,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 0,
            COMPLETED: 0,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 0,
          },
        },
      },
    },
    {
      isActive: true,
      version: 8,
      stateHistory: {
        DRAFT: '2023-05-24T04:17:16.019Z',
      },
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'slack',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      lastUpdatedAt: 1684901836019,
      createdBy: 'ria+magnifydemo@magnify.io',
      playbookId: 'f144d2ce-705c-44a5-a514-5c39e2cad254',
      currState: 'DRAFT',
      title: '[Demo] A/B Test VariantB: Feature Adoption Expansion Motion',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 0,
            users: 0,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 0,
            COMPLETED: 0,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 0,
          },
        },
      },
    },
    {
      isActive: true,
      version: 9,
      stateHistory: {
        DRAFT: '2023-05-24T04:17:10.579Z',
      },
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      lastUpdatedAt: 1684901830579,
      createdBy: 'ria+magnifydemo@magnify.io',
      playbookId: '3d75e649-08a9-4892-b541-dcd9f4d58cf2',
      currState: 'DRAFT',
      title: '[Demo] A/B Test VariantA: Feature Adoption Expansion Motion',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 0,
            users: 0,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 0,
            COMPLETED: 0,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 0,
          },
        },
      },
    },
    {
      isActive: true,
      version: 4,
      stateHistory: {
        DRAFT: '2023-05-23T20:24:37.584Z',
      },
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'slack',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      lastUpdatedAt: 1684873477584,
      createdBy: 'ria+magnifydemo@magnify.io',
      playbookId: 'd1da5c33-6be1-495b-9095-057ab1728b40',
      currState: 'DRAFT',
      title: '[Demo]: Feature Adoption Expansion Motion',
      isInvalid: false,
      observabilityMetrics: {
        participantStatistics: {
          totals: {
            accounts: 0,
            users: 0,
          },
          state: {
            ENTERED: 0,
            ACTIVE: 0,
            COMPLETED: 0,
          },
        },
        actionStatistics: {
          totals: {
            IN_PROGRESS: 0,
            FAILED: 0,
            SUCCEEDED: 0,
          },
        },
      },
    },
  ],
  total: 10,
}

export const demoGetMotions = [
  {
    data: {
      version: 5,
      dslUrl: '3161a7db-92e3-43af-9f0f-97d8f6f4e1b6-5.json',
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'gainsight',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1693924557852,
      goal: {},
      lastUpdatedAt: 1693924557852,
      createdBy: 'peter+magnifydemo@magnify.io',
      playbookId: '3161a7db-92e3-43af-9f0f-97d8f6f4e1b6',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-09-05T14:35:57.852Z',
      },
      segmentExports: [
        {
          id: '1',
          timestamp: '2024-06-16T08:53:00.000Z',
          requestedBy: 'jon@magnify.io',
          status: 'SUCCESS',
          path: '1/saved_journeys/draft/a45b3958-e871-4602-ad4c-8c7bc8d4f7a0/1/2024-08-13T12:57:08.638Z.csv',
        },
        {
          id: '2',
          timestamp: '2024-06-14T12:40:00.000Z',
          requestedBy: 'josh@magnify.io',
          status: 'SUCCESS',
          path: '1/saved_journeys/draft/a45b3958-e871-4602-ad4c-8c7bc8d4f7a0/1/2024-08-13T12:57:08.638Z.csv',
        },
        {
          id: '3',
          timestamp: '2024-06-06T12:03:00.000Z',
          requestedBy: 'chandler@magnify.io',
          status: 'PENDING',
        },
        {
          id: '4',
          timestamp: '2024-05-25T09:53:09.000Z',
          requestedBy: 'angela@magnify.io',
          status: 'SUCCESS',
          path: '1/saved_journeys/draft/a45b3958-e871-4602-ad4c-8c7bc8d4f7a0/1/2024-08-13T12:57:08.638Z.csv',
        },
        {
          id: '5',
          timestamp: '2024-05-12T07:53:19.000Z',
          requestedBy: 'mike@magnify.io',
          status: 'FAILED',
          message: 'Error: Something went wrong',
        },
      ],
      metrics: [
        {
          field: 'Age',
          trend: '-',
          magnifyDisplayName: 'Call To Action',
          name: 'Age',
          aggregation: 'countOfValues',
          timespan: '7days',
          type: 'integer',
          userCreated: true,
          key: 0,
          platform: 'gainsight',
          target: 7,
          object: 'Call_To_Action',
        },
      ],
      description: '',
      currState: 'EXECUTING',
      title: 'Tech Touch Motion',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'string',
                      field: 'Account Name',
                      key: 'Name',
                      operator: 'anyOf',
                      value: ['Magnify Developers', 'Magnify Product'],
                      isNewStatement: false,
                      solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                      isDynamicInput: false,
                      magnifyDisplayName: 'Account',
                    },
                  ],
                  relationOperator: 'AND',
                },
              ],
              relationOperator: 'OR',
            },
            excludedUserIds: ['0051r00000ZP7AgAA1', '0051r00000YQ8BhBB1', '0051r00000XN9CfCC1', '0051r00000WM0DgDD1'],
            description: 'Segment',
            position: {
              x: 380,
              y: 50,
            },
            next: 'triggerEvent584732690288',
          },
          triggerEvent584732690288: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'eventPayload',
                  isDynamicInput: false,
                  type: 'eventPayload',
                  value: {},
                },
              ],
            },
            description: 'Onboarding Resource Guide',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '333b9438-ce6f-426a-88d3-e7180907ca2c',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Trigger_Event',
            solutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
            iconName: 'triggerEvent',
            next: 'timeDelay602644717162',
          },
          timeDelay602644717162: {
            type: 'timeDelay',
            payload: {
              amount: 2,
              units: 'hours',
            },
            description: '2 Weeks',
            position: {
              x: 380,
              y: 470,
            },
            next: 'triggerEvent814560792971',
          },
          triggerEvent814560792971: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'eventPayload',
                  isDynamicInput: false,
                  type: 'eventPayload',
                  value: {},
                },
              ],
            },
            description: 'Automated Check-in',
            position: {
              x: 380,
              y: 680,
            },
            actionId: '333b9438-ce6f-426a-88d3-e7180907ca2c',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Trigger_Event',
            solutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
            iconName: 'triggerEvent',
            next: 'ifElse615143814040',
          },
          ifElse615143814040: {
            type: 'ifElse',
            payload: {},
            description: 'NPS Promoter?',
            position: {
              x: 380,
              y: 890,
            },
            default: 'triggerEvent809017933089',
            choices: [
              {
                next: 'createCta350189859510',
              },
            ],
          },
          triggerEvent809017933089: {
            type: 'action',
            payload: {},
            description: 'Trigger event',
            position: {
              x: 380,
              y: 1100,
            },
            actionId: '333b9438-ce6f-426a-88d3-e7180907ca2c',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Trigger_Event',
            solutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
            iconName: 'triggerEvent',
            next: 'merge966515920853',
          },
          merge966515920853: {
            type: 'merge',
            payload: {
              targets: ['989016907386'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 1520,
            },
            next: 'timeDelay137539969123',
          },
          timeDelay137539969123: {
            type: 'timeDelay',
            payload: {
              amount: 0,
              units: 'hours',
            },
            description: '90 Days',
            position: {
              x: 380,
              y: 1730,
            },
            next: 'triggerEvent662029071632',
          },
          triggerEvent662029071632: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'eventPayload',
                  isDynamicInput: false,
                  type: 'eventPayload',
                  value: {},
                },
              ],
            },
            description: 'Quarterly Check-in',
            position: {
              x: 380,
              y: 1940,
            },
            actionId: '333b9438-ce6f-426a-88d3-e7180907ca2c',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Trigger_Event',
            solutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
            iconName: 'triggerEvent',
            next: 'loop880325097032',
          },
          loop880325097032: {
            type: 'loop',
            payload: {
              timeOfLoop: 0,
              amount: 0,
              units: 'hours',
              exitCondition: {
                groups: [],
                relationOperator: 'OR',
              },
            },
            description: 'Loop',
            position: {
              x: 380,
              y: 2150,
            },
            targetNodeId: 'timeDelay137539969123',
            end: true,
          },
          createCta350189859510: {
            type: 'action',
            payload: {},
            description: 'Create CTA',
            position: {
              x: 880,
              y: 1100,
            },
            actionId: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Call_To_Action',
            solutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
            iconName: 'createCustomObject',
            next: 'triggerEvent989016907386',
          },
          triggerEvent989016907386: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'eventPayload',
                  isDynamicInput: false,
                  type: 'eventPayload',
                  value: {},
                },
              ],
            },
            description: 'Schedule Time with CSM',
            position: {
              x: 880,
              y: 1310,
            },
            actionId: '333b9438-ce6f-426a-88d3-e7180907ca2c',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Trigger_Event',
            solutionInstanceId: '0b671e7e-1fe7-4d3d-bcf4-10a420464f4f',
            iconName: 'triggerEvent',
            next: 'merge966515920853',
          },
        },
      },
    },
  },
  {
    data: {
      version: 6,
      dslUrl: 'dd8f70fb-ce5d-47cd-93f3-7ecf6be142fa-6.json',
      validationErrors: [],
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'intercom',
        },
        {
          platform: 'slack',
        },
        {
          platform: 'zendesk',
        },
        {
          platform: 'hubspot',
        },
        {
          platform: 'magnify',
        },
      ],
      tenantId: '5b699535-bb99-4654-a796-a141f7a9f6d4',
      createdAt: 1714572326676,
      goal: {
        type: 'churn',
        value: 0,
      },
      lastUpdatedAt: 1714572326676,
      createdBy: 'carolyn.eames@magnify.io',
      playbookId: 'dd8f70fb-ce5d-47cd-93f3-7ecf6be142fa',
      isActive: true,
      stateHistory: {
        DRAFT: '2024-05-01T14:05:26.676Z',
      },
      segmentExports: [],
      metrics: [],
      description: 'Omni-channel motion to increase usage of feature A ',
      currState: 'DRAFT',
      title: 'Low Feature Adoption Motion ',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'datetime',
                      field: 'Created Date',
                      key: 'CreatedDate',
                      operator: 'equalsOrGreaterThan',
                      value: '2023-01-01',
                      isNewStatement: false,
                      solutionInstanceId: '1',
                      isDynamicInput: false,
                      magnifyDisplayName: 'Account',
                    },
                  ],
                  relationOperator: 'AND',
                },
              ],
              relationOperator: 'OR',
            },
            description: 'Segment  of accounts with less than 30% of users adopting feature A',
            position: {
              x: 380,
              y: 50,
            },
            next: 'createTimelineEvent469605743499',
          },
          createTimelineEvent469605743499: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'Activity Date',
                  isDynamicInput: false,
                  type: 'datetime',
                  value: '2024-04-30',
                },
                {
                  key: 'Author Id',
                  isDynamicInput: true,
                  type: 'string',
                  value: {
                    key: 'Author Id',
                    field: 'Author Id',
                    type: 'string',
                    platform: 'gainsight',
                    object: 'Activity_Timeline',
                    solutionInstanceId: '1',
                    magnifyDisplayName: 'Activity Timeline',
                    value: '${dynamicInput}',
                  },
                },
                {
                  key: 'Notes',
                  isDynamicInput: true,
                  type: 'string',
                  value: {
                    key: 'Email (Company)',
                    field: 'Email (Company)',
                    type: 'string',
                    platform: 'gainsight',
                    object: 'Company_Person',
                    solutionInstanceId: '1',
                    magnifyDisplayName: 'Company Person',
                    value: '${dynamicInput}',
                  },
                },
                {
                  key: 'Subject',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Low adoption of Feature A - less than 30% of users ',
                },
              ],
            },
            description: 'Create Timeline Event for low usage ',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            solutionInstanceId: '1',
            iconName: 'createCalendar',
            next: 'sendEmail819962625800',
          },
          sendEmail819962625800: {
            type: 'action',
            payload: {},
            description: 'Enroll in persona-based usecase campaign\n\n',
            position: {
              x: 380,
              y: 470,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'timeDelay483661157452',
          },
          timeDelay483661157452: {
            type: 'timeDelay',
            payload: {
              amount: 3,
              units: 'weeks',
            },
            description: 'Time delay',
            position: {
              x: 380,
              y: 680,
            },
            next: 'ifElse839622364382',
          },
          ifElse839622364382: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'User starts session?',
            position: {
              x: 380,
              y: 890,
            },
            default: 'launchGuide182960991772',
            choices: [
              {
                next: 'sendEmail186325438867',
              },
            ],
          },
          launchGuide182960991772: {
            type: 'action',
            payload: {},
            description: 'Launch feature A beginner guide',
            position: {
              x: 380,
              y: 1100,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'lightBulb',
            next: 'merge90003734814',
          },
          merge90003734814: {
            type: 'merge',
            payload: {
              targets: ['228638666877'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 2990,
            },
            next: 'ifElse637427996764',
          },
          ifElse637427996764: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'Did user use start using feature A? ',
            position: {
              x: 380,
              y: 3200,
            },
            default: 'ifElse274003398141',
            choices: [
              {
                next: 'sendMessage106265213334',
              },
            ],
          },
          ifElse274003398141: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'Did user complete using feature A? ',
            position: {
              x: 380,
              y: 3410,
            },
            default: 'createTicket583758452772',
            choices: [
              {
                next: 'sendMessage164816285165',
              },
            ],
          },
          createTicket583758452772: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'ticketName',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Send inapp message ',
                },
                {
                  key: 'ticketTitle',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Send inapp message ',
                },
                {
                  key: 'ticketDescription',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Send inapp message ',
                },
              ],
            },
            description: 'Send in-app message reinforcing positive behavior and sharing links for deeper learning',
            position: {
              x: 380,
              y: 3620,
            },
            actionId: 'eb3e0b4a-c2e4-46e2-aa88-bd4724a95dfe',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'intercom',
            object: 'Tickets',
            iconName: 'createFiles',
            next: 'timeDelay672718209350',
          },
          timeDelay672718209350: {
            type: 'timeDelay',
            payload: {
              amount: 0,
              units: 'hours',
            },
            description: 'Wait 1 week',
            position: {
              x: 380,
              y: 3830,
            },
            next: 'ifElse227556883386',
          },
          ifElse227556883386: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'More than 30% of users have adopted feature A?',
            position: {
              x: 380,
              y: 4040,
            },
            default: 'createTimelineEvent168385258423',
            choices: [
              {
                next: 'createCta554721349177',
              },
            ],
          },
          createTimelineEvent168385258423: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'Activity Date',
                  isDynamicInput: false,
                  type: 'datetime',
                  value: '2024-04-30',
                },
                {
                  key: 'Author Id',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'CSM',
                },
                {
                  key: 'Notes',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Congrats, your customer, ACEM CORP has more than 30% of users adopting feature A',
                },
                {
                  key: 'Subject',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Adoption risk mitigated (feature A) ',
                },
              ],
            },
            description: 'Update timeline to indicate reduction of churn risk due to low feature adoption',
            position: {
              x: 380,
              y: 4250,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            solutionInstanceId: '1',
            iconName: 'createCalendar',
            end: true,
          },
          createCta554721349177: {
            type: 'action',
            payload: {},
            description: 'Create CTA',
            position: {
              x: 880,
              y: 4250,
            },
            actionId: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Call_To_Action',
            solutionInstanceId: '1',
            iconName: 'createCustomObject',
            end: true,
          },
          sendMessage164816285165: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 1380,
              y: 3620,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'launchGuide482113996676',
          },
          launchGuide482113996676: {
            type: 'action',
            payload: {},
            description: 'Launch in-app CES survey ',
            position: {
              x: 1380,
              y: 3830,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'lightBulb',
            next: 'ifElse878765354182',
          },
          ifElse878765354182: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'Does survey capture Support challenges?',
            position: {
              x: 1380,
              y: 4040,
            },
            default: 'merge803085369570',
            choices: [
              {
                next: 'ifElse153304623180',
              },
            ],
          },
          merge803085369570: {
            type: 'merge',
            payload: {
              targets: ['143292228800'],
            },
            description: 'Merge',
            position: {
              x: 1380,
              y: 4880,
            },
            next: 'createTicket920932489421',
          },
          createTicket920932489421: {
            type: 'action',
            payload: {},
            description: 'Create Ticket',
            position: {
              x: 1380,
              y: 5090,
            },
            actionId: '9b724756-0f99-498d-abfd-9f440f629eb6',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'zendesk',
            object: 'Ticket',
            iconName: 'createFiles',
            next: 'createCta100820457075',
          },
          createCta100820457075: {
            type: 'action',
            payload: {},
            description: 'Create CTA',
            position: {
              x: 1380,
              y: 5300,
            },
            actionId: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Call_To_Action',
            solutionInstanceId: '1',
            iconName: 'createCustomObject',
            end: true,
          },
          ifElse153304623180: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'Is survey score a 5 ot above?',
            position: {
              x: 1880,
              y: 4250,
            },
            default: 'sendTransactionalEmail979049549990',
            choices: [
              {
                next: 'createCta724801775138',
              },
            ],
          },
          sendTransactionalEmail979049549990: {
            type: 'action',
            payload: {},
            description: 'Send Transactional Email',
            position: {
              x: 1880,
              y: 4460,
            },
            actionId: '7d8f029e-8d83-4490-950b-72c2da7a5e39',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'hubspot',
            object: 'Send_Transactional_Email',
            iconName: 'sendEmail',
            end: true,
          },
          createCta724801775138: {
            type: 'action',
            payload: {},
            description: 'Create CTA',
            position: {
              x: 2380,
              y: 4460,
            },
            actionId: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Call_To_Action',
            solutionInstanceId: '1',
            iconName: 'createCustomObject',
            end: true,
          },
          sendMessage106265213334: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 2880,
              y: 3410,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'waitForTrigger143292228800',
          },
          waitForTrigger143292228800: {
            type: 'waitForTrigger',
            payload: {},
            description: 'Wait for trigger',
            position: {
              x: 2880,
              y: 3620,
            },
            next: 'merge803085369570',
          },
          sendEmail186325438867: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'senderName',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Customer Success',
                },
                {
                  key: 'senderEmail',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'csm@amcecorp.com',
                },
                {
                  key: 'recipientName',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Amazing Customer ',
                },
                {
                  key: 'recipientEmail',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'customer@acmecorp.com',
                },
                {
                  key: 'subject',
                  isDynamicInput: false,
                  type: 'string',
                  value: 'Why you should be logging on',
                },
                {
                  key: 'template',
                  isDynamicInput: false,
                  type: 'html',
                  value: 'Fun Survey Template',
                },
                {
                  key: 'templateId',
                  isDynamicInput: false,
                  type: 'hidden',
                  value: '333d75fc-f211-4d98-a36e-cbba07c7bc0d',
                },
                {
                  key: 'templateVersion',
                  isDynamicInput: false,
                  type: 'hidden',
                  value: 10,
                },
              ],
            },
            description: 'Send "We haven\'t seen you in a while" email',
            position: {
              x: 880,
              y: 1100,
            },
            actionId: '7a01af85-1d1b-4f0e-932b-ee9ca1f6c38d',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'magnify',
            object: 'Email',
            iconName: 'sendEmail',
            next: 'loop314444559447',
          },
          loop314444559447: {
            type: 'loop',
            payload: {},
            description: 'Loop',
            position: {
              x: 880,
              y: 1310,
            },
            targetNodeId: 'sendEmail186325438867',
            next: 'ifElse656140635122',
          },
          ifElse656140635122: {
            type: 'ifElse',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'User starts session? ',
            position: {
              x: 880,
              y: 1520,
            },
            default: 'merge723296888293',
            choices: [
              {
                next: 'updateAccount263797783160',
              },
            ],
          },
          merge723296888293: {
            type: 'merge',
            payload: {
              targets: ['679778837525'],
            },
            description: 'Merge',
            position: {
              x: 880,
              y: 2570,
            },
            next: 'launchGuide228638666877',
          },
          launchGuide228638666877: {
            type: 'action',
            payload: {},
            description: 'Launch Feature A beginner guide',
            position: {
              x: 880,
              y: 2780,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'lightBulb',
            next: 'merge90003734814',
          },
          updateAccount263797783160: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'Description',
                  name: 'Account Description',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Description',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Fax',
                  name: 'Account Fax',
                  type: 'phone',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Fax',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Id',
                  name: 'Account ID',
                  type: 'id',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Id',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Name',
                  name: 'Account Name',
                  type: 'string',
                  required: true,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Name',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'AccountNumber',
                  name: 'Account Number',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'AccountNumber',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Phone',
                  name: 'Account Phone',
                  type: 'phone',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Phone',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Rating',
                  name: 'Account Rating',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Rating',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Site',
                  name: 'Account Site',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Site',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'AccountSource',
                  name: 'Account Source',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'AccountSource',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Type',
                  name: 'Account Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Type',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Active__c',
                  name: 'Active',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Active__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'AnnualRevenue',
                  name: 'Annual Revenue',
                  type: 'currency',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'AnnualRevenue',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingAddress',
                  name: 'Billing Address',
                  type: 'address',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingAddress',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingCity',
                  name: 'Billing City',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingCity',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingCountry',
                  name: 'Billing Country',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingCountry',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingGeocodeAccuracy',
                  name: 'Billing Geocode Accuracy',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingGeocodeAccuracy',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingLatitude',
                  name: 'Billing Latitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingLatitude',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingLongitude',
                  name: 'Billing Longitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingLongitude',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingState',
                  name: 'Billing State/Province',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingState',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingStreet',
                  name: 'Billing Street',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingStreet',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'BillingPostalCode',
                  name: 'Billing Zip/Postal Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingPostalCode',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'CleanStatus',
                  name: 'Clean Status',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CleanStatus',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'CreatedById',
                  name: 'Created By ID',
                  type: 'reference',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CreatedById',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'CreatedDate',
                  name: 'Created Date',
                  type: 'datetime',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CreatedDate',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'CustomerPriority__c',
                  name: 'Customer Priority',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CustomerPriority__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'DandbCompanyId',
                  name: 'D&B Company ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'DandbCompanyId',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'DunsNumber',
                  name: 'D-U-N-S Number',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'DunsNumber',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Jigsaw',
                  name: 'Data.com Key',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Jigsaw',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'IsDeleted',
                  name: 'Deleted',
                  type: 'boolean',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'IsDeleted',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'NumberOfEmployees',
                  name: 'Employees',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NumberOfEmployees',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Industry',
                  name: 'Industry',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Industry',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'JigsawCompanyId',
                  name: 'Jigsaw Company ID',
                  type: 'string',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'JigsawCompanyId',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'LastActivityDate',
                  name: 'Last Activity',
                  type: 'date',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastActivityDate',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'LastModifiedById',
                  name: 'Last Modified By ID',
                  type: 'reference',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastModifiedById',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'LastModifiedDate',
                  name: 'Last Modified Date',
                  type: 'datetime',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastModifiedDate',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'LastReferencedDate',
                  name: 'Last Referenced Date',
                  type: 'datetime',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastReferencedDate',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'LastViewedDate',
                  name: 'Last Viewed Date',
                  type: 'datetime',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastViewedDate',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Account_Manager__c',
                  name: 'M_Account_Manager',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Account_Manager__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Billing_Cycle__c',
                  name: 'M_Billing_Cycle',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Billing_Cycle__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Billing_Method__c',
                  name: 'M_Billing_Method',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Billing_Method__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Comments__c',
                  name: 'M_Comments',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Comments__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Contact_Date__c',
                  name: 'M_Contact_Date',
                  type: 'datetime',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Contact_Date__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Employee_Tier__c',
                  name: 'M_Employee_Tier',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Employee_Tier__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_LastModifiedDate__c',
                  name: 'M_LastModifiedDate',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_LastModifiedDate__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'M_Stage__c',
                  name: 'M_Stage',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Stage__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'MasterRecordId',
                  name: 'Master Record ID',
                  type: 'reference',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'MasterRecordId',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'NaicsCode',
                  name: 'NAICS Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NaicsCode',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'NaicsDesc',
                  name: 'NAICS Description',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NaicsDesc',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'November_15_Date_Field__c',
                  name: 'November 15 Date Field',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'November_15_Date_Field__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'November_15_checkbox__c',
                  name: 'November 15 checkbox',
                  type: 'boolean',
                  required: true,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'November_15_checkbox__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'NumberofLocations__c',
                  name: 'Number of Locations',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NumberofLocations__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'OperatingHoursId',
                  name: 'Operating Hour ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'OperatingHoursId',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'OwnerId',
                  name: 'Owner ID',
                  type: 'reference',
                  required: true,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'OwnerId',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Ownership',
                  name: 'Ownership',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Ownership',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ParentId',
                  name: 'Parent Account ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ParentId',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'PhotoUrl',
                  name: 'Photo URL',
                  type: 'url',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'PhotoUrl',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Sic',
                  name: 'SIC Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Sic',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'SicDesc',
                  name: 'SIC Description',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SicDesc',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'SLA__c',
                  name: 'SLA',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SLA__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'SLAExpirationDate__c',
                  name: 'SLA Expiration Date',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SLAExpirationDate__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'SLASerialNumber__c',
                  name: 'SLA Serial Number',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SLASerialNumber__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingAddress',
                  name: 'Shipping Address',
                  type: 'address',
                  required: false,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingAddress',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingCity',
                  name: 'Shipping City',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingCity',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingCountry',
                  name: 'Shipping Country',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingCountry',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingGeocodeAccuracy',
                  name: 'Shipping Geocode Accuracy',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingGeocodeAccuracy',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingLatitude',
                  name: 'Shipping Latitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingLatitude',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingLongitude',
                  name: 'Shipping Longitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingLongitude',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingState',
                  name: 'Shipping State/Province',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingState',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingStreet',
                  name: 'Shipping Street',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingStreet',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'ShippingPostalCode',
                  name: 'Shipping Zip/Postal Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingPostalCode',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'SystemModstamp',
                  name: 'System Modstamp',
                  type: 'datetime',
                  required: true,
                  updateable: false,
                  createable: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SystemModstamp',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'TickerSymbol',
                  name: 'Ticker Symbol',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'TickerSymbol',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Tradestyle',
                  name: 'Tradestyle',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Tradestyle',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'UpsellOpportunity__c',
                  name: 'Upsell Opportunity',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'UpsellOpportunity__c',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'Website',
                  name: 'Website',
                  type: 'url',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Website',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
                {
                  key: 'YearStarted',
                  name: 'Year Started',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'YearStarted',
                  solutionInstanceId: '1',
                  isDynamicInput: false,
                },
              ],
            },
            description: 'Update Contact to dormant user in SFDC ',
            position: {
              x: 1380,
              y: 1730,
            },
            actionId: '103eee45-2d0e-4928-a1ca-e4e6bfe88a8f',
            actionLevel: 'account',
            action: 'update',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Account',
            solutionInstanceId: '1',
            iconName: 'updateUser',
            next: 'waitForTrigger899112809689',
          },
          waitForTrigger899112809689: {
            type: 'waitForTrigger',
            payload: {
              groups: [],
              relationOperator: 'OR',
            },
            description: 'Wait for trigger- does user have a session? ',
            position: {
              x: 1380,
              y: 1940,
            },
            next: 'launchGuide535152108755',
          },
          launchGuide535152108755: {
            type: 'action',
            payload: {},
            description: 'Launch product refresher guide',
            position: {
              x: 1380,
              y: 2150,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'lightBulb',
            next: 'updateAccount679778837525',
          },
          updateAccount679778837525: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'Description',
                  name: 'Account Description',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Description',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Fax',
                  name: 'Account Fax',
                  type: 'phone',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Fax',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Id',
                  name: 'Account ID',
                  type: 'id',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Id',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Name',
                  name: 'Account Name',
                  type: 'string',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Name',
                  solutionInstanceId: '1',
                },
                {
                  key: 'AccountNumber',
                  name: 'Account Number',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'AccountNumber',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Phone',
                  name: 'Account Phone',
                  type: 'phone',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Phone',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Rating',
                  name: 'Account Rating',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Rating',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Site',
                  name: 'Account Site',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Site',
                  solutionInstanceId: '1',
                },
                {
                  key: 'AccountSource',
                  name: 'Account Source',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'AccountSource',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Type',
                  name: 'Account Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Type',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Active__c',
                  name: 'Active',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Active__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'AnnualRevenue',
                  name: 'Annual Revenue',
                  type: 'currency',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'AnnualRevenue',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingAddress',
                  name: 'Billing Address',
                  type: 'address',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingAddress',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingCity',
                  name: 'Billing City',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingCity',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingCountry',
                  name: 'Billing Country',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingCountry',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingGeocodeAccuracy',
                  name: 'Billing Geocode Accuracy',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingGeocodeAccuracy',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingLatitude',
                  name: 'Billing Latitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingLatitude',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingLongitude',
                  name: 'Billing Longitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingLongitude',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingState',
                  name: 'Billing State/Province',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingState',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingStreet',
                  name: 'Billing Street',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingStreet',
                  solutionInstanceId: '1',
                },
                {
                  key: 'BillingPostalCode',
                  name: 'Billing Zip/Postal Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'BillingPostalCode',
                  solutionInstanceId: '1',
                },
                {
                  key: 'CleanStatus',
                  name: 'Clean Status',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CleanStatus',
                  solutionInstanceId: '1',
                },
                {
                  key: 'CreatedById',
                  name: 'Created By ID',
                  type: 'reference',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CreatedById',
                  solutionInstanceId: '1',
                },
                {
                  key: 'CreatedDate',
                  name: 'Created Date',
                  type: 'datetime',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CreatedDate',
                  solutionInstanceId: '1',
                },
                {
                  key: 'CustomerPriority__c',
                  name: 'Customer Priority',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'CustomerPriority__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'DandbCompanyId',
                  name: 'D&B Company ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'DandbCompanyId',
                  solutionInstanceId: '1',
                },
                {
                  key: 'DunsNumber',
                  name: 'D-U-N-S Number',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'DunsNumber',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Jigsaw',
                  name: 'Data.com Key',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Jigsaw',
                  solutionInstanceId: '1',
                },
                {
                  key: 'IsDeleted',
                  name: 'Deleted',
                  type: 'boolean',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'IsDeleted',
                  solutionInstanceId: '1',
                },
                {
                  key: 'NumberOfEmployees',
                  name: 'Employees',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NumberOfEmployees',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Industry',
                  name: 'Industry',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Industry',
                  solutionInstanceId: '1',
                },
                {
                  key: 'JigsawCompanyId',
                  name: 'Jigsaw Company ID',
                  type: 'string',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'JigsawCompanyId',
                  solutionInstanceId: '1',
                },
                {
                  key: 'LastActivityDate',
                  name: 'Last Activity',
                  type: 'date',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastActivityDate',
                  solutionInstanceId: '1',
                },
                {
                  key: 'LastModifiedById',
                  name: 'Last Modified By ID',
                  type: 'reference',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastModifiedById',
                  solutionInstanceId: '1',
                },
                {
                  key: 'LastModifiedDate',
                  name: 'Last Modified Date',
                  type: 'datetime',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastModifiedDate',
                  solutionInstanceId: '1',
                },
                {
                  key: 'LastReferencedDate',
                  name: 'Last Referenced Date',
                  type: 'datetime',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastReferencedDate',
                  solutionInstanceId: '1',
                },
                {
                  key: 'LastViewedDate',
                  name: 'Last Viewed Date',
                  type: 'datetime',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'LastViewedDate',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Account_Manager__c',
                  name: 'M_Account_Manager',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Account_Manager__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Billing_Cycle__c',
                  name: 'M_Billing_Cycle',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Billing_Cycle__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Billing_Method__c',
                  name: 'M_Billing_Method',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Billing_Method__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Comments__c',
                  name: 'M_Comments',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Comments__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Contact_Date__c',
                  name: 'M_Contact_Date',
                  type: 'datetime',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Contact_Date__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Employee_Tier__c',
                  name: 'M_Employee_Tier',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Employee_Tier__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_LastModifiedDate__c',
                  name: 'M_LastModifiedDate',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_LastModifiedDate__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'M_Stage__c',
                  name: 'M_Stage',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'M_Stage__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'MasterRecordId',
                  name: 'Master Record ID',
                  type: 'reference',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'MasterRecordId',
                  solutionInstanceId: '1',
                },
                {
                  key: 'NaicsCode',
                  name: 'NAICS Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NaicsCode',
                  solutionInstanceId: '1',
                },
                {
                  key: 'NaicsDesc',
                  name: 'NAICS Description',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NaicsDesc',
                  solutionInstanceId: '1',
                },
                {
                  key: 'November_15_Date_Field__c',
                  name: 'November 15 Date Field',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'November_15_Date_Field__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'November_15_checkbox__c',
                  name: 'November 15 checkbox',
                  type: 'boolean',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'November_15_checkbox__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'NumberofLocations__c',
                  name: 'Number of Locations',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'NumberofLocations__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'OperatingHoursId',
                  name: 'Operating Hour ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'OperatingHoursId',
                  solutionInstanceId: '1',
                },
                {
                  key: 'OwnerId',
                  name: 'Owner ID',
                  type: 'reference',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'OwnerId',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Ownership',
                  name: 'Ownership',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Ownership',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ParentId',
                  name: 'Parent Account ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ParentId',
                  solutionInstanceId: '1',
                },
                {
                  key: 'PhotoUrl',
                  name: 'Photo URL',
                  type: 'url',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'PhotoUrl',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Sic',
                  name: 'SIC Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Sic',
                  solutionInstanceId: '1',
                },
                {
                  key: 'SicDesc',
                  name: 'SIC Description',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SicDesc',
                  solutionInstanceId: '1',
                },
                {
                  key: 'SLA__c',
                  name: 'SLA',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SLA__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'SLAExpirationDate__c',
                  name: 'SLA Expiration Date',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SLAExpirationDate__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'SLASerialNumber__c',
                  name: 'SLA Serial Number',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SLASerialNumber__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingAddress',
                  name: 'Shipping Address',
                  type: 'address',
                  required: false,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingAddress',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingCity',
                  name: 'Shipping City',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingCity',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingCountry',
                  name: 'Shipping Country',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingCountry',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingGeocodeAccuracy',
                  name: 'Shipping Geocode Accuracy',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingGeocodeAccuracy',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingLatitude',
                  name: 'Shipping Latitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingLatitude',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingLongitude',
                  name: 'Shipping Longitude',
                  type: 'double',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingLongitude',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingState',
                  name: 'Shipping State/Province',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingState',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingStreet',
                  name: 'Shipping Street',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingStreet',
                  solutionInstanceId: '1',
                },
                {
                  key: 'ShippingPostalCode',
                  name: 'Shipping Zip/Postal Code',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'ShippingPostalCode',
                  solutionInstanceId: '1',
                },
                {
                  key: 'SystemModstamp',
                  name: 'System Modstamp',
                  type: 'datetime',
                  required: true,
                  updateable: false,
                  createable: false,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'SystemModstamp',
                  solutionInstanceId: '1',
                },
                {
                  key: 'TickerSymbol',
                  name: 'Ticker Symbol',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'TickerSymbol',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Tradestyle',
                  name: 'Tradestyle',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Tradestyle',
                  solutionInstanceId: '1',
                },
                {
                  key: 'UpsellOpportunity__c',
                  name: 'Upsell Opportunity',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'UpsellOpportunity__c',
                  solutionInstanceId: '1',
                },
                {
                  key: 'Website',
                  name: 'Website',
                  type: 'url',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'Website',
                  solutionInstanceId: '1',
                },
                {
                  key: 'YearStarted',
                  name: 'Year Started',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Account',
                  field: 'YearStarted',
                  solutionInstanceId: '1',
                },
              ],
            },
            description: 'Update Contact to Active user',
            position: {
              x: 1380,
              y: 2360,
            },
            actionId: '103eee45-2d0e-4928-a1ca-e4e6bfe88a8f',
            actionLevel: 'account',
            action: 'update',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Account',
            solutionInstanceId: '1',
            iconName: 'updateUser',
            next: 'merge723296888293',
          },
        },
      },
    },
  },
  {
    data: {
      version: 2,
      dslUrl: 'dbd9b833-8bb7-4651-8ff3-12b5f7c4f4cf-2.json',
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'slack',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1690292609954,
      goal: {
        type: 'expansion',
        value: 10,
      },
      lastUpdatedAt: 1690292609954,
      createdBy: 'jon@magnify.io',
      playbookId: 'dbd9b833-8bb7-4651-8ff3-12b5f7c4f4cf',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-07-25T13:43:29.954Z',
      },
      metrics: [
        {
          field: 'user_id',
          trend: 'higher',
          name: 'USER_ID',
          aggregation: 'numberOf',
          timespan: '7days',
          type: '',
          userCreated: true,
          key: 0,
          operator: 'none',
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
        {
          field: 'Usage Minutes',
          trend: 'higher',
          name: 'USAGE_TIME',
          aggregation: 'total',
          timespan: '7days',
          type: '',
          userCreated: true,
          key: 1,
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
      ],
      description: '',
      currState: 'DRAFT',
      title: 'Re-engage Customer',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'datetime',
                      field: 'Created Date',
                      key: 'CreatedDate',
                      operator: 'equalsOrGreaterThan',
                      value: '2023-02-01T05:08:26.471Z',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: 'AND',
                },
                {
                  groups: [
                    {
                      platform: 'snowflake',
                      object: 'CLOUD_INSTANCE_USAGE',
                      type: 'string',
                      field: 'STATUS',
                      key: 'status',
                      operator: 'contains',
                      value: 'Active',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: '',
                },
              ],
              relationOperator: 'OR',
            },
            description: 'No Customer Activity since 5/1',
            position: {
              x: 380,
              y: 50,
            },
            next: 'createTimelineEvent125771455477',
          },
          createTimelineEvent125771455477: {
            type: 'action',
            payload: {},
            description: 'Create Timeline Event',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            iconName: 'createTimelineEvent',
            next: 'sendEmail488656606012',
          },
          sendEmail488656606012: {
            type: 'action',
            payload: {},
            description: 'Send Email',
            position: {
              x: 380,
              y: 470,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'ifElse189654004641',
          },
          ifElse189654004641: {
            type: 'ifElse',
            payload: {},
            description: 'If read Email?',
            position: {
              x: 380,
              y: 680,
            },
            default: 'merge420320713100',
            choices: [
              {
                next: 'sendMessage899241049789',
              },
            ],
          },
          merge420320713100: {
            type: 'merge',
            payload: {
              targets: ['899241049789'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 1100,
            },
            next: 'ifElse714088672398',
          },
          ifElse714088672398: {
            type: 'ifElse',
            payload: {},
            description: 'If logged in to check the new feature?',
            position: {
              x: 380,
              y: 1310,
            },
            default: 'launchGuide738029042563',
            choices: [
              {
                next: 'createTask350656697549',
              },
            ],
          },
          launchGuide738029042563: {
            type: 'action',
            payload: {},
            description: 'Launch Guide',
            position: {
              x: 380,
              y: 1520,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'launchGuide',
            end: true,
          },
          createTask350656697549: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'OwnerId',
                  name: 'Assigned To ID',
                  type: 'reference',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'OwnerId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallDurationInSeconds',
                  name: 'Call Duration',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallDurationInSeconds',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallObject',
                  name: 'Call Object Identifier',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallObject',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallDisposition',
                  name: 'Call Result',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallDisposition',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallType',
                  name: 'Call Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'IsRecurrence',
                  name: 'Create Recurring Series of Tasks',
                  type: 'boolean',
                  required: true,
                  updateable: false,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'IsRecurrence',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Description',
                  name: 'Description',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Description',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'ActivityDate',
                  name: 'Due Date Only',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'ActivityDate',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'WhoId',
                  name: 'Name ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'WhoId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Priority',
                  name: 'Priority',
                  type: 'select',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Priority',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceDayOfMonth',
                  name: 'Recurrence Day of Month',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceDayOfMonth',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceDayOfWeekMask',
                  name: 'Recurrence Day of Week Mask',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceDayOfWeekMask',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceEndDateOnly',
                  name: 'Recurrence End',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceEndDateOnly',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceInstance',
                  name: 'Recurrence Instance',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceInstance',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceInterval',
                  name: 'Recurrence Interval',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceInterval',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceMonthOfYear',
                  name: 'Recurrence Month of Year',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceMonthOfYear',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceStartDateOnly',
                  name: 'Recurrence Start',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceStartDateOnly',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceTimeZoneSidKey',
                  name: 'Recurrence Time Zone',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceTimeZoneSidKey',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceType',
                  name: 'Recurrence Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'WhatId',
                  name: 'Related To ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'WhatId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'ReminderDateTime',
                  name: 'Reminder Date/Time',
                  type: 'datetime',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'ReminderDateTime',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'IsReminderSet',
                  name: 'Reminder Set',
                  type: 'boolean',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'IsReminderSet',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceRegeneratedType',
                  name: 'Repeat This Task',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceRegeneratedType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Status',
                  name: 'Status',
                  type: 'select',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Status',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Subject',
                  name: 'Subject',
                  type: 'combobox',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Subject',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'TaskSubtype',
                  name: 'Task Subtype',
                  type: 'select',
                  required: false,
                  updateable: false,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'TaskSubtype',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
              ],
            },
            description: 'Engage CSM to nurture',
            position: {
              x: 880,
              y: 1520,
            },
            actionId: 'baeb3b22-91c9-4295-a84f-568824316e7b',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Task',
            solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
            iconName: 'createTask',
            end: true,
          },
          sendMessage899241049789: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 880,
              y: 890,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'merge420320713100',
          },
        },
      },
    },
  },
  {
    data: {
      version: 11,
      dslUrl: 'a71299a6-5862-47eb-8201-7ec1060c7309-11.json',
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'slack',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1689967643019,
      goal: {
        type: 'churn',
        value: 20,
      },
      lastUpdatedAt: 1689967643019,
      createdBy: 'josh+magnifydemo@magnify.io',
      playbookId: 'a71299a6-5862-47eb-8201-7ec1060c7309',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-07-21T19:27:23.019Z',
      },
      segmentTotals: [
        {
          totalAccount: 10,
          timestamp: '2023-08-31T09:48:53.065Z',
          totalUsers: 20,
        },
      ],
      metrics: [
        {
          field: 'provision_date',
          trend: 'increased',
          name: 'PROVISION_DATE',
          aggregation: '-',
          timespan: '7days',
          type: 'string',
          userCreated: true,
          key: 0,
          operator: 'none',
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
      ],
      description: '',
      currState: 'DRAFT',
      title: 'Onboarding Motion',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'datetime',
                      field: 'Created Date',
                      key: 'CreatedDate',
                      operator: 'equalsOrGreaterThan',
                      value: '2023-02-01T05:08:26.471Z',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: 'AND',
                },
                {
                  groups: [
                    {
                      platform: 'snowflake',
                      object: 'CLOUD_INSTANCE_USAGE',
                      type: 'string',
                      field: 'STATUS',
                      key: 'status',
                      operator: 'contains',
                      value: 'Active',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: '',
                },
              ],
              relationOperator: 'OR',
            },
            description: 'Segment',
            position: {
              x: 380,
              y: 50,
            },
            next: 'createTimelineEvent125771455477',
          },
          createTimelineEvent125771455477: {
            type: 'action',
            payload: {},
            description: 'Create Timeline Event',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            iconName: 'createTimelineEvent',
            next: 'sendMessage852843308867',
          },
          sendMessage852843308867: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'channel',
                  name: 'Channel',
                  type: 'string',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                },
                {
                  key: 'message',
                  name: 'Message',
                  type: 'string',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                },
              ],
            },
            description: 'Send Message',
            position: {
              x: 380,
              y: 470,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'sendEmail488656606012',
          },
          sendEmail488656606012: {
            type: 'action',
            payload: {},
            description: 'Send Email',
            position: {
              x: 380,
              y: 680,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'loop716053093748',
          },
          loop716053093748: {
            type: 'loop',
            payload: {
              timeOfLoop: 3,
              amount: 1,
              units: 'days',
              exitCondition: {
                groups: [],
                relationOperator: '',
              },
            },
            description: 'Loop',
            position: {
              x: 380,
              y: 890,
            },
            targetNodeId: 'sendEmail488656606012',
            next: 'ifElse98233927926',
          },
          ifElse98233927926: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 380,
              y: 1100,
            },
            default: 'launchGuide341887956342',
            choices: [
              {
                next: 'sendMessage603144939326',
              },
            ],
          },
          launchGuide341887956342: {
            type: 'action',
            payload: {},
            description: 'Launch Guide',
            position: {
              x: 380,
              y: 1310,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'launchGuide',
            next: 'merge100326961909',
          },
          merge100326961909: {
            type: 'merge',
            payload: {
              targets: ['606721943842'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 1730,
            },
            next: 'sendEmail48796976943',
          },
          sendEmail48796976943: {
            type: 'action',
            payload: {},
            description: 'Send Email',
            position: {
              x: 380,
              y: 1940,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'ifElse965515987529',
          },
          ifElse965515987529: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 380,
              y: 2150,
            },
            default: 'waitForTrigger858938312400',
            choices: [
              {
                next: 'sendMessage851374031246',
              },
            ],
          },
          waitForTrigger858938312400: {
            type: 'waitForTrigger',
            payload: {},
            description: 'Wait for trigger',
            position: {
              x: 380,
              y: 2360,
            },
            next: 'updateAccount236011995326',
          },
          updateAccount236011995326: {
            type: 'action',
            payload: {},
            description: 'Update Account',
            position: {
              x: 380,
              y: 2570,
            },
            actionId: '103eee45-2d0e-4928-a1ca-e4e6bfe88a8f',
            actionLevel: 'account',
            action: 'update',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Account',
            iconName: 'updateAccount',
            next: 'merge927650084749',
          },
          merge927650084749: {
            type: 'merge',
            payload: {
              targets: ['695236037462'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 2780,
            },
            end: true,
          },
          sendMessage851374031246: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 880,
              y: 2360,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'ifElse695236037462',
          },
          ifElse695236037462: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 880,
              y: 2570,
            },
            default: 'merge927650084749',
            choices: [
              {
                next: 'createCase152402074147',
              },
            ],
          },
          createCase152402074147: {
            type: 'action',
            payload: {},
            description: 'Create Case',
            position: {
              x: 1380,
              y: 2780,
            },
            actionId: '6ed6ebf8-f2c4-4c5c-883b-7faf163dd882',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Case',
            iconName: 'createCase',
            end: true,
          },
          sendMessage603144939326: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 880,
              y: 1310,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'ifElse606721943842',
          },
          ifElse606721943842: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 880,
              y: 1520,
            },
            default: 'merge100326961909',
            choices: [
              {
                next: 'createCta996379949842',
              },
            ],
          },
          createCta996379949842: {
            type: 'action',
            payload: {},
            description: 'Create CTA',
            position: {
              x: 1380,
              y: 1730,
            },
            actionId: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'CTA',
            iconName: 'createCta',
            end: true,
          },
        },
      },
    },
  },
  {
    data: {
      version: 8,
      dslUrl: 'f144d2ce-705c-44a5-a514-5c39e2cad254-8.json',
      numSteps: 0,
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'slack',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1684901836019,
      goal: {
        type: 'expansion',
        value: 10,
      },
      lastUpdatedAt: 1684901836019,
      createdBy: 'ria+magnifydemo@magnify.io',
      playbookId: 'f144d2ce-705c-44a5-a514-5c39e2cad254',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-05-24T04:17:16.019Z',
      },
      metrics: [
        {
          field: 'user_id',
          trend: 'higher',
          name: 'USER_ID',
          aggregation: 'numberOf',
          timespan: '7days',
          type: '',
          userCreated: true,
          key: 0,
          operator: 'none',
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
        {
          field: 'Usage Minutes',
          trend: 'higher',
          name: 'USAGE_TIME',
          aggregation: 'total',
          timespan: '7days',
          type: '',
          userCreated: true,
          key: 1,
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
      ],
      description: '',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'datetime',
                      field: 'Created Date',
                      key: 'CreatedDate',
                      operator: 'equalsOrGreaterThan',
                      value: '2023-02-01T05:08:26.471Z',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: 'AND',
                },
                {
                  groups: [
                    {
                      platform: 'snowflake',
                      object: 'CLOUD_INSTANCE_USAGE',
                      type: 'string',
                      field: 'STATUS',
                      key: 'status',
                      operator: 'contains',
                      value: 'Active',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: '',
                },
              ],
              relationOperator: 'OR',
            },
            description: "Segment who hasn't really started using the feature",
            position: {
              x: 380,
              y: 50,
            },
            next: 'createTimelineEvent125771455477',
          },
          createTimelineEvent125771455477: {
            type: 'action',
            payload: {},
            description: 'Create Timeline Event',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            iconName: 'createTimelineEvent',
            next: 'sendEmail488656606012',
          },
          sendEmail488656606012: {
            type: 'action',
            payload: {},
            description: 'Send Email',
            position: {
              x: 380,
              y: 470,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'ifElse189654004641',
          },
          ifElse189654004641: {
            type: 'ifElse',
            payload: {},
            description: 'If read Email?',
            position: {
              x: 380,
              y: 680,
            },
            default: 'merge420320713100',
            choices: [
              {
                next: 'sendMessage899241049789',
              },
            ],
          },
          merge420320713100: {
            type: 'merge',
            payload: {
              targets: ['899241049789'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 1100,
            },
            next: 'ifElse714088672398',
          },
          ifElse714088672398: {
            type: 'ifElse',
            payload: {},
            description: 'If logged in to check the new feature?',
            position: {
              x: 380,
              y: 1310,
            },
            default: 'launchGuide738029042563',
            choices: [
              {
                next: 'createTask350656697549',
              },
            ],
          },
          launchGuide738029042563: {
            type: 'action',
            payload: {},
            description: 'Launch Guide',
            position: {
              x: 380,
              y: 1520,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'launchGuide',
            end: true,
          },
          createTask350656697549: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'OwnerId',
                  name: 'Assigned To ID',
                  type: 'reference',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'OwnerId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallDurationInSeconds',
                  name: 'Call Duration',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallDurationInSeconds',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallObject',
                  name: 'Call Object Identifier',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallObject',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallDisposition',
                  name: 'Call Result',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallDisposition',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallType',
                  name: 'Call Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'IsRecurrence',
                  name: 'Create Recurring Series of Tasks',
                  type: 'boolean',
                  required: true,
                  updateable: false,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'IsRecurrence',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Description',
                  name: 'Description',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Description',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'ActivityDate',
                  name: 'Due Date Only',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'ActivityDate',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'WhoId',
                  name: 'Name ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'WhoId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Priority',
                  name: 'Priority',
                  type: 'select',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Priority',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceDayOfMonth',
                  name: 'Recurrence Day of Month',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceDayOfMonth',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceDayOfWeekMask',
                  name: 'Recurrence Day of Week Mask',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceDayOfWeekMask',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceEndDateOnly',
                  name: 'Recurrence End',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceEndDateOnly',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceInstance',
                  name: 'Recurrence Instance',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceInstance',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceInterval',
                  name: 'Recurrence Interval',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceInterval',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceMonthOfYear',
                  name: 'Recurrence Month of Year',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceMonthOfYear',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceStartDateOnly',
                  name: 'Recurrence Start',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceStartDateOnly',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceTimeZoneSidKey',
                  name: 'Recurrence Time Zone',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceTimeZoneSidKey',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceType',
                  name: 'Recurrence Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'WhatId',
                  name: 'Related To ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'WhatId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'ReminderDateTime',
                  name: 'Reminder Date/Time',
                  type: 'datetime',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'ReminderDateTime',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'IsReminderSet',
                  name: 'Reminder Set',
                  type: 'boolean',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'IsReminderSet',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceRegeneratedType',
                  name: 'Repeat This Task',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceRegeneratedType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Status',
                  name: 'Status',
                  type: 'select',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Status',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Subject',
                  name: 'Subject',
                  type: 'combobox',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Subject',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'TaskSubtype',
                  name: 'Task Subtype',
                  type: 'select',
                  required: false,
                  updateable: false,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'TaskSubtype',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
              ],
            },
            description: 'Engage CSM to nurture',
            position: {
              x: 880,
              y: 1520,
            },
            actionId: 'baeb3b22-91c9-4295-a84f-568824316e7b',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Task',
            solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
            iconName: 'createTask',
            end: true,
          },
          sendMessage899241049789: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 880,
              y: 890,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'merge420320713100',
          },
        },
      },
      currState: 'DRAFT',
      title: '[Demo] A/B Test VariantB: Feature Adoption Expansion Motion',
    },
  },
  {
    data: {
      version: 9,
      dslUrl: '3d75e649-08a9-4892-b541-dcd9f4d58cf2-9.json',
      numSteps: 0,
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1684901830579,
      goal: {
        type: 'expansion',
        value: 10,
      },
      lastUpdatedAt: 1684901830579,
      createdBy: 'ria+magnifydemo@magnify.io',
      playbookId: '3d75e649-08a9-4892-b541-dcd9f4d58cf2',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-05-24T04:17:10.579Z',
      },
      metrics: [
        {
          field: 'user_id',
          trend: 'higher',
          name: 'USER_ID',
          aggregation: 'numberOf',
          timespan: '7days',
          type: '',
          userCreated: true,
          key: 1,
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
        {
          field: 'Usage Minutes',
          trend: 'higher',
          name: 'USAGE_TIME',
          aggregation: 'total',
          timespan: '7days',
          type: '',
          userCreated: true,
          key: 2,
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
      ],
      description: '',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'datetime',
                      field: 'Created Date',
                      key: 'CreatedDate',
                      operator: 'equalsOrGreaterThan',
                      value: '2023-02-01T05:08:26.471Z',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: 'AND',
                },
                {
                  groups: [
                    {
                      platform: 'snowflake',
                      object: 'CLOUD_INSTANCE_USAGE',
                      type: 'string',
                      field: 'STATUS',
                      key: 'status',
                      operator: 'contains',
                      value: 'Active',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: '',
                },
              ],
              relationOperator: 'OR',
            },
            description: "Segment who hasn't really started using the feature",
            position: {
              x: 380,
              y: 50,
            },
            next: 'createTimelineEvent125771455477',
          },
          createTimelineEvent125771455477: {
            type: 'action',
            payload: {},
            description: 'Create Timeline Event',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            iconName: 'createTimelineEvent',
            next: 'sendEmail488656606012',
          },
          sendEmail488656606012: {
            type: 'action',
            payload: {},
            description: 'Send Email',
            position: {
              x: 380,
              y: 470,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'loop716053093748',
          },
          loop716053093748: {
            type: 'loop',
            payload: {
              timeOfLoop: 3,
              amount: 1,
              units: 'days',
              exitCondition: {
                groups: [],
                relationOperator: '',
              },
            },
            description: 'Loop twice until read',
            position: {
              x: 380,
              y: 680,
            },
            targetNodeId: 'sendEmail488656606012',
            next: 'ifElse151110144203',
          },
          ifElse151110144203: {
            type: 'ifElse',
            payload: {},
            description: 'If logged in to check the new feature',
            position: {
              x: 380,
              y: 890,
            },
            default: 'launchGuide868351131200',
            choices: [
              {
                next: 'createTask396251162021',
              },
            ],
          },
          launchGuide868351131200: {
            type: 'action',
            payload: {},
            description: 'Launch Guide',
            position: {
              x: 380,
              y: 1100,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'launchGuide',
            end: true,
          },
          createTask396251162021: {
            type: 'action',
            payload: {
              fields: [
                {
                  key: 'OwnerId',
                  name: 'Assigned To ID',
                  type: 'reference',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'OwnerId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallDurationInSeconds',
                  name: 'Call Duration',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallDurationInSeconds',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallObject',
                  name: 'Call Object Identifier',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallObject',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallDisposition',
                  name: 'Call Result',
                  type: 'string',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallDisposition',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'CallType',
                  name: 'Call Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'CallType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'IsRecurrence',
                  name: 'Create Recurring Series of Tasks',
                  type: 'boolean',
                  required: true,
                  updateable: false,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'IsRecurrence',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Description',
                  name: 'Description',
                  type: 'textarea',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Description',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'ActivityDate',
                  name: 'Due Date Only',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'ActivityDate',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'WhoId',
                  name: 'Name ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'WhoId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Priority',
                  name: 'Priority',
                  type: 'select',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Priority',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceDayOfMonth',
                  name: 'Recurrence Day of Month',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceDayOfMonth',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceDayOfWeekMask',
                  name: 'Recurrence Day of Week Mask',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceDayOfWeekMask',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceEndDateOnly',
                  name: 'Recurrence End',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceEndDateOnly',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceInstance',
                  name: 'Recurrence Instance',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceInstance',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceInterval',
                  name: 'Recurrence Interval',
                  type: 'int',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceInterval',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceMonthOfYear',
                  name: 'Recurrence Month of Year',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceMonthOfYear',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceStartDateOnly',
                  name: 'Recurrence Start',
                  type: 'date',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceStartDateOnly',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceTimeZoneSidKey',
                  name: 'Recurrence Time Zone',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceTimeZoneSidKey',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceType',
                  name: 'Recurrence Type',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'WhatId',
                  name: 'Related To ID',
                  type: 'reference',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'WhatId',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'ReminderDateTime',
                  name: 'Reminder Date/Time',
                  type: 'datetime',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'ReminderDateTime',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'IsReminderSet',
                  name: 'Reminder Set',
                  type: 'boolean',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'IsReminderSet',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'RecurrenceRegeneratedType',
                  name: 'Repeat This Task',
                  type: 'select',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'RecurrenceRegeneratedType',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Status',
                  name: 'Status',
                  type: 'select',
                  required: true,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Status',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'Subject',
                  name: 'Subject',
                  type: 'combobox',
                  required: false,
                  updateable: true,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'Subject',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
                {
                  key: 'TaskSubtype',
                  name: 'Task Subtype',
                  type: 'select',
                  required: false,
                  updateable: false,
                  createable: true,
                  isDynamicInput: false,
                  platform: 'salesforce',
                  object: 'Task',
                  field: 'TaskSubtype',
                  solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
                },
              ],
            },
            description: 'Engage CSM to nurture',
            position: {
              x: 880,
              y: 1100,
            },
            actionId: 'baeb3b22-91c9-4295-a84f-568824316e7b',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Task',
            solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
            iconName: 'createTask',
            end: true,
          },
        },
      },
      currState: 'DRAFT',
      title: '[Demo] A/B Test VariantA: Feature Adoption Expansion Motion',
    },
  },
  {
    data: {
      version: 4,
      dslUrl: 'd1da5c33-6be1-495b-9095-057ab1728b40-4.json',
      numSteps: 0,
      integrations: [
        {
          platform: 'salesforce',
        },
        {
          platform: 'snowflake',
        },
        {
          platform: 'gainsight',
        },
        {
          platform: 'marketo',
        },
        {
          platform: 'pendo',
        },
        {
          platform: 'slack',
        },
      ],
      tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
      createdAt: 1684873477584,
      goal: {
        type: 'churn',
        value: 20,
      },
      lastUpdatedAt: 1684873477584,
      createdBy: 'ria+magnifydemo@magnify.io',
      playbookId: 'd1da5c33-6be1-495b-9095-057ab1728b40',
      isActive: true,
      stateHistory: {
        DRAFT: '2023-05-23T20:24:37.584Z',
      },
      metrics: [
        {
          field: 'provision_date',
          trend: 'increased',
          name: 'PROVISION_DATE',
          aggregation: '-',
          timespan: '7days',
          type: 'string',
          userCreated: true,
          key: 0,
          operator: 'none',
          platform: 'snowflake',
          target: null,
          object: 'CLOUD_INSTANCE_USAGE',
        },
      ],
      description: '',
      dsl: {
        startAt: 'segment1',
        states: {
          segment1: {
            type: 'segment',
            payload: {
              groups: [
                {
                  groups: [
                    {
                      platform: 'salesforce',
                      object: 'Account',
                      type: 'datetime',
                      field: 'Created Date',
                      key: 'CreatedDate',
                      operator: 'equalsOrGreaterThan',
                      value: '2023-02-01T05:08:26.471Z',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: 'AND',
                },
                {
                  groups: [
                    {
                      platform: 'snowflake',
                      object: 'CLOUD_INSTANCE_USAGE',
                      type: 'string',
                      field: 'STATUS',
                      key: 'status',
                      operator: 'contains',
                      value: 'Active',
                      isNewStatement: false,
                      isDynamicInput: false,
                    },
                  ],
                  relationOperator: '',
                },
              ],
              relationOperator: 'OR',
            },
            description: "Yellow segment who hasn't really started using the feature",
            position: {
              x: 380,
              y: 50,
            },
            next: 'createTimelineEvent125771455477',
          },
          createTimelineEvent125771455477: {
            type: 'action',
            payload: {},
            description: 'Create Timeline Event',
            position: {
              x: 380,
              y: 260,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            iconName: 'createTimelineEvent',
            next: 'sendEmail488656606012',
          },
          sendEmail488656606012: {
            type: 'action',
            payload: {},
            description: 'Send Email',
            position: {
              x: 380,
              y: 470,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'loop716053093748',
          },
          loop716053093748: {
            type: 'loop',
            payload: {
              timeOfLoop: 3,
              amount: 1,
              units: 'days',
              exitCondition: {
                groups: [],
                relationOperator: '',
              },
            },
            description: 'Loop',
            position: {
              x: 380,
              y: 680,
            },
            targetNodeId: 'sendEmail488656606012',
            next: 'ifElse98233927926',
          },
          ifElse98233927926: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 380,
              y: 890,
            },
            default: 'merge100326961909',
            choices: [
              {
                next: 'sendMessage603144939326',
              },
            ],
          },
          merge100326961909: {
            type: 'merge',
            payload: {
              targets: ['606721943842'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 1520,
            },
            next: 'launchGuide563728785301',
          },
          launchGuide563728785301: {
            type: 'action',
            payload: {},
            description: 'Launch Guide',
            position: {
              x: 380,
              y: 1730,
            },
            actionLevel: undefined,
            actionId: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
            action: 'create',
            fakeAction: false,
            platform: 'pendo',
            object: 'Guide',
            iconName: 'launchGuide',
            next: 'ifElse965515987529',
          },
          ifElse965515987529: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else expansion variable check',
            position: {
              x: 380,
              y: 1940,
            },
            default: 'merge520107833910',
            choices: [
              {
                next: 'sendEmail556872640267',
              },
            ],
          },
          merge520107833910: {
            type: 'merge',
            payload: {},
            description: 'Merge',
            position: {
              x: 380,
              y: 2150,
            },
            next: 'waitForTrigger568654667579',
          },
          waitForTrigger568654667579: {
            type: 'waitForTrigger',
            payload: {},
            description: 'Wait for trigger for avg usage across the users in the account',
            position: {
              x: 380,
              y: 2360,
            },
            next: 'createOpportunity459047695922',
          },
          createOpportunity459047695922: {
            type: 'action',
            payload: {},
            description: 'Create Opportunity',
            position: {
              x: 380,
              y: 2570,
            },
            actionId: '98d26a16-34b3-4eb3-b5ca-de8d6b6bdd5b',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Opportunity',
            solutionInstanceId: 'b7bc8c74-e17b-4634-ae78-9b4154335840',
            iconName: 'createOpportunity',
            next: 'updateAccount236011995326',
          },
          updateAccount236011995326: {
            type: 'action',
            payload: {},
            description: 'Update Account',
            position: {
              x: 380,
              y: 2780,
            },
            actionId: '103eee45-2d0e-4928-a1ca-e4e6bfe88a8f',
            actionLevel: 'account',
            action: 'update',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Account',
            iconName: 'updateAccount',
            next: 'merge927650084749',
          },
          merge927650084749: {
            type: 'merge',
            payload: {
              targets: ['695236037462'],
            },
            description: 'Merge',
            position: {
              x: 380,
              y: 2990,
            },
            next: 'createTimelineEvent20225144280',
          },
          createTimelineEvent20225144280: {
            type: 'action',
            payload: {},
            description: 'Create Timeline Event',
            position: {
              x: 380,
              y: 3200,
            },
            actionId: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'gainsight',
            object: 'Activity_Timeline',
            iconName: 'createTimelineEvent',
            end: true,
          },
          sendEmail556872640267: {
            type: 'action',
            payload: {},
            description: 'Send Email for another nurture campaign',
            position: {
              x: 880,
              y: 2150,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            next: 'ifElse695236037462',
          },
          ifElse695236037462: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 880,
              y: 2360,
            },
            default: 'merge927650084749',
            choices: [
              {
                next: 'createCase152402074147',
              },
            ],
          },
          createCase152402074147: {
            type: 'action',
            payload: {},
            description: 'Create Case to engage CSM',
            position: {
              x: 1380,
              y: 2570,
            },
            actionId: '6ed6ebf8-f2c4-4c5c-883b-7faf163dd882',
            actionLevel: 'account',
            action: 'create',
            fakeAction: false,
            platform: 'salesforce',
            object: 'Case',
            iconName: 'createCase',
            end: true,
          },
          sendMessage603144939326: {
            type: 'action',
            payload: {},
            description: 'Send Message',
            position: {
              x: 880,
              y: 1100,
            },
            actionId: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'slack',
            object: 'Message',
            iconName: 'sendMessage',
            next: 'ifElse606721943842',
          },
          ifElse606721943842: {
            type: 'ifElse',
            payload: {},
            description: 'If / Else',
            position: {
              x: 880,
              y: 1310,
            },
            default: 'merge100326961909',
            choices: [
              {
                next: 'sendEmail515018482485',
              },
            ],
          },
          sendEmail515018482485: {
            type: 'action',
            payload: {},
            description: 'nurture campaign',
            position: {
              x: 1380,
              y: 1520,
            },
            actionId: '776b77df-da11-4a84-abe8-37955da11b00',
            actionLevel: 'user',
            action: 'create',
            fakeAction: false,
            platform: 'marketo',
            object: 'Campaign',
            iconName: 'sendEmail',
            end: true,
          },
        },
      },
      currState: 'DRAFT',
      title: '[Demo]: Feature Adoption Expansion Motion',
    },
  },
]

export const demoSegmentExportResponse = {
  message: 'When complete, the file will be ready for download in Notifications.',
  segmentExports: [
    {
      id: 'testId',
      timestamp: new Date().toISOString(),
      requestedBy: 'admin@magnify.io',
      status: 'SUCCESS',
      path: '1/saved_journeys/draft/a45b3958-e871-4602-ad4c-8c7bc8d4f7a0/1/2024-08-13T12:57:08.638Z.csv',
    },
  ],
  status: 200,
}
