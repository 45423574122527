import { Select } from 'antd'

import SelectDropdownIcon from 'assets/icons/select-dropdown'
import ExperimentDoughnutChart from 'pages/Motions/ExperimentDoughnutChart'

import type { ABExperimentSegement } from 'models/motion.model'

interface FilterItemProps {
  title: ABExperimentSegement
  segmentSize: number
  totalAccounts: number
  handleVariantChange: (value: number, title: ABExperimentSegement, totalAccounts: number) => void
}

const FilterItem = ({ title, segmentSize, totalAccounts, handleVariantChange }: FilterItemProps) => {
  const totalSelectedAccounts = totalAccounts * segmentSize

  return (
    <div className='modal__create-experiment__filters__item'>
      <div className='modal__create-experiment__filters__item__info'>
        <h5 className='modal__create-experiment__filters__item__info__label'>{title}</h5>
        {title === 'Control' && (
          <p className='modal__create-experiment__filters__item__info__control-text'>
            This group will not participate the Motion, but we will track the metrics as reference
          </p>
        )}
      </div>
      <div className='modal__create-experiment__filters__item__accounts'>
        <div>
          <Select
            className='modal__create-experiment__filters__item__accounts__select'
            defaultValue={segmentSize}
            suffixIcon={<SelectDropdownIcon />}
            onChange={(value) => handleVariantChange(value, title, totalAccounts)}
            options={[
              {
                value: 0.95,
                label: '95% of segment',
              },
              {
                value: 0.9,
                label: '90% of segment',
              },
              {
                value: 0.85,
                label: '85% of segment',
              },
              {
                value: 0.8,
                label: '80% of segment',
              },
              {
                value: 0.75,
                label: '75% of segment',
              },
              {
                value: 0.7,
                label: '70% of segment',
              },
              {
                value: 0.65,
                label: '65% of segment',
              },
              {
                value: 0.6,
                label: '60% of segment',
              },
              {
                value: 0.55,
                label: '55% of segment',
              },
              {
                value: 0.5,
                label: '50% of segment',
              },
              {
                value: 0.45,
                label: '45% of segment',
              },
              {
                value: 0.4,
                label: '40% of segment',
              },
              {
                value: 0.35,
                label: '35% of segment',
              },
              {
                value: 0.3,
                label: '30% of segment',
              },
              {
                value: 0.25,
                label: '25% of segment',
              },
              {
                value: 0.2,
                label: '20% of segment',
              },
              {
                value: 0.15,
                label: '15% of segment',
              },
              {
                value: 0.1,
                label: '10% of segment',
              },
              {
                value: 0.05,
                label: '5% of segment',
              },
            ]}
          />
        </div>
        <div className='modal__create-experiment__filters__item__accounts__details'>
          <div className='modal__create-experiment__filters__item__accounts__details__info'>
            {/* Temporarily round down until logic is implemented to prevent decimal points for selected accounts totals */}
            <h5>{Number.isNaN(totalSelectedAccounts) ? 0 : totalSelectedAccounts.toFixed(0)}</h5>
            <p>Accounts</p>
          </div>
          <div>
            <div className='modal__create-experiment__filters__item__accounts__details__chart'>
              <ExperimentDoughnutChart segmentSize={segmentSize} totalAccounts={totalAccounts} size={2.4375} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
FilterItem.displayName = 'FilterItem'

export default FilterItem
