import { toJS } from 'mobx'

import { exportToCSV } from 'pages/Motions/InternalOperationalStats/components/utils'

import type { ColumnType, ColumnsType } from 'antd/es/table'

export interface ExecutedActions {
  key: string
  actionId: string
  platform: string
  status: string
  timestamp: string
  userId: string
  account: string
  actionName: string
}

export const handleExportOnClickHandler = (tableData: ExecutedActions[], motionTitle: string) => {
  const csvColumns = columns.map((column: ColumnType<ExecutedActions>) => column.dataIndex)

  const executionStatusFieldValues = tableData.map((status) => {
    return Object.entries(status)
      .map(([key, value]) => {
        if (csvColumns.includes(key)) {
          if (value === '' || value === null || value === undefined) {
            return { [key]: '' }
          }

          return { [key]: String(value) }
        } else {
          return null
        }
      })
      .filter((datapoint) => datapoint !== null)
  })

  const flattenedArray = executionStatusFieldValues.map((value) => {
    return value
      .sort((a, b) => {
        return (
          csvColumns.findIndex((key) => String(key) in (a ?? {})) -
          csvColumns.findIndex((key) => String(key) in (b ?? {}))
        )
      })
      .map((individualProperty) => Object.values(individualProperty ?? {}) as [string, string])
  })

  exportToCSV(toJS([csvColumns, ...flattenedArray]), `${motionTitle} - Executed Actions (Sandbox)`)
}

const formatTimestamp = (timestamp: string) => {
  const formattedDate = new Date(timestamp)

  const month = String(formattedDate.getMonth() + 1).padStart(2, '0')
  const day = String(formattedDate.getDate()).padStart(2, '0')
  const year = String(formattedDate.getFullYear()).slice(2)
  const hours = String(formattedDate.getHours()).padStart(2, '0')
  const minutes = String(formattedDate.getMinutes()).padStart(2, '0')

  return `${month}/${day}/${year} ${hours}:${minutes}`
}

const getActionColumnDisplayData = (actionId: string, actionName: string, platform: string) => {
  if (!actionName || !platform) return <span>{actionId}</span>

  const capitalizePlatform = platform
    .split(' ')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

  return (
    <span>
      {capitalizePlatform}: {actionName}
    </span>
  )
}

export const columns: ColumnsType<ExecutedActions> = [
  {
    title: 'Action',
    dataIndex: 'actionId',
    render: (_, { actionId, actionName, platform }) => {
      const actionDisplayData = getActionColumnDisplayData(actionId, actionName, platform)
      return <span>{actionDisplayData}</span>
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    render: (_, { timestamp }) => {
      return <span>{formatTimestamp(timestamp)}</span>
    },
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
  },
  {
    title: 'Account',
    dataIndex: 'account',
  },
]
