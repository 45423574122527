import { InputNumber, Layout, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

import { DropdownArrowDown } from 'components/common/Icons/Icons'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { ConfigPanelPayload, TimeDelayPayload, unitsValue } from 'models/motion/motionBuilder.model'

interface TimeDelayPanelProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

export const TimeDelayPanel = observer(({ payload, setPayload }: TimeDelayPanelProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { motionStore } = useStore()
  const initialPayload: TimeDelayPayload = { amount: 0, units: 'hours' }

  useEffect(() => {
    // set initial payload
    setPayload({ ...initialPayload, ...segmentBuilderData.payload })
  }, [])

  const handleChangeDelayTimeUnits = (value: string | null) => {
    setPayload((prevState: ConfigPanelPayload) => {
      return { ...prevState, units: value as unitsValue }
    })
  }

  const handleChange = (value: number | null) => {
    setPayload((prev) => {
      return { ...prev, amount: value as number }
    })
  }

  return (
    <Layout style={{ marginTop: 30 }}>
      <div className='segment-builder__time-delay-container' data-testid='timeDelay'>
        <span className='segment-builder__time-delay-container__input-title'>Time to delay</span>

        <InputNumber
          key='timeDelay'
          name='amount'
          className='segment-builder__time-delay-container__input'
          controls={{
            upIcon: <DropdownArrowDown data-testid='inputUpIcon' />,
            downIcon: <DropdownArrowDown data-testid='inputDownIcon' />,
          }}
          min={0}
          step={1}
          precision={0}
          bordered={false}
          value={payload?.amount}
          onChange={handleChange}
          addonAfter={
            <Select
              defaultValue={payload?.units || 'hours'}
              value={payload?.units}
              suffixIcon={<DropdownArrowDown data-testid='selectUnits' />}
              dropdownAlign={{ offset: [0, 8] }}
              onChange={handleChangeDelayTimeUnits}
              popupClassName='group-container__item-container__item__select-input-range__dropdown'
              style={{
                width: 148,
              }}
              disabled={motionStore.isSegmentBuilderEditDisabled}>
              <Select.Option data-testid='minutes' value='minutes'>
                Minutes
              </Select.Option>
              <Select.Option data-testid='hours' value='hours'>
                Hours
              </Select.Option>
              <Select.Option data-testid='days' value='days'>
                Days
              </Select.Option>
              <Select.Option data-testid='weeks' value='weeks'>
                Weeks
              </Select.Option>
            </Select>
          }
          disabled={motionStore.isSegmentBuilderEditDisabled}
        />
      </div>
    </Layout>
  )
})
