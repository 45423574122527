import { IconArrowUpPositiveGreen } from 'components/common/Icons/Icons'

export interface VariantRecord {
  level: string
  impact: string
  value: number
  trend: string
  unit: string
}

export interface VariantCellProps {
  record: VariantRecord
  className?: string
}

const VariantCell = ({ record, className }: VariantCellProps) => {
  return (
    <div className={`variant-cell ${className}`}>
      <span>{record.level}</span>
      <span className='value'>
        <span className={`variant-impact variant-impact--${record.impact} trend--${record.trend}`}>
          <IconArrowUpPositiveGreen />
          {record.value}
          {record.unit}
        </span>
      </span>
    </div>
  )
}
VariantCell.displayName = 'VariantCell'

export default VariantCell
