import { Doughnut } from 'react-chartjs-2'

import {
  EXPERIMENT_DOUGHNUT_GREEN,
  EXPERIMENT_DOUGHNUT_GREY,
  EXPERIMENT_DOUGHNUT_ORANGE,
  EXPERIMENT_DOUGHNUT_RED,
} from 'pages/Motions/constants'

interface ExperimentDoughnutChartProps {
  segmentSize: number
  totalAccounts: number
  size: number
}

const ExperimentDoughnutChart = ({ segmentSize, totalAccounts, size }: ExperimentDoughnutChartProps) => {
  const getBackgroundColors = () => {
    if (segmentSize >= 0.5) {
      return [EXPERIMENT_DOUGHNUT_GREY, EXPERIMENT_DOUGHNUT_GREEN]
    } else if (segmentSize >= 0.25) {
      return [EXPERIMENT_DOUGHNUT_GREY, EXPERIMENT_DOUGHNUT_ORANGE]
    }
    return [EXPERIMENT_DOUGHNUT_GREY, EXPERIMENT_DOUGHNUT_RED]
  }

  const totalSelectedAccounts = totalAccounts * segmentSize

  const data = {
    datasets: [
      {
        data: [totalAccounts - totalSelectedAccounts, totalSelectedAccounts],
        backgroundColor: getBackgroundColors(),
        borderWidth: 0,
      },
    ],
  }

  const options = {
    events: [],
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tableUnderChart: {
        display: false,
      },
    },
  }

  return (
    <div style={{ width: `${size}rem` }}>
      <Doughnut data={data} options={options} />
    </div>
  )
}

export default ExperimentDoughnutChart
