import {
  demoGetPlatformsMetadata,
  demoGetPlatformsMetadataDefault,
  demoGetPlatformsObjectsMetadata,
} from 'api/mockResponses/demo/metadata.mock'

export const getPlatformsMetadataDemoData = (
  platform: string | undefined,
  object: string | undefined,
  field: string | undefined,
) => {
  if (platform && object && field) {
    return demoGetPlatformsMetadataDefault
  } else if (platform && object) {
    return demoGetPlatformsObjectsMetadata
      .find((item) => item.platform === platform)
      ?.data.find((item) => item.object === object)
  } else if (platform) {
    return demoGetPlatformsMetadata.find((item) => item.platform === platform)
  } else {
    return demoGetPlatformsMetadataDefault
  }
}
