import { Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import StatsOverview from 'components/MotionObservability/ActiveMotionsReporting/components/StatsOverview'
import TopMotionsByUsers from 'components/MotionObservability/ActiveMotionsReporting/components/TopMotionsByUsers'
import useStore from 'store/useStore'

import type { DateRangeProps } from 'models/observability.model'

interface PastWeeksCardProps {
  pastWeeksOption: DateRangeProps
}

const PastWeeksCard = observer(({ pastWeeksOption }: PastWeeksCardProps) => {
  const { observabilityStore } = useStore()
  const { isLoadingFetchTenantMotionActionPastWeeks, tenantMotionActionPastWeeks, rangeToKeyConverter } =
    observabilityStore

  const tenantCachedActionPastWeeks =
    tenantMotionActionPastWeeks !== null ? tenantMotionActionPastWeeks[rangeToKeyConverter(pastWeeksOption)] : null

  if (isLoadingFetchTenantMotionActionPastWeeks) {
    return (
      <div className='motion-observability__card' data-testid='past-weeks-card-loading'>
        <Skeleton active />
      </div>
    )
  }

  return (
    <div className='motion-observability__card' data-testid='past-weeks-card'>
      <div className='motion-observability__card__header'>
        <h5 data-testid='past-weeks-card-header'>Past 4 Weeks</h5>
      </div>
      <div className='motion-observability__card__content'>
        <StatsOverview
          observabilityTenantTotals={tenantCachedActionPastWeeks?.totals}
          observabilityTenantPreviousTotals={tenantCachedActionPastWeeks?.previousTotals}
        />
        <TopMotionsByUsers pastWeeksOption={pastWeeksOption}></TopMotionsByUsers>
      </div>
      <div className='motion-observability__card__footer'>
        <Link className='button button-secondary' to='/motions' data-testid='view-running-motions'>
          View Motions
        </Link>
      </div>
    </div>
  )
})
PastWeeksCard.displayName = 'PastWeeksCard'

export default PastWeeksCard
