import { Checkbox } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useContext, useEffect } from 'react'

import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'

import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

/** The allowed types to show the checkbox on. */
const allowedTypes = ['action']
const FakeActionCheckbox = () => {
  const { segmentBuilderData, setSegmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)

  const { fakeActions, sandbox } = useFlags<LaunchDarklyFeatureFlags>()

  useEffect(() => {
    if (sandbox) {
      setSegmentBuilderData((prev) => ({
        ...prev,
        fakeAction: true,
      }))
    }
  }, [sandbox, segmentBuilderData.payload])

  if (!fakeActions || sandbox) {
    return null
  }

  if (!allowedTypes.includes(segmentBuilderData?.type?.toLowerCase())) {
    return null
  }

  return (
    <div data-testid='fake-action-container' className='fake-action-container'>
      <span className='fake-action-label-container'>Send as a fake action?</span>
      <Checkbox
        data-testid='fake-action-checkbox'
        checked={segmentBuilderData.fakeAction ?? false}
        onChange={(event: CheckboxChangeEvent) => {
          const checked = event.target.checked
          setSegmentBuilderData((prev) => ({
            ...prev,
            fakeAction: checked,
          }))
        }}
      />
    </div>
  )
}
FakeActionCheckbox.displayName = 'FakeActionCheckbox'

export default FakeActionCheckbox
