import { notification } from 'antd'

type NotificationType = 'success' | 'error' | 'warning'

export const openNotificationPopup = (title: string, description: string, type: NotificationType) => {
  notification[type]({
    message: title,
    description,
    placement: 'topRight',
  })
}
