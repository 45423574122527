import { fieldTypes } from 'components/common'

import { FieldTypeEnum } from 'models/metadata.model'
import { InputOperatorEnum } from 'models/motion/motionBuilder.model'
import { MotionStateEnum } from 'models/motion.model'

export const goals = [
  {
    label: 'Churn probability',
    type: 'churn',
    value: 0,
    goal: 'decrease',
  },
  {
    label: 'Expansion probability',
    type: 'expansion',
    value: 0,
    goal: 'increase',
  },
]

export const motionGoalsMock = {
  modalTitle: 'Create a new Motion',
  modalTitleOnEdit: 'Edit the Motion goals',
  motionNameLabel: 'Motion Name',
  motionNamePlaceholder: 'Enter the name of the Motion',
  motionDescriptionLabel: 'Description (Optional)',
  motionDescriptionPlaceholder: 'Describe the objective of the Motion',
  goalsTitle: 'Do you also want to track Predictive Insights? (Optional)',
  metricsTitle: 'Define metrics to track the Motion performance',
  errorMessage: 'Motion name is required.',
  dataFieldLabel: 'Select a data field',
  aggregationLabel: 'Aggregation',
  trendLabel: 'Trend',
  timespanLabel: 'Time span',
  setTargetLabel: 'Set a target',
  maxMetrics: 3,
}

export const emptyMetric = {
  key: 0,
  field: '',
  aggregation: null,
  trend: null,
  timespan: '7days',
  target: null,
  userCreated: true,
}

export const emptyMotionGoals = {
  title: '',
  description: '',
  goal: {},
  metrics: [],
  currState: MotionStateEnum.Draft,
}

export const emptyOption: { label: string; value: string | null } = { label: '-', value: null }

export const trendOptions = [
  emptyOption,
  {
    label: 'Increase to',
    value: 'higher',
  },
  {
    label: 'Decrease to',
    value: 'lower',
  },
]

export const timespanOptions = [
  { label: 'Weekly', value: '7days' },
  // The options commented are not supported yet
  // { label: '14 days', value: '14days' },
  // { label: '30 days', value: '30days' },
  // { label: '60 days', value: '60days' },
  // { label: '90 days', value: '90days' },
  // { label: '180 days', value: '180days' },
]

export const operatorOptions = [
  { label: 'none', value: '' },
  { label: '>=', value: InputOperatorEnum.EqualsOrGreaterThan },
  { label: '>', value: InputOperatorEnum.GreaterThan },
  { label: '=', value: InputOperatorEnum.Equals },
  { label: '<', value: InputOperatorEnum.LessThan },
  { label: '<=', value: InputOperatorEnum.EqualsOrLessThan },
]

const aggregationTypes = [
  FieldTypeEnum.Number,
  FieldTypeEnum.Percent,
  FieldTypeEnum.Boolean,
  FieldTypeEnum.Collection,
  FieldTypeEnum.Date,
  FieldTypeEnum.Others,
]
const aggregationOptions = {
  total: { label: 'Total', value: 'total' },
  average: { label: 'Average', value: 'average' },
  min: { label: 'Min', value: 'min' },
  max: { label: 'Max', value: 'max' },
  countOfValues: { label: 'Count', value: 'countOfValues' },
}

export const getAggregationOptions = (type?: string) => {
  const commonOptions = [emptyOption, aggregationOptions.countOfValues]
  let options = commonOptions
  const categoryTypes = fieldTypes.filter((types) => type && types.includes(type))

  const filteredTypes = aggregationTypes.filter((aggregationType: string) =>
    categoryTypes[0]?.some((categoryType: string) => aggregationType === categoryType),
  )

  if (filteredTypes[0] === FieldTypeEnum.Number) {
    if (type === FieldTypeEnum.Percent) {
      options = options.concat([aggregationOptions.average, aggregationOptions.min, aggregationOptions.max])
    } else {
      options = options.concat([
        aggregationOptions.total,
        aggregationOptions.average,
        aggregationOptions.min,
        aggregationOptions.max,
      ])
    }
  }

  return options
}
