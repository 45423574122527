import { useEffect, useRef, useState } from 'react'

import type { TooltipPosition } from 'components/MotionDetails/GoalMetric/Chart/helpers/types'

interface ChartTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  tooltipText: string | null
  tooltipPosition: TooltipPosition | null
  children: JSX.Element
}
const ChartTooltip = ({ tooltipText, tooltipPosition, children, ...other }: ChartTooltipProps) => {
  const [tooltipContainerHeight, setTooltipContainerHeight] = useState(0)
  const [yPosition, setYPosition] = useState(0)
  const tooltipContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (tooltipContainerRef?.current?.clientHeight) {
      setTooltipContainerHeight(tooltipContainerRef.current.clientHeight)
    }
  }, [tooltipContainerRef, setTooltipContainerHeight])

  useEffect(() => {
    if (tooltipPosition?.y) {
      setYPosition(tooltipContainerHeight - tooltipPosition?.y)
    }
  }, [tooltipPosition, tooltipContainerHeight, setYPosition])

  return (
    <div ref={tooltipContainerRef} className='chart-tooltip-container' {...other}>
      <span
        data-testid='chart-tooltip'
        className='chart-tooltip-text'
        style={{
          visibility: tooltipPosition ? 'visible' : 'hidden',
          bottom: `calc(10% + ${yPosition}px)`,
          left: `calc(${tooltipPosition?.x}px - 4.375rem)`,
        }}>
        {tooltipText}
      </span>
      {children}
    </div>
  )
}

export { ChartTooltip }
