import { makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { CreateEmailTemplatePayload, EmailTemplate } from 'models/email-templates.model'

export const DEFAULT_PAGE_SIZE = 10

export class EmailTemplatesStore implements ChildStore {
  loading = {
    isTemplateLoading: false,
    isTemplateListLoading: false,
  }
  data = {
    templateList: [] as EmailTemplate[],
    template: {} as EmailTemplate,
  }

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
    })
  }

  reset = () => {
    this.loading = {
      isTemplateLoading: false,
      isTemplateListLoading: false,
    }
    this.data = {
      templateList: [] as EmailTemplate[],
      template: {} as EmailTemplate,
    }
    this.apiError = null
  }

  resetSelectedTemplate = () => {
    this.loading = {
      isTemplateLoading: false,
      isTemplateListLoading: false,
    }
    this.data = {
      templateList: this.data.templateList,
      template: {} as EmailTemplate,
    }
    this.apiError = null
  }

  /**
   * Create a new email template.
   * @param {CreateEmailTemplatePayload} payload The payload to create a new email template.
   * @returns {Promise<EmailTemplate>} The created email template
   */
  createTemplate = async (payload: CreateEmailTemplatePayload) => {
    this.loading.isTemplateLoading = true
    try {
      const template = await API.emailTemplates.create(payload)
      runInAction(() => {
        this.data.template = template
      })
      return template
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isTemplateLoading = false
      })
    }
  }

  /**
   * Fetch a specific version of an email template.
   * @returns {Promise<EmailTemplate>} The requested email template
   */
  getTemplate = async (params: { magnifyTemplateId: string; magnifyVersion: number }) => {
    this.loading.isTemplateLoading = true
    try {
      const template = await API.emailTemplates.get(params)
      runInAction(() => {
        this.data.template = template
      })
      return template
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isTemplateLoading = false
      })
    }
  }

  setTemplate = (template: EmailTemplate) => {
    runInAction(() => {
      this.data.template = template
    })
  }

  /**
   * Update a templates name by ID.
   * @returns {Promise<EmailTemplate>} The updated template.
   */
  updateTemplate = async (params: { magnifyTemplateId: string; payload: CreateEmailTemplatePayload }) => {
    this.loading.isTemplateLoading = true
    try {
      const template = await API.emailTemplates.update(params)
      runInAction(() => {
        this.data.template = template
      })
      return template
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isTemplateLoading = false
      })
    }
  }

  /**
   * Get the email templates list.
   * @returns {Promise<EmailTemplate[]>} The list of email templates
   */
  getTemplateList = async (): Promise<void> => {
    this.loading.isTemplateListLoading = true
    try {
      const { data } = await API.emailTemplates.getAll()
      runInAction(() => {
        this.data.templateList = data
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isTemplateListLoading = false
      })
    }
  }

  /**
   * Clones the template given the templateId
   * @returns {Promise<void>}
   */
  cloneTemplate = async (magnifyTemplateId: string, magnifyVersion: number, templateName: string): Promise<void> => {
    this.loading.isTemplateListLoading = true
    try {
      await API.emailTemplates.duplicate({
        magnifyTemplateId,
        magnifyVersion,
        name: `${templateName} (copy)`,
      })

      // Re-hydrate template list
      await this.getTemplateList()
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isTemplateListLoading = false
      })
    }
  }

  /**
   * Archives the template given the templateId
   * @returns {Promise<void>}
   */
  archiveTemplate = async (magnifyTemplateId: string): Promise<void> => {
    this.loading.isTemplateListLoading = true
    try {
      await API.emailTemplates.archive({
        magnifyTemplateId,
      })

      // Re-hydrate template list
      await this.getTemplateList()
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isTemplateListLoading = false
      })
    }
  }

  get isTemplateEmpty(): boolean {
    return !Object.keys(this.data.template ?? {}).length
  }

  get isTemplateListEmpty(): boolean {
    return !this.data.templateList || !this.data.templateList.length
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
