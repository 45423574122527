import type { BubbleDataPoint, Chart, ChartDataset, Point } from 'chart.js'
import type { TablePluginOptions } from 'chartjs-plugins'

const svgImageSrc =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGhlaWdodD0iMTNweCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTA3LjY5IDE0OS4xMiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXllcl8xLTIiPjxnPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTM1LjcsMTMwLjY0bC0xMy41OC0xMy42OCw0MC42OS00MWMuNzctLjc4LC43Ny0yLjA0LDAtMi44MUwyMi4xMywzMi4xNWwxMy41OC0xMy42OCwyNy4yOSwyNy41MWMxNS42NywxNS43OSwxNS42Nyw0MS4zOCwwLDU3LjE3bC0yNy4yOSwyNy41MWgwWiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTU0LjAzLDE0OS4xMmwtMTMuNTgtMTMuNjgsNTguNjctNTkuMTJjLjk2LS45NywuOTYtMi41NCwwLTMuNTFMNDAuNDUsMTMuNjgsNTQuMDMsMGwzOS4yOSwzOS42YzE5LjE2LDE5LjMxLDE5LjE2LDUwLjYsMCw2OS45MWwtMzkuMjksMzkuNmgwWiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTMyLjUyLDU4LjA3SDEuNjJjLS44OSwwLTEuNjIsLjczLTEuNjIsMS42M3YzMS4xNGMwLC45LC43MywxLjYzLDEuNjIsMS42M2gzMC45Yy44OSwwLDEuNjItLjczLDEuNjItMS42M3YtMzEuMTRjMC0uOS0uNzMtMS42My0xLjYyLTEuNjNaIi8+PC9nPjwvZz48L3N2Zz4K'
const iconImage = new Image()
iconImage.src = svgImageSrc

/**
 * Split lines that are longer than a provided maximum width to multiple lines.
 * @param {string | string[]} text The text to process
 * @param {number} number The maximum width for text before it is split.
 * @param {CanvasRenderingContext2D} ctx The current Canvas context, used to measure text.
 * @returns {string[]} The text, either as it was but in an array, or split and in an array.
 */
export const splitTextToLines = (
  text: string | string[] | undefined = '',
  maxWidth: number,
  ctx: CanvasRenderingContext2D,
) => {
  // Check if it is already an array
  if (Array.isArray(text)) {
    return text
  }

  const words = text.split(' ')
  const lines = []
  let currentLine = words[0]

  for (let i = 1; i < words.length; i++) {
    const word = words[i]
    const width = ctx.measureText(currentLine + ' ' + word).width
    if (width < maxWidth) {
      currentLine += ' ' + word
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  }
  lines.push(currentLine)
  return lines
}

/**
 * Custom ChartJS plugin for rendering a table under a chart.
 */
export const tablePlugin = {
  id: 'tableUnderChart',
  afterDraw(chart: Chart, _args: object, options: TablePluginOptions) {
    if (options?.display !== true) {
      return
    }
    const ctx = chart.ctx
    const xAxis = chart.scales.x
    const yAxis = chart.scales.y

    // Ensure everything has loaded
    if (!yAxis || !xAxis) {
      return
    }

    // Assuming your chart has labels and datasets structured for a bar chart
    const datasets: ChartDataset[] | undefined = (options.datasets || chart.data.datasets) ?? []

    // Customize these values
    const tableStartY = yAxis.bottom + options.tableStartY || 30 // Start drawing the table 10px under the chart
    const rowHeight = options.rowHeight || 40 // Height of each row in the table
    const headerColor = options.headerColor || '#000' // Color of the table header text (for columns)
    const rowHeaderColor = options.rowHeaderColor || '#717171' // Color of the row headers (to the left)
    const textColor = options.textColor || '#767676' // Color of the table row text
    const rowHeaderMargin = options.rowHeaderMargin || 0 // How far the row headers are from the start of the data
    const drawLines = options.drawLines ?? true // Option to draw lines between rows
    const lineColor = options.lineColor || '#E4E4E4' // Line color
    const lineHeight = options.lineHeight || 10 // Line height for multiline text
    const format = options.format || ((value: string): string => `${value}`)
    const forecastCount = options.forecastCount || 1
    const dataColumnsCount = options.dataColumnsCount || 1

    // Draw table column headers (above the data, aligned with bars)
    if (options.drawTableHeaders && chart.data.labels) {
      ctx.fillStyle = headerColor
      ctx.textAlign = 'center'
      chart.data.labels.forEach((label: unknown, index: number) => {
        const xPosition = xAxis.getPixelForTick(index)
        ctx.fillText(label as string, xPosition, tableStartY)
      })
    }

    // Draw table rows and row headers (to the left)
    datasets.forEach((dataset: ChartDataset, datasetIndex: number) => {
      const rowY = tableStartY + rowHeight * (datasetIndex + 1)
      ctx.fillStyle = textColor
      ctx.textAlign = 'center'

      // Draw row values
      dataset.data.forEach((value: number | [number, number] | Point | BubbleDataPoint | null, index: number) => {
        const xPosition = xAxis.getPixelForTick(index)
        // Bold the last 2 items
        if (index > 3) {
          ctx.font = 'bold 12px Suisse, Arial'
          ctx.fillStyle = '#000000'
        }
        if (typeof value === 'number') {
          ctx.fillText(format(value.toFixed(1), datasetIndex), xPosition, rowY)
        }
      })

      // Draw row headers in bold to the left of the data
      ctx.font = '12px Suisse, Arial'
      ctx.fillStyle = rowHeaderColor
      ctx.textAlign = 'right'
      const rowHeaderX = xAxis.left - rowHeaderMargin

      // Check for multirow lines.
      const lines = splitTextToLines(dataset.label, options.rowHeaderMaxWidth || 100, ctx)
      lines.forEach((line, lineIndex) => {
        if (lines.length > 1) {
          const textHeight = lines.length * lineHeight
          const rowMidpoint = rowY + (lineHeight * (lineIndex + 1)) / lines.length
          const textStartY = rowMidpoint - textHeight / 2 + lineHeight / 2
          ctx.fillText(line, rowHeaderX, textStartY + (lineIndex - 1) * lineHeight)
        } else {
          ctx.fillText(line, rowHeaderX, rowY + lineHeight * lineIndex)
        }
      })

      // Optionally draw line after row
      if (drawLines && datasetIndex < datasets.length - 1) {
        // Skip line after last row
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.moveTo(xAxis.left, rowY - 2 + rowHeight / 2)
        ctx.lineTo(xAxis.right, rowY - 2 + rowHeight / 2)
        ctx.strokeStyle = lineColor
        ctx.stroke()
      }

      // Reset font for any other text being drawn
      ctx.font = '12px Suisse, Arial'
    })

    // Draw annotation box
    // For all the end of line comments below, they are the values going into getPixelForTick for a standard size of data
    ctx.lineWidth = 2
    ctx.strokeStyle = '#303EF5'
    ctx.setLineDash([7])
    ctx.strokeRect(
      (xAxis.getPixelForTick(dataColumnsCount - 1) + xAxis.getPixelForTick(dataColumnsCount)) / 2, // 3 + 4
      24,
      xAxis.getPixelForTick(dataColumnsCount - 1 + forecastCount) - // 3 (0-3) + number of forcasts
        1 +
        (xAxis.getPixelForTick(dataColumnsCount) - xAxis.getPixelForTick(dataColumnsCount - 1)) / 2 - // 4 - 3
        (xAxis.getPixelForTick(dataColumnsCount - 1) + xAxis.getPixelForTick(dataColumnsCount)) / 2, // 3 + 4
      tableStartY + rowHeight * datasets.length - 7,
    )
    // Reset dash
    ctx.setLineDash([])

    // Draw the banner
    const boxWidth =
      xAxis.getPixelForTick(dataColumnsCount - 1 + forecastCount) + // 3 (0-3) + number of forcasts
      1 +
      (xAxis.getPixelForTick(dataColumnsCount) - xAxis.getPixelForTick(dataColumnsCount - 1)) / 2 - // 4 - 3
      (xAxis.getPixelForTick(dataColumnsCount - 1) + xAxis.getPixelForTick(dataColumnsCount)) / 2 // 3 + 4
    const boxHeight = 23
    const boxX = (xAxis.getPixelForTick(dataColumnsCount - 1) + xAxis.getPixelForTick(dataColumnsCount)) / 2 - 1 // 3 + 4
    const boxY = 0
    const borderRadius = 8 // Radius of the rounded corners

    // Draw the box
    ctx.fillStyle = '#303EF5'
    // ctx.fillRect(boxX, boxY, boxWidth, boxHeight) // Simple Box
    ctx.beginPath()
    // Move to the start point, minus the border radius to prevent the left edge from being visible
    ctx.moveTo(boxX + borderRadius, boxY)
    // Top edge, moving right
    ctx.lineTo(boxX + boxWidth - borderRadius, boxY)
    // Top right corner
    ctx.arc(boxX + boxWidth - borderRadius, boxY + borderRadius, borderRadius, Math.PI * 1.5, Math.PI * 2)
    // Right edge, moving down
    ctx.lineTo(boxX + boxWidth, boxY + boxHeight)
    // Bottom edge, moving left
    ctx.lineTo(boxX, boxY + boxHeight)
    // Left edge, moving up
    ctx.lineTo(boxX, boxY + borderRadius)
    // Top left corner
    ctx.arc(boxX + borderRadius, boxY + borderRadius, borderRadius, Math.PI, Math.PI * 1.5)
    ctx.closePath()
    ctx.fill()

    // Draw the icon
    // Your SVG icon as a data URL
    const iconWidth = 9 // Define according to your SVG's aspect ratio
    const iconHeight = 13 // Ensure this matches the intended size
    const spacingBetweenIconAndText = 5 // Spacing between the icon and the text
    const text = 'FORECAST'
    const textWidth = ctx.measureText(text).width
    const totalWidth = iconWidth + spacingBetweenIconAndText + textWidth

    // Calculate start positions
    const contentStartX = boxX + (boxWidth - totalWidth) / 2
    const contentStartY = boxY + boxHeight / 2 + iconHeight / 2

    // Draw the text next to the icon
    ctx.font = 'semibold 12px Suisse, Arial'
    ctx.fillStyle = '#FFFFFF'
    ctx.textAlign = 'left'
    ctx.textBaseline = 'middle'
    ctx.fillText(text, contentStartX + iconWidth + spacingBetweenIconAndText, boxY + 1.5 + boxHeight / 2)

    // Draw the icon aligned with the start of the text
    ctx.drawImage(iconImage, contentStartX, contentStartY - iconHeight, iconWidth, iconHeight)
  },
}
