import axios from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetAllEmailTemplates, demoGetEmailTemplates } from 'api/mockResponses/demo/emailTemplates.mock'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type {
  CreateEmailTemplatePayload,
  EmailTemplate,
  GetAllEmailTemplatesResponse,
} from 'models/email-templates.model'

/**
 * Get all email templates.
 * Templates are scoped to tenant under the user's `custom:tenantId` attribute.
 * @returns {Promise<GetAllEmailTemplatesResponse>}
 */
export const getAll = async () => {
  try {
    const { data } = await axios.get<GetAllEmailTemplatesResponse>(
      `${getBaseUrl('CORE_API')}/v1/core/email-templates`,
      {
        demoData: demoGetAllEmailTemplates,
      } as DemoAxiosRequestConfig,
    )

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll email-templates error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch all email templates')
    }
  }
}

/**
 * Create a new email template.
 * Templates are scoped to tenant under the user's `custom:tenantId` attribute.
 * @param {CreateEmailTemplatePayload} params
 * @returns {Promise<EmailTemplate>}
 */
export const create = async (params: CreateEmailTemplatePayload) => {
  try {
    const { data } = await axios.post<EmailTemplate>(`${getBaseUrl('CORE_API')}/v1/core/email-templates`, params)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'create email-templates error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to create email template')
    }
  }
}

/**
 * Duplicate an email template.
 * Templates are scoped to tenant under the user's `custom:tenantId` attribute.
 * @param {object} params The params object of magnifyTemplateId, magnifyVersion and name
 * @returns {Promise<EmailTemplate>}
 */
export const duplicate = async (params: { magnifyTemplateId: string; magnifyVersion: number; name: string }) => {
  try {
    const { data } = await axios.post<EmailTemplate>(
      `${getBaseUrl('CORE_API')}/v1/core/email-templates/duplicate`,
      params,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'duplicate email-templates error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error(`Failed to duplicate email template with payload of '${JSON.stringify(params, null, 2)}'`)
    }
  }
}

/**
 * Return an email template for the provided ID.
 * Templates are scoped to tenant under the user's `custom:tenantId` attribute.
 * @param {object} params The params object of magnifyTemplateId and magnifyVersion
 * @returns {Promise<EmailTemplate>}
 */
export const get = async ({
  magnifyTemplateId,
  magnifyVersion,
}: {
  magnifyTemplateId: string
  magnifyVersion: number
}) => {
  try {
    const { data } = await axios.get<EmailTemplate>(
      `${getBaseUrl('CORE_API')}/v1/core/email-templates/templateId/${magnifyTemplateId}/version/${magnifyVersion}`,
      {
        demoData: demoGetEmailTemplates.find((item) => item.magnifyTemplateId === magnifyTemplateId),
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get email-templates error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error(
        `Failed to get email template with magnifyTemplateId of '${magnifyTemplateId}' & magnifyVersion of '${magnifyVersion}'`,
      )
    }
  }
}

/**
 * Update an email template for the provide ID.
 * Templates are scoped to tenant under the user's `custom:tenantId` attribute.
 * @param {object} params The params object of magnifyTemplateId and payload
 * @returns {Promise<EmailTemplate>}
 */
export const update = async ({
  magnifyTemplateId,
  payload,
}: {
  magnifyTemplateId: string
  payload: CreateEmailTemplatePayload
}) => {
  try {
    const { data } = await axios.put<EmailTemplate>(
      `${getBaseUrl('CORE_API')}/v1/core/email-templates/${magnifyTemplateId}`,
      payload,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'update email-templates error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error(`Failed to update email template with ID of '${magnifyTemplateId}'`)
    }
  }
}

/**
 * Archive (soft delete) an email template for the provide ID.
 * Templates are scoped to tenant under the user's `custom:tenantId` attribute.
 * @param {object} params The params object of magnifyTemplateId
 * @returns {Promise<EmailTemplate>}
 */
export const archive = async ({ magnifyTemplateId }: { magnifyTemplateId: string }) => {
  try {
    const { data } = await axios.delete<EmailTemplate>(
      `${getBaseUrl('CORE_API')}/v1/core/email-templates/${magnifyTemplateId}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'archive email-templates error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error(`Failed to archive email template with ID of '${magnifyTemplateId}'`)
    }
  }
}
