import { Modal } from 'antd'

import { Button } from 'components/common'

interface DiscardChangesModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  handleCloseEditView: () => void
}

const DiscardChangesModal = ({ isModalOpen, setIsModalOpen, handleCloseEditView }: DiscardChangesModalProps) => {
  const handleOk = () => {
    handleCloseEditView()
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      title='Discard email and all changes made?'
      width={656}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key='discard-updates-email-go-back-btn'
          text='Go back'
          type='secondary'
          onClickHandler={handleCancel}
        />,
        <Button
          key='discard-updates-email-btn'
          text='Discard updates'
          onClickHandler={handleOk}
          className='footer__button'
        />,
      ]}>
      <p className='modal__text'>Are you sure you'd like to discard all unsaved edits/changes you've made?</p>
    </Modal>
  )
}
DiscardChangesModal.displayName = 'DiscardChangesModal'

export default DiscardChangesModal
