import { Button, Row } from 'antd'
import classNames from 'classnames'

import { IconActionTarget, IconRemoveTarget } from 'components/common/Icons/Icons'
import { BuilderIcon } from 'services/Utils/BuilderIcon'

interface SelectTargetProps {
  isActive: boolean
  hasTarget: boolean
  iconName?: string
  actionText: string
  testIdContainer?: string
  testIdAction?: string
  isDisabled?: boolean
  onClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
  onBlurHandler?: () => void
  onClickRemoveHandler: (e: React.MouseEvent<HTMLOrSVGElement>) => void
}

const SelectTarget = ({
  isActive,
  hasTarget,
  iconName,
  actionText,
  testIdContainer,
  testIdAction,
  isDisabled = false,
  onClickHandler,
  onBlurHandler,
  onClickRemoveHandler,
}: SelectTargetProps) => {
  return (
    <Row
      className={classNames('select-target', {
        active: isActive,
        'has--target': hasTarget,
        'select-target--disabled': isDisabled,
      })}
      data-testid={`${testIdContainer ? testIdContainer : ''}`}>
      <Button
        onClick={onClickHandler}
        className='action__btn'
        onBlur={onBlurHandler}
        data-testid={testIdAction}
        disabled={isDisabled}>
        {iconName && (
          <BuilderIcon
            name={`${iconName}`}
            options={{ width: 20, height: 20, draggable: false, className: 'target--icon' }}
          />
        )}
        <span className='select-target__name'>{actionText}</span>
        <IconActionTarget className='action__icon' />
      </Button>
      {!isDisabled && (
        <IconRemoveTarget className='remove__btn' onClick={onClickRemoveHandler} data-testid='remove-action' />
      )}
    </Row>
  )
}

export default SelectTarget
