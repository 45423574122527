import { Layout, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { Route, Routes } from 'react-router-dom'

import { Button, Heading } from 'components/common'
import MotionListObservability from 'components/MotionObservability/MotionListObservability'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import InternalOperationalStats from 'pages/Motions/InternalOperationalStats'
import { MotionPage } from 'pages/Motions/Motion/Motion.page'
import MotionDetailsPage from 'pages/Motions/MotionDetails'
import MotionGoalsModal from 'pages/Motions/MotionGoalsModal'
import MotionsList from 'pages/Motions/MotionsList/MotionsList'
import useStore from 'store/useStore'

export const Motions = observer(() => {
  const { motionGoalsStore, motionStore } = useStore()
  const { journeyObservability } = useFlags<LaunchDarklyFeatureFlags>()

  return (
    <main className='motions-main' data-testid='motions-main'>
      <Routes>
        <Route path='motion/new' element={<MotionPage />} />
        <Route path='motion/:id/:version' element={<MotionPage />} />
        <Route path='motion/:id/:version/details' element={<MotionDetailsPage />} />
        <Route path='motion/:id/:version/statistics' element={<InternalOperationalStats />} />
        <Route
          path='/*'
          element={
            <Layout className='bg-secondary ant-layout-has-sider'>
              <Content className='container motions-main__container'>
                <Content className='content content__spacing'>
                  <Row justify='space-between'>
                    <Heading variant='1' level='1'>
                      Motions
                    </Heading>
                    <Button
                      text='New Motion'
                      testId='motions-create-button'
                      onClickHandler={() => {
                        motionStore.setIsSegmentBuilderEditDisabled(false)
                        motionGoalsStore.setMotionGoalsModal({ isOpen: true })
                      }}
                    />
                  </Row>
                  {journeyObservability && <MotionListObservability />}
                  <MotionsList />
                </Content>
              </Content>
            </Layout>
          }
        />
      </Routes>

      <MotionGoalsModal />
    </main>
  )
})
Motions.displayName = 'Motions'
