import { Card } from 'components/common/Card/Card'
import Chart from 'components/MotionDetails/GoalMetric/Chart'
import { goalMetricData } from 'components/MotionDetails/share/mock'

export const NUMBER_OF_CAROUSEL_CARDS = 2

// Carousel max with depending of the number of cards
export const CAROUSEL_MAX_WIDTH = {
  1: 850,
  2: 1_600,
  3: 2_350,
}

export const slidesToShow = (windowSize: number) => {
  return windowSize < CAROUSEL_MAX_WIDTH[NUMBER_OF_CAROUSEL_CARDS] ? 1 : NUMBER_OF_CAROUSEL_CARDS
}

export const generateCarouselCards = () => {
  return Array.from({ length: NUMBER_OF_CAROUSEL_CARDS }).map((_, index: number) => (
    <Card variant='2' className='goal-metric-card' key={index}>
      <Chart
        labels={goalMetricData.labels}
        data={goalMetricData.data}
        title={goalMetricData.title}
        annotation={goalMetricData.annotation}
        visualization={goalMetricData.visualization}
      />
    </Card>
  ))
}
