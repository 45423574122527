import Logo from 'components/common/Logo'

interface LogoListProps {
  integrations: string[]
  limit?: number
}

const LogoList = ({ integrations, limit = 3 }: LogoListProps) => {
  const shouldDisplayPlaceholder = integrations.length > limit + 1
  const viewLimit = shouldDisplayPlaceholder ? limit : limit + 1

  return (
    <div className='logo__list' data-testid='logo-list'>
      {integrations.slice(0, viewLimit).map((integrationName) => (
        <Logo key={integrationName} platformName={integrationName} />
      ))}

      {shouldDisplayPlaceholder && (
        <div className='logo__list--placeholder'>
          <span className='number' data-testid='logo-number'>{`+${integrations.length - limit}`}</span>
        </div>
      )}
    </div>
  )
}
export default LogoList
