import { makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type {
  SalesforceOpportunity,
  MarketoAction,
  IntegrationNew,
  IntegrationParam,
  Integration,
} from 'models/Integration.model'

export class IntegrationsStore implements ChildStore {
  isLoading = false
  isLoadingActions = false
  currentIntegrationNew: IntegrationNew | null = null
  actions: SalesforceOpportunity[] = []
  marketoData: MarketoAction = { campaigns: [], leads: [] }
  integrations: Integration[] = []

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      integrations: observable,
      isLoading: observable,
      currentIntegrationNew: observable,
    })
  }

  reset = () => {
    this.integrations = []
    this.isLoading = false
    this.isLoadingActions = false
    this.currentIntegrationNew = null
    this.actions = []
    this.marketoData = { campaigns: [], leads: [] }
    this.apiError = null
  }

  setLoading = (value: boolean) => {
    this.isLoading = value
  }

  getActions = async (app: string, connectionId: string): Promise<void> => {
    this.isLoadingActions = true
    const actions = await API.integrations.getActionsData(app, connectionId)

    runInAction(() => {
      switch (app) {
        case 'salesforce':
          this.actions = actions as SalesforceOpportunity[]
          break
        case 'marketo':
          this.marketoData = actions as MarketoAction
          break

        default:
          break
      }
      this.isLoadingActions = false
    })
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }

  setCurrentIntegrationNew = (newIntegration: IntegrationNew) => {
    runInAction(() => {
      this.currentIntegrationNew = newIntegration
    })
  }

  getNew = async (id: string, options?: IntegrationParam): Promise<void> => {
    if (!id) {
      return
    }
    this.isLoading = true
    try {
      const integration = (await API.integrations.getNew(id, options)) as IntegrationNew
      runInAction(() => {
        this.currentIntegrationNew = integration
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  postNew = async (id: string, data: any): Promise<any> => {
    this.isLoading = true

    try {
      const newIntegration = await API.integrations.postNew(id, data)
      return newIntegration
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getIntegrations = async (): Promise<void> => {
    this.isLoading = true
    try {
      const { platforms } = await API.integrations.getIntegrations()
      runInAction(() => {
        this.integrations = platforms
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }
}
