import { Tag } from 'antd'

import { IconExecuteMotionSchedule } from 'components/common/Icons/Icons'
import { DateService } from 'services/Utils/date'
import { ordinalSuffix } from 'services/Utils/misc'

import type { Schedule } from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'
import type { ReportingMotion } from 'models/reporting.model'

export const generateTitleAndDescription = (title: string, description: string | JSX.Element): JSX.Element => {
  return (
    <div className='motion-details-header-bottom-group-container'>
      <span>{title}</span>
      <span>{description}</span>
    </div>
  )
}

export const generateMotionTag = (motion: ReportingMotion | undefined) => {
  const classPrefix = [MotionStateEnum.Published, MotionStateEnum.Scheduled, MotionStateEnum.Suspended].includes(
    motion?.currState as MotionStateEnum,
  )
    ? 'pending'
    : motion?.currState?.toLowerCase()

  let displayStatus = `${motion?.currState?.charAt(0)}${motion?.currState?.slice(1).toLowerCase()}`

  if (motion?.currState === MotionStateEnum.Executing) {
    displayStatus = 'Running'
  }

  return (
    <Tag className={`motion-details-header-tag--${classPrefix}`}>
      <span>{displayStatus}</span>
    </Tag>
  )
}

const isValidSchedule = (motion: ReportingMotion | undefined): boolean => {
  const schedule = motion?.schedule && (JSON.parse(motion?.schedule) as Schedule)

  return (
    !!schedule &&
    schedule.enabled &&
    typeof schedule.minutes === 'number' &&
    typeof schedule.hours === 'number' &&
    !!schedule.dayOfWeek &&
    !!schedule.executionCadence &&
    (schedule.executionCadence === 'multiple' ? !!schedule.recurringCadence : true)
  )
}

// Multiple Runs (Every Monday 12:00)
// Once (on Monday {30 AUG} 12:00)

export const generateRunSchedule = (motion: ReportingMotion | undefined) => {
  if (!isValidSchedule(motion)) return 'N/A'

  const schedule = JSON.parse(motion?.schedule as string) as Schedule
  const date = DateService.getLocalDateTime(schedule)

  if (schedule.executionCadence === 'once') {
    const onDay = date.dayOfMonth && date.month ? ` on ${ordinalSuffix(+date.dayOfMonth)} ${date.month} ` : ' '

    return `Once (${date.dayOfWeek}${onDay}at ${date.time})`
  } else {
    const recurringCadence = schedule.recurringCadence!.charAt(0).toUpperCase() + schedule.recurringCadence?.slice(1)
    return (
      <>
        <IconExecuteMotionSchedule />
        {`Multiple Runs (${recurringCadence} on ${date.dayOfWeek} at ${date.time})`}
      </>
    )
  }
}
