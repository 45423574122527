import { Skeleton } from 'antd'

export const LoadingHeader = () => {
  return (
    <section data-testid='loading-header-container' className='loading-header-container'>
      <Skeleton active paragraph={{ rows: 5 }}></Skeleton>
    </section>
  )
}

export const LoadingOperationalStatisticsMetrics = () => {
  return (
    <section
      data-testid='loading-operational-statistics-metrics-container'
      className='loading-operational-statistics-metrics-container'>
      <Skeleton.Node active />
    </section>
  )
}

export const LoadingExecutedActionsSandbox = () => {
  return (
    <section data-testid='loading-account-list-container' className='loading-account-list-container'>
      {Array.from({ length: 10 }).map((_, idx) => (
        <Skeleton.Input key={idx} active size='default' />
      ))}
    </section>
  )
}

export const LoadingGoalMetrics = () => {
  return (
    <section data-testid='loading-goal-metrics-container' className='loading-goal-metrics-container'>
      <Skeleton.Node active />
      <Skeleton.Node active />
    </section>
  )
}

export const LoadingMachineLearningMetrics = () => {
  return (
    <section
      data-testid='loading-machine-learning-metrics-container'
      className='loading-machine-learning-metrics-container'>
      <Skeleton.Node active />
    </section>
  )
}

export const LoadingEmailStats = () => {
  return (
    <section data-testid='loading-email-stats-table-container' className='loading-email-stats-table-container'>
      <Skeleton.Node active />
    </section>
  )
}

export const LoadingAccountList = () => {
  return (
    <section data-testid='loading-account-list-container' className='loading-account-list-container'>
      {Array.from({ length: 10 }).map((_, idx) => (
        <Skeleton.Input key={idx} active size='default' />
      ))}
    </section>
  )
}
