import type { Role } from 'models/users.model'

export const getRoleName = (id: string | undefined, roles: Role[]) => {
  if (!Object.keys(roles).length) {
    return
  }
  const roleObj = roles.find((role: Role) => role.id === id)
  // the super Admin doesn't have an available role
  return roleObj?.role ? roleObj?.role : 'SUPER ADMIN'
}
