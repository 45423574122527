import colors from 'components/MotionDetails/MachineLearningMetric/Chart/helpers/chart-colors.module.scss'

export const createBackgroundColors = (ctx: CanvasRenderingContext2D, chartColor?: string) => {
  const bgStartColor = chartColor ? colors[`backgroundStart-${chartColor}`] : colors.backgroundStart
  const bgEndColor = chartColor ? colors[`backgroundEnd-${chartColor}`] : colors.backgroundEnd

  const gradient = ctx.createLinearGradient(0, 0, 0, 300)
  gradient.addColorStop(0, bgStartColor)
  gradient.addColorStop(1, bgEndColor)
  return gradient
}
