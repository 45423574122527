export const getInsightHeading = (insightType: string) => {
  switch (insightType) {
    case 'churn':
      return 'Reduce churn probability'
    case 'expansion':
      return 'Increase expansion probability'
    case 'conversion':
      return 'Increase trial conversion probability'
    default:
      return 'Unknown insight type'
  }
}

export const buildHeadingForTooltip = (driverType: string) => {
  switch (driverType) {
    case 'churn':
      return 'decrease churn probability'
    case 'expansion':
      return 'increase expansion probability'
    case 'conversion':
      return 'increase conversion probability'
    default:
      return 'Unknown driver type'
  }
}

export const getAggregationFromText = (text: string) => {
  const aggregations = [
    { label: 'total', value: 'total' },
    { label: 'average', value: 'average' },
    { label: 'minimum', value: 'min' },
    { label: 'maximum', value: 'max' },
  ]

  const match = aggregations.find((aggregation) => text.includes(aggregation.label)) ?? {
    label: '-',
    value: '-',
  }

  return match.value
}

/**
 * Returns a number to a fixed number of decimal places if necessary.
 * @param {string | number} value The value to convert
 * @param {number} [fractionDigits=0] The number of decimal places to round to.
 * @returns {number} The rounded value.
 */
export const toFixedIfNecessary = (value: string | number, fractionDigits: number = 0) => {
  const parsedValue = Number(value)
  return isNaN(parsedValue) ? 0 : Number(parsedValue.toFixed(fractionDigits))
}

export const getRiskSegment = (segmentsList: string[] | undefined): string => {
  if (!segmentsList || segmentsList.length === 0) {
    return 'no segment'
  }

  const segmentLength = segmentsList.length

  if (segmentLength === 1) {
    return `${segmentsList[0]} risk segment`
  } else if (segmentLength === 2) {
    return `${segmentsList[0]} and ${segmentsList[1]} risk segment`
  } else {
    const joinedSegments = segmentsList.slice(0, -1).join(', ')
    const lastSegment = segmentsList[segmentLength - 1]
    return `${joinedSegments}, and ${lastSegment} risk segment`
  }
}
