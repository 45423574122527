import { beautifyFloat } from 'services/Utils/Format/NumberFormat'
import { getAuthTokenId } from 'services/Utils/getAuthToken'
import { getRoleName } from 'services/Utils/users.service'

export const UtilService = {
  getAuthTokenId,
  getRoleName,
}

export * from 'services/Utils/Conventions/Conventions'
export * from 'services/Utils/dslConversion'

export const NumberFormat = {
  beautifyFloat,
}
