import { makeAutoObservable, toJS } from 'mobx'

import type { ChildStore } from 'store/StoreTypes'

import type {
  Goal,
  GoalType,
  MotionGoalsModal,
  MotionGoalsModalData,
  MotionGoalsPopover,
  Metrics,
  MetricItem,
} from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

export class MotionGoalsStore implements ChildStore {
  motionTitle: string = ''
  motionDescription: string = ''
  motionGoal: Goal = {}
  motionMetrics: Metrics[] = []
  motionCurrState: MotionStateEnum = MotionStateEnum.Draft
  motionGoalsModal: MotionGoalsModal = {
    isOpen: false,
    isEditing: false,
  }

  filterByType: boolean | string[] = []
  dataFieldIndex: number = -1
  motionGoalsPopover: MotionGoalsPopover = {
    isVisible: false, // used for toggling between goals and empty goals
    forceHidden: false, // used to hide the popover when the user's clicking the button within
  }
  currentMotionGoals: MotionGoalsModalData = {
    title: '',
    description: '',
    goal: {},
    metrics: [],
    currState: MotionStateEnum.Draft,
  }

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.motionTitle = ''
    this.motionDescription = ''
    this.motionGoal = {}
    this.motionMetrics = []
    this.motionCurrState = MotionStateEnum.Draft
    this.motionGoalsModal = {
      isOpen: false,
      isEditing: false,
    }
    this.filterByType = []
    this.dataFieldIndex = -1
    this.motionGoalsPopover = {
      isVisible: false, // used for toggling between goals and empty goals
      forceHidden: false, // used to hide the popover when the user's clicking the button within
    }
    this.currentMotionGoals = {
      title: '',
      description: '',
      goal: {},
      metrics: [],
      currState: MotionStateEnum.Draft,
    }
  }

  setMotionGoalsModal = (props: MotionGoalsModal) => {
    this.motionGoalsModal = props

    if (!props.isOpen) {
      this.filterByType = false
    }

    this.setMotionGoals({
      title: this.currentMotionGoals.title,
      description: this.currentMotionGoals.description,
      goal: toJS(this.currentMotionGoals.goal),
      metrics: toJS(this.currentMotionGoals.metrics),
      currState: toJS(this.currentMotionGoals.currState),
    })
  }

  setMotionGoalsVisibility = ({ isVisible, forceHidden }: MotionGoalsPopover) => {
    if (forceHidden) {
      this.motionGoalsPopover.forceHidden = isVisible
    } else {
      this.motionGoalsPopover.isVisible = isVisible
    }
  }

  setMotionGoals = ({ title, description, goal, metrics, currState }: MotionGoalsModalData) => {
    this.setMotionTitle(title)
    this.setMotionDescription(description)
    this.setMotionGoal(goal)
    this.setMotionMetrics(metrics)
    this.setMotionState(currState)
  }

  setMotionTitle = (title: string) => {
    this.motionTitle = title
  }

  setMotionState = (state: MotionStateEnum) => {
    this.motionCurrState = state
  }

  setMotionDescription = (description: string) => {
    this.motionDescription = description
  }

  setMotionGoal = (goal: Goal) => {
    this.motionGoal = goal
  }

  setEmptyGoal = () => {
    this.motionGoal = {}
  }

  setGoalProbability = (value: number | null) => {
    this.motionGoal.value = value as number
  }

  setGoalType = (type: GoalType) => {
    this.motionGoal.type = type

    if (!this.motionGoal.value) {
      this.motionGoal.value = 0
    }
  }

  setMotionMetrics = (metrics: Metrics[]) => {
    this.motionMetrics = metrics
  }

  addMetric = (emptyMetric: Metrics) => {
    this.motionMetrics = [
      ...this.motionMetrics,
      {
        ...emptyMetric,
        ...(this.motionMetrics.length && { key: this.motionMetrics[this.motionMetrics.length - 1].key + 1 }),
      },
    ]
  }

  removeMetric = (index: number) => {
    if (this.motionMetrics.length === 1) {
      this.motionMetrics[0] = {
        key: 0,
        field: '',
        aggregation: null,
        trend: null,
        timespan: '7days',
        target: null,
        userCreated: true,
      }

      return
    }

    this.motionMetrics.splice(index, 1)
  }

  onChangeDataField = ({
    item,
    hasDifferentType,
    index,
  }: {
    item: MetricItem
    hasDifferentType: boolean
    index: number
  }) => {
    this.motionMetrics[index] = {
      ...this.motionMetrics[index],
      ...item,
      // if the previous field type and the new one are in different categories, reset the aggregation selected option
      ...(hasDifferentType && { aggregation: null }),
    }
  }

  onChangeAggregation = (value: string, index: number) => {
    this.motionMetrics[index] = { ...this.motionMetrics[index], aggregation: value }
  }

  onChangeTrend = (value: string, index: number) => {
    this.motionMetrics[index] = { ...this.motionMetrics[index], trend: value }
  }

  onChangeTimespan = (value: string, index: number) => {
    this.motionMetrics[index] = { ...this.motionMetrics[index], timespan: value }
  }

  onChangeTarget = (value: number | null, index: number) => {
    this.motionMetrics[index] = { ...this.motionMetrics[index], target: value }
  }

  setFilterByType = (type?: boolean | string[]) => {
    this.filterByType = type || false
  }

  setDataFieldIndex = (index: number) => {
    this.dataFieldIndex = index
  }

  setCurrentMotionGoals = (data: { [key: string]: string | Goal | Metrics[] }) => {
    this.currentMotionGoals = { ...this.currentMotionGoals, ...data }
  }
}
