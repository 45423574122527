import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'

import TemplateList from 'components/EmailTemplates/components/TemplateList'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

const EmailTemplatesPage = () => {
  const { emailTemplatesStore } = useStore()
  useDisplayErrorNotification(emailTemplatesStore)

  return (
    <Layout className='bg-secondary' data-testid='email-template-layout'>
      <Content className='container ant-layout-has-sider email-templates'>
        <TemplateList />
      </Content>
    </Layout>
  )
}
EmailTemplatesPage.displayName = 'EmailTemplatesPage'

export default observer(EmailTemplatesPage)
