import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'

import colors from 'components/MotionDetails/MachineLearningMetric/Chart/helpers/chart-colors.module.scss'
import { createBackgroundColors } from 'components/MotionDetails/MachineLearningMetric/Chart/helpers/colors'
import { getOptions } from 'components/MotionDetails/MachineLearningMetric/Chart/helpers/options'

import type { ChartData, ChartOptions, CoreChartOptions } from 'chart.js'

import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

interface ChartProps {
  labels: string[]
  data: number[]
  title: string
  isPercentage?: boolean
  shouldRoundNumbers?: boolean
  shouldFormatKMB?: boolean
  yMax?: number
  yMin?: number
  chartColor?: 'purple' | 'blue'
}

const Chart = ({
  labels = [],
  data,
  title,
  isPercentage = false,
  shouldRoundNumbers = false,
  shouldFormatKMB = false,
  yMax,
  yMin,
  chartColor,
}: ChartProps) => {
  // Convert any integer labels to dates.
  const cleanLabels = labels.map((label) =>
    typeof label === 'number' ? dayjs(label * 1000).format('YYYY-MM-DD') : label,
  )
  const chartRef: React.ForwardedRef<ChartJSOrUndefined<'line'>> = useRef(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    labels: !labels || labels.length < 1 ? [] : [...cleanLabels],
    datasets: [
      {
        pointBorderColor: chartColor ? colors[`line-${chartColor}`] : colors.line,
        pointBackgroundColor: 'white',
        pointBorderWidth: 2,
        pointRadius: 5,
        fill: true,
        borderWidth: 3,
        borderColor: chartColor ? colors[`line-${chartColor}`] : colors.line,
        data,
      },
    ],
  })
  const options: ChartOptions = getOptions(title, isPercentage, shouldRoundNumbers, yMax, yMin, shouldFormatKMB)

  // generate under the line gradient color
  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const backgroundColor = createBackgroundColors(chart.ctx, chartColor)
    setChartData((prev: ChartData<'line'>) => ({
      ...prev,
      datasets: [
        {
          ...prev.datasets[0],
          backgroundColor,
        },
      ],
    }))
  }, [chartRef])

  useEffect(() => {
    setChartData((prev: ChartData<'line'>) => ({
      ...prev,
      labels: cleanLabels,
      datasets: [
        {
          ...prev.datasets[0],
          data,
        },
      ],
    }))
  }, [data])

  return (
    <div className='canvas-container motion-details-machine-learning-metric'>
      <Line data-testid='canvas' ref={chartRef} data={chartData} options={options as CoreChartOptions<'line'>} />
    </div>
  )
}
Chart.displayName = 'Chart'

export default Chart
