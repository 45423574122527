import { notification } from 'antd'

import type { CoreAPIErrorResponse } from 'api/errors'
import Demo from 'configs/demo'

const displayErrorNotification = (error: CoreAPIErrorResponse) => {
  // Explicit no-op if the demo flag is enabled
  if (Demo.mockApiIsEnabled()) {
    console.log('Demo Mode: Error notification suppressed', error.message, error.detail?.trim())
    return
  }

  notification.error({
    message: error.message,
    description: error.detail?.trim(),
    duration: 5,
  })
}

export { displayErrorNotification }
