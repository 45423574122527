import Table from 'antd/lib/table'
import { useMemo } from 'react'

import { Heading } from 'components/common'
import DynamicCell from 'components/common/DynamicCell'
import { IconAccounts } from 'components/common/Icons/Icons'

import type { ColumnsType } from 'antd/lib/table'

import type { ExperimentColumn, ExperimentRow } from 'models/experiment.model'
import { ImpactEnum, TableColumnTypeEnum } from 'models/insights'

interface AccountsListMock {
  columns: ExperimentColumn[]
  rows: ExperimentRow[]
}

const accountsListMock: AccountsListMock = {
  columns: [
    {
      key: 'metrics',
      title: 'Motion Metrics',
      type: TableColumnTypeEnum.MotionMetrics,
    },
    {
      key: 'variant A',
      title: 'Variant A',
      type: TableColumnTypeEnum.Variant,
      accounts: 459,
      percentage: 65,
      isWinner: true,
    },
    {
      key: 'variant B',
      title: 'Variant B',
      type: TableColumnTypeEnum.Variant,
      accounts: 156,
      percentage: 25,
    },
    {
      key: 'control',
      title: 'Control',
      type: TableColumnTypeEnum.Variant,
      accounts: 71,
      percentage: 10,
    },
  ],
  rows: [
    {
      key: '1',
      metrics: {
        text: 'Account distribution for product stay duration',
        label: 'Significant',
      },
      'variant A': {
        level: 'Average',
        impact: ImpactEnum.Positive,
        value: 1.08,
        trend: 'ascendent',
        unit: '%',
      },
      'variant B': {
        level: 'Average',
        impact: ImpactEnum.Positive,
        value: 0.12,
        trend: 'ascendent',
        unit: '%',
      },
      control: {
        level: 'Average',
        impact: ImpactEnum.Positive,
        value: 0.05,
        trend: 'ascendent',
        unit: '%',
      },
    },
    {
      key: '2',
      metrics: {
        text: 'Account distribution for # of service tickets',
        label: 'Not significant',
      },
      'variant A': {
        level: 'Average',
        impact: ImpactEnum.Positive,
        value: 0.56,
        trend: 'descendent',
        unit: '%',
      },
      'variant B': {
        level: 'Average',
        impact: ImpactEnum.Positive,
        value: 1.03,
        trend: 'descendent',
        unit: '%',
      },
      control: {
        level: 'Average',
        impact: ImpactEnum.Positive,
        value: 0.17,
        trend: 'descendent',
        unit: '%',
      },
    },
    {
      key: '3',
      metrics: {
        text: 'Average churn probability',
        label: 'Significant',
      },
      'variant A': {
        level: '',
        impact: ImpactEnum.Positive,
        value: 1.99,
        trend: 'descendent',
        unit: '%',
      },
      'variant B': {
        level: '',
        impact: ImpactEnum.Negative,
        value: 0.16,
        trend: 'ascendent',
        unit: '%',
      },
      control: {
        level: '',
        impact: ImpactEnum.Negative,
        value: 0.17,
        trend: 'ascendent',
        unit: '%',
      },
    },
  ],
}

const ExperimentResult = () => {
  const accountTableColumns = useMemo(() => getTableColumns(accountsListMock.columns), [accountsListMock.rows])

  return (
    <section className='experiment-result'>
      <Heading variant='7' level='2'>
        Experiment Result
      </Heading>

      <div className='result-table'>
        <Table
          dataSource={accountsListMock.rows}
          columns={accountTableColumns}
          pagination={{ hideOnSinglePage: true }}
          showSorterTooltip={false}
        />
      </div>
    </section>
  )
}
ExperimentResult.displayName = 'ExperimentResult'

function getTableColumns(data: ExperimentColumn[]): ColumnsType<ExperimentRow> {
  return data.map((column, index) => ({
    title: (
      <>
        {column.isWinner && (
          <div className='winner-label'>
            <span>Winner</span>
          </div>
        )}
        <span>{column.title}</span>
        <span className='account__details'>
          {column.accounts && (
            <span>
              {' '}
              <IconAccounts />
              {column.accounts}
            </span>
          )}
          {column.percentage && <span> ({column.percentage}%)</span>}
        </span>
      </>
    ),
    className: column.isWinner ? 'winner' : '',
    dataIndex: column.key,
    align: index ? 'right' : 'left',
    key: 'id',
    render: (_: any, record) => {
      return (
        <div className='table__row'>
          <DynamicCell column={column} record={record} />
        </div>
      )
    },
  }))
}

export default ExperimentResult
