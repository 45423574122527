import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined'
import { Button as AButton, notification, Form, Modal, Row, Input } from 'antd'
import Upload from 'antd/lib/upload/Upload'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import Button from 'components/common/Button'
import { IconClose } from 'components/common/Icons/Icons'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { UploadFile } from 'antd'
import type { UploadProps } from 'antd/lib/upload/Upload'

const FileUploadModal = () => {
  const [form] = Form.useForm()
  const { fileAttachmentsStore } = useStore()
  useDisplayErrorNotification(fileAttachmentsStore)

  // a local state to store the currently selected file.
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  /** Once the form is validated we send the payload to the email-attachments API. */
  const onFinish = async (_values: any) => {
    try {
      if (!file || !name || !description) {
        return
      }

      const formData = new FormData()
      formData.append('name', name)
      formData.append('description', description)
      formData.append('file', file, file.name)

      await fileAttachmentsStore.uploadFile(formData)

      // Close the modal and reset the form.
      form.resetFields()
      notification.success({
        message: 'File uploaded successfully.',
        duration: 5,
      })

      // Fetch the list of file attachments.
      await fileAttachmentsStore.getFileList()

      fileAttachmentsStore.setModal(false)
    } catch (error: unknown) {
      // Errors are caught & displayed by the store.
    }
  }

  // Upload button props.
  const props: UploadProps = {
    onRemove: (file: any) => {
      setFileList([])
      setFile(null)
    },
    beforeUpload: (file) => {
      setFileList([file])
      setFile(file)
      return false
    },
    fileList,
  }

  return (
    <Modal
      open={fileAttachmentsStore.modal.isOpen}
      keyboard
      width={567}
      maskClosable={false}
      className='modal upload-file-modal'
      onOk={form.submit}
      onCancel={() => fileAttachmentsStore.setModal(false)}
      closeIcon={<IconClose className='btn__close' data-testid='modal-close' />}
      title={<p>Upload File</p>}
      data-testid='upload-file-modal'
      footer={[
        <React.Fragment key='modal__footer'>
          <Button
            text='Cancel'
            type='secondary'
            size='XL'
            onClickHandler={() => {
              form.resetFields()
              fileAttachmentsStore.setModal(false)
            }}
            testId='cancel-btn'
          />
          <Button
            text='Submit'
            size='XL'
            className='m-l-10'
            htmlType='submit'
            testId='submit-btn'
            onClickHandler={() => {
              form.submit()
            }}
          />
        </React.Fragment>,
      ]}>
      <Form className='modal__body' data-testid='upload-file-modal-body' form={form} onFinish={onFinish}>
        <div className='upload-file-modal__inputs'>
          <Row>
            <Form.Item
              data-testid='upload-file-modal__inputs--name'
              label='File Name'
              name='name'
              className='required-item full-width'
              rules={[
                {
                  message: 'Please provide a valid file name',
                  required: true,
                  validateTrigger: 'onSubmit',
                  whitespace: true,
                },
              ]}>
              <Input
                data-testid='name-input'
                value={name}
                name='name'
                type='text'
                placeholder='File Name'
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              data-testid='upload-file-modal__inputs--description'
              label='Description'
              name='description'
              className='required-item full-width'
              rules={[
                {
                  message: 'Please provide a description for the file',
                  required: true,
                  validateTrigger: 'onSubmit',
                  whitespace: true,
                },
              ]}>
              <Input.TextArea
                data-testid='description-input'
                value={description}
                name='description'
                rows={4}
                placeholder='Please describe your file'
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </Row>
          <Row>
            <Upload data-testid='file-input' {...props}>
              <AButton icon={<UploadOutlined data-testid='upload-input' />}>Select File</AButton>
            </Upload>
          </Row>
        </div>
      </Form>
    </Modal>
  )
}
FileUploadModal.displayName = 'FileUploadModal'

export default observer(FileUploadModal)
