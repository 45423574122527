import { makeAutoObservable } from 'mobx'

import type { ChildStore } from 'store/StoreTypes'

export class ConfigPanelStore implements ChildStore {
  displayRecordLocatorModal: boolean = false
  displayConfirmationModal: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.displayRecordLocatorModal = false
    this.displayConfirmationModal = false
  }

  setDisplayRecordLocatorModal = (state: boolean) => {
    this.displayRecordLocatorModal = state
  }

  setDisplayConfirmationModal = (state: boolean) => {
    this.displayConfirmationModal = state
  }
}
