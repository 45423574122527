import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { ReactFlowProvider } from 'react-flow-renderer'

import { Button } from 'components/common'
import { SegmentBuilder } from 'components/MotionBuilder/SegmentBuilder/SegmentBuilder'
import Flow from 'components/MotionTarget/Flow'
import { addNode, moveElementsPosition } from 'components/MotionTarget/motionTarget.utils'
import SettingsBadge from 'components/MotionTarget/SettingsBadge'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

interface MotionTargetProps {
  onShowDrawer: (data: any) => void
  totalAccounts: number
}

export const MotionTarget = observer(({ onShowDrawer, totalAccounts }: MotionTargetProps) => {
  const { motionStore, mergePanelStore } = useStore()
  const { droppedElement, dragOverElement, setDroppedElement, goUp, goDown } = motionStore

  const { isABSplitScreenEnabled, elements, setElements, setElementsWithOptimizedPositions, editExperimentVariant } =
    useContext(SegmentBuilderContext)

  // Move elements position based on hover over edge droppable section
  useEffect(() => {
    if (dragOverElement && (goDown || goUp)) {
      const updatedPositionElements = moveElementsPosition(elements, dragOverElement, goDown)
      setElements(updatedPositionElements)
    }
  }, [goUp, goDown])

  useEffect(() => {
    if (droppedElement) {
      const newStateElements = addNode(elements, droppedElement, {
        onShowDrawer,
        setElements: setElementsWithOptimizedPositions,
      })

      setElementsWithOptimizedPositions(newStateElements)
      setDroppedElement(null)
      mergePanelStore.setMergeBasedOnElements(newStateElements)
    }
  }, [droppedElement])

  return (
    <div className='motion-flow'>
      <div className='motion-flow__wrapper'>
        {!isABSplitScreenEnabled() && <SegmentBuilder />}

        <div className={`motion-flow__container ${isABSplitScreenEnabled() && 'motion-flow__container--split-screen'}`}>
          <div className='motion-flow__section'>
            {isABSplitScreenEnabled() ? (
              <ReactFlowProvider>
                <Flow isExperiment={false} />
              </ReactFlowProvider>
            ) : (
              <Flow isExperiment={false} />
            )}

            {isABSplitScreenEnabled() && (
              <>
                <SettingsBadge title='Variant A' totalAccounts={totalAccounts} />
                <div className='motion-flow__edit-btn'>
                  <Button
                    text='Edit variant A'
                    testId='edit-variant'
                    type='secondary'
                    size='L'
                    onClickHandler={() => editExperimentVariant('a')}
                  />
                </div>
              </>
            )}
          </div>

          {/* Variant B */}
          {isABSplitScreenEnabled() && (
            <div className='motion-flow__section'>
              <ReactFlowProvider>
                <Flow isExperiment={true} />
              </ReactFlowProvider>
              <SettingsBadge title='Variant B' totalAccounts={totalAccounts} />
              <div className='motion-flow__edit-btn'>
                <Button
                  text='Edit variant B'
                  testId='edit-variant'
                  type='secondary'
                  size='L'
                  onClickHandler={() => editExperimentVariant('b')}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isABSplitScreenEnabled() && <div className='motion-flow__variant-divider'></div>}
    </div>
  )
})
MotionTarget.displayName = 'MotionTarget'
