import type { ScriptableScaleContext, TooltipItem } from 'chart.js'
import type { Context } from 'chartjs-plugin-datalabels'

import type { FutureRevenue, RevenueRetentionForecast } from 'models/insights'

/**
 * Return the correct color for a given event.
 * @param {string} eventName The event name to get the color for
 * @returns {string} The CSS style color to use for the event.
 */
export const getBackgroundColor = (eventName: string) => {
  switch (eventName) {
    case 'Expansion': {
      return '#2E4FEF'
    }
    case 'Renewal': {
      return '#73A6F1'
    }
    case 'Churn': {
      return '#F13F62'
    }
    default: {
      console.error('Unknown Event:', eventName)
      return '#000000'
    }
  }
}

export function formatEventValue(event: FutureRevenue, type: string) {
  if (type === 'value') {
    return event.value.value / 1000000
  } else if (type === 'percent') {
    return event.percent.value * 100
  }
  return 0
}

export function formatTooltip(event: FutureRevenue) {
  const formattedValue = Math.abs(event.value.value / 1000000).toFixed(1)
  const formattedPercent = Math.trunc(event.percent.value * 100)
  const sign = event.value.value < 0 ? '-' : ''
  return `${sign}$${formattedValue}M (${formattedPercent}%)`
}

/**
 * Transform Revenue Retention Forecast data to be used by ChartJS
 * @param {RevenueRetentionForecast} payload The Revenue Retention Forecast data
 * @param {string} type The type of data to process, `value` or `percent`
 * @returns The data ready to be used by ChartJS
 */
export const transformPayload = (payload: RevenueRetentionForecast, type: string) => {
  if (!payload) {
    return {
      labels: [],
      datasets: [],
    }
  }
  const labels = payload.revenue_trend.map((item) => item.label)

  const eventsMap: { [key: string]: number[] } = {}
  /** Container for our combined tooltip items. */
  const tooltips: { [key: string]: string[] } = {}

  // Initialize eventsMap based on the event names found in the first item's events array.
  // Assuming all items have the same event structure.
  payload.revenue_trend[0].events.forEach((event) => {
    eventsMap[event.name] = []
    tooltips[event.name] = []
  })

  // Fill eventsMap with event values for each label.
  payload.revenue_trend.forEach((item) => {
    item.events.forEach((event) => {
      const formattedValue = formatEventValue(event, type)
      eventsMap[event.name].push(formattedValue)

      const tooltip = formatTooltip(event)
      tooltips[event.name].push(tooltip)
    })
  })

  const datasets = Object.entries(eventsMap).map(([eventName, values], index) => {
    return {
      label: eventName,
      data: values,
      backgroundColor: getBackgroundColor(eventName),
      order: 100 - (index + 1),
      barThickness: 52,
      tooltip: tooltips[eventName],
    }
  })
  return {
    labels,
    datasets,
  }
}

/**
 * Extract the table data for a given type.
 * @param {RevenueRetentionForecast} payload The Revenue Retention Forecast data
 * @param {string} type The type of data to use, `value` or `percent`.
 * @returns The data formatted for the ChartJS table plugin
 */
export const transformRevenueData = (payload: RevenueRetentionForecast, type: string) => {
  const pastRevenueData: number[] = payload.revenue_trend.map((item) => {
    if (type === 'value') {
      return item.past_revenue[type].value / 1000000
    }
    if (type === 'percent') {
      return item.past_revenue[type].value * 100
    }
    console.error('Unknown Type:', type)
    return 0
  })

  const futureRevenueData: number[] = payload.revenue_trend.map((item) => item.future_revenue.value.value / 1000000)

  return [
    {
      label: type === 'value' ? 'NRR (M)' : 'NRR',
      data: pastRevenueData,
    },
    {
      label: ['Starting', 'ARR (M)'],
      data: futureRevenueData,
    },
  ]
}

/**
 * The base options for the Revenue Retention Forecast chart.
 * Extended based on dynamic values in the component rendering the chart.
 */
export const baseChartOptions = {
  plugins: {
    tableUnderChart: {
      display: true,
      drawLines: true,
      lineColor: '#E4E4E4',
      tableStartY: 30,
      datasets: [],
      format: (value: string) => value,
    },
    annotation: {
      annotations: {
        box1: {
          drawTime: 'afterDatasetsDraw',
          type: 'box',
          xMin: -1,
          xMax: 3.5,
          backgroundColor: '#F3F3F380',
          borderWidth: 0,
        },
      },
    },
    datalabels: {
      borderWidth: 0.5,
      color: 'white',
      anchor: 'center',
      align: 'center',
      offset: 0,
      formatter: (value: string, context: Context) => value,
      font: {
        size: 12,
      },
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      boxPadding: 2,
      padding: 12,
      bodyFont: {
        weight: 500,
      },
      callbacks: {
        title: (context: TooltipItem<'bar'>[]) => {
          return context[0].label
        },
        label: (context: TooltipItem<'bar'>) => {
          let label = context.dataset.label || ''
          if (label) {
            label += ': '
          }
          if (context.dataset.tooltip) {
            label += context.dataset.tooltip[context.dataIndex]
          } else if (context.dataset.data[context.dataIndex]) {
            // Try to at least render the value if we don't have a tooltip
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            label += `${context.dataset.data[context.dataIndex]}`
          }
          return label
        },
      },
      itemSort: (a: { datasetIndex: number }, b: { datasetIndex: number }) => a.datasetIndex - b.datasetIndex,
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      bottom: 100, // Adjust this value based on the need height of the table; number of rows and their height
      right: 1, // Dashed line space
      top: 23, // Banner height
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  resizeDelay: 5,
  scales: {
    x: {
      alignToPixels: true,
      grid: {
        display: false,
      },
      stacked: true,
      ticks: {
        color: '#717171',
      },
    },
    y: {
      alignToPixels: true,
      // This allows for space so the bars do not overlap the Forecast flag
      grace: 4,
      grid: {
        color: (ctx: ScriptableScaleContext) => (ctx.tick.value === 0 ? '#E6E6E6' : 'transparent'),
        lineWidth: 2,
      },
      stacked: true,
      ticks: {
        color: '#717171',
      },
      title: {
        color: '#717171',
        display: true,
        text: 'ARR ($M)',
      },
    },
  },
}
