import { ImpactEnum } from 'models/insights'
import type { TenantInMotionReporting } from 'models/observability.model'
import type { AccountList, GoalMetrics, MachineLearningMetrics } from 'models/reporting.model'
import { AccountListKeyEnum, AccountListTypeEnum, ChartLegendEnum, CompositeUnitEnum } from 'models/reporting.model'

export const goalMetricData: GoalMetrics = {
  labels: ['Sep 02', '08', '15', '22', '29', 'Oct 06', '13', '20', '27', 'Nov 03'],
  data: [
    {
      label: ChartLegendEnum.Average,
      data: [100, 140, 150, 130, 160, 170, 90, 300, 30, 100],
    },
    {
      label: ChartLegendEnum.FirstQuartile,
      data: [
        [100, 120],
        [140, 150],
        [150, 170],
        [130, 150],
        [160, 180],
        [170, 190],
        [90, 110],
        [300, 280],
        [20, 30],
        [100, 120],
      ],
    },
    {
      label: ChartLegendEnum.SecondQuartile,
      data: [
        [120, 150],
        [150, 170],
        [170, 190],
        [150, 170],
        [180, 200],
        [190, 210],
        [110, 130],
        [280, 300],
        [30, 50],
        [120, 140],
      ],
    },
    {
      label: ChartLegendEnum.ThirdQuartile,
      data: [
        [150, 170],
        [170, 190],
        [190, 210],
        [170, 190],
        [200, 220],
        [210, 230],
        [130, 150],
        [300, 320],
        [50, 70],
        [140, 160],
      ],
    },
    {
      label: ChartLegendEnum.FourthQuartile,
      data: [
        [170, 200],
        [190, 210],
        [210, 230],
        [190, 210],
        [220, 240],
        [230, 250],
        [150, 170],
        [320, 340],
        [70, 90],
        [160, 180],
      ],
    },
  ],
  title: {
    yTitle: 'Churn Probability',
    chartTitle: 'Account distribution for product stay duration',
  },
  annotation: {
    limit: 325,
    label: 325,
    tooltip: 'This is the limit',
  },
  visualization: 'quartile',
}

export const machineLearningMetricData: MachineLearningMetrics = {
  labels: ['Sep 01', '08', '15', '22', '29', 'Oct 06', '13', '20', '27'],
  data: [100, 140, 150, 130, 160, 170, 90, 300, 10],
  title: 'Churn Probability',
}

export const accountListMock = {
  columns: [
    {
      title: 'Account',
      key: AccountListKeyEnum.Account,
      type: AccountListTypeEnum.String,
    },
    {
      title: 'Users',
      key: AccountListKeyEnum.TotalUsers,
      type: AccountListTypeEnum.Number,
    },
    {
      title: 'CS Manager',
      key: AccountListKeyEnum.CsManager,
      type: AccountListTypeEnum.String,
    },
    {
      title: 'Participated Since',
      key: AccountListKeyEnum.ParticipatedSince,
      type: AccountListTypeEnum.String,
    },
    {
      title: 'Account distribution for product stay duration',
      key: AccountListKeyEnum.ProductStayDuration,
      type: AccountListTypeEnum.Composite,
    },
    {
      title: 'Account distribution for # of service tickets',
      key: AccountListKeyEnum.ServiceTickets,
      type: AccountListTypeEnum.Composite,
    },
    {
      title: 'Churn Probability',
      key: AccountListKeyEnum.ChurnProbability,
      type: AccountListTypeEnum.Composite,
    },
  ],
  rows: [
    {
      id: '0044587ac45fe7ee944da1e20d5bbc1c9caf58191955f87001c5e054',
      account: 'Openlane',
      totalUsers: 10,
      csManager: 'John Doe',
      participatedSince: '2020-01-01',
      productStayDuration: {
        old: 95,
        new: 152,
        unit: CompositeUnitEnum.Months,
        impact: ImpactEnum.Positive,
      },
      serviceTickets: { old: 51, new: 51, impact: ImpactEnum.Negative },
      churnProbability: {
        old: 71.9,
        new: 60.2,
        unit: CompositeUnitEnum.Percentage,
        impact: ImpactEnum.Negative,
      },
    },
  ],
}

export const accountList = (): AccountList => {
  const items = Array.from({ length: 10 }).map((_, index) => {
    const row = { ...accountListMock.rows[0] }
    row.id = `${row.id}${index}`
    row.totalUsers += index
    row.account += index
    row.productStayDuration = { ...row.productStayDuration, new: row.productStayDuration.new + index }
    return row
  })
  return {
    columns: accountListMock.columns,
    data: [
      {
        page: 1,
        items,
      },
    ],
    total: 20,
  }
}

export const tenantInMotionReportingMock: TenantInMotionReporting = {
  actions: [
    {
      marketo_send_email: {
        statistics: {
          FAILED: 25,
          SUCCEEDED: 100,
        },
      },
    },
    {
      gainsight_create_cta: {
        statistics: {
          FAILED: 6,
          SUCCEEDED: 10,
        },
      },
    },
    {
      salesforce_update_opportunity: {
        statistics: {
          FAILED: 1,
          SUCCEEDED: 0,
        },
      },
    },
    {
      salesforce_create_opportunity: {
        statistics: {
          FAILED: 25,
          SUCCEEDED: 100,
        },
      },
    },
  ],
  executionId: '176b8b04-8fcb-43d1-92dc-3a3f6db0b5cf',
  journey: {
    users: 1758,
    accounts: 5169,
  },
}
