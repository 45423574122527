import { IconChevronRight } from 'components/common/Icons/Icons'

interface ArrowProps {
  currentSlide?: number
  slideCount?: number
}

const Arrow = ({ currentSlide, slideCount, ...props }: ArrowProps) => {
  return <IconChevronRight {...props} />
}
Arrow.displayName = 'Arrow'

export default Arrow
