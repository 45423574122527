import { Heading } from 'components/common'

interface EmptyStateProps {
  title: string
  message: string
}

const EmptyState = ({ title, message }: EmptyStateProps) => {
  return (
    <div className='account-details__content__empty-state' data-testid='account-details__content__empty-state'>
      <Heading variant='2' level='2'>
        {title}
      </Heading>

      <div className='account-details__content__empty-state__wrapper'>
        <p data-testid='account-details__content__empty-state__text'>{message}</p>
      </div>
    </div>
  )
}

export default EmptyState
