import { Skeleton } from 'antd'
import dayjs from 'dayjs'

import EmptyStateImage from 'assets/images/motion-list-observability-empty-graph.svg?react'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import HorizontalStackedChart from 'components/MotionObservability/components/HorizontalStackedChart'
import StatsSummary from 'components/MotionObservability/components/StatsSummary'
import VolumeChart from 'components/MotionObservability/components/VolumeChart'
import { getPercentage } from 'components/MotionObservability/utils'

import { DataFormatEnum } from 'models/insights'
import type { TenantMotionTotals } from 'models/observability.model'

interface CompletedUsersCardProps {
  tenantMotionRangeTotals: TenantMotionTotals | null
  isLoading: boolean
}

const CompletedUsersCard = ({ tenantMotionRangeTotals, isLoading }: CompletedUsersCardProps) => {
  if (isLoading) {
    return (
      <div className='motion-observability__card' data-testid='completed-users-card-loading'>
        <Skeleton active />
      </div>
    )
  }

  const particpiantTotals = tenantMotionRangeTotals?.tenantTotals?.participantTotals
  const totalParticipants: number = Object.values(particpiantTotals || {}).reduce(
    (acc: number, val: number) => acc + val,
    0,
  ) as number
  const participantsByMotionStateData = tenantMotionRangeTotals?.tenantTotals?.participantTotals
    ? {
        completed: {
          total: tenantMotionRangeTotals?.tenantTotals?.participantTotals.COMPLETED,
          percentageTotal: getPercentage(
            tenantMotionRangeTotals?.tenantTotals?.participantTotals.COMPLETED,
            totalParticipants,
          ),
        },
        active: {
          total: tenantMotionRangeTotals?.tenantTotals?.participantTotals.ACTIVE,
          percentageTotal: getPercentage(
            tenantMotionRangeTotals?.tenantTotals?.participantTotals.ACTIVE,
            totalParticipants,
          ),
        },
        entered: {
          total: tenantMotionRangeTotals?.tenantTotals?.participantTotals.ENTERED,
          percentageTotal: getPercentage(
            tenantMotionRangeTotals?.tenantTotals?.participantTotals.ENTERED,
            totalParticipants,
          ),
        },
      }
    : {}

  const hasAnyMotionRun = tenantMotionRangeTotals ? tenantMotionRangeTotals?.topJourneys?.length > 0 : false
  const chartData =
    tenantMotionRangeTotals?.tenantTotals?.graphDataPoints?.weekly.map((point) => point.total).reverse() || []
  const labels =
    tenantMotionRangeTotals?.tenantTotals?.graphDataPoints?.weekly.map(({ date }) => {
      return dayjs(date).format('MMM DD')
    }) || []

  // Attempt to calculate the average duration in days.
  // From the provided JSON, we can see the number of completed Motions
  // but we don't have explicit start and end dates for each Motion to calculate the exact duration.
  // If we assume the "daily" entries represent the completion data & each entry is the completion count for that day,
  // you could approximate the average by using these dates and totals.
  const totalCompleted =
    tenantMotionRangeTotals?.tenantTotals?.graphDataPoints?.daily?.reduce((acc, { total }) => acc + total, 0) || 0
  // Find the earliest and latest dates
  const dates = tenantMotionRangeTotals?.tenantTotals?.graphDataPoints?.daily?.map(({ date }) => date) || []
  const startDate = Math.min(...dates)
  const endDate = Math.max(...dates)
  // Calculate the total duration in days
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24)
  // Calculate the average days to complete a Motion
  const averageDays = totalDays / totalCompleted

  return (
    <div className='motion-observability__card' data-testid='completed-users-card'>
      <div className='motion-observability__card__content'>
        <HorizontalStackedChart
          title='Participants by Motion State'
          items={participantsByMotionStateData}
          hasAnyData={hasAnyMotionRun}
        />
        <StatsSummary
          summaryItems={[
            {
              title: 'Average Days to Complete Motion',
              value: averageDays,
            },
            {
              title: 'Total Motion Participants',
              value: totalParticipants,
            },
          ]}
          hasAnyActionsRun={hasAnyMotionRun}
        />
        <div className='motion-observability__card__content__stat-total'>
          <h5>Completed Participants</h5>
          {hasAnyMotionRun ? (
            <h5>
              {formatNumber({
                number: tenantMotionRangeTotals?.tenantTotals?.participantTotals.COMPLETED ?? 0,
                format: DataFormatEnum.Kmb,
                decimal: 1,
              })}
            </h5>
          ) : (
            <div className='motion-observability__card__value--empty' />
          )}
        </div>
        {hasAnyMotionRun ? <VolumeChart title='Users' labels={labels} chartData={chartData} /> : <EmptyStateImage />}
      </div>
    </div>
  )
}
CompletedUsersCard.displayName = 'CompletedUsersCard'

export default CompletedUsersCard
