import { Button as AntBtn, Dropdown } from 'antd'
import dayjs from 'dayjs'

import { IconEmailTemplateListDropdown } from 'components/EmailTemplates/icons'

import type { MenuProps } from 'antd'

import type { EmailTemplate } from 'models/email-templates.model'

interface TemplateCardProps extends EmailTemplate {
  selected: boolean
  handleEditClick: (event: any) => void
  handleViewClick: (event: any) => void
  handleCloneClick: (magnifyTemplateId: string, magnifyTemplateVersion: number, templateName: string) => void
  handleArchiveClick: (magnifyTemplateId: string) => void
}

const TemplateCard = ({
  magnifyTemplateId,
  magnifyVersion,
  name,
  userEmail,
  selected,
  updatedAt,
  handleEditClick,
  handleCloneClick,
  handleViewClick,
  handleArchiveClick,
  thumbnailUrl,
}: TemplateCardProps) => {
  const items: MenuProps['items'] = [
    {
      label: 'Edit',
      key: 'edit-template',
      className: 'dropdown__item',
      onClick: handleEditClick,
    },
    {
      label: 'Save as New Email',
      key: 'clone-template',
      className: 'dropdown__item',
      onClick: () => handleCloneClick(magnifyTemplateId, magnifyVersion, name),
    },
    {
      label: 'Delete',
      key: 'delete-template',
      className: 'dropdown__item',
      onClick: () => handleArchiveClick(magnifyTemplateId),
    },
  ]

  return (
    <div key={magnifyTemplateId} className={`template-card ${selected ? 'selected' : 'unselected'}`}>
      <img
        src={thumbnailUrl || "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E"}
        alt={name}
        title={name}
        className='preview-image'
        loading='lazy'
        onClick={handleViewClick}
      />
      <div className='metadata'>
        <div className='heading-wrapper'>
          <span onClick={handleViewClick}>
            <h2 title={name}>{name}</h2>
          </span>
          <div>
            <Dropdown
              overlayClassName='motions-table-row-actions'
              menu={{ items }}
              placement='bottomRight'
              trigger={['click']}>
              <AntBtn data-testid='template-card-actions-button' className='motions-table-row-actions-button'>
                <IconEmailTemplateListDropdown />
              </AntBtn>
            </Dropdown>
          </div>
        </div>
        <div onClick={handleViewClick}>
          <p className='last-edited'>Last Edited: {dayjs(updatedAt).format('MM/DD/YY (HH:mm)')}</p>
          <p className='author'>Updated by: {userEmail ?? ''}</p>
        </div>
      </div>
    </div>
  )
}
TemplateCard.displayName = 'TemplateCard'

export default TemplateCard
