import { useMemo } from 'react'

import type { CreateActionFields, Item } from 'models/motion/motionBuilder.model'
import { InputOperatorEnum } from 'models/motion/motionBuilder.model'

const useInputType = (item: Item | CreateActionFields) => {
  const isMultipleInput = useMemo(() => getMultipleStatus(item), [(item as Item).operator])
  const isNullishInput = useMemo(() => getNullishStatus(item), [(item as Item).operator])

  return {
    isMultipleInput,
    isNullishInput,
  }
}
/**
 * Function that return if an item is a multi-value field
 * @param item The item we want to check if it is multi-value
 * @returns True if the is a multi-value field, false if is not
 */
export const getMultipleStatus = (item: Item | CreateActionFields) =>
  [
    InputOperatorEnum.AnyOf,
    InputOperatorEnum.NoneOf,
    InputOperatorEnum.ContainsOneOf,
    InputOperatorEnum.NotContainsAnyOf,
  ].some((operator) => (item as Item)?.operator === operator)

export const getNullishStatus = (item: Item | CreateActionFields) =>
  [InputOperatorEnum.IsNull, InputOperatorEnum.IsNotNull].some((operator) => (item as Item)?.operator === operator)

export default useInputType
