import { Form, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { DropdownArrowDown } from 'components/common'
import { SegmentBuilderContext, type SegmentBuilderStore } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

// TODO:Revert these provisory changes
// MAGPROD-951 & MAGPROD-2001

/**
 * This component is provisory, just for DEMO porpuses where we want to display in some cases future UI implementations
 * @returns - one of the views specifc for mocked platforms/objects
 */
const ProvisoryMock = observer(() => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { motionStore } = useStore()

  if (segmentBuilderData.platform === 'pendo') {
    return (
      <div className='action-inputs mocked' data-testid='action-inputs'>
        <Form.Item colon={false} label={'Guide'} initialValue={'Guide'}>
          <Select
            size='large'
            className='criteria-input_select_right'
            popupClassName='group-container__item-container__item__select-input-range__dropdown'
            maxTagCount='responsive'
            suffixIcon={<DropdownArrowDown />}
            maxTagPlaceholder={(e) => {
              return `+${e.length}`
            }}
            defaultValue={'First Time User'}
            options={[{ value: 'First Time User', label: 'First Time User' }]}
            disabled={motionStore.isSegmentBuilderEditDisabled}></Select>
        </Form.Item>
      </div>
    )
  }

  return null
})
ProvisoryMock.displayName = 'ProvisoryMock'

export default ProvisoryMock
