import { Carousel, Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import MotionCard from 'components/ActiveMotions/components/MotionCard'
import { Button, IconChevronRight } from 'components/common'
import Arrow from 'components/common/Arrow'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { Schedule } from 'models/motion.model'

const isMultipleRun = (schedule?: Schedule) => {
  if (!schedule) {
    return false
  }

  return schedule.executionCadence === 'multiple'
}

const ActiveMotions = observer(() => {
  const { motionStore } = useStore()
  const { isLoading, getAll, dashboardActiveMotions, motions } = motionStore
  const navigate = useNavigate()
  const redirectToMotions = useCallback(() => navigate('/motions'), [navigate])

  const canDisplay = !isLoading && dashboardActiveMotions.length
  const showAllMotionBtn = motions.data.length > dashboardActiveMotions.length
  useEffect(() => {
    getAll().catch(console.error)
  }, [])

  useDisplayErrorNotification(motionStore)

  // TODO: Update to actually pull integrations from Motion

  if (isLoading) {
    return <Skeleton className='active-motions-skeleton-container' />
  }

  return (
    <div>
      <h1 data-testid='active-motions-heading' className='active-motions-heading'>
        Active Motions
      </h1>
      {!isLoading && !dashboardActiveMotions.length && <p>There are no active Motions</p>}

      <div className='carousel'>
        <Carousel
          arrows
          adaptiveHeight={true}
          variableWidth={true}
          slidesToShow={1}
          swipeToSlide={true}
          dots={false}
          infinite={false}
          nextArrow={<Arrow />}
          prevArrow={<Arrow />}>
          {canDisplay &&
            dashboardActiveMotions.map((activeMotion) => (
              <div className='carousel__item' key={activeMotion.playbookId}>
                <MotionCard
                  version={activeMotion.version}
                  journeyId={activeMotion.playbookId}
                  name={activeMotion.title}
                  updatedAt={activeMotion.lastUpdatedAt}
                  integrations={activeMotion.integrations}
                  state={activeMotion.currState}
                  stateHistory={activeMotion.stateHistory}
                  segmentData={activeMotion.segmentTotals}
                  multipleRuns={isMultipleRun(activeMotion.schedule as unknown as Schedule)}
                />
              </div>
            ))}

          {showAllMotionBtn && (
            <div className='carousel__item btn__container'>
              <Button
                text='All Motions'
                icon={{ element: <IconChevronRight />, position: 'right' }}
                link
                onClickHandler={redirectToMotions}></Button>
            </div>
          )}
        </Carousel>
      </div>
    </div>
  )
})
ActiveMotions.displayName = 'ActiveMotions'

export default ActiveMotions
