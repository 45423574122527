import { useState } from 'react'

interface TabsProps {
  TabContext: React.Context<{
    activeTab: number
    setTab: (tabId: number) => void
  }>
  children: React.ReactNode
}

const Tabs = ({ TabContext, children }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(1)

  const setTab = (tab: number) => {
    setActiveTab(tab)
  }

  return (
    <TabContext.Provider value={{ activeTab, setTab }}>
      <div className=''>{children}</div>
    </TabContext.Provider>
  )
}

export default Tabs
