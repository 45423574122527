import { Form, Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'

import { aggregationsDataUtils, removeAggregations } from 'components/common/AggregationsData/index.utils'
import BreadcrumbTag from 'components/common/BreadcrumbTag'
import Button from 'components/common/Button'
import { IconClose } from 'components/common/Icons/Icons'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { ConfigPanelPayload, CurrentUsedAggregation } from 'models/motion/motionBuilder.model'

interface RemoveAggregationModalProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const RemoveAggregationModal = observer(({ payload, setPayload }: RemoveAggregationModalProps) => {
  const { elements, setElements } = useContext<SegmentBuilderStore>(SegmentBuilderContext)

  const { aggregationsDataStore } = useStore()
  const {
    aggregationsList,
    currentUuid,
    currentUsedAggregations,
    displayRemoveModal,
    setDisplayRemoveModal,
    setCurrentUuid,
    setCurrentUsedAggregations,
  } = aggregationsDataStore

  const currentAggregation = aggregationsList.find((aggregation) => aggregation.aggregationUuid === currentUuid)

  const handleClose = () => {
    setDisplayRemoveModal(false)
    setCurrentUuid(null)
    setCurrentUsedAggregations(null)
  }

  const handleSubmit = () => {
    if (aggregationsDataStore.currentUuid) {
      const { elementsCopy, payloadCopy } = removeAggregations({
        elements,
        payload: { ...payload },
        aggregations: aggregationsDataStore.currentUsedAggregations,
      })

      setPayload({ ...payloadCopy })
      setElements([...elementsCopy])

      aggregationsDataStore.removeItemFromAggregationsList(aggregationsDataStore.currentUuid)
      handleClose()
    }
  }

  const getUsedAggregations = () => {
    const data: CurrentUsedAggregation[] = []

    currentUsedAggregations.forEach((aggregation) => {
      const existingData = data.find(
        (element) => element.nodeId === aggregation.nodeId || element.nodeName === 'Current node',
      )
      if (!existingData && !aggregation.hasSamePayload) {
        data.push(aggregation)
      }
    })

    return data
  }

  return (
    <Modal
      open={displayRemoveModal}
      keyboard
      width={898}
      maskClosable={false}
      className='modal remove-aggregations-data-modal'
      onCancel={handleClose}
      closeIcon={<IconClose className='btn__close' data-testid='modal-close' />}
      title={
        <>
          {aggregationsDataUtils.removeModalTitleBeforeTag}
          <BreadcrumbTag breadcrumb={{ field: currentAggregation?.name || '' }} />
          {aggregationsDataUtils.removeModalTitleAfterTag}
        </>
      }
      footer={[
        <React.Fragment key='modal__footer'>
          <Button text='Cancel' type='secondary' size='XL' onClickHandler={handleClose} testId='cancel-btn' />

          <Button text='Continue' size='XL' className='m-l-10' onClickHandler={handleSubmit} testId='continue-btn' />
        </React.Fragment>,
      ]}>
      <Form className='modal__body' data-testid='aggregations-data__remove-modal-body'>
        {aggregationsDataUtils.removeBodyContent}
        <ul>
          {getUsedAggregations().map((element) => {
            return (
              <li key={crypto.randomUUID()}>
                <div>
                  <strong>Node: </strong>
                  {element.nodeName}
                </div>
                {element.nodeDescription && (
                  <div>
                    <strong>Description: </strong>
                    {element.nodeDescription}
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </Form>
    </Modal>
  )
})
RemoveAggregationModal.displayName = 'RemoveAggregationModal'

export default RemoveAggregationModal
