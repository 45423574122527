abstract class Demo {
  private static PROD_DEMO_MOCK_API_ENABLED_KEY = 'PROD_DEMO_ENABLED'

  public static enableMockApi() {
    localStorage.setItem(Demo.PROD_DEMO_MOCK_API_ENABLED_KEY, 'true')
  }

  public static disableMockApi() {
    localStorage.removeItem(Demo.PROD_DEMO_MOCK_API_ENABLED_KEY)
  }

  public static mockApiIsEnabled(): boolean {
    return localStorage.getItem(Demo.PROD_DEMO_MOCK_API_ENABLED_KEY) === 'true'
  }
}

export default Demo
