import axios from 'axios'

import * as actions from 'api/actions.api'
import * as admin from 'api/admin.api'
import * as emailTemplates from 'api/emailTemplates.api'
import * as fileAttachments from 'api/fileAttachments.api'
import * as insights from 'api/insights.api'
import * as integrations from 'api/integrations.api'
import * as metadata from 'api/metadata.api'
import * as motions from 'api/motions.api'
import * as notifications from 'api/notifications.api'
import * as observability from 'api/observability.api'
import * as reporting from 'api/reporting.api'
import * as slackMessages from 'api/slackMessages.api'
import * as support from 'api/support.api'
import * as users from 'api/users.api'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'

import type { AxiosRequestConfig, AxiosResponse } from 'axios'

export interface DemoAxiosRequestConfig extends AxiosRequestConfig {
  /** Demo data to use for the request */
  demoData?: object
  /** Whether to use demo data for the request when in Sandbox. */
  sandboxUseDemoData?: boolean
}

const axiosInstance = axios.create()

/**
 * Demo adapter for all requests but only short circuit when the Mock API is enabled,
 * or if we are in a Sandbox environment for marked requests.
 * If we do not do this for all requests, the adapter may not be setup correctly after logout.
 *
 * @param {DemoAxiosRequestConfig} config Axios request config
 * @returns {Promise<AxiosResponse<any, any>>} Axios response
 */
export const adapter = async (config: DemoAxiosRequestConfig) => {
  if ((Demo.mockApiIsEnabled() || (Sandbox.isEnabled() && config.sandboxUseDemoData)) && config.demoData) {
    return Promise.resolve({
      data: config.demoData,
      status: 200,
      statusText: 'OK',
      headers: config.headers,
      config,
      request: {},
    } as AxiosResponse<typeof config.demoData>)
  } else {
    if (Demo.mockApiIsEnabled()) {
      console.warn('Request in demo environment not caught!', config.url)
    }
    return axiosInstance.request({
      ...config,
      adapter: axiosInstance.defaults.adapter,
    })
  }
}
axios.defaults.adapter = adapter

const coreApiServiceIntegration = {
  actions,
  admin,
  emailTemplates,
  fileAttachments,
  insights,
  integrations,
  motions,
  metadata,
  notifications,
  observability,
  reporting,
  slackMessages,
  support,
  users,
}

export const API = coreApiServiceIntegration
