import { Row, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

import { DynamicCell, Heading, Search } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import InsightsDetailsFooter from 'components/Insights/components/InsightsDetails/components/InsightsDetailsFooter'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'
import useStore from 'store/useStore'

import type { ColumnProps, ColumnsType } from 'antd/lib/table'
import type { SortOrder, TableRowSelection } from 'antd/lib/table/interface'

import type { AccountRowValue, Impact, TableColumn } from 'models/insights'
import { DriverSegmentEnum, TableColumnTypeEnum } from 'models/insights'
import type { AccountListRow } from 'models/reporting.model'

interface SegmentAccountTableProps {
  filterBy?: Array<DriverSegmentEnum>
  isCustomSegmentSelected: boolean
}

/**
 * Generate Ant Design table columns from data.
 * @see https://ant.design/components/table/#column
 */
function getTableColumns(
  data: TableColumn[],
  impact: Impact,
  defaultSortKey: string = '',
  defaultSortDirection: SortOrder = 'descend',
): ColumnsType<any> {
  return data.map((column) => {
    const output: ColumnProps<any> = {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      render: (_: any, record: AccountListRow) => (
        <div className='table__row'>
          <DynamicCell column={column} record={record} impact={impact} />
        </div>
      ),
      sorter: (a: AccountRowValue, b: AccountRowValue) => {
        const valueA = (a[column.key] as { value: string | number }).value
        const valueB = (b[column.key] as { value: string | number }).value
        switch (column.type) {
          case TableColumnTypeEnum.String:
          case TableColumnTypeEnum.Str:
          case TableColumnTypeEnum.Prediction:
            return sortAlphabetically(valueA as string, valueB as string)
          case TableColumnTypeEnum.Number:
          case TableColumnTypeEnum.Int:
          case TableColumnTypeEnum.Float:
          case TableColumnTypeEnum.Percent:
            return sorterNumbers(valueA as number, valueB as number)
          default:
            return sorterNumbers(a[column.key] as number, b[column.key] as number)
        }
      },
    }
    if (column.key === defaultSortKey) {
      output.defaultSortOrder = defaultSortDirection
    }
    return output
  })
}

const SegmentAccountTable = ({
  filterBy = [DriverSegmentEnum.High],
  isCustomSegmentSelected,
}: SegmentAccountTableProps) => {
  const { insightsStore } = useStore()
  const { accountData: defaultAccountData, customSegmentAccountData } = insightsStore
  const accountData = isCustomSegmentSelected ? customSegmentAccountData : defaultAccountData
  const customSegmentAccounts = customSegmentAccountData.tableRows

  const [searchInput, setSearchInput] = useState<string>('')

  const [unselectedRowsKey, setUnselectedRowsKey] = useState<React.Key[]>([])

  /** Table column names, keys, render function and sorter functions. */
  const accountsColumns = useMemo(
    () => getTableColumns(accountData.tableColumns, accountData.impact, 'revenue_elift_improve_by', 'descend'),

    [accountData.tableColumns],
  )

  /** Filtered accountData accounts based on the provided `filterBy` prop. */
  const filteredAccountsTable = useMemo(() => {
    if (isCustomSegmentSelected) {
      return accountData.tableRows
    }

    return accountData.tableRows.filter((row) => filterBy.includes(row.segment))
  }, [accountData, filterBy, customSegmentAccounts?.length])

  const [accountsTableSource, setAccountsTableSource] = useState<AccountRowValue[]>(filteredAccountsTable)

  const selectedAccountsKeys = useMemo(() => {
    return accountsTableSource.map((row) => row.account_id).filter((id) => !unselectedRowsKey.includes(id))
  }, [unselectedRowsKey, accountsTableSource])

  useEffect(() => {
    // set initial accounts rows
    setDefaultAccounts()
  }, [accountData.tableRows])

  useEffect(() => {
    // set initial accounts rows after filter changes
    setAccountsTableSource(filteredAccountsTable)
  }, [filterBy])

  useEffect(() => {
    const accounts = accountsTableSource.filter((account) => !unselectedRowsKey.includes(account.account_id))
    // set selected accounts
    if (filterBy.includes(DriverSegmentEnum.Custom)) {
      insightsStore.setSelectedCustomSegmentAccounts(accounts)
    }

    insightsStore.setSelectedAccounts(accounts)
  }, [selectedAccountsKeys])

  useEffect(() => {
    // update unselected rows taking into consideration source update
    const removedUnselectedKeys = unselectedRowsKey.filter(
      (rowId) => !filteredAccountsTable.map((row) => row.account_id as React.Key).includes(rowId),
    )

    if (removedUnselectedKeys.length) {
      const newUnselectedKeys = unselectedRowsKey.filter((key) => !removedUnselectedKeys.includes(key))

      setUnselectedRowsKey(newUnselectedKeys)
    }
  }, [filteredAccountsTable])

  useEffect(() => {
    if (unselectedRowsKey.length) {
      // reset custom segment selection
      setUnselectedRowsKey([])
      insightsStore.setSelectedCustomSegmentAccounts([])
    }
  }, [isCustomSegmentSelected])

  const setDefaultAccounts = () => {
    setAccountsTableSource(filteredAccountsTable)
  }

  const handleOnSearch = (value: string) => {
    if (!value.length) {
      return setDefaultAccounts()
    }

    const filteredAccountTable: AccountRowValue[] = filteredAccountsTable.filter((acc) =>
      `${acc.account_name.value}`.toLowerCase().includes(value.toLowerCase()),
    )
    setAccountsTableSource(filteredAccountTable)
  }

  const handleClose = () => {
    setDefaultAccounts()
    setSearchInput('')
  }

  const predictions = filterBy.join(', ')

  const handleCheckboxChangeFactory = (record: AccountRowValue) => {
    const selectedRecordIndex = unselectedRowsKey.indexOf(record.account_id)

    if (selectedRecordIndex > -1) {
      return setUnselectedRowsKey((prev) => prev.filter((id) => id !== record.account_id))
    } else {
      return setUnselectedRowsKey((prev) => [...prev, record.account_id])
    }
  }

  const onRowSelect = (record: AccountRowValue) => ({
    onClick: () => handleCheckboxChangeFactory(record),
  })

  const rowSelection: TableRowSelection<AccountRowValue> = {
    selectedRowKeys: selectedAccountsKeys,
    onSelectAll: (selected: boolean, selectedRows: AccountRowValue[], changeRows: AccountRowValue[]) => {
      const updatedIds: React.Key[] = changeRows.map((row: AccountRowValue) => row.account_id)

      if (selected) {
        return setUnselectedRowsKey((prev) => prev.filter((prevId) => !updatedIds.includes(prevId)))
      } else {
        return setUnselectedRowsKey((prev) => {
          return [...prev, ...updatedIds]
        })
      }
    },
    onSelect: (record) => {
      handleCheckboxChangeFactory(record)
    },
  }

  const selectedUsers = useMemo(() => {
    const selectedAccounts = accountsTableSource.filter((row) => !unselectedRowsKey.includes(row.account_id))

    return selectedAccounts.reduce((n, { user_count }) => {
      const value = parseFloat(user_count?.value as string)
      if (!isNaN(value)) {
        return n + value
      } else {
        return NaN
      }
    }, 0)
  }, [unselectedRowsKey, accountsTableSource])

  const totalUsers = useMemo(() => {
    return filteredAccountsTable.reduce((n, { user_count }) => {
      const value = parseFloat(user_count?.value as string)
      if (!isNaN(value)) {
        return n + value
      } else {
        return NaN
      }
    }, 0)
  }, [unselectedRowsKey, accountsTableSource])

  return (
    <div className='segment-accounts m-t-40' data-testid='segment-accounts'>
      <Row justify='space-between'>
        <div className='heeading-section'>
          <Heading level='3' variant='3'>
            {`Accounts in segment (${predictions})`}
          </Heading>

          <MockDataCallout />
        </div>

        <Search
          placeholder='Search...'
          onClose={handleClose}
          searchType={['onEnter', 'onType']}
          onFilter={handleOnSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </Row>

      <Table
        rowKey={'account_id'}
        bordered={false}
        dataSource={accountsTableSource}
        columns={accountsColumns}
        pagination={{ hideOnSinglePage: true }}
        showSorterTooltip={false}
        rowSelection={{ ...rowSelection }}
        className='account-table'
        data-testid='segment-account-table'
        onRow={onRowSelect}
        scroll={{ x: true }}
      />
      <InsightsDetailsFooter
        noSelectedAccounts={selectedAccountsKeys.length}
        noSelectedUsers={selectedUsers}
        totalAccounts={filteredAccountsTable.length}
        totalUsers={totalUsers}
        selectedSegments={filterBy}
      />
    </div>
  )
}
SegmentAccountTable.displayName = 'SegmentAccountTable'

export default SegmentAccountTable
