import Table from 'antd/lib/table'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { DynamicCell, Heading } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'

import { TableColumnTypeEnum } from 'models/insights'
import type { EmailStatistics } from 'models/reporting.model'

const EmailStatsTable = observer(({ data = [], loading = false }: { data: EmailStatistics[]; loading: boolean }) => {
  /**
   * Generate Ant Design table columns from data.
   * @see https://ant.design/components/table/#column
   */
  const columns = useMemo(() => {
    const data = [
      {
        title: 'Item',
        key: 'templateName',
        type: TableColumnTypeEnum.String,
      },
      {
        title: 'Sent',
        key: 'processed',
        type: TableColumnTypeEnum.Number,
      },
      {
        title: 'Delivered',
        key: 'deliveredPercentage',
        type: TableColumnTypeEnum.String,
      },
      {
        title: 'Opens',
        key: 'openPercentage',
        type: TableColumnTypeEnum.String,
      },
      {
        title: 'Clicks',
        key: 'clickPercentage',
        type: TableColumnTypeEnum.String,
      },
      {
        title: 'Bounces',
        key: 'bounce',
        type: TableColumnTypeEnum.String,
      },
      // Not provided in the data.
      // {
      //   title: 'Unsubscribes',
      //   key: 'unsubscribe',
      //   type: TableColumnTypeEnum.Number,
      // },
      {
        title: 'Spam Reports',
        key: 'spamreport',
        type: TableColumnTypeEnum.Number,
      },
    ]

    return data.map((column) => {
      return {
        title: column.title,
        dataIndex: column.key,
        key: column.key,
        render: (_: any, record: EmailStatistics) => <DynamicCell column={column} record={record} />,
        sorter: (a: EmailStatistics, b: EmailStatistics) => {
          const valueA = a[column.key] as string | number
          const valueB = b[column.key] as string | number
          switch (column.type) {
            case TableColumnTypeEnum.String: {
              return sortAlphabetically(valueA as string, valueB as string)
            }
            case TableColumnTypeEnum.Number: {
              return sorterNumbers(valueA as number, valueB as number)
            }
            default: {
              return sorterNumbers(valueA as number, valueB as number)
            }
          }
        },
      }
    })
  }, [])

  return (
    <section className='email-stats-table-container' id='emailStats' data-testid='email-stats-table-container'>
      <header className='email-stats-table__header'>
        <Heading variant='7' level='2'>
          Email Stats
        </Heading>
        <MockDataCallout />
      </header>
      <Table
        className='email-stats-table'
        data-testid='email-stats-table'
        loading={loading}
        rowKey='magnifyTemplateId'
        dataSource={data}
        columns={columns}
        pagination={false}
        showSorterTooltip={false}
      />
    </section>
  )
})
EmailStatsTable.displayName = 'EmailStatsTable'

export default EmailStatsTable
