import { useEffect, useState } from 'react'

import { dslToReactFlow } from 'services/Utils/dslConversion/dslToReactFlow/dslToReactFlow.utils'
import { optimizePositions } from 'services/Utils/optimizePositions/optimizer.utils'
import useStore from 'store/useStore'

import type { Elements, FlowElement } from 'react-flow-renderer'

import type { SegmentBuilderData } from 'models/motion/motionBuilder.model'
import type { Dsl } from 'models/motion.model'

/**
 * A custom react hook that provides state and functions for managing the Motion elements and the experiment elements.
 * Elements are the nodes and edges of a Motion.
 * @param {Function} onShowDrawer Takes a data object as an argument and shows the corresponding drawer component for the element.
 * @returns {Object} An object containing the state and functions for the Motion elements and the experiment elements.
 */
const useMotionElements = (onShowDrawer: (data: SegmentBuilderData) => void) => {
  const { aggregationsDataStore, motionStore } = useStore()

  const [elements, setElements] = useState<Elements>([])
  const [experimentElements, setExperimentElements] = useState<Elements>([])
  const [reset, setReset] = useState<boolean>(false)

  /**
   * Sets the elements state with the given elements array after optimizing their positions for the screen using the optimizePositions function.
   * @param {Elements} elements An array of elements representing the Motion flow.
   */
  const setElementsWithOptimizedPositions = (elements: Elements) => setElements(optimizePositions(elements))

  /**
   * Sets the experiment elements state with the given elements array after optimizing their positions for the screen using the optimizePositions function.
   * @param {Elements} experimentElements An array of elements representing the experiment flow.
   */
  const setExperimentElementsWithOptimizedPositions = (experimentElements: Elements) =>
    setExperimentElements(optimizePositions(experimentElements))

  /**
   * Updates the elements or the experiment elements state using the given DSL object by converting it to a react flow structure and adding some properties to each element.
   * @param {Dsl | undefined} dsl The DSL object representing the Motion or the experiment structure.
   * @param {boolean} isExperimentElements A boolean value indicating if the DSL object is for the experiment elements or not.
   */
  const updateElementsUsingDsl = (dsl: Dsl | undefined, isExperimentElements: boolean) => {
    if (dsl) {
      const reactFlowStructure = dslToReactFlow(dsl)
      if (!isExperimentElements) {
        aggregationsDataStore.setAggregationsList(dsl?.aggregations)
      }
      reactFlowStructure.forEach(
        (
          el: FlowElement<{
            onShowDrawer: (data: SegmentBuilderData) => void
            setElements: (elements: Elements) => void
            description?: string
            name: string
          }>,
        ) => {
          const element = { ...el }
          element.data!.onShowDrawer = onShowDrawer
          element.data!.setElements = isExperimentElements
            ? setExperimentElementsWithOptimizedPositions
            : setElementsWithOptimizedPositions
          element.data!.description = element.data?.description || element.data?.name
          return element
        },
      )
      if (isExperimentElements) {
        setExperimentElementsWithOptimizedPositions(reactFlowStructure)
      } else {
        setElementsWithOptimizedPositions(reactFlowStructure)
      }
    }
  }

  /**
   * Runs when the current Motion changes and updates the elements and the experiment elements (if it exists) state using their respective DSL objects.
   */
  useEffect(() => {
    updateElementsUsingDsl(motionStore.currentMotion?.dsl, false)
    if (motionStore.currentMotion?.experiment?.dsl) {
      updateElementsUsingDsl(motionStore.currentMotion?.experiment?.dsl, true)
    }
  }, [motionStore.currentMotion])

  return {
    elements,
    setElements,
    experimentElements,
    setExperimentElements,
    setElementsWithOptimizedPositions,
    setExperimentElementsWithOptimizedPositions,
    updateElementsUsingDsl,
    reset,
    setReset,
  }
}

export default useMotionElements
