import { Tabs } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { Heading } from 'components/common'
import {
  DataSourceSegmentSidebar,
  CalculatedDataSegmentSidebar,
} from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar'
import { IS_OVER_FREE_AREA_IN_DROPZONE } from 'components/MotionBuilder/Utils/motionBuilderConstants'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction, DragEvent } from 'react'

import type { DragEvents, Item, SourceData, InputOperatorEnum } from 'models/motion/motionBuilder.model'

interface DataSourcePanelSidebarProps {
  displayHeader?: boolean
  withCalculatedData?: boolean
  setDragData: Dispatch<SetStateAction<Item | undefined>>
  setDragEvents: Dispatch<SetStateAction<DragEvents>>
}

export const generateSidebar = ({
  headingTitle,
  showHeading,
  isCalculatedData,
  handleDragStart,
  handleDragEnd,
  displayHeader,
}: {
  headingTitle: string
  showHeading: boolean
  isCalculatedData?: boolean
  handleDragStart: (e: DragEvent<HTMLDivElement>, item: SourceData) => void
  handleDragEnd: () => void
  displayHeader: boolean
}) => (
  <Sider width={290} className='segment-builder__sider' data-testid='segment-builder-sider'>
    {showHeading && (
      <Heading level='2' variant='6'>
        {headingTitle}
      </Heading>
    )}
    {isCalculatedData ? (
      <CalculatedDataSegmentSidebar handleDragStart={handleDragStart} handleDragEnd={handleDragEnd} />
    ) : (
      <DataSourceSegmentSidebar
        displayHeader={displayHeader}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
      />
    )}
  </Sider>
)

const DataSourcePanelSidebar = observer(
  ({ displayHeader = true, withCalculatedData = false, setDragData, setDragEvents }: DataSourcePanelSidebarProps) => {
    const { aggregationsDataStore } = useStore()
    const { aggregationsList } = aggregationsDataStore

    const handleDragStart = (e: DragEvent<HTMLDivElement>, item: SourceData) => {
      const isValidsource =
        e.currentTarget?.className?.includes('menu__item') || e.currentTarget?.className?.includes('search__item')

      if (!isValidsource) {
        return
      }

      const data: Item = {
        platform: item.platform || '',
        object: item.object || '',
        type: item.type,
        field: item.name,
        key: item.key,
        operator: '' as InputOperatorEnum,
        value: '',
        isNewStatement: true,
        solutionInstanceId: item.solutionInstanceId,
        isDynamicInput: item.isDynamicInput ?? false,
        ...(item.aggregationUuid && { aggregationId: item.aggregationUuid, isAggregationCriteria: true }),
        ...(item.aggregationType && { aggregationType: item.aggregationType }),
        ...(item.aggregationLevel && { aggregationLevel: item.aggregationLevel }),
        ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
      }

      setDragEvents((drag) => ({ ...drag, isDragStarting: true }))
      setDragData(data)
    }

    const handleDragEnd = () => {
      setDragEvents((drag) => ({ ...drag, isDragStarting: false, dragOverGroup: IS_OVER_FREE_AREA_IN_DROPZONE }))
    }

    const items = [
      {
        label: 'Data Source',
        key: 'dataSource',
        children: generateSidebar({
          headingTitle: 'Data Source',
          showHeading: false,
          isCalculatedData: !withCalculatedData,
          handleDragStart,
          handleDragEnd,
          displayHeader,
        }),
      },
    ]

    if (withCalculatedData && !!aggregationsList.length) {
      items.push({
        label: 'Calculated Data',
        key: 'calculatedData',
        children: generateSidebar({
          headingTitle: 'Calculated Data',
          showHeading: false,
          isCalculatedData: withCalculatedData,
          handleDragStart,
          handleDragEnd,
          displayHeader,
        }),
      })
    }
    return (
      <Tabs
        items={items}
        tabBarGutter={0}
        centered={false}
        data-testid='sidebar-tabs'
        className={classNames('segment-builder__sidebar-tabs', {
          'default-tab': items.length === 1,
          'no-header': !displayHeader,
        })}
      />
    )
  },
)
DataSourcePanelSidebar.displayName = 'DataSourcePanelSidebar'
export default DataSourcePanelSidebar
