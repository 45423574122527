import colors from 'components/MotionDetails/GoalMetric/Chart/helpers/chart-colors.module.scss'
import { legend } from 'components/MotionDetails/GoalMetric/Chart/helpers/legend'
import { sharedChartOptions } from 'components/MotionDetails/share/chart'

import type { Align, ChartEvent } from 'chart.js'
import type { EventContext, PartialEventContext } from 'chartjs-plugin-annotation'
import type { Dispatch, SetStateAction } from 'react'

import type { TooltipPosition } from 'components/MotionDetails/GoalMetric/Chart/helpers/types'

export interface GetChartOptions {
  tooltipPosition: TooltipPosition | null
  setTooltipPosition: Dispatch<SetStateAction<TooltipPosition | null>>
  isChartRenderComplete: boolean
  setIsChartRenderComplete: Dispatch<SetStateAction<boolean>>
  yAnnotationAdjustment: number | null
  setYAnnotationAdjustment: Dispatch<SetStateAction<number | null>>
  showLegend: boolean
  yTitle: string
  chartTitle: string
  annotationLimit: number
  annotationLabel: string | number
  labels: string[]
}

export const getChartOptions = ({
  tooltipPosition,
  setTooltipPosition,
  isChartRenderComplete,
  setIsChartRenderComplete,
  yAnnotationAdjustment,
  setYAnnotationAdjustment,
  showLegend,
  yTitle,
  chartTitle,
  annotationLimit,
  annotationLabel,
  labels,
}: GetChartOptions) => ({
  ...sharedChartOptions,
  animation: {
    onComplete: () => {
      setIsChartRenderComplete(true)
    },
  },
  barThickness: 16,
  plugins: {
    datalabels: {
      display: false,
    },
    tableUnderChart: {
      display: false,
    },
    title: {
      display: true,
      text: chartTitle,
      position: 'top' as const,
      align: 'start' as Align,
      color: colors.title,
      font: {
        weight: 600,
        family: 'Suisse',
        size: 16,
      },
      padding: {
        bottom: 20,
      },
    },
    legend: showLegend ? legend : { display: false },
    annotation: {
      clip: false,
      annotations: {
        lineAnnotation: {
          type: 'line',
          yMin: annotationLimit,
          yMax: annotationLimit,
          borderColor: colors.annotation,
          borderWidth: 1,
          xMax: labels.length - 1,
          label: {
            display: true,
            content: annotationLabel,
            position: 'end',
            backgroundColor: colors.annotation,
            color: 'white',
            yAdjust: ({ element }: PartialEventContext) => {
              const canSetAdjustment =
                isChartRenderComplete &&
                yAnnotationAdjustment === null &&
                element &&
                'centerY' in element &&
                'label' in element &&
                'centerY' in element.label!
              if (canSetAdjustment) {
                const positionAdjustment = element.centerY! - element.label!.centerY
                setYAnnotationAdjustment(positionAdjustment)
                return positionAdjustment
              } else {
                return yAnnotationAdjustment !== null ? yAnnotationAdjustment : 0
              }
            },
          },
          enter: (context: EventContext, event: ChartEvent) => {
            if (context.element.label && event.x && event.y) {
              const {
                x: labelStartPositionOnX,
                x2: labelEndPositionOnX,
                y: labelStartPositionOnY,
                y2: labelEndPositionOnY,
                centerX: labelCenterOnX,
                centerY: labelCenterOnY,
              } = context.element?.label

              const { x: cursorXPosition, y: cursorYPosition } = event
              const isCursorOnXAxis = cursorXPosition >= labelStartPositionOnX && cursorXPosition <= labelEndPositionOnX
              const isCursorOnYAxis = cursorYPosition >= labelStartPositionOnY && cursorYPosition <= labelEndPositionOnY
              setTooltipPosition(isCursorOnXAxis && isCursorOnYAxis ? { x: labelCenterOnX, y: labelCenterOnY } : null)
            } else {
              if (tooltipPosition) {
                setTooltipPosition(null)
              }
            }
          },
          leave: () => {
            if (tooltipPosition) {
              setTooltipPosition(null)
            }
          },
        },
      },
    },
  },
  scales: {
    y: {
      ...sharedChartOptions.scales.y,
      beginAtZero: false,
      stacked: false,
      ticks: {
        ...sharedChartOptions.scales.y.ticks,
        color: colors.ticks,
      },
      title: {
        ...sharedChartOptions.scales.y.title,
        text: yTitle,
      },
    },
    x: {
      ...sharedChartOptions.scales.x,
      stacked: true,
      ticks: {
        ...sharedChartOptions.scales.x.ticks,
        color: colors.ticks,
      },
    },
  },
})
