import { Layout } from 'antd'
import { useContext, type Dispatch, type SetStateAction } from 'react'

import { InputFields } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/index'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'

import type { ConfigPanelPayload } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum } from 'models/motion/motionBuilder.model'

interface CreatePanelProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const CreatePanel = ({ payload, setPayload }: CreatePanelProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  return (
    <Layout className='action-container'>
      <div className='list'>
        <InputFields
          actionType={
            segmentBuilderData.actionType === ActionTypeEnum.UpdateAccount
              ? ActionTypeEnum.Update
              : ActionTypeEnum.Create
          }
          payload={payload}
          setPayload={setPayload}
        />
      </div>
    </Layout>
  )
}
CreatePanel.displayName = 'CreatePanel'

export default CreatePanel
