import { Col, InputNumber } from 'antd'
import classnames from 'classnames'
import { useRef, useState } from 'react'

import Button from 'components/common/Button'
import {
  getAccountsInDriverRange,
  getSourceValueBasedOnBoundaries,
} from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { AccountRowValue, DriverSegmentEnum, SegmentV3 } from 'models/insights'

interface SegmentRange {
  min: undefined | number
  max: undefined | number
}

interface CustomSegmentProps {
  isSelected: boolean
  segmentTitle: DriverSegmentEnum
  segmentValues: SegmentV3
  allAccountsRows: AccountRowValue[]
  segmentRange: SegmentRange
  selectedAccountsRows: AccountRowValue[]
  onClick: (title: DriverSegmentEnum) => void
  setSegmentRange: Dispatch<SetStateAction<SegmentRange>>
}

const CustomSegment = ({
  isSelected = true,
  segmentTitle,
  segmentValues,
  allAccountsRows,
  segmentRange,
  selectedAccountsRows,
  onClick,
  setSegmentRange,
}: CustomSegmentProps) => {
  const inputMinRef = useRef<HTMLInputElement>(null)
  const inputMaxRef = useRef<HTMLInputElement>(null)
  const { insightsStore } = useStore()

  const [showOutput, setShowOutput] = useState<boolean>(false)

  const handleSetCustomSegment = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    setShowOutput(true)

    const range = {
      min: parseFloat(inputMinRef.current?.value ?? ''),
      max: parseFloat(inputMaxRef.current?.value ?? ''),
    }

    const accountsInRange = getAccountsInDriverRange({
      source: {
        title: segmentValues.num_accounts.title,
        column: segmentValues.num_accounts.column,
        aggregator: segmentValues.num_accounts.aggregator,
        format: segmentValues.num_accounts.format,
        decimal: segmentValues.num_accounts.decimal,
        driverColumn: segmentValues.driver.column,
        min: range.min,
        max: range.max,
      },
      tableRows: allAccountsRows,
    })

    setSegmentRange(range)
    insightsStore.setCustomSegmentAccounts(accountsInRange)
  }

  const stopPropagation = (
    event: React.FocusEvent<HTMLInputElement, Element> | React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    event.stopPropagation()
  }

  const tableRows = selectedAccountsRows.length ? selectedAccountsRows : allAccountsRows

  return (
    <div
      className={classnames('custom-segment', { selected: isSelected })}
      onClick={() => onClick(segmentTitle)}
      data-testid='custom-segment'>
      <div className='custom-segment__heading'>
        <span className='status-circle'></span>
        <span className='title' data-testid='custom-segment-title'>
          CUSTOM SEGMENT
        </span>

        {showOutput && (
          <div className='stats'>
            {`${getSourceValueBasedOnBoundaries({
              source: {
                title: segmentValues.num_accounts.title,
                column: segmentValues.num_accounts.column,
                aggregator: segmentValues.num_accounts.aggregator,
                format: segmentValues.num_accounts.format,
                decimal: segmentValues.num_accounts.decimal,
                driverColumn: segmentValues.driver.column,
                min: segmentRange.min,
                max: segmentRange.max,
              },
              tableRows: tableRows,
            })} Accounts | ${getSourceValueBasedOnBoundaries({
              source: {
                title: segmentValues.revenue_elift_improve_from.title,
                column: segmentValues.revenue_elift_improve_from.column,
                aggregator: segmentValues.revenue_elift_improve_from.aggregator,
                format: segmentValues.revenue_elift_improve_from.format,
                decimal: segmentValues.revenue_elift_improve_from.decimal,
                driverColumn: segmentValues.driver.column,
                min: segmentRange.min,
                max: segmentRange.max,
              },
              tableRows: tableRows,
            })} ${segmentValues.revenue_elift_improve_from.title}`}{' '}
          </div>
        )}
      </div>

      <div className='custom-segment__body'>
        <Col xs={24}>
          <label data-testid='set-driver'>Set {segmentValues.driver.title} Range : </label>
          <InputNumber
            ref={inputMinRef}
            placeholder='Min'
            defaultValue={segmentRange.min}
            controls={false}
            className='custom-segment__input'
            onClick={stopPropagation}
          />
          <label>
            to
            <InputNumber
              ref={inputMaxRef}
              placeholder='Max'
              defaultValue={segmentRange.max}
              controls={false}
              className='custom-segment__input'
              onClick={stopPropagation}
            />
          </label>
          <Button
            text='Update'
            className='update-segment'
            onClickHandler={handleSetCustomSegment}
            testId='update-segment'></Button>
        </Col>
      </div>
    </div>
  )
}

CustomSegment.displayName = 'CustomSegment'

export default CustomSegment
