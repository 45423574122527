import { capitalizeFirstLetter } from 'services/Utils/parseString.utils'

import type { MlMetricCategory } from 'models/account.model'

interface TabProps {
  type: MlMetricCategory
}

const Tab = ({ type }: TabProps) => {
  return (
    <div className='ml-metrics-tab-item-container'>
      <div className='ml-metrics-tab-item-heading'>
        <h5>{capitalizeFirstLetter(type)} Drivers</h5>
      </div>
    </div>
  )
}
Tab.displayName = 'Tab'

export default Tab
