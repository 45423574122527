import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import colors from 'components/MotionDetails/MachineLearningMetric/Chart/helpers/chart-colors.module.scss'
import { sharedChartOptions } from 'components/MotionDetails/share/chart'

import type { ChartOptions, Tick } from 'chart.js'

import { DataFormatEnum } from 'models/insights'

export const getMaxYAxisValue = (arr: number[], scaleFactor: number) => {
  if (arr.length === 0) {
    return 0
  }
  const maxVal = Math.max(...arr)
  const maxValScaled = Math.ceil(maxVal + maxVal * scaleFactor)
  const numDigits = maxValScaled.toString().length
  const roundValue = parseInt('1'.concat('0'.repeat(numDigits - 1)))

  return Math.ceil(maxValScaled / roundValue) * roundValue
}

export const getOptions = (
  title: string,
  isPercentage: boolean,
  shouldRoundNumbers: boolean,
  yMax?: number,
  yMin?: number,
  shouldFormatKMB?: boolean,
): ChartOptions => {
  return {
    ...sharedChartOptions,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tableUnderChart: {
        display: false,
      },
    },
    scales: {
      y: {
        ...sharedChartOptions.scales.y,
        ...(isPercentage && {
          max: 100,
          min: 0,
        }),
        ...(yMax &&
          (yMin || yMin === 0) && {
            max: yMax,
            min: yMin,
          }),
        title: {
          display: true,
          text: title,
        },
        ticks: {
          ...sharedChartOptions.scales.y.ticks,
          ...(isPercentage && {
            callback: (value) => `${value}%`,
            stepSize: 20,
          }),
          ...(shouldRoundNumbers && {
            callback: function (value) {
              const roundedValue = formatNumber({
                number: value,
                format: DataFormatEnum.CurrencyKMB,
                decimal: 1,
              }).slice(1)

              return roundedValue
            },
          }),
          ...(shouldFormatKMB && {
            callback: (tickValue: string | number, index: number, ticks: Tick[]) => {
              return formatNumber({
                number: tickValue,
                format: DataFormatEnum.Kmb,
                decimal: 0,
              })
            },
          }),
          color: colors.ticks,
        },
      },
      x: {
        ...sharedChartOptions.scales.x,
        ticks: {
          ...sharedChartOptions.scales.x.ticks,
          color: colors.ticks,
        },
      },
    },
  }
}
