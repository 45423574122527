import { Modal } from 'antd'

import { Button } from 'components/common'

interface ArchiveTemplateModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  handleArchiveTemplate: () => void
  isTemplateCurrentlySelected: boolean
  setCurrentlySelectedTemplate: (state: null) => void
}

const ArchiveTemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  handleArchiveTemplate,
  isTemplateCurrentlySelected,
  setCurrentlySelectedTemplate,
}: ArchiveTemplateModalProps) => {
  const handleDeleteConfirmation = () => {
    handleArchiveTemplate()
    setIsModalOpen(false)
    setCurrentlySelectedTemplate(null)
  }

  const handleGoBack = () => {
    setIsModalOpen(false)
    setCurrentlySelectedTemplate(null)
  }

  return (
    <Modal
      title='Delete Email'
      width={656}
      open={isModalOpen}
      onOk={handleGoBack}
      onCancel={handleGoBack}
      footer={[
        <Button
          key='archive-email-template-submit-btn'
          text='Delete Email'
          type='secondary'
          onClickHandler={handleDeleteConfirmation}
          disabled={!isTemplateCurrentlySelected}
        />,
        <Button
          key='archive-email-template-go-back-btn'
          text='Go Back'
          onClickHandler={handleGoBack}
          className='footer__button'
        />,
      ]}>
      <p className='modal__text'>
        Are you sure you want to delete this email? This will remove the email for all Motions using this email.
      </p>
    </Modal>
  )
}
ArchiveTemplateModal.displayName = 'ArchiveTemplateModal'

export default ArchiveTemplateModal
