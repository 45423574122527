import type { ConfigPanelPayload } from 'models/motion/motionBuilder.model'

const isStatusFailure = (result: { response: { status: number } }): boolean => {
  const status = result?.response?.status

  return status < 200 || status >= 300
}

const validateCriteriaPayload = ({
  payload,
  requiredPayload = true,
}: {
  payload: ConfigPanelPayload
  requiredPayload?: boolean
}) => {
  let fieldsWithoutValue: number = 0

  if (!payload?.groups?.length) {
    return !requiredPayload
  }

  payload.groups.forEach((group) => {
    group.groups.forEach((item) => {
      if ((Array.isArray(item.value) && !item.value.length) || (!item.value && typeof item.value !== 'boolean')) {
        fieldsWithoutValue++
      }
    })
  })

  return fieldsWithoutValue === 0
}

export { isStatusFailure, validateCriteriaPayload }
