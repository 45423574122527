import { ActionsStore } from 'store/actions/actions.store'
import { AdminStore } from 'store/admin/admin.store'
import { EmailTemplatesStore } from 'store/emailTemplates/emailTemplates.store'
import { FileAttachmentsStore } from 'store/fileAttachments/fileAttachments.store'
import { HelpCenterStore } from 'store/helpCenter.store'
import { InsightsStore } from 'store/insights/insights.store'
import { IntegrationsStore } from 'store/integrations/integrations.store'
import { MetadataStore } from 'store/metadata/metadata.store'
import { AggregationsDataStore } from 'store/motion/aggregationsData/aggregationsData.store'
import { ConfigPanelStore } from 'store/motion/configPanel/configPanel.store'
import { DynamicInputStore } from 'store/motion/dynamicInput/dynamicInput.store'
import { LoopPanelStore } from 'store/motion/loopPanel/loopPanel.store'
import { MergePanelStore } from 'store/motion/mergePanel/mergePanel.store'
import { MotionGoalsStore } from 'store/motion/motionGoals/motionGoals.store'
import { MotionStore } from 'store/motion.store'
import { NotificationStore } from 'store/notifications.store'
import { ObservabilityStore } from 'store/observability/observability.store'
import { ReportingStore } from 'store/reporting/reporting.store'
import { SlackMessagesStore } from 'store/slackMessages/slackMessages.store'
import { UserStore } from 'store/user.store'

class RootStore {
  actionsStore
  adminStore
  aggregationsDataStore
  configPanelStore
  dynamicInputStore
  emailTemplatesStore
  fileAttachmentsStore
  helpCenterStore
  insightsStore
  integrationsStore
  motionGoalsStore
  motionStore
  loopPanelStore
  mergePanelStore
  metadataStore
  notificationStore
  observabilityStore
  reportingStore
  userStore
  slackMessagesStore

  constructor() {
    this.actionsStore = new ActionsStore()
    this.adminStore = new AdminStore()
    this.aggregationsDataStore = new AggregationsDataStore()
    this.configPanelStore = new ConfigPanelStore()
    this.dynamicInputStore = new DynamicInputStore()
    this.emailTemplatesStore = new EmailTemplatesStore()
    this.fileAttachmentsStore = new FileAttachmentsStore()
    this.helpCenterStore = new HelpCenterStore()
    this.insightsStore = new InsightsStore()
    this.integrationsStore = new IntegrationsStore()
    this.motionGoalsStore = new MotionGoalsStore()
    this.motionStore = new MotionStore()
    this.loopPanelStore = new LoopPanelStore()
    this.mergePanelStore = new MergePanelStore()
    this.metadataStore = new MetadataStore()
    this.notificationStore = new NotificationStore()
    this.observabilityStore = new ObservabilityStore()
    this.reportingStore = new ReportingStore()
    this.userStore = new UserStore()
    this.slackMessagesStore = new SlackMessagesStore()
  }

  clearStore = () => {
    this.actionsStore.reset()
    this.adminStore.reset()
    this.aggregationsDataStore.reset()
    this.configPanelStore.reset()
    this.dynamicInputStore.reset()
    this.emailTemplatesStore.reset()
    this.fileAttachmentsStore.reset()
    this.helpCenterStore.reset()
    this.insightsStore.reset()
    this.integrationsStore.reset()
    this.motionGoalsStore.reset()
    this.motionStore.reset()
    this.loopPanelStore.reset()
    this.mergePanelStore.reset()
    this.metadataStore.reset()
    this.notificationStore.reset()
    this.observabilityStore.reset()
    this.reportingStore.reset()
    this.userStore.reset()
    this.slackMessagesStore.reset()
  }
}

export default RootStore
