import axios from 'axios'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ReactFlowProvider } from 'react-flow-renderer'
import { Routes, Route, Navigate } from 'react-router-dom'
import { tablePlugin } from 'utils/chartjs-plugin-table'

import AdminBar from 'components/AdminBar'
import { AuthListener } from 'components/Auth/AuthListener/AuthListener'
import PrivateRoute from 'components/Auth/PrivateRoute/PrivateRoute'
import { ResetPass } from 'components/Auth/ResetPass/ResetPass'
import { SignIn } from 'components/Auth/SignIn/SignIn'
import { SignOut } from 'components/Auth/SignOut/SignOut'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import useDatadogRumTracking from 'hooks/useDatadogRumTracking'
import useDemoFeature from 'hooks/useDemoFeature'
import useSandboxEnv from 'hooks/useSandboxEnv'
import Main from 'pages/Main'
import PreviewInbrowser from 'pages/Motions/PreviewInBrowser/PreviewInbrowser'
import { UtilService } from 'services/Utils'
import RootStore from 'store/Rootstore'
import StoreProvider from 'store/StoreProvider'

import type { AxiosError, InternalAxiosRequestConfig } from 'axios'

import '~/App.scss'

// Register ChartJS Plugins
// Ensure that when adding a new plugin existing charts do not default to display: true
Chart.register(
  annotationPlugin,
  ArcElement,
  BarElement,
  CategoryScale,
  ChartDataLabels,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  tablePlugin,
  Title,
  Tooltip,
)

// Required for AntD form date input helpers
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(utc)

const store = new RootStore()

const App = observer((): JSX.Element => {
  useDatadogRumTracking()
  const { enableDemoMockApi, disableDemoMockApi } = useDemoFeature()
  const { enableSandboxEnv, disableSandboxEnv } = useSandboxEnv()
  const { userStore } = store
  const { username, tenant } = userStore
  const { sandbox, demo } = useFlags<LaunchDarklyFeatureFlags>()
  const isAdmin = localStorage.getItem('ADMIN_PANEL') === 'true'

  useEffect(() => {
    if (demo) {
      enableDemoMockApi()
    } else {
      disableDemoMockApi()
    }
  }, [tenant, demo, enableDemoMockApi, disableDemoMockApi])

  useEffect(() => {
    if (sandbox) {
      enableSandboxEnv()
    } else {
      disableSandboxEnv()
    }
  }, [sandbox])

  axios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = await UtilService.getAuthTokenId()

      config.headers = config.headers ?? {}

      if (token) {
        config.headers['Authorization'] = token
      }

      config.headers['Content-Type'] = 'application/json'

      return config
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    },
  )

  return (
    <StoreProvider store={store}>
      <AuthListener />
      <div className='App'>
        {isAdmin && <AdminBar />}
        <ReactFlowProvider>
          <Routes>
            <Route path='/signin'>
              {username ? (
                <Route path='/signin' element={<Navigate to='/dashboard' />} />
              ) : (
                <Route path='/signin' element={<SignIn />} />
              )}
            </Route>
            <Route path='/reset'>
              {username ? (
                <Route path='/reset' element={<Navigate to='/dashboard' />} />
              ) : (
                <Route path='/reset' element={<ResetPass />} />
              )}
            </Route>
            <Route path='/signout' element={<SignOut />} />
            <Route path='/motions/motion/emailpreview/:id/:version' element={<PreviewInbrowser />} />

            <Route path='*' element={<PrivateRoute component={Main} />}></Route>
          </Routes>
        </ReactFlowProvider>
      </div>
    </StoreProvider>
  )
})

export default App
