import { useContext } from 'react'

interface TabSwitcherProps {
  tabId: number
  TabContext: React.Context<{
    activeTab: number
    setTab: (tabId: number) => void
  }>
  children: React.ReactNode
}

const TabSwitcher = ({ tabId, TabContext, children }: TabSwitcherProps) => {
  const { activeTab, setTab } = useContext(TabContext)

  const onClick = () => setTab(tabId)

  const className = ['ml-metrics-tab-switcher']
  if (activeTab === tabId) className.push('ml-metrics-tab-switcher--active')

  return (
    <div className={className.join(' ')} onClick={onClick}>
      {children}
    </div>
  )
}

export default TabSwitcher
