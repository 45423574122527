import { Layout, Row } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState, useEffect, useContext } from 'react'

import segment from 'assets/images/mocks/segment.png'
import { Button, EMPTY_GROUPS_PAYLOAD, Heading, IconUserGroupDelete } from 'components/common'
import DataSourcePanelSidebar from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/DataSourcePanel/DataSourcePanelSidebar'
import { ExcludeUsersModal } from 'components/MotionBuilder/SegmentBuilder/ExcludeUsers/ExcludeUsersModal'
import { SegmentCriteria } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/SegmentCriteria'
import { IS_OVER_NEW_GROUP, IS_OVER_FREE_AREA_IN_DROPZONE } from 'components/MotionBuilder/Utils/motionBuilderConstants'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import useDemoFeature from 'hooks/useDemoFeature'
import { clone } from 'services/Utils/misc'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'

import type { DragEvent, Dispatch, SetStateAction } from 'react'

import type { DragEvents, Item, Groups, DragOverGroupType, ConfigPanelPayload } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum, RelationOperator } from 'models/motion.model'

interface DataSourcePanelProps {
  displayHeader?: boolean
  payload: ConfigPanelPayload
  forceDisableDynamicInput?: boolean
  withCalculatedData?: boolean
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const getStatementName = (type: NodeTypeEnum) => {
  switch (type) {
    case NodeTypeEnum.Branch:
      return 'If statement'

    case NodeTypeEnum.Segment:
      return 'Criteria'

    case NodeTypeEnum.WaitForTrigger:
      return 'Trigger statement'

    case NodeTypeEnum.Loop:
      return 'Exit condition'

    default:
      return ''
  }
}

export const DataSourcePanel = ({
  payload,
  setPayload,
  displayHeader = true,
  forceDisableDynamicInput = false,
  withCalculatedData = true,
}: DataSourcePanelProps) => {
  const { segmentBuilderData, setSegmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { excludeParticipants } = useFlags<LaunchDarklyFeatureFlags>()

  const [dragData, setDragData] = useState<Item>()
  const [dragEvents, setDragEvents] = useState<DragEvents>({
    isDragStarting: false,
    dragOverGroup: IS_OVER_FREE_AREA_IN_DROPZONE,
  })
  const [isExcludeUsersModalVisible, setIsExcludeUsersModalVisible] = useState(false)

  const { isMockApiEnabled } = useDemoFeature()

  useEffect(() => {
    // set initial payload
    if (segmentBuilderData.type === NodeTypeEnum.Loop && segmentBuilderData?.payload?.exitCondition) {
      setPayload({
        ...EMPTY_GROUPS_PAYLOAD,
        ...clone(segmentBuilderData?.payload?.exitCondition),
      } as ConfigPanelPayload)
    } else {
      setPayload({ ...EMPTY_GROUPS_PAYLOAD, ...clone(segmentBuilderData.payload) })
    }
  }, [])

  const onDragEnterGroup = (e: DragEvent<HTMLDivElement>, group: DragOverGroupType) => {
    e.preventDefault()

    setDragEvents((drag) => {
      return { ...drag, dragOverGroup: group }
    })
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    setDragEvents((drag) => {
      return { ...drag, isDragStarting: false }
    })

    if (dragData?.isNewStatement) {
      dragData.isNewStatement = false
      addStatement(dragData, dragEvents.dragOverGroup)
    }
  }

  const addStatement = (item: Item, groupIndex: DragOverGroupType) => {
    if (!payload?.groups?.length) {
      /* ADD THE FIRST STATEMENT */
      setPayload((payload: ConfigPanelPayload) => {
        return {
          ...payload,
          groups: [{ groups: [item], relationOperator: RelationOperator.AND }],
          relationOperator: RelationOperator.OR,
        }
      })
    } else {
      /* ADD STATEMENT IN A NEW GROUP */
      if (
        dragEvents?.dragOverGroup === IS_OVER_NEW_GROUP ||
        dragEvents?.dragOverGroup === IS_OVER_FREE_AREA_IN_DROPZONE
      ) {
        setDragEvents((drag) => {
          return { ...drag, groupCounter: 0 }
        })

        setPayload((payload: ConfigPanelPayload) => {
          payload?.groups.push({ groups: [item], relationOperator: RelationOperator.AND })

          return payload
        })
      } else {
        /* ADD STATEMENT ON SPECIFIC GROUP */
        payload?.groups.map((statement: Groups, statementIndex: number) => {
          if (statementIndex === groupIndex) {
            setDragEvents((drag) => {
              return { ...drag, groupCounter: 0 }
            })
            setPayload((payload: ConfigPanelPayload) => {
              payload.groups[statementIndex].groups.push(item)
              payload.groups[statementIndex].relationOperator = RelationOperator.AND

              return payload
            })
          }
          return statement
        })
      }

      setDragEvents((drag) => {
        return { ...drag, dragOverGroup: IS_OVER_NEW_GROUP }
      })
    }
  }

  const handleExcludeUsersConfirm = (userIdsToExclude: string[] | undefined) => {
    if (setSegmentBuilderData) {
      setSegmentBuilderData((data) => {
        return {
          ...data,
          excludedUserIds: userIdsToExclude || [],
        }
      })
    }
    setIsExcludeUsersModalVisible(false)
  }

  if (isMockApiEnabled) {
    // TODO: Revert this and all the additional code to display an image clicking on hidden square
    if (segmentBuilderData.nodeId === '1' && segmentBuilderData?.isHiddenSegment) {
      return (
        <div style={{ marginTop: 30 }}>
          <img src={segment} alt='segment' />
        </div>
      )
    }
  }

  return (
    <Layout
      key={`data-source-panel-${segmentBuilderData.id || 'segment'}`}
      style={{ flexDirection: 'row', height: '400px' }}>
      <DataSourcePanelSidebar
        displayHeader={displayHeader}
        setDragData={setDragData}
        setDragEvents={setDragEvents}
        withCalculatedData={withCalculatedData}
      />
      <Layout className='criteria-builder'>
        {displayHeader && (
          <Row>
            <Heading level='2' variant='6' className='grow '>
              {getStatementName(segmentBuilderData.type)}
            </Heading>
            {segmentBuilderData.type === NodeTypeEnum.Segment && excludeParticipants && (
              <Button
                link
                icon={{ element: <IconUserGroupDelete />, position: 'left' }}
                text={`Exclude users${segmentBuilderData?.excludedUserIds?.length ? ` (${segmentBuilderData?.excludedUserIds?.length})` : ''}`}
                size='L'
                style={{ marginBottom: '12px' }}
                onClickHandler={() => setIsExcludeUsersModalVisible(true)}
              />
            )}
          </Row>
        )}

        <SegmentCriteria
          dragEvents={dragEvents}
          payload={payload}
          setPayload={setPayload}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          onDragEnterGroup={onDragEnterGroup}
          {...(forceDisableDynamicInput && { forceDisableDynamicInput })}
        />
      </Layout>

      <ExcludeUsersModal
        isVisable={isExcludeUsersModalVisible}
        handleCancel={() => setIsExcludeUsersModalVisible(false)}
        handleConfirm={handleExcludeUsersConfirm}
        isLoading={false}
        excludedUserIds={segmentBuilderData?.excludedUserIds || []}
      />
    </Layout>
  )
}
DataSourcePanel.displayName = 'DataSourcePanel'
