import { ArrowHeadType, getIncomers, getOutgoers } from 'react-flow-renderer'

import { mergeArraysWithoutDuples } from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils.common'
import type {
  ValidNodes,
  GetNodeOptionProps,
  SiblingsOutput,
  LoopFree,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopPanelTypes'
import { getMarkedEdges } from 'services/Utils/motionHelpers/motionHelpers.utils'

import type { Edge, Node } from 'react-flow-renderer'

import type { SegmentBuilderData } from 'models/motion/motionBuilder.model'
import { HeighlightNodeStatusEnum } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

// Return a list of nodes without a specific one (the looop node)
export const getPossibleTargets = (potentialTargets: Node<{ type: string }>[], excludedId: string) => {
  const possibleTargets = potentialTargets.filter((node) => {
    if (node.id !== excludedId && node.data?.type !== NodeTypeEnum.Segment) {
      return node
    }
    return undefined
  })
  return possibleTargets
}

export const unmarkTargetNodes = (nodes: Node<SegmentBuilderData>[], markedNodeId?: string): Node[] => {
  return nodes.map((node) => {
    const data = { ...node.data }
    delete data.status
    if (markedNodeId && node.id === markedNodeId) {
      delete data.loopSourceId
    }
    if (markedNodeId && data.targetNodeId === markedNodeId) {
      delete data.targetNodeId
    }

    return { ...node, data }
  })
}

// All the functions together
export function getValidLoopNodes({ currentNode, elements, loopId, nodes }: ValidNodes) {
  /*
  Scenario logic
  get all the potentialTargets
  get the posible targets
  get impossible targets
  update valid targets with a flag to mark possible
  update impossible targets with a flag to make them disabled
  update the Elements state
  */

  // get the limit nodes
  // find branch siblings on the same Y we need only the first on top and bottom() if any
  const siblingsBranches = getSiblingsYAxis({ node: currentNode, elements, nodeType: NodeTypeEnum.Branch })
  const siblingsLoops = getSiblingsYAxis({ node: currentNode, elements, nodeType: NodeTypeEnum.Loop })
  const siblingsMerges = getSiblingsYAxis({ node: currentNode, elements, nodeType: NodeTypeEnum.Merge })
  const siblingsLoopsTargets = getSiblingsYAxis({ node: currentNode, elements, loopTarget: true })
  // the nodes between branches are available targets

  const validTargets = validatePossibleTargets([siblingsBranches, siblingsLoops, siblingsLoopsTargets, siblingsMerges])

  const { min, max } = getLimitNodes(validTargets, currentNode.position.y)

  // get potential targets
  const potentialTargets = getPotentialTargets(nodes, currentNode.position.x)

  const validatedNodes = validateNodes(potentialTargets, min, max)

  const validTargetNodes = getPossibleTargets(validatedNodes, loopId)

  // mark valid nodes
  const markedTargetNodes = markTargetNodes(validTargetNodes, HeighlightNodeStatusEnum.Valid)

  const invalidNodes = nodes.filter((node) => {
    return !markedTargetNodes.includes(node) && node.id !== loopId
  })
  const markedInvalidNodes = markTargetNodes(invalidNodes, HeighlightNodeStatusEnum.Invalid)

  const updatedNodes = mergeArraysWithoutDuples([...markedInvalidNodes, currentNode], markedTargetNodes)
  return updatedNodes
}

export function getStatusFreeNodes(nodes: Node<SegmentBuilderData>[], markedNodeId?: string) {
  const unMarkedNodes = unmarkTargetNodes(nodes, markedNodeId)

  const cleanNodes = mergeArraysWithoutDuples(nodes, unMarkedNodes)
  return cleanNodes
}

// Local functions

/**
 * Function that gets the parent node of a specific type of a specific node
 * @param    {Node} node - Node that you are looking for parent.
 * @param    {Elements} elements - Elements where the parent can be.
 * @param    {string} [nodeType] - The type of the node.
 * @param    {boolean} [loopTarget] Check for loopTarget property in nodes data.
 * @return   {Node | undefined} - Parent node if there is one or undefined.
 */
export function getParentTypedNode({
  node,
  elements,
  nodeType,
  loopTarget,
}: GetNodeOptionProps): Node<SegmentBuilderData> | undefined {
  let parentTypeNode
  ;(function transverse(nodes: Node<SegmentBuilderData>[]) {
    const parentNode = nodes[0]

    if (parentNode) {
      const isNodeTypeEnum = nodeType && parentNode?.data?.type === nodeType
      const isLoopTarget = loopTarget && parentNode?.data?.loopSourceId
      if (isNodeTypeEnum || isLoopTarget) {
        parentTypeNode = { ...parentNode }
        return parentTypeNode
      }
      transverse([...(getIncomers(parentNode, elements) as Node<SegmentBuilderData>[])])
    }
  })(getIncomers(node, elements) as Node<SegmentBuilderData>[])

  return parentTypeNode
}

/**
 * Function that gets the parent node of a specific type of a specific node
 * @param    {Node} node - Node that you are looking for parent.
 * @param    {Elements} elements - Elements where the parent can be.
 * @param    {string} [nodeType] - The type of the node.
 * @param    {boolean} [loopTarget] Check for loopTarget property in nodes data.
 * @return   {Node | undefined} - Parent node if there is one or undefined.
 */
function getChildTypedNode({ node, elements, nodeType, loopTarget }: GetNodeOptionProps): Node | undefined {
  let childTypeNode
  ;(function transverse(nodes: Node<SegmentBuilderData>[]) {
    const childNode = nodes[0]

    if (childNode) {
      const isNodeTypeEnum = nodeType && childNode?.data?.type === nodeType
      const isLoopTarget = loopTarget && childNode?.data?.loopSourceId
      if (isNodeTypeEnum || isLoopTarget) {
        childTypeNode = childNode
        return childTypeNode
      }
      transverse([...(getOutgoers(childNode, elements) as Node<SegmentBuilderData>[])])
    }
  })(getOutgoers(node, elements) as Node<SegmentBuilderData>[])

  return childTypeNode
}

/**
 * Function that gets the siblings nodes based on some criterias (on Y axis)
 * @param    {Node} node - Node that you are looking for parent.
 * @param    {Elements} elements - Elements where the parent can be.
 * @param    {string} [nodeType] - The type of the node.
 * @param    {boolean} [loopTarget] Check for loopTarget property in nodes data.
 * @return   { parentTypeNode: Node | undefined; childTypeNode: Node | undefined } - The parent node (upper node) and child node (lower node) on Y-axis if there is one or undefined.
 */
export function getSiblingsYAxis({ node, elements, nodeType, loopTarget }: GetNodeOptionProps): SiblingsOutput {
  const parentTypeNode = getParentTypedNode({ node, elements, nodeType, loopTarget })
  const childTypeNode = getChildTypedNode({ node, elements, nodeType, loopTarget })
  return { parentTypeNode, childTypeNode }
}

// Return an ordered list extracted from the nodes siblings
export function validatePossibleTargets(siblings: SiblingsOutput[]) {
  const arrayOfSiblings: Node[] = []
  for (const sibling of siblings) {
    arrayOfSiblings.push(...(Object.values(sibling) as Node[]))
  }

  const validSiblings = excludeUndefinedFromArray(arrayOfSiblings)
  const positions = validSiblings.map((item) => item.position.y)
  const orderedNumbers = [...positions].sort(sortNumbers)
  return orderedNumbers
}

function excludeUndefinedFromArray(array: Node[]): Node[] {
  return array.filter((item) => item !== undefined)
}

function sortNumbers(a: number, b: number) {
  return a - b
}

// Returns the min and max of a position
export function getLimitNodes(positions: number[], loopPosition: number) {
  const allPositions = [...positions, loopPosition].sort(sortNumbers)
  const indexOfLoop = allPositions.indexOf(loopPosition)
  const min = allPositions[indexOfLoop - 1]
  const max = allPositions[indexOfLoop + 1]
  return {
    min,
    max,
  }
}

// Return the nodes based on min and max limitation
export function validateNodes(nodes: Node<{ type: string }>[], min: number, max: number) {
  if (min && max) {
    const between = nodes.filter((node) => node?.position?.y > min && node?.position?.y < max)
    return between
  } else if (min) {
    const basedOnMin = nodes.filter((node) => node?.position?.y > min)

    return basedOnMin
  } else if (max) {
    const basedOnMax = nodes.filter((node) => node?.position?.y < max)
    return basedOnMax
  }
  return nodes
}

// Exclude the nodes from different x position (different to loop node)
export function getPotentialTargets(nodes: Node<{ type: string }>[], xPosition: number): Node<{ type: string }>[] {
  const potentialTargets = nodes.filter((node) => node.position.x === xPosition)
  return potentialTargets
}

export function markTargetNodes(nodes: Node[], flag: string): Node[] {
  return nodes.map((node: Node<{ type: string }>) => {
    const data = { ...node.data, status: flag }

    return { ...node, data }
  })
}

export function getLoopEdgeId(source: string, target: string) {
  // prefix le from loop edge
  return `le${source}-${target}`
}

export function loopArrowEdge(source: string, target: string): Edge {
  const arrow: Edge = {
    id: getLoopEdgeId(source, target),
    source: source,
    target: target,
    type: NodeTypeEnum.Loop,
    data: {},
    sourceHandle: 's-right',
    targetHandle: 't-right',
    arrowHeadType: ArrowHeadType.Arrow,
  }
  return arrow
}

export function getElementsWithoutLoopStatus({ nodes, edges, targetNodeId, currentLoopId }: LoopFree) {
  const cleanNodes = getStatusFreeNodes(nodes, targetNodeId)
  const edgesWithoutPrevSelected = edges.filter((edge) => edge.id !== getLoopEdgeId(currentLoopId, targetNodeId))

  const elementsWithoutLoopResiduals = [...cleanNodes, ...edgesWithoutPrevSelected]

  const elementsWithUnMarkedEdges = getMarkedEdges({
    elements: elementsWithoutLoopResiduals,
    nodeSourceId: currentLoopId,
    nodeTargetId: targetNodeId,
    elementsType: [NodeTypeEnum.Loop, NodeTypeEnum.Branch, NodeTypeEnum.Merge],
    unmark: true,
  })

  return elementsWithUnMarkedEdges
}
