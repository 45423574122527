import { IconArrowUpRight } from 'components/common/Icons/Icons'
import { NumberFormat } from 'services/Utils'

import type { Impact } from 'models/insights'
import type { CompositeUnitEnum } from 'models/reporting.model'

interface CompositeCellProps {
  new: number
  old: number
  unit?: CompositeUnitEnum
  impact: Impact
  className?: string
}

/**
 * Return the new and old values and their respective unit if the case.
 * e.g. 278.28 m {icon} 200 m
 * if new value is higher then style it red and if positive then green
 * @param {number} new the new value for specified metric
 * @param {number} old the old value for specified metric
 * @param {string} unit the unit which will be shown alongside the values, if any
 * @param {Impact} impact based on this value will be calculated the color
 * @param {string} className additional class name to be applied to the cell
 */
const CompositeCell = ({ new: newValue, old, unit, impact, className }: CompositeCellProps) => {
  return (
    <span className={`composite-cell ${className}`} data-testid='composite-cell'>
      <span>
        {NumberFormat.beautifyFloat(old, 1)} {unit}
      </span>
      <span
        className={`composite-cell-arrow${impact ? `--${impact}` : ''} composite-cell-arrow${
          newValue !== old ? `--${newValue > old ? 'up' : 'down'}` : ''
        }`}>
        <IconArrowUpRight />
      </span>
      <span>
        {NumberFormat.beautifyFloat(newValue, 1)} {unit}
      </span>
    </span>
  )
}
CompositeCell.displayName = 'CompositeCell'

export default CompositeCell
