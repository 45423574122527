import type {
  ParticipantTotals,
  TenantActionTotals,
  TenantActionTotalsIntegrations,
  TopActions,
  TopMotion,
} from 'models/observability.model'
import { ParticipantTotalsEnum } from 'models/observability.model'

export const sortIntegrations = (integrations: TenantActionTotalsIntegrations = {}): TenantActionTotalsIntegrations => {
  return Object.keys(integrations)
    .sort((a, b) => Number(integrations[b].percentageTotal) - Number(integrations[a].percentageTotal))
    .reduce((acc: { [key: string]: any }, key) => {
      acc[key] = integrations[key]
      return acc
    }, {})
}

export const sortByTopActions = (
  integrations: TenantActionTotalsIntegrations,
  topActionLimit: number = 3,
): TopActions[] => {
  return Object.keys(integrations)
    .map((platform) => {
      return Object.keys(integrations[platform].actions).map((actionName) => {
        return {
          name: actionName,
          total: integrations[platform].actions[actionName].total,
          platform: platform,
        }
      })
    })
    .flat()
    .sort((a, b) => b.total - a.total)
    .slice(0, topActionLimit)
}

export const getAverageActionsPerAccount = (totalNumOfActions: number, totalNumOfAccounts: number): number => {
  if (totalNumOfAccounts <= 0) return 0
  return Math.round(totalNumOfActions / totalNumOfAccounts)
}

export const emptyTenantMotionTotals: TenantActionTotals = {
  totals: {
    actions: 0,
    users: 0,
    accounts: 0,
  },
  integrations: {
    marketo: {
      total: 0,
      percentageTotal: '100',
      actions: {
        'Send Email': {
          total: 0,
          percentageTotal: '100',
        },
      },
    },
    gainsight: {
      total: 0,
      percentageTotal: '100',
      actions: {
        'Create Timeline Event': {
          total: 0,
          percentageTotal: '100',
        },
      },
    },
    zendesk: {
      total: 0,
      percentageTotal: '100',
      actions: {
        'Create Ticket': {
          total: 0,
          percentageTotal: '100',
        },
      },
    },
    pendo: {
      total: 0,
      percentageTotal: '100',
      actions: {
        'Launch Guide': {
          total: 0,
          percentageTotal: '100',
        },
      },
    },
    slack: {
      total: 0,
      percentageTotal: '100',
      actions: {
        'Send Message': {
          total: 0,
          percentageTotal: '100',
        },
      },
    },
  },
}

export const emptyTopActions = [
  {
    name: 'Send Email',
    total: 0,
    platform: 'marketo',
  },
  {
    name: 'Create Timeline Event',
    total: 0,
    platform: 'gainsight',
  },
  {
    name: 'Create Ticket',
    total: 0,
    platform: 'zendesk',
  },
  {
    name: 'Launch Guide',
    total: 0,
    platform: 'pendo',
  },
  {
    name: 'Send Message',
    total: 0,
    platform: 'slack',
  },
]

export const motionTotalColor = (status: `${ParticipantTotalsEnum}`) => {
  switch (status) {
    case ParticipantTotalsEnum.Completed:
      return 'lighter-blue--2'
    case ParticipantTotalsEnum.Active:
      return 'lighter-blue--1'
    case ParticipantTotalsEnum.Entered:
      return 'primary-blue'

    default:
      return 'lighter-blue--2'
  }
}

export const getMotionTotals = (users: ParticipantTotals): number => {
  const output: number = Object.values(users).reduce((acc: number, value: number) => acc + value, 0) as number
  return output
}

export const sortByTotalUsers = ({
  motions,
  limit = 4,
}: {
  motions: TopMotion[] | undefined
  limit?: number
}): TopMotion[] => {
  if (!motions) {
    return []
  }

  const sortedJournys = [...motions]
    .sort((a, b) => getMotionTotals(b.totals) - getMotionTotals(a.totals))
    .slice(0, limit)

  return sortedJournys
}

export const getPercentage = (value: number, total: number) => {
  return ((value / total) * 100).toFixed(0)
}
