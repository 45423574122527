import { useContext } from 'react'
import ReactFlow, { Background, Controls, MiniMap, PanOnScrollMode, SmoothStepEdge, addEdge } from 'react-flow-renderer'

import { EdgeLoop } from 'components/common/MotionThumbnail/Edges/EdgeLoop/EdgeLoop'
import EdgeSegment from 'components/common/MotionThumbnail/Edges/EdgeSegment/EdgeSegment'
import { End } from 'components/common/MotionThumbnail/Nodes/End/End'
import { Segment } from 'components/common/MotionThumbnail/Nodes/Segment/Segment'
import EditVariantLabel from 'components/MotionTarget/EditVariantLabel'
import { ARROW_HEAD_COLOR } from 'pages/Motions/constants'
import MotionGoalsPopover from 'pages/Motions/MotionGoalsPopover'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'

import type { Elements, EdgeTypesType, Connection, Edge } from 'react-flow-renderer'

interface FlowProps {
  isExperiment: boolean
}

const nodeTypes = {
  end: End,
  segment: Segment,
}

const edgeTypes: EdgeTypesType = {
  custom: EdgeSegment,
  smoothstep: SmoothStepEdge,
  loop: EdgeLoop,
}

const Flow = ({ isExperiment }: FlowProps) => {
  const {
    isABSplitScreenEnabled,
    isABExperimentModeEnabled,
    isEditingVariantA,
    isEditingVariantB,
    elements,
    setElements,
    experimentElements,
  } = useContext(SegmentBuilderContext)
  const onConnect = (params: Connection | Edge) => setElements((els: Elements) => addEdge(params, els))

  return (
    <ReactFlow
      preventScrolling={false}
      elementsSelectable={!isABSplitScreenEnabled()}
      elements={isExperiment ? experimentElements : elements}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      arrowHeadColor={ARROW_HEAD_COLOR}
      defaultZoom={1}
      minZoom={0.2}
      maxZoom={2}
      nodesDraggable={false}
      panOnScroll={true}
      panOnScrollMode={PanOnScrollMode.Vertical}
      zoomOnScroll={false}
      paneMoveable={true}
      snapGrid={[10, 10]}
      data-testid='canvas'>
      <Background gap={24} />
      {(!isABExperimentModeEnabled || isEditingVariantA || isEditingVariantB) && (
        <>
          <Controls
            showInteractive={false}
            showFitView={false}
            style={{ position: 'absolute', top: '2rem', left: '2rem' }}
          />
          <MiniMap />
        </>
      )}
      {!isABExperimentModeEnabled && <MotionGoalsPopover />}
      {isEditingVariantA && <EditVariantLabel variant='a' />}
      {isEditingVariantB && <EditVariantLabel variant='b' />}
    </ReactFlow>
  )
}

export default Flow
