import { Alert, Form, Input, Modal } from 'antd'
import { useState } from 'react'

import { Button } from 'components/common'

import type { ErrorMesage } from 'models'

interface SaveAsNewTemplateModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  templateName: string
  saveAsNewTemplate: (templateName: string) => void
}

const SaveAsNewTemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  templateName,
  saveAsNewTemplate,
}: SaveAsNewTemplateModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorMesage | null>(null)

  const handleOk = () => {
    setLoading(true)
    form
      .validateFields()
      .then((values: { templateName: string }) => {
        // If the name is the same as the original, we need to append ` (copy)` to the name
        const name = templateName === values.templateName ? `${templateName} (copy)` : values.templateName
        saveAsNewTemplate(name)
      })
      .then(() => {
        setLoading(false)
        form.resetFields()
        setIsModalOpen(false)
      })
      .catch(() => {
        setError({
          message: 'There was a problem saving this email as a new template. Please try again later.',
          name: 'error',
          code: 'error',
        })
        setLoading(false)
      })
  }

  const handleCancel = () => {
    form.resetFields()
    setError(null)
    setIsModalOpen(false)
  }

  return (
    <Modal
      title='Save as New Email'
      width={656}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='save-as-new-template-go-back-btn' text='Cancel' type='secondary' onClickHandler={handleCancel} />,
        <Button
          key='save-as-new-template-btn'
          text='Save as New Email'
          onClickHandler={handleOk}
          className='footer__button'
          disabled={loading}
        />,
      ]}>
      <p className='modal__text'>
        This action creates a new copy of this email. Any Motions using the original email will not get these updates.
      </p>
      <Form
        className='form'
        form={form}
        name='save-as-new-template-form'
        layout='vertical'
        initialValues={{ templateName: templateName }}>
        <Form.Item
          name='templateName'
          label='Save new email as'
          rules={[{ required: true, message: '"Email Name" is required' }]}>
          <Input placeholder='Enter email name' />
        </Form.Item>
      </Form>
      {error && <Alert message={error.message} type='error' />}
    </Modal>
  )
}

export default SaveAsNewTemplateModal
