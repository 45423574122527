export const cognitoConfig = {
  REGION: import.meta.env.VITE_REGION,
  USER_POOL_ID: import.meta.env.VITE_USER_POOL_ID,
  APP_CLIENT_ID: import.meta.env.VITE_APP_CLIENT_ID,
  IDENTITY_POOL_ID: import.meta.env.VITE_IDENTITY_POOL_ID,
  OAUTH: {
    HOSTED_UI_DOMAIN: import.meta.env.VITE_OAUTH_HOSTED_UI_DOMAIN,
    REDIRECT_SIGN_IN: JSON.parse(import.meta.env.VITE_OAUTH_REDIRECT_SIGN_IN) as string[],
    REDIRECT_SIGN_OUT: JSON.parse(import.meta.env.VITE_OAUTH_REDIRECT_SIGN_OUT) as string[],
    RESPONSE_TYPE: 'code',
    SCOPES: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
  },
}
