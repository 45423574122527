import dayjs from 'dayjs'

import clientSuccessIcon from 'assets/icons/integrations/clientSuccess.png'
import gainsightIcon from 'assets/icons/integrations/gainsight.png'
import hubspotIcon from 'assets/icons/integrations/hubspot.png'
import intercomIcon from 'assets/icons/integrations/intercom.png'
import marketoIcon from 'assets/icons/integrations/marketo.png'
import mongodbIcon from 'assets/icons/integrations/mongodb.png'
import netsuiteIcon from 'assets/icons/integrations/netsuite.png'
import pendoIcon from 'assets/icons/integrations/pendo.png'
import pokemonIcon from 'assets/icons/integrations/pokemon-catalog.png'
import redshiftIcon from 'assets/icons/integrations/redshift.png'
import salesforceIcon from 'assets/icons/integrations/salesforce.png'
import sftpBulkIcon from 'assets/icons/integrations/sftp-bulk.png'
import shopifyIcon from 'assets/icons/integrations/shopify.png'
import slackIcon from 'assets/icons/integrations/slack.png'
import snowflakeIcon from 'assets/icons/integrations/snowflake.png'
import zendeskIcon from 'assets/icons/integrations/zendesk.png'
import { IconCheckCircle, IconInfo, IconProcessing } from 'components/common'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { DataFormatEnum } from 'models/insights'
import type { Integration, IntegrationConnection } from 'models/Integration.model'

/**
 * Return the icon for a given integration key.
 * @param {string} key The integration key.
 * @returns The icon URL.
 */
export function getIconUrl(key: string) {
  switch (key) {
    case 'client-success':
      return clientSuccessIcon
    case 'hubspot':
      return hubspotIcon
    case 'gainsight':
      return gainsightIcon
    case 'intercom':
      return intercomIcon
    case 'marketo':
      return marketoIcon
    case 'mongodb':
      return mongodbIcon
    case 'netsuite':
      return netsuiteIcon
    case 'pendo':
      return pendoIcon
    case 'pokemon-catalog':
      return pokemonIcon
    case 'redshift':
      return redshiftIcon
    case 'salesforce':
      return salesforceIcon
    case 'sftp-bulk':
      return sftpBulkIcon
    case 'shopify':
      return shopifyIcon
    case 'slack':
      return slackIcon
    case 'snowflake':
    case 'snowflakedb':
      return snowflakeIcon
    case 'zendesk':
    case 'zendesk-support':
      return zendeskIcon
    default:
      console.warn('Unknown Icon Key:', key)
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
  }
}

export const getLiveConnectionsStatCardData = (integrationConnections: Integration[]) => {
  if (!integrationConnections || integrationConnections.length === 0) {
    return {
      title: 'Live Connections',
      integrations: [],
      text: '-',
    }
  }
  const liveConnections = integrationConnections.filter(
    (integration) => integration.connections && integration.connections.length,
  )

  return {
    title: 'Live Connections',
    integrations: liveConnections,
    text: liveConnections.length ? liveConnections.length.toString() : '-',
  }
}

export const getLatestConnection = (connections: IntegrationConnection[]) => {
  if (connections.length === 1) {
    return connections[0]
  }

  return connections.reduce(
    (acc, connection) => {
      if (!connection.lastIngestedDate) return acc
      if (!acc || connection.lastIngestedDate > acc.lastIngestedDate) {
        return connection
      }
      return acc
    },
    { ...connections[0] } as IntegrationConnection,
  )
}

export const getIntegrationWithLatestConnection = (integrationConnections: Integration[]) => {
  return integrationConnections.reduce((acc, integration) => {
    if (!integration.connections) return acc

    const latestConnection = getLatestConnection(integration.connections)

    if (!acc || !acc.connections || latestConnection.lastIngestedDate > (acc.connections[0]?.lastIngestedDate ?? 0)) {
      return {
        ...integration,
        connections: [latestConnection],
      }
    }
    return acc
  }, integrationConnections[0])
}

export const getLatestIngestStatCardData = (integrationConnections: Integration[]) => {
  if (
    !integrationConnections ||
    integrationConnections.length === 0 ||
    !getIntegrationsWithConnections(integrationConnections).length
  ) {
    return {
      title: 'Lastest Ingest',
      integrations: [],
      text: '-',
    }
  }

  const latestIngestDateConnection = getIntegrationWithLatestConnection(integrationConnections)

  const formattedDate =
    !latestIngestDateConnection.connections || !latestIngestDateConnection.connections.length
      ? '-'
      : dayjs(latestIngestDateConnection.connections[0].lastIngestedDate).format('D[-]MMM h:mm A')

  return {
    title: 'Lastest Ingest',
    integrations: [latestIngestDateConnection],
    text: formattedDate,
  }
}

export const getActionsExecutedStatCardData = (integrationConnections: Integration[]) => {
  if (!integrationConnections || integrationConnections.length === 0) {
    return {
      title: 'Actions Executed',
      integrations: [],
      text: '-',
    }
  }

  let totalActionsExecuted = 0

  const connectionsWithExecutedActions = integrationConnections.reduce((acc, integration) => {
    const totalActions = integration.actions?.total || 0
    if (totalActions > 0) {
      totalActionsExecuted += totalActions
      acc.push(integration)
    }
    return acc
  }, [] as Integration[])

  const formattedNumber = formatNumber({
    number: totalActionsExecuted,
    format: DataFormatEnum.CurrencyKMB,
    decimal: 1,
  }).slice(1)

  return {
    title: 'Actions Executed',
    integrations: connectionsWithExecutedActions,
    text: connectionsWithExecutedActions.length > 0 ? formattedNumber : '-',
  }
}

export const getStatusIcon = (status: string) => {
  switch (status) {
    case 'Live':
      return <IconCheckCircle />
    case 'Processing':
      return <IconProcessing />
    default:
      return <IconInfo />
  }
}

export const groupIntegrationsByCategory = (integrations: Integration[]) => {
  if (!integrations) return {}
  return integrations.reduce(
    (acc, integration) => {
      if (!acc[integration.category]) {
        acc[integration.category] = []
      }
      acc[integration.category].push(integration)
      return acc
    },
    {} as Record<string, Integration[]>,
  )
}

export const countStatusByCategory = (integrations: Integration[]) => {
  if (!integrations) return {}
  return integrations.reduce(
    (acc, integration) => {
      if (!acc[integration.category]) {
        acc[integration.category] = {
          Live: 0,
          Processing: 0,
          Error: 0,
        }
      }
      if (!integration.connections) return acc
      integration.connections.forEach((connection) => {
        acc[integration.category][connection.status]++
      })
      return acc
    },
    {} as Record<string, Record<string, number>>,
  )
}

export const getIntegrationsWithConnections = (integrations: Integration[]) => {
  if (!integrations) return []
  return integrations.filter((integration) => integration.connections && integration.connections.length)
}
