import { getIncomers, isEdge } from 'react-flow-renderer'

import { mergeArraysWithoutDuples } from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils.common'
import { markTargetNodes } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import {
  getChildElementsOfNo,
  getElementById,
  getFragmentY,
  getParentElements,
  mergeArrowEdge,
} from 'components/MotionTarget/motionTarget.utils'
import {
  generateEndNodeEdgePair,
  getMergeTargetSourcePairs,
} from 'services/Utils/dslConversion/dslToReactFlow/dslToReactFlow.utils'
import { elementsSeparator } from 'services/Utils/motionHelpers/motionHelpers.utils'

import type { Edge, Elements, FlowElement, Node } from 'react-flow-renderer'

import type { MergePayload, SegmentBuilderData } from 'models/motion/motionBuilder.model'
import { HeighlightNodeStatusEnum } from 'models/motion/motionBuilder.model'
import type { NodePayload } from 'models/motion.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'

export interface ValidMergeNodesProps {
  elements: Elements
  mergeId: string
}
/**
 * Function that return the array of elements marked as valid and invalid
 * @param  param0
 * @returns The array of elements marked as valid and invalid
 */
export const getMarkedStatusMergeNodes = ({ mergeId, elements }: ValidMergeNodesProps) => {
  /*
  Function logic
  get all the potentialTargets
  get the posible targets
  get invalid targets
  update valid targets with a flag to mark as valid
  update invalid targets with a flag to make them disabled
  update the Elements state
  */
  const { edges, nodes } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const edgesWithoutProvisoryEdges = edges.filter((edge) => edge.type !== NodeTypeEnum.Merge)
  const cleanElements = [...nodes, ...edgesWithoutProvisoryEdges]

  const possibleTargets = getPossibleMergeTargets(cleanElements, mergeId)
  const currentNode = getElementById(cleanElements, mergeId) as Node

  // mark valid nodes
  const markedValidNodes = markTargetNodes(possibleTargets, HeighlightNodeStatusEnum.Valid)

  const invalidNodes = nodes.filter((node) => {
    return !markedValidNodes.includes(node) && node.id !== mergeId
  })

  const markedInvalidNodes = markTargetNodes(invalidNodes, HeighlightNodeStatusEnum.Invalid)

  const validTargetNodes = mergeArraysWithoutDuples([...markedInvalidNodes, currentNode], markedValidNodes)

  return validTargetNodes
}

/**
 * Function that return the list of possible merge target
 * @param elements The array of nodes and edges
 * @param mergeId The id of the merge node
 * @returns - The array of possible nodes
 */
export const getPossibleMergeTargets = (elements: Elements, mergeId: string): Node[] => {
  /*   possible merge target is every node above the end node of upper branches that is not merged yet
  requirements: upper branches end nodes in order to get the targets everything else is not valid  */

  const { nodes, edges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const basicElements = [...nodes, ...edges]

  const mergeNode = getElementById(elements, mergeId) as Node<SegmentBuilderData>
  if (!mergeNode) {
    return []
  }

  const parentBranches = getParentElements({
    startNode: mergeNode,
    elements: basicElements,
    nodeType: NodeTypeEnum.Branch,
  })
  const childNodesOfBranch: Node[] = []

  parentBranches.forEach((branch) => {
    childNodesOfBranch.push(...getChildElementsOfNo(branch, basicElements))
  })

  const endNodes = [...new Map(childNodesOfBranch.map((item) => [item.id, item])).values()].filter(
    (childNode: Node<{ isFinal: boolean }>) => childNode.data?.isFinal && childNode.position.x !== mergeNode.position.x,
  )

  const filteredEndNodes = endNodes.filter((endNode) => {
    // eliminate end nodes that has as imediatley parent a branch and the end node is on the "no" path
    const parentEdge = edges.find((edge) => edge.target === endNode.id)
    const parentNode = nodes.find((node) => node.id === parentEdge?.source) as Node<{ type: string }>
    return !(parentNode?.data?.type === NodeTypeEnum.Branch && endNode.position.x !== parentNode.position.x)
  })

  const noSourceIds = filteredEndNodes.map((noNode) => getSourceOfNode(basicElements, noNode.id))
  const potentialNodes = noSourceIds.map((noSourceId) => getElementById(basicElements, noSourceId) as Node)

  const validatedNodes = overlapValidator({
    elements: basicElements,
    nodesToValidate: potentialNodes,
    currentMergeNode: mergeNode,
  })

  return validatedNodes
}

interface OverlapValidatorProps {
  elements: Elements
  nodesToValidate: Node[]
  currentMergeNode: Node
}
/**
 * The function takes into consideration the configured existing merges, potential nodes for targets, and the merge element that is about to be configured and validates for overlaps eliminating those nodes that cause visual overlap
 * @param props
 * @returns Non-overlapping potential target nodes
 */
const overlapValidator = ({ elements, nodesToValidate, currentMergeNode }: OverlapValidatorProps): Node[] => {
  const excludedNodes: Node[] = []
  const mergeSourcePairs = getMergeTargetSourcePairs(elements)

  mergeSourcePairs.forEach((merge) => {
    const mergeElement = getElementById(elements, merge.id) as Node

    if (currentMergeNode.id !== mergeElement.id) {
      const mergeTargetsMaxX = merge.targets
        .map((targetId) => getElementById(elements, targetId) as Node)
        .reduce((prev, curr) => (curr.position.x > prev.position.x ? curr : prev))

      const parentXNodes = getSameXParentNodes(mergeTargetsMaxX, elements)
      const branchFirstElement = parentXNodes.reduce((prev, curr) => (curr.position.y < prev.position.y ? curr : prev))

      // Extreme points for this merge configuration
      const extremePoints = {
        x: mergeTargetsMaxX.position.x,
        minY: branchFirstElement.position.y - getFragmentY(),
        maxY: mergeElement.position.y,
      }

      const isCurrentMergeInner =
        currentMergeNode.position.y < extremePoints.maxY &&
        currentMergeNode.position.x < extremePoints.x &&
        currentMergeNode.position.y > extremePoints.minY

      nodesToValidate.forEach((node) => {
        if (
          !isCurrentMergeInner &&
          node.position.x < extremePoints.x &&
          node.position.y < extremePoints.maxY &&
          node.position.y > extremePoints.minY
        ) {
          excludedNodes.push(node)
        } else if (isCurrentMergeInner && (node.position.x > extremePoints.x || node.position.y < extremePoints.minY)) {
          excludedNodes.push(node)
        }
      })
    }
  })

  const validatedNodes = nodesToValidate.filter((node) => !excludedNodes.includes(node))
  return validatedNodes
}

/**
 * Goes from node to node on the same axis and returns the parent nodes
 * @param startNode The note from that starts to go up for "parents"
 * @param elements All the elements in reactflow format
 * @returns The list of parent elements if any or the element itself
 */
const getSameXParentNodes = (startNode: Node, elements: Elements) => {
  const chainOfParent: Node[] = []
  const xAxis = startNode.position.x

  const traverse = (nodes: Node[]) => {
    for (const node of nodes) {
      if (node.position.x === xAxis) {
        chainOfParent.push(node)
        traverse([...getIncomers(node, elements)])
      }
    }
  }

  traverse(getIncomers(startNode, elements))

  return chainOfParent.length ? chainOfParent : [startNode]
}

/**
 * Function that retuns the parent node id based on the child id
 * @param elements The array of nodes and edges
 * @param nodeId The id of the child node
 * @returns The id of the parent node
 */
export const getSourceOfNode = (elements: Elements, nodeId: string): string => {
  const edges = elements.filter(isEdge)
  const connectedEdge = edges.find((edge) => edge?.target === nodeId) as Edge
  return connectedEdge?.source
}

interface SetMergeUIProps {
  elements: Elements<SegmentBuilderData>
  sourceId: string
  targetId: string
}
/**
 * Function that removes the end node of a merge target and adds the edge from the target to merge
 * @param param0
 * @returns The Elements without merge target end node and
 */
export const getMergeUIElements = ({ elements, sourceId, targetId }: SetMergeUIProps): Elements => {
  const elementsCopy: Elements = elements.map(
    (el) => ({ ...el, ...(el.data && { data: { ...el.data } }) }) as FlowElement,
  )

  const mergeNode = getElementById(elementsCopy, sourceId) as Node<{ payload: MergePayload }>
  const targetEdge = elementsCopy
    .filter(isEdge)
    .find(
      (edge) =>
        edge.source === targetId && (edge as Edge<{ edgeLabel: string }>).data?.edgeLabel !== BranchLabelEnum.No,
    )

  const targetEndNode = getElementById(elementsCopy, (targetEdge as Edge)?.target)

  if (mergeNode && targetId && targetEdge && targetEndNode) {
    const mergeTargets =
      mergeNode.data?.payload?.targets?.length && !mergeNode.data?.payload?.targets?.includes(targetId)
        ? [...mergeNode?.data?.payload?.targets, targetId]
        : mergeNode?.data?.payload?.targets

    if (!mergeNode.data) {
      mergeNode.data = { payload: { targets: [] } }
    }

    mergeNode.data.payload = {
      ...mergeNode.data.payload,
      targets: mergeNode.data?.payload?.targets?.length && mergeTargets ? mergeTargets : [targetId],
    }

    const elementsWithoutTargetEnd = elementsCopy.filter(
      (element) => element.id !== targetEndNode.id && element.id !== targetEdge.id,
    )

    const mergeEdge = mergeArrowEdge(targetId, sourceId)

    return [...elementsWithoutTargetEnd, mergeEdge]
  }

  return elementsCopy
}

/**
 * Function that remove the special edge and adds back the end node
 * @param elements The array of nodes and edges
 * @param mergeTargetId The target node id that is merged
 * @returns The cleaned up array of elements
 */
export const unsetMerge = (elements: Elements, mergeTargetId: string): Elements => {
  const { edges, nodes, loopEdges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const mergeTargetEdge = edges.find(
    (edge) => edge.source === mergeTargetId && edge.data?.edgeType === NodeTypeEnum.Merge,
  )
  const mergeTargetNode = nodes.find((node) => node.id === mergeTargetId)

  const mergeNode = nodes.find((node) => node.id === mergeTargetEdge?.target) as Node<{ payload: MergePayload }>
  // - update special merge edge into a normal one
  // - point the edge to an end node
  // - end node poition is upper node + y fragment

  if (mergeTargetEdge && mergeTargetNode) {
    const newElements = generateEndNodeEdgePair({ position: mergeTargetNode.position, sourceId: mergeTargetNode.id })
    const indexOfMergeEdge = edges.indexOf(mergeTargetEdge)
    if (indexOfMergeEdge > -1) {
      edges.splice(indexOfMergeEdge, 1)
      // remove the merge edge
    }
    if (mergeNode?.data?.payload?.targets?.length) {
      mergeNode.data.payload.targets = mergeNode?.data.payload.targets.filter((id: string) => id !== mergeTargetId)
      if (!mergeNode.data.payload.targets.length) {
        mergeNode.data.payload.targets = []
      }
    }

    const updatedElements = [...nodes, ...edges, ...newElements]

    // move mergeElements position if needed
    const mergeElements = mergeArraysWithoutDuples(nodes, updatedElements)

    return [...mergeElements, ...edges, ...newElements, ...loopEdges]
  }

  return [...nodes, ...edges, ...loopEdges]
}
