import { ArrowHeadType, getIncomers, getOutgoers, isNode } from 'react-flow-renderer'

import { getParentTypedNode } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import {
  FRAGMENT_X,
  FRAGMENT_Y,
  INITIAL_X_POSITION,
  INITIAL_Y_POSITION,
} from 'components/MotionTarget/MotionTargetSettings'
import { getRandomId } from 'services/Utils'
import { clone } from 'services/Utils/misc'
import { elementsSeparator, getElementsMergeValidated } from 'services/Utils/motionHelpers/motionHelpers.utils'

import type { Edge, Elements, FlowElement, Node } from 'react-flow-renderer'

import type {
  ConfigPanelPayload,
  CreateEdgeProps,
  DroppedAction,
  EdgeToDrop,
  SegmentBuilderData,
} from 'models/motion/motionBuilder.model'
import type { NodePayload } from 'models/motion.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'

export const getElementById = (elements: Elements, id?: string): FlowElement | undefined => {
  return elements.find((element) => element.id === id)
}

export const createEdge = (props: CreateEdgeProps): Edge<{ edgeLabel: string; edgeType?: NodeTypeEnum }> => {
  const { parentNode, childNode, dropEdge, isOnNoBranch } = props
  const childId = typeof childNode === 'string' ? childNode : childNode.id
  const edgeLabel: string =
    typeof dropEdge === 'string' || dropEdge === undefined ? dropEdge || BranchLabelEnum.Yes : dropEdge?.data?.edgeLabel

  return {
    id: `e${parentNode.id}-${childId}`,
    source: `${parentNode.id}`,
    target: `${childId}`,
    type: 'custom',
    sourceHandle: isOnNoBranch ? 's-right' : 's-bottom',
    targetHandle: 't-top',
    data: { edgeLabel: edgeLabel },
    arrowHeadType: ArrowHeadType.Arrow,
  }
}

export const addNode = (
  elementsList: Elements,
  droppedElement: DroppedAction,
  additionalMethods: {
    onShowDrawer?: (data: any) => void
    setElements?: (elements: Elements) => void
  },
) => {
  if (!droppedElement.data && !droppedElement.edgeToDrop) {
    return elementsList
  }

  const isDropedOnMergeEdge = droppedElement.data.data.edgeType === (NodeTypeEnum.Merge as string)

  // I split the elements in order to remove the loop edges initially, because they are not relevant and cause a lot of problems
  const { nodes, edges, loopEdges } = elementsSeparator<
    SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elementsList)
  const elementsWithoutLoopEdges = [...nodes, ...edges]

  const isOnNoBranch = droppedElement.data.data.edgeLabel === (BranchLabelEnum.No as string)

  const elementsCopy = [...elementsWithoutLoopEdges]
  const parent = getElementById(elementsCopy, droppedElement.edgeToDrop.source) as Node
  const edgeToRemove = getElementById(elementsCopy, droppedElement.edgeToDrop.id) as Edge

  const [endOfCurrentNode] = [...getChildElementsOfNo(parent, elementsWithoutLoopEdges)]

  let position = {
    x: isOnNoBranch ? parent?.position?.x + getFragmentX() : parent?.position?.x,
    y: parent?.position.y + getFragmentY(),
  }

  // if is dropped as a child of choice element get the end position
  if (endOfCurrentNode && isOnNoBranch) {
    position = {
      x: endOfCurrentNode.position.x,
      y: endOfCurrentNode.position.y - getFragmentY(),
    }
  }

  const newNode = {
    id: `${getRandomId()}`,
    type: NodeTypeEnum.Segment,
    data: {
      nodeId: '',
      ...additionalMethods,
      ...droppedElement.data,
      payload: {},
      data: { edgeLabel: droppedElement.data.data.edgeLabel },
      ...(!droppedElement.data.description && { description: droppedElement.data.name }),
    },
    position: position,
  }
  // add the nodeId into data when node is created
  newNode.data.nodeId = newNode.id

  let newEdge: Edge
  if (isDropedOnMergeEdge) {
    // create a merge Arrow Edge
    // update the merge payload with the new target
    newEdge = mergeArrowEdge(newNode.id, droppedElement.edgeToDrop.target)
    const connectedMerge: Node<SegmentBuilderData> | undefined = nodes.find((node) => node.id === edgeToRemove.target)
    if (connectedMerge) {
      const indexOfReplacedNode = connectedMerge.data!.payload.targets.indexOf(edgeToRemove.source)
      if (indexOfReplacedNode !== -1) {
        connectedMerge.data!.payload.targets[indexOfReplacedNode] = newEdge.source
      }
    }
  } else {
    newEdge = createEdge({
      parentNode: newNode,
      childNode: droppedElement.edgeToDrop.target,
    })
  }

  const edgeToReplace = createEdge({
    parentNode: parent,
    childNode: newNode,
    isOnNoBranch,
    dropEdge: droppedElement.data,
  })

  const indexOfRemovedEdge = elementsCopy.indexOf(edgeToRemove)
  elementsCopy.splice(indexOfRemovedEdge, 1, edgeToReplace as Edge, newNode as Node, newEdge)

  if (droppedElement.data.type === NodeTypeEnum.Branch) {
    const endNode = {
      id: `${getRandomId()}`,
      type: 'end',
      data: { isFinal: true, name: 'End', description: 'End' },
      position: {
        x: newNode.position.x + getFragmentX(),
        y: newNode.position.y + getFragmentY(),
      },
    }

    const newEdge = createEdge({
      parentNode: newNode,
      childNode: endNode,
      isOnNoBranch: true,
      dropEdge: BranchLabelEnum.No,
    })

    elementsCopy.push(endNode as Node, newEdge as Edge)
  }

  const mergedElements = [...elementsCopy, ...loopEdges]
  //after all the computation are done, add back the edges for loops

  const finalElements = getElementsMergeValidated(mergedElements)
  return finalElements
}

export const getChildElementsOfNo = (node: Node, initialElements: Elements) => {
  const chainOfChildrens: Node[] = []
  const yesXAxis = node?.position?.x

  ;(function transverse(nodes: Node[]) {
    for (const currentNode of nodes) {
      const isNodeOnYes = yesXAxis === currentNode.position.x
      const isOnMerge = currentNode.position.x < node?.position?.x
      if (!isNodeOnYes && !isOnMerge) {
        chainOfChildrens.push(currentNode)
        transverse([...getOutgoers(currentNode, initialElements)])
      }
    }
  })(getOutgoers(node, initialElements))

  return chainOfChildrens
}

export const getChildNodes = (node: Node, initialElements: Elements, dragOverElement?: EdgeToDrop) => {
  if (!node) {
    return []
  }

  const chainOfChildrens: Node[] = []

  ;(function transverse(nodes: Node[]) {
    const nodeChild = nodes.find((node: Node) => node.id === dragOverElement?.target)

    for (const node of nodes) {
      if (nodes.length > 1 && nodeChild) {
        chainOfChildrens.push(nodeChild)
        transverse(getOutgoers(nodeChild, initialElements))
        break
      } else {
        chainOfChildrens.push(node)
        transverse([...getOutgoers(node, initialElements)])
      }
    }
  })(getOutgoers(node, initialElements))

  return chainOfChildrens
}

export const getLevelX = (x: number): number => {
  return (x - getInitialX()) / getFragmentX()
}

export const getLevelY = (y: number): number => {
  return (y - getInitialY()) / getFragmentY()
}

export const getBranchFirstNoNode = (node: Node, initialElements: Elements) => {
  const yesXAxis = node.position.x
  const childElements = getOutgoers(node, initialElements)
  const noFirstChild = childElements.find((element) => element.position.x !== yesXAxis)
  return noFirstChild
}

export const getNoBranchEndNode = (node: Node, elements: Elements) => {
  const firstNoNode = getBranchFirstNoNode(node, elements)
  const mainNoXAxis = firstNoNode?.position.x

  let result
  ;(function transverse(nodes: Node[]) {
    for (const currentNode of nodes) {
      if (mainNoXAxis === currentNode.position.x) {
        if (!getOutgoers(currentNode, elements).length) {
          result = currentNode
        }
        transverse([...getOutgoers(currentNode, elements)])
      }
    }
  })(getOutgoers(node, elements))

  return result
}

export const moveElementsPosition = (elements: Elements, dragOverEdge: EdgeToDrop, goDown: boolean) => {
  const { nodes, edges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(clone(elements))
  const elementsWithoutLoopEdges = [...nodes, ...edges]

  const parentElement = getElementById(elementsWithoutLoopEdges, dragOverEdge.source) as Node<SegmentBuilderData>
  const childElements = getChildNodes(parentElement, elementsWithoutLoopEdges, dragOverEdge)

  const dragOverEdgeObject: Edge<{ edgeType: NodeTypeEnum }> = getElementById(
    elementsWithoutLoopEdges,
    dragOverEdge.id,
  ) as Edge<{ edgeType: NodeTypeEnum }>
  const ACCEPTED_NODE_DIFFERENCE = 1
  if (dragOverEdgeObject?.data?.edgeType === NodeTypeEnum.Merge) {
    // if is dropped on merge edge not always the nodes needs to be updated
    const mergeNode = getElementById(elementsWithoutLoopEdges, dragOverEdge.target) as Node
    const needsToBasicUpdate =
      getLevelY(mergeNode.position.y) - getLevelY(parentElement.position.y) <= ACCEPTED_NODE_DIFFERENCE

    if (!needsToBasicUpdate && goDown) return elements
  }

  if (parentElement) {
    const innerMerge = getInnerMerge(parentElement, elementsWithoutLoopEdges)
    // if is inner a merge not always the nodes below merge needs to update
    if (innerMerge) {
      if (innerMerge.id === dragOverEdge.target) {
        // if inner merge and it's dropped on the edge to the merge
        const mergeParents = getParentMergeNodes(innerMerge, elementsWithoutLoopEdges)
        const mergeTargetHeighestY = getMergeTargetMaxY(mergeParents)
        const needsToBasicUpdate =
          getLevelY(innerMerge.position.y) - getLevelY(mergeTargetHeighestY.position.y) <= ACCEPTED_NODE_DIFFERENCE

        if (mergeTargetHeighestY.position.y !== parentElement.position.y && needsToBasicUpdate) {
          return elements
        }
      } else {
        // if dropped inner merge but not the last position
        const childSorted = [...childElements].sort(sortNodeByPositionY)
        const indexOfMerge = childSorted.indexOf(innerMerge)

        const nodesBetweenParentMerge = childSorted.slice(0, indexOfMerge)
        const lastElementBetween = nodesBetweenParentMerge.at(-1)

        if (lastElementBetween) {
          const needsToBasicUpdate =
            getLevelY(innerMerge.position.y) - getLevelY(lastElementBetween.position.y) <= ACCEPTED_NODE_DIFFERENCE
          const updatedElements = getUpdatedElements(nodesBetweenParentMerge, elements, goDown)

          if (!needsToBasicUpdate && goDown) {
            if (lastElementBetween.position.x === innerMerge.position.x) {
              return updatedElements
            }
          }
          const mergeParents = getParentMergeNodes(innerMerge, elementsWithoutLoopEdges)
          const mergeTargetHeighestY = getMergeTargetMaxY(mergeParents)
          const areMoreHigherNodes =
            mergeParents.filter((node) => node.position.y === mergeTargetHeighestY.position.y).length > 1

          if (needsToBasicUpdate && !goDown && areMoreHigherNodes) {
            return updatedElements
          }
        }
      }
    }
  }

  const updatedElements = getUpdatedElements(childElements, elements, goDown)

  return updatedElements
}

const getUpdatedElements = (elementsToUpdate: Node[], allElements: Elements, goDown: boolean) => {
  const idOfElementsThatNeedsUpdate = elementsToUpdate.map((element) => element.id)
  const updatedElements = allElements.map((el: FlowElement) => {
    if (idOfElementsThatNeedsUpdate.includes(el.id) && isNode(el)) {
      const updatedPosition = {
        x: el.position.x,
        y: el.position.y + (goDown ? getFragmentY() : -getFragmentY()),
      }
      const element = { ...el, position: updatedPosition }
      return element
    }

    return el
  })
  return updatedElements
}

const getParentMergeNodes = (mergeNode: Node, elements: Elements) => {
  const { edges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const mergeParents = edges
    .filter((edge) => edge.target === mergeNode.id)
    .map((edge) => getElementById(elements, edge.source) as Node)
    .filter((node) => !!node)

  return mergeParents
}
/**
 * Function that gets the parent branch node of a specific node
 * @param    {Node} node - Node that you are looking for parent.
 * @param    {Elements} elements - Elements where the parent can be.
 * @return   {Node | undefined} - Parent branch if there is one or undefined.
 */
export const getParentBranchNode = (node: Node, elements: Elements): Node | undefined => {
  let parentBranchNode
  ;(function transverse(nodes: Node<{ type: NodeTypeEnum }>[]) {
    const parentNode: Node<{ type: NodeTypeEnum }> = nodes[0]
    if (parentNode) {
      if (parentNode?.data?.type === NodeTypeEnum.Branch) {
        parentBranchNode = { ...parentNode }
        return parentBranchNode
      }
      transverse([...getIncomers(parentNode, elements)] as Node<{ type: NodeTypeEnum }>[])
    }
  })(getIncomers(node, elements) as Node<{ type: NodeTypeEnum }>[])
  return parentBranchNode
}
/**
 * Function that gets the child branch node of a specific node
 * @param    {Node} node - Node that you are looking for child node.
 * @param    {Elements} elements - Elements where the child node can be.
 * @return   {Node | undefined} - Child branch if there is one or undefined.
 */
export const getChildBranchNode = (node: Node, elements: Elements): Node | undefined => {
  let childBranchNode
  ;(function transverse(nodes: Node<SegmentBuilderData>[]) {
    const childNode: Node<{ type: NodeTypeEnum }> = nodes[0]
    if (childNode) {
      if (childNode?.data?.type === NodeTypeEnum.Branch) {
        childBranchNode = childNode
        return childBranchNode
      }

      transverse([...getOutgoers(childNode, elements)] as Node<SegmentBuilderData>[])
    }
  })(getOutgoers(node, elements) as Node<SegmentBuilderData>[])

  return childBranchNode
}
/**
 * Function that gets the siblings branches (on X axis)
 * @param    {Node} node - Node that you are looking for siblings branches.
 * @param    {Elements} elements - Elements where the siblings branches can be.
 * @return   { parentBranch: Node | undefined; childBranch: Node | undefined } - The parent branch (upper branch) and child branch (lower branch) on x-axis if there is one or undefined.
 */
export const getSiblingsYAxisBranches = (
  node: Node,
  elements: Elements,
): { parentBranch: Node | undefined; childBranch: Node | undefined } => {
  const parentBranch = getParentBranchNode(node, elements)
  const childBranch = getChildBranchNode(node, elements)
  return { parentBranch, childBranch }
}

/**
 * Function that gets the siblings nodes (on X axis)
 * @param    {Node} node - Node that you are looking for siblings nodes.
 * @param    {Elements} elements - Elements where the siblings nodes can be.
 * @return   { parentNode: Node; childNode: Node } - The parent node (upper node) and child node (lower node) on x-axis if there is one or undefined.
 */
export const getSiblingsYAxisNodes = (node: Node, elements: Elements): { parentNode: Node; childNode: Node } => {
  const parentNode = getIncomers(node, elements)[0]
  const childNode = getOutgoers(node, elements)[0]
  return { parentNode, childNode }
}

// Instead of using directly the constant variable I used a function to get the value because it can be mocked

export const getFragmentX = (): number => FRAGMENT_X
export const getFragmentY = (): number => FRAGMENT_Y

export const getInitialY = (): number => INITIAL_Y_POSITION
export const getInitialX = (): number => INITIAL_X_POSITION

export const getMergeTargetMaxY = (mergeTargetNode: Node[]) => {
  const configuredMergeMaxY = mergeTargetNode.reduce(
    (prev, curr) => {
      return curr.position.y > prev.position.y ? curr : prev
    },
    { position: { y: 0, x: 0 } },
  )
  return configuredMergeMaxY
}

/**
 * Function that checks if a node is inner a merge
 * @param node the node we want to check if it's inner merge
 * @param elements the array of all nodes and edges
 * @returns the merge node where the element it's inner or undefined
 */
export const getInnerMerge = (node: Node<SegmentBuilderData>, elements: Elements) => {
  const { nodes, edges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const basicElements = [...nodes, ...edges]
  const parentBranches = getParentElements({ startNode: node, elements: basicElements, nodeType: NodeTypeEnum.Branch })
  const childNodes = getChildNodes(node, basicElements)

  if (node.data!.type === NodeTypeEnum.Branch) {
    parentBranches.push(node)
  } else if (!parentBranches.length) {
    const { parentBranch } = getSiblingsYAxisBranches(node, elements)
    if (parentBranch) {
      parentBranches.push(parentBranch)
    }
  }
  let foundMerge

  for (const branch of parentBranches) {
    const firstNodeNoBranch = getBranchFirstNoNode(branch, basicElements)

    if (firstNodeNoBranch) {
      const childOfBranch = getChildNodes(branch, basicElements)
      foundMerge = childOfBranch.find(
        (childNode: Node<{ type: NodeTypeEnum; payload: ConfigPanelPayload }>) =>
          childNode.data?.type === NodeTypeEnum.Merge &&
          childNode.data?.payload?.targets?.length &&
          childNodes.includes(childNode),
      )
      break
    }
  }

  return foundMerge
}

export function sortNodeByPositionY(a: Node, b: Node) {
  return a.position.y - b.position.y
}

interface TraverseParentProps {
  startNode: Node<SegmentBuilderData>
  elements: Elements
  nodeType?: NodeTypeEnum
}
/**
 * Function that returns parent nodes
 * @param param0 The object to find nodes
 * @returns The array of nodes found
 */
export const getParentElements = ({ startNode, elements, nodeType }: TraverseParentProps): Node[] => {
  const parentBranches: Node[] = []
  // go for all parent nodes
  ;(function traverseParent({ startNode, elements, nodeType }: TraverseParentProps) {
    const parentBranch = getParentTypedNode({ node: startNode, elements, nodeType })
    if (parentBranch) {
      parentBranches.push(parentBranch)
      traverseParent({
        startNode: parentBranch,
        elements,
        nodeType,
      })
    } else {
      return parentBranches
    }
  })({
    startNode,
    elements,
    nodeType,
  })

  return parentBranches
}

/**
 * Function that creates the id of an edge bsed on source and terget
 * @param source The node id where it start the edge
 * @param target The node id where it end the edge
 * @returns
 */
export function getEdgeId(source: string, target: string) {
  return `e${source}-${target}`
}

/**
 * Function that create a merge Edge (arrow) based on source and target ids
 * @param source The node id where it start the edge
 * @param target The node id where it end the edge
 * @returns The edge structure
 */
export function mergeArrowEdge(source: string, target: string): Edge {
  const arrow: Edge = {
    id: getEdgeId(source, target),
    source,
    target,
    type: 'custom',
    data: { edgeLabel: BranchLabelEnum.Yes, edgeType: NodeTypeEnum.Merge },
    sourceHandle: 's-bottom',
    targetHandle: 't-right',
    arrowHeadType: ArrowHeadType.Arrow,
  }
  return arrow
}
