import classNames from 'classnames'

import { LogoService } from 'services/Utils/logo'
import { getNormalize } from 'services/Utils/parseString.utils'

type shapeType = 'round'

interface LogoProps {
  platformName: string
  shape?: shapeType
  bordered?: boolean
}

/**
 * Component that display the logo image based on platform name
 * @param {LogoProps} props The props
 * @param {string} props.platformName The platform name.
 * @param {shapeType} [props.shape] The shape of element default round.
 * @param {boolean} [props.bordered] Display a border or not default is true.
 * @returns The Logo react component.
 */
const Logo = ({ platformName, shape = 'round', bordered = true }: LogoProps) => {
  return (
    <div
      key={`${platformName}`}
      className={classNames('platform__logo', `platform__logo--${shape}`, { bordered })}
      data-testid='logo'>
      <img src={LogoService.getIcon(getNormalize(platformName))} alt={platformName} data-testid='logo-image' />
    </div>
  )
}

export default Logo
