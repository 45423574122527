import { downloadData } from 'aws-amplify/storage'
import isEqual from 'utils/isEqual'

import { API } from 'api/api'
import { LoggerService } from 'services/LogService/LogService'
import { openNotificationPopup } from 'services/Utils/openNotificationPopup'

import type { Elements, FlowElement, Node } from 'react-flow-renderer'

import type { CurrentUsedAggregation, SegmentBuilderData, PayloadData, Item } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum, ActionEnum } from 'models/motion/motionBuilder.model'
import type { NodePayload, SendEmailPayload, SendTestMessagePayload } from 'models/motion.model'
import { NodeTypeEnum } from 'models/motion.model'

export const defaultPanelSizes = { small: '432px', medium: '736px', large: '1080px' }
export const actionsType = [ActionTypeEnum.Create]

const elementsWithSmallDrawer = [NodeTypeEnum.TimeDelay, ActionTypeEnum.UpdateAccount, NodeTypeEnum.Merge]

export const getDrawerSize = (data: SegmentBuilderData) => {
  if (data.action === ActionEnum.Create) {
    return true
  } else {
    if (elementsWithSmallDrawer.includes(data.actionType || data.type)) {
      return true
    } else {
      return false
    }
  }
}

export const handleSpecialCase = (panelType: ActionTypeEnum, type: ActionEnum | undefined) => {
  if (panelType === ActionTypeEnum.UpdateAccount && type === ActionEnum.Update) {
    return ActionTypeEnum.Create
  }

  if (panelType === ActionTypeEnum.TriggerEvent) {
    return ActionTypeEnum.TriggerEvent
  }
}

const isValidValue = (value: any) => {
  return value !== undefined && value !== null && value !== ''
}

export const doesCriteriaContainEmptyInputs = (payload: PayloadData): boolean => {
  if (!payload?.groups) {
    return false
  }

  return payload.groups.some((criteria) => {
    return criteria.groups.some((group) => {
      if (typeof group.value === 'boolean') {
        return false
      }

      const isWhitespace = typeof group.value === 'string' ? group.value.trim().length < 1 : false
      const isDynamicInputWhitespace =
        typeof group.value === 'object' && group.value !== null && 'value' in group.value && group.value?.value
          ? (group.value?.value as string).trim().length < 1
          : false

      return (
        (group.value as string)?.length < 1 || isWhitespace || isDynamicInputWhitespace || !isValidValue(group.value)
      )
    })
  })
}

export const getListOfAggregationsUsedAcrossMotion = ({
  aggregationUuid,
  elements,
  elementPayload,
  payload,
}: {
  aggregationUuid: string
  elements: Elements
  elementPayload: PayloadData
  payload: PayloadData
}) => {
  const usedAggregationsList: CurrentUsedAggregation[] = []
  const elementsWithValidPayloads = elements.filter(
    (element: FlowElement<{ payload: NodePayload; type?: NodeTypeEnum }>) => {
      const payload = element.data?.payload?.groups
      const exitConditionPayload = element.data?.payload?.exitCondition?.groups
      return (
        (payload?.length ?? exitConditionPayload?.length ?? 0) > 0 &&
        new Set([NodeTypeEnum.Segment, NodeTypeEnum.Branch, NodeTypeEnum.Loop, NodeTypeEnum.WaitForTrigger]).has(
          element.data?.type as NodeTypeEnum,
        )
      )
    },
  )

  elementsWithValidPayloads.forEach(
    (element: FlowElement<{ payload: NodePayload; nodeId?: string; name?: string; description?: string }>) => {
      const currentElement = element.data?.payload?.exitCondition || element.data?.payload
      currentElement?.groups?.forEach((group, groupIndex) => {
        group.groups.forEach((item, itemIndex) => {
          if ((item as Item).aggregationId === aggregationUuid && (item as Item).isAggregationCriteria) {
            usedAggregationsList.push({
              nodeId: element.data?.nodeId,
              nodeName: element.data?.name,
              nodeDescription: element.data?.description,
              groupIndex,
              itemIndex,
            })
          }
        })
      })
    },
  )

  payload?.groups?.forEach((group, groupIndex) => {
    group.groups.forEach((item, itemIndex) => {
      if (item.aggregationId === aggregationUuid && item.isAggregationCriteria) {
        usedAggregationsList.push({
          nodeName: 'Current node',
          hasSamePayload: isEqual(payload, elementPayload),
          nodeDescription: 'Unsaved payload',
          groupIndex,
          itemIndex,
        })
      }
    })
  })

  return usedAggregationsList
}

export const checkRequiredFields = (updatedNode: Node<SegmentBuilderData>): string[] => {
  const errors: string[] = []
  for (const field of updatedNode.data?.payload?.fields || []) {
    if (field.required && (field.value === undefined || field.value === '')) {
      field.error = true
      errors.push(field.name || '')
    } else {
      field.error = false
    }
  }
  return errors
}

export const showRequiredFieldsError = (requiredFieldsErrors: string[]) => {
  const plural = requiredFieldsErrors.length > 1 ? 'These fields are required:' : 'This field is required:'
  openNotificationPopup(
    `Missing Required Field${requiredFieldsErrors.length > 1 ? 's' : ''}`,
    `${plural} ${requiredFieldsErrors.join(', ')}`,
    'error',
  )
}

export const replaceDynamicInputText = (inputValue: string | { value: string; field: string }) => {
  if (typeof inputValue === 'string') return inputValue

  const regex = /\${dynamicInput}/g
  return inputValue.value.replace(regex, `{${inputValue.field.toUpperCase().replace(/\s+/g, '_')}}`)
}

export const TEST_EMAIL_FROM_ADDRESS = 'noreply@noreply.magnify.io'

export const triggerSendTestEmail = async (to: string, subject: string, htmlContent: string) => {
  if (!htmlContent) {
    openNotificationPopup('Failed to send test email', 'Template must have content to send test email.', 'error')
    return
  }
  try {
    const emailPayload: SendEmailPayload = {
      from: TEST_EMAIL_FROM_ADDRESS,
      to: replaceDynamicInputText(to),
      subject: replaceDynamicInputText(subject),
      text: '',
      html: htmlContent,
    }

    const response = await API.motions.sendTestEmail(emailPayload)
    if (response && response.status !== 200) {
      throw new Error(response.message)
    }

    openNotificationPopup(
      'Test email sent',
      `You have sent a test email from ${TEST_EMAIL_FROM_ADDRESS} to ${to}`,
      'success',
    )
  } catch (error: unknown) {
    if (error instanceof Error && error.message) {
      openNotificationPopup('Failed to send test email', error.message, 'error')
    } else {
      openNotificationPopup('Failed to send test email', 'Something went wrong sending the test email', 'error')
    }
  }
}

export const triggerSendTestMessage = async (to: string, from: string, message: string) => {
  if (!to) {
    openNotificationPopup('Failed to send test message', 'Message must have a "to"', 'error')
    return
  }
  try {
    const messagePayload: SendTestMessagePayload = {
      from,
      to: replaceDynamicInputText(to),
      message: replaceDynamicInputText(message ?? ''),
    }

    const response = await API.motions.sendTestMessage(messagePayload)
    if (response && response.status !== 200) {
      throw new Error(response.message)
    }

    openNotificationPopup('Test message sent', `You have sent a test message from ${from} to ${to}`, 'success')
  } catch (error: unknown) {
    if (error instanceof Error && error.message) {
      openNotificationPopup('Failed to send test message', error.message, 'error')
    } else {
      openNotificationPopup('Failed to send test message', 'Something went wrong sending the test message', 'error')
    }
  }
}

const parseFilename = (filename: string) => {
  const filenameArray = filename.split('/')
  let userFilename: string

  if (filenameArray.includes('executing')) {
    userFilename = 'Executing Motion Latest Execution Segment'
  } else if (filenameArray.includes('draft')) {
    userFilename = 'Saved Motion Generated Segment'
  } else {
    userFilename = 'Unsaved Motion Generated Segment'
  }

  const dateWithExtension = filenameArray[filenameArray.length - 1]
  const date = dateWithExtension.split('.csv')[0]

  return `${userFilename} - ${date}`
}

/**
 * Generate a download anchor that self clicks from a AWS Amplify Storage blob.
 * Taken verbatim from the example docs.
 * @param {Blob} blob The AWS Amplify Storage.get response that will be downloaded.
 * @param {string} [filename='download'] The filename that be used for the downloaded file.
 * @returns {HTMLAnchorElement} The download link as an anchor element.
 * @see {@link https://docs.amplify.aws/lib/storage/download/q/platform/js/#file-download-option}
 */
const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export const downloadSegment = async (filename: string) => {
  try {
    const parsedFilename = parseFilename(filename)
    const storageResult = await downloadData({
      key: filename,
      options: {
        accessLevel: 'guest',
      },
    }).result
    // Convert the response to a blob.
    const blob = await storageResult.body.blob()
    downloadBlob(blob, `${parsedFilename}.csv`)
  } catch (error: unknown) {
    if (error instanceof Error) {
      openNotificationPopup('Something went wrong trying to download the segment CSV', error.message, 'error')
    }
    LoggerService.error({
      message: 'Something went wrong trying to download the segment CSV. Please try again',
      error,
    })
  }
}
