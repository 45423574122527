import { createContext } from 'react'

import type { ReactNode } from 'react'

export interface ModalContextStore {
  isDynamicInputModalOpen: boolean
  setIsDynamicInputModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentDynamicInputModalKey: string
  setCurrentDynamicInputModalKey: React.Dispatch<React.SetStateAction<string>>
}

export interface ModalProviderProps {
  store: ModalContextStore
  children: ReactNode
}

export const ModalContext = createContext<ModalContextStore>({} as ModalContextStore)

export const ModalProvider = ({ store, children }: ModalProviderProps) => {
  return <ModalContext.Provider value={store}>{children}</ModalContext.Provider>
}
