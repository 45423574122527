import { Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import isEqual from 'utils/isEqual'

import { Breadcrumbs } from 'components/common/Breadcrumb'
import { Search } from 'components/common/Search/Search'
import {
  searchFields,
  getMenuListFromPath,
} from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource/SegmentSidebarUtils'
import SidebarListView from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource/SidebarListView'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import useStore from 'store/useStore'

import type { DragEvent } from 'react'

import type { MetadataDescription, MetadataRoot } from 'models/metadata.model'
import type { BreadcrumbInfo, SourceData } from 'models/motion/motionBuilder.model'
import type { MetricItem } from 'models/motion.model'

interface SegmentSidebarProps {
  displayHeader?: boolean
  handleDragStart?: (e: DragEvent<HTMLDivElement>, item: SourceData, breadCrumbItem: any[], position: any) => void
  handleDragEnd?: () => void
  handleSelectDataField?: (item: MetricItem) => void
}

const SegmentSidebar = observer(
  ({ displayHeader = true, handleDragStart, handleDragEnd, handleSelectDataField }: SegmentSidebarProps) => {
    const { metadataStore, aggregationsDataStore, motionStore } = useStore()
    const {
      metadata,
      criteriaLocatorMetadata,
      addObjectsEntityType,
      setViewListMetadata,
      breadCrumbItems,
      setBreadCrumbItems,
      shouldApplyLocalMetadataFilter,
      setFilteredMetadataList,
    } = metadataStore

    const [searchInput, setSearchInput] = useState<string>('')

    const isLoadingList =
      metadataStore.isLoading || metadataStore.isSearchLoading || metadataStore.customMetadataLoading

    const handleResetBreadcrumbs = () => {
      if (isLoadingList || criteriaLocatorMetadata.isOpen) {
        return
      }

      metadataStore.setCurrentItem(null)
      setViewListMetadata(metadata, true)
      metadataStore.setHasError(false)
    }

    useEffect(() => {
      setFilteredMetadataList(metadataStore.viewListMetadata)
    }, [metadataStore.viewListMetadata])

    useEffect(() => {
      if (Object.keys(metadataStore.currentItem).length === 0) {
        setViewListMetadata(metadata, true)

        setDisplay(false)
        setBreadCrumbItems([])
      }
    }, [metadataStore.currentItem])

    const handleSelectOption = async (item: MetadataDescription) => {
      if (motionStore.isSegmentBuilderEditDisabled) return

      metadataStore.setCurrentItem(item)
      const options = metadataStore.currentItem
      const alreadyLoadedMetadata = metadataStore.getLoadedMetadata({
        metadata,
        options,
        iterationNumber: 0,
      })
      if (!alreadyLoadedMetadata?.length) {
        await metadataStore.get(options)
      } else {
        metadataStore.setViewListMetadata({ data: alreadyLoadedMetadata })
      }

      addNewBreadcrumb(item)
    }

    const handleSelectBreadcrumb = async (index: number) => {
      if (isLoadingList || criteriaLocatorMetadata.isOpen) {
        return
      }

      const path = breadCrumbItems[index].path
      const remainedBreadcrumbs = breadCrumbItems.filter((breadcrumb) => {
        return path.includes(breadcrumb.name)
      })

      if (isEqual(breadCrumbItems, remainedBreadcrumbs)) {
        return
      }

      setBreadCrumbItems(remainedBreadcrumbs)
      delete metadataStore.currentItem.field
      const displayName = remainedBreadcrumbs[remainedBreadcrumbs.length - 1]?.magnifyDisplayName

      const currentItem: MetadataDescription = {
        name: remainedBreadcrumbs[remainedBreadcrumbs.length - 1].name,
        entityType: remainedBreadcrumbs[remainedBreadcrumbs.length - 1].entityType,
        data: [],
        connections: [],
        ...(displayName && { magnifyDisplayName: displayName }),
      }

      metadataStore.setCurrentItem(currentItem)
      const viewItems = getMenuListFromPath(path, metadata.data) as MetadataRoot

      if (!viewItems.data) {
        const options = { ...metadataStore.currentItem }
        delete options.object
        await metadataStore.get(options)
      }

      const viewItemsWithTypes = addObjectsEntityType(viewItems)
      setViewListMetadata({ data: viewItemsWithTypes }, true)
      metadataStore.setHasError(false)
      setSearchInput('')
    }

    function addNewBreadcrumb(newItem: MetadataDescription) {
      const path: string[] = []
      breadCrumbItems.forEach((element) => {
        path.push(element.name)
      })
      const breadcrumb: BreadcrumbInfo = {
        name: newItem.name,
        path: [...path, newItem.name],
        entityType: newItem.entityType,
        ...(newItem.magnifyDisplayName && { magnifyDisplayName: newItem.magnifyDisplayName }),
      }

      setBreadCrumbItems([...breadCrumbItems, breadcrumb])
    }

    const filter = async (inputValue: string) => {
      if (!inputValue.length && !criteriaLocatorMetadata.isOpen) {
        setFilteredMetadataList(metadataStore.viewListMetadata)
      } else {
        if (criteriaLocatorMetadata.isOpen || shouldApplyLocalMetadataFilter()) {
          const fieldsToFilter = criteriaLocatorMetadata.isOpen
            ? metadataStore.criteriaLocatorMetadata.metadataViewList
            : metadataStore.viewListMetadata
          const result = searchFields(fieldsToFilter, inputValue)
          setFilteredMetadataList(result)
        } else {
          const { platform } = metadataStore.currentItem
          await metadataStore.fetchSearch({
            search: inputValue,
            platform,
          })
          metadataStore.setDisplaySearchResult(true)
        }
      }
    }

    const setDisplay = (value: boolean) => {
      if (!criteriaLocatorMetadata.isOpen) {
        metadataStore.setDisplaySearchResult(value)
        if (!value && metadataStore.hasError) {
          metadataStore.setHasError(false)
        }
      }
    }
    const handleClose = () => {
      setSearchInput('')
      metadataStore.setHasError(false)

      if (shouldApplyLocalMetadataFilter()) {
        setFilteredMetadataList(metadataStore.viewListMetadata)
      } else {
        if (criteriaLocatorMetadata.isOpen) {
          metadataStore.setViewListMetadata({ data: criteriaLocatorMetadata.metadataViewList })
        } else {
          setDisplay(false)
          metadataStore.setCurrentItem(null)
        }
      }
    }

    const handleSelectField = (item: MetadataDescription) => {
      handleSelectDataField?.({
        field: item.key,
        name: item.name,
        type: item.type,
        platform: item.platform,
        object: item.object,
        ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
      })
    }

    const getMetadataList = () => {
      if (isLoadingList) {
        return <Skeleton active></Skeleton>
      }

      return metadataStore.hasError ? (
        <span>{metadataStore.errorMsg}</span>
      ) : (
        <SidebarListView
          breadCrumbItems={breadCrumbItems}
          displayHeader={displayHeader}
          handleSelectOption={handleSelectOption}
          handleSelectField={handleSelectField}
          handleDragStart={handleDragStart}
          handleDragEnd={handleDragEnd}
          setSearchInput={setSearchInput}
        />
      )
    }

    return (
      <div className='segment-sidebar' data-testid='segment-sidebar'>
        {displayHeader && (
          <Search
            placeholder='Search data'
            onFilter={filter}
            searchType={['onEnter', 'onClick']}
            setDisplay={setDisplay}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            onClose={handleClose}
            isDisabled={motionStore.isSegmentBuilderEditDisabled}
          />
        )}

        {!metadataStore.displaySearchResult &&
          (displayHeader ? (
            <Breadcrumbs
              disabled={isLoadingList}
              breadCrumbItems={breadCrumbItems}
              handleSelectBreadcrumb={handleSelectBreadcrumb}
              handleResetBreadcrumbs={handleResetBreadcrumbs}
            />
          ) : (
            <div className='segment-sidebar__default-breadcrumb'>
              <span>
                {(breadCrumbItems?.[0] ?? aggregationsDataStore.currentItem) &&
                  getMenuItemIcon(
                    breadCrumbItems[0] ?? { entityType: 'platform', name: aggregationsDataStore.currentItem?.platform },
                  )}
              </span>
              <span
                title={breadCrumbItems?.[breadCrumbItems.length - 1]?.name ?? aggregationsDataStore.currentItem?.object}
                data-testid='filters-object'>
                {breadCrumbItems?.[breadCrumbItems.length - 1]?.magnifyDisplayName ??
                  breadCrumbItems?.[breadCrumbItems.length - 1]?.name ??
                  aggregationsDataStore.currentItem?.magnifyDisplayName ??
                  aggregationsDataStore.currentItem?.object}
              </span>
            </div>
          ))}

        {getMetadataList()}
      </div>
    )
  },
)

export default SegmentSidebar
