import { useEffect, useMemo, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { getChartOptions } from 'components/MotionDetails/GoalMetric/Chart/helpers/options'
import { ChartTooltip } from 'components/MotionDetails/GoalMetric/Chart/Tooltip'

import type { ChartOptions } from 'chart.js'
import type { ForwardedRef } from 'react'

import type { GoalMetricsData } from 'models/reporting.model'

import type { TooltipPosition } from 'components/MotionDetails/GoalMetric/Chart/helpers/types'
import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

interface ChartProps {
  labels: string[]
  data: GoalMetricsData[]
  title: {
    yTitle: string
    chartTitle: string
  }
  annotation: {
    label: string | number
    limit: number
    tooltip: string
  }
  visualization: string
}

const Chart = ({
  labels,
  data,
  title: { yTitle, chartTitle },
  annotation: { label, limit, tooltip: tooltipText },
  visualization,
}: ChartProps) => {
  const chartRef: ForwardedRef<ChartJSOrUndefined<'bar', number[] | number[][], unknown>> | undefined = useRef(null)
  const [chartData, setChartData] = useState<{ labels: string[]; datasets: GoalMetricsData[] }>({
    datasets: [],
    labels: [],
  })
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null)
  const [isChartRenderComplete, setIsChartRenderComplete] = useState<boolean>(false)
  const [yAnnotationAdjustment, setYAnnotationAdjustment] = useState<number | null>(null)

  const options = useMemo(
    () =>
      getChartOptions({
        tooltipPosition,
        setTooltipPosition,
        isChartRenderComplete,
        setIsChartRenderComplete,
        yAnnotationAdjustment,
        setYAnnotationAdjustment,
        showLegend: visualization === 'quartile',
        yTitle,
        chartTitle,
        annotationLimit: limit,
        annotationLabel: label,
        labels,
      }),
    [
      tooltipPosition,
      isChartRenderComplete,
      yAnnotationAdjustment,
      visualization,
      yTitle,
      chartTitle,
      limit,
      label,
      labels,
    ],
  )

  // Initialize chart with default values
  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      labels,
      datasets: data,
    }

    setChartData(chartData)
  }, [chartRef])

  return (
    <ChartTooltip tooltipText={tooltipText} tooltipPosition={tooltipPosition}>
      <div className='canvas-container motion-details-goal-metric'>
        <Bar data-testid='canvas' ref={chartRef} data={chartData} options={options as ChartOptions<'bar'>} />
      </div>
    </ChartTooltip>
  )
}
Chart.displayName = 'Chart'

export default Chart
