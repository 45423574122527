import { Modal } from 'antd'

import { Button } from 'components/common'

export interface EditWarningModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  actionConfirm?: () => void
  actionCancel?: () => void
  title?: string
  bodyText?: string
}

const EditWarningModal = ({
  isModalOpen,
  setIsModalOpen,
  actionConfirm,
  actionCancel,
  title = 'Edit Email',
  bodyText = 'This action will update all Motions that use this email. To only modify this email for this Motion, Save as New Email to create a new copy.',
}: EditWarningModalProps) => {
  /** Handles affirmative actions and closes the modal. */
  const handleOk = () => {
    if (typeof actionConfirm === 'function') {
      actionConfirm()
    }
    setIsModalOpen(false)
  }

  /** Handles cancel actions and closes the modal. */
  const handleCancel = () => {
    if (typeof actionCancel === 'function') {
      actionCancel()
    }
    setIsModalOpen(false)
  }

  return (
    <Modal
      title={title}
      width={656}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='edit-email-cancel-btn' text='Cancel' type='secondary' onClickHandler={handleCancel} />,
        <Button
          key='edit-email-edit-btn'
          text='Continue to Edit'
          onClickHandler={handleOk}
          className='footer__button'
        />,
      ]}>
      <p className='modal__text'>{bodyText}</p>
    </Modal>
  )
}
EditWarningModal.displayName = 'EditWarningModal'

export default EditWarningModal
