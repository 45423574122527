import { Layout } from 'antd'
import Table from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'

import { Button, DynamicCell, Heading } from 'components/common'
import FileUploadModal from 'components/FileUploadModal'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { ColumnType } from 'antd/es/table'

import type { FileAttachment } from 'models/file-attachments.model'
import type { TableColumnTypeEnum } from 'models/insights'

export interface FileAttachmentsTableColumn {
  key: string
  title: string
  type: `${TableColumnTypeEnum}`
  align: string
}

function getTableColumns(data: FileAttachmentsTableColumn[]) {
  return data.map((column, index) => ({
    title: column.title,
    className: '',
    dataIndex: column.key,
    align: column.align,
    key: 'id',
    render: (_: any, record: FileAttachment) => {
      return (
        <div className='table__row'>
          <DynamicCell column={column} record={record} />
        </div>
      )
    },
  }))
}

const columns = [
  {
    key: 'name',
    title: 'File Name',
    type: 'string',
    align: 'left',
  },
  {
    key: 'size',
    title: 'File Size',
    type: 'number',
    align: 'left',
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string',
    align: 'left',
  },
]

const FileAttachmentsPage = () => {
  const { fileAttachmentsStore } = useStore()
  useDisplayErrorNotification(fileAttachmentsStore)

  useEffect(() => {
    // Fetch the list of file attachments.
    fileAttachmentsStore.getFileList().catch(console.error)
  }, [])

  const accountTableColumns = useMemo(
    () => getTableColumns(columns as FileAttachmentsTableColumn[]),
    [fileAttachmentsStore.data.list],
  )

  return (
    <Layout className='file-attachments' data-testid='file-attachments-layout'>
      <section className='file-attachments-table'>
        <Heading variant='7' level='2'>
          File Attachments
        </Heading>

        <Table
          dataSource={fileAttachmentsStore.data.list}
          columns={accountTableColumns as ColumnType<FileAttachment>[]}
          pagination={{ hideOnSinglePage: true }}
          showSorterTooltip={false}
        />
      </section>
      <FileUploadModal />
      <Button text='Upload File' onClickHandler={() => fileAttachmentsStore.setModal(true)} />
    </Layout>
  )
}
FileAttachmentsPage.displayName = 'FileAttachmentsPage'

export default observer(FileAttachmentsPage)
