import { Badge, Popover, Row } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import NotificationBell from 'assets/icons/sideMenu/notification-bell.svg?react'
import { Button, Heading } from 'components/common'
import { NOTIFICATIONS_PER_PAGE } from 'configs'
import useStore from 'store/useStore'

export const NotificationsPopUp = observer(() => {
  const { notificationStore } = useStore()
  const { unSeenNotifications, markSeenNotifications } = notificationStore

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)

  const content = (
    <div className='popup-content'>
      <Row>
        <Heading level='3' variant='2' className='notifications-popup-heading'>
          Notifications
        </Heading>
        <Link to={'/account-settings/notifications'}>
          <Button
            testId='notifications-popup-view-all'
            text='View all'
            link
            onClickHandler={() => {
              changeVisibilityHandler(false)
            }}
          />
        </Link>
      </Row>

      {unSeenNotifications.length ? (
        unSeenNotifications.map((notification) => (
          <div
            key={notification.id}
            className={`notification-card notification-card--${
              notification.type === 's3Download' ? 'success' : notification.priority
            }`}>
            <div className='notification-text'>
              <span>{notification.message}</span>
              {notification?.info?.app && (
                <Link to={`/integrations/${notification.info.app}/edit/${notification.info.connectionId}`}>
                  Adress the issue
                </Link>
              )}
            </div>

            <p className='notification-time'>
              {dayjs(notification.createdAt).format('MMM DD, YYYY')} at {dayjs(notification.createdAt).format('h:mm A')}
            </p>
          </div>
        ))
      ) : (
        <div className='notifications-popup-container'>No new notifications</div>
      )}
    </div>
  )
  const changeVisibilityHandler = (visible: boolean) => {
    setIsPopupVisible(visible)
    if (!visible && unSeenNotifications.length) {
      markSeenNotifications().catch(console.error)
    }
  }

  return (
    <Popover
      content={content}
      trigger='click'
      overlayClassName='notifications-popup'
      open={isPopupVisible}
      onOpenChange={changeVisibilityHandler}
      data-testid='notifications-popup'>
      <Badge
        count={
          unSeenNotifications.length > NOTIFICATIONS_PER_PAGE - 1
            ? `${NOTIFICATIONS_PER_PAGE - 1}+`
            : unSeenNotifications.length
        }
        offset={[-5, 0]}
        data-testid='notifications-popup-badge'>
        <NotificationBell data-testid='notifications-popup-bell' />
      </Badge>
    </Popover>
  )
})
