/**
 * Sorts the segment risk level array into high/medium/low
 * @param a string
 * @param b string
 */
export const sortSegmentRiskLevel = (a: string, b: string) => {
  if (a === b) {
    return 0
  }

  if (a === 'high' && b === 'medium') {
    return -1
  }

  if (a === 'high' && b === 'low') {
    return -1
  }

  if (a === 'medium' && b === 'low') {
    return -1
  }

  return 1
}

/**
 * Formats a number with an abbreviation for thousands, millions, billions, etc.
 *
 * @param {number} num - The number to be formatted.
 * @param {number} fractionDigits - The number of digits, 2 by default.
 * @returns {string} The formatted number with an abbreviation.
 */
export const formatNumberWithAbbreviation = (num: number, fractionDigits: number = 1): string => {
  const abbreviations = ['', 'K', 'M', 'B', 'T']

  const absNum = Math.abs(num)
  const abbreviationIndex = Math.max(0, Math.min(Math.floor(Math.log10(absNum) / 3), abbreviations.length - 1))

  const basicNumber = absNum / Math.pow(10, abbreviationIndex * 3)
  const formattedNum = Number.isInteger(basicNumber) ? basicNumber : basicNumber.toFixed(fractionDigits)

  return num >= 0
    ? `${formattedNum}${abbreviations[abbreviationIndex]}`
    : `-${formattedNum}${abbreviations[abbreviationIndex]}`
}

/**
 * Format a number as a currency with an abbreviation.
 *
 * @param {number} num - The number to format as currency.
 * @param {number} [fractionDigits=1] - The number of decimal places to round to (default is 1).
 * @returns {string} The formatted currency string with abbreviation.
 */
export const formatCurrencyWithAbbreviation = (num: number, fractionDigits: number = 1) => {
  const numberWithAbbreviation = formatNumberWithAbbreviation(num, fractionDigits)

  const isNegative = numberWithAbbreviation.includes('-')

  // Add currency symbol ('$') and handle negative numbers
  return isNegative ? numberWithAbbreviation.replace('-', '-$') : `$${numberWithAbbreviation}`
}
