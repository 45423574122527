import { Empty } from 'antd'
import Carousel from 'antd/lib/carousel'
import { useRef, useState, useEffect } from 'react'

import { IconChevronRight, IconChevronLeft } from 'components/common/Icons/Icons'
import OperationalStatsCard from 'components/MotionDetails/OperationalStats/OperationalStatsCard'
import {
  getMagnifySendEmailOperationalStatsCardDescription,
  getMaxSlide,
  handleSlideChange,
  OPERATIONAL_STATISTICS_METRICS,
  SEND_EMAIL_ALLOWED_EVENTS_ORDERED,
  sortEmailStatisticCards,
  sortStatisticCards,
} from 'components/MotionDetails/OperationalStats/utils'
import Sandbox from 'configs/sandbox'
import useDemoFeature from 'hooks/useDemoFeature'

import type { CarouselRef } from 'antd/lib/carousel'

import type { TenantInMotionReporting } from 'models/observability.model'

interface OperationalStatsProps {
  operationalStatistics: TenantInMotionReporting | undefined
  isOperationalStatisticsEmpty: boolean
}

const OperationalStats = ({ operationalStatistics, isOperationalStatisticsEmpty }: OperationalStatsProps) => {
  const { enableDemoMockApi } = useDemoFeature()
  const slider = useRef<CarouselRef | null>(null)
  const [showArrow, setShowArrow] = useState({
    left: false,
    right: false,
  })

  useEffect(() => {
    const maxSlide = getMaxSlide(slider)
    setShowArrow({
      left: false,
      right: maxSlide > -1,
    })
  }, [slider])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: false,
    arrows: false,
    beforeChange: (current: number, next: number) =>
      handleSlideChange(
        // AntD Provides an any type for innerSlider
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        slider.current?.innerSlider.props.slidesToShow,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        slider.current?.innerSlider.state.slideCount,
        current,
        next,
        setShowArrow,
      ),
    responsive: [
      {
        breakpoint: 6000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const getStatisticCards = (operationalStatistics?: TenantInMotionReporting) => {
    if (!operationalStatistics) return null

    const statisticCards: React.JSX.Element[] = []

    if (operationalStatistics.journey) {
      statisticCards.push(
        <OperationalStatsCard
          key={OPERATIONAL_STATISTICS_METRICS.JOURNEY}
          metric={OPERATIONAL_STATISTICS_METRICS.JOURNEY}
          service={OPERATIONAL_STATISTICS_METRICS.JOURNEY}
          stats={operationalStatistics.journey}
        />,
      )
    }

    // Loop through actions and create cards
    if (Array.isArray(operationalStatistics.actions)) {
      operationalStatistics.actions.forEach((action) => {
        Object.entries(action).forEach(([actionKey, actionValue]) => {
          const { statistics } = actionValue
          if (!statistics || Object.entries(statistics ?? {}).length === 0) {
            console.warn('No statistics for', actionKey)
            return
          }

          if (actionKey === OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL && !Sandbox.isEnabled()) {
            const sortedEmailStatisticsCards = sortEmailStatisticCards(
              Object.entries(statistics),
              SEND_EMAIL_ALLOWED_EVENTS_ORDERED,
            )

            const emailCards: JSX.Element[] = []
            sortedEmailStatisticsCards.forEach(([name, value]) => {
              if (SEND_EMAIL_ALLOWED_EVENTS_ORDERED.includes(name)) {
                const description = getMagnifySendEmailOperationalStatsCardDescription(name)
                emailCards.push(
                  <OperationalStatsCard
                    key={actionKey}
                    metric={actionKey}
                    service={actionKey.split('_')[0]}
                    stats={statistics}
                    actionValue={value as string}
                    actionDescription={description}
                  />,
                )
              }
            })
            statisticCards.push(...emailCards)
          } else {
            statisticCards.push(
              <OperationalStatsCard
                key={actionKey}
                metric={actionKey}
                service={actionKey.split('_')[0]}
                stats={statistics}
              />,
            )
          }
        })
      })
    }

    return sortStatisticCards(statisticCards)
  }

  return (
    <div className='operational-stats'>
      <div className='operational-stats__container'>
        {!enableDemoMockApi && isOperationalStatisticsEmpty ? (
          <Empty />
        ) : (
          <div>
            {showArrow.left && (
              <div
                className='operational-stats__carousel-arrow operational-stats__carousel-arrow--left'
                onClick={() => {
                  slider.current?.prev()
                }}>
                <IconChevronLeft />
              </div>
            )}
            <Carousel {...settings} ref={slider}>
              {getStatisticCards(operationalStatistics)}
            </Carousel>
            {showArrow.right && (
              <div
                className='operational-stats__carousel-arrow operational-stats__carousel-arrow--right'
                onClick={() => slider.current?.next()}>
                <IconChevronRight />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default OperationalStats
