export const demoGetAllUsers = [
  {
    username: 'Magnify',
    status: 'CONFIRMED',
    created_at: '2024-01-09T11:17:21.362Z',
    mfaEnabled: false,
    emailNotifications: false,
    smsNotifications: false,
    tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
    sub: '633aa9c6-b889-4839-a52f-c5a95f569b47',
    email_verified: true,
    roleId: 'fd1d894f-87c2-40f4-948d-669873373148',
    email: 'magnify@magnify.io',
    tenantName: 'Magnify',
  },
  {
    username: 'JoshCrossman',
    status: 'CONFIRMED',
    created_at: '2023-03-23T13:47:51.430Z',
    mfaEnabled: false,
    emailNotifications: false,
    smsNotifications: false,
    tenantId: 'b5c90b87-bc0a-4a50-b85f-bbfe665ea318',
    sub: '91a063d9-9861-4b98-a8b9-5cbd365dc26e',
    email_verified: true,
    roleId: 'fd1d894f-87c2-40f4-948d-669873373148',
    email: 'josh@magnify.io',
    tenantName: 'JoshCrossman',
  },
]
