import { useContext } from 'react'

import SettingsIcon from 'assets/icons/settings'
import ExperimentDoughnutChart from 'pages/Motions/ExperimentDoughnutChart'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'

import type { ABExperimentSegement } from 'models/motion.model'

export interface SettingsBadgeProps {
  title: ABExperimentSegement
  totalAccounts: number
}

const SettingsBadge = ({ title, totalAccounts }: SettingsBadgeProps) => {
  const { selectedExperimentSegementSizes, setShowExperimentModal } = useContext(SegmentBuilderContext)
  return (
    <div className='settings-badge' data-testid='settings-badge' onClick={() => setShowExperimentModal(true)}>
      <h6>{title}</h6>
      <div className='settings-badge__details'>
        <div className='settings-badge__details__chart'>
          <ExperimentDoughnutChart
            segmentSize={selectedExperimentSegementSizes[title]}
            totalAccounts={totalAccounts}
            size={1}
          />
        </div>
        <p>{(selectedExperimentSegementSizes[title] * 100).toFixed(0)}% of segment</p>
      </div>
      <SettingsIcon />
    </div>
  )
}
SettingsBadge.displayName = 'SettingsBadge'

export default SettingsBadge
