import { Layout } from 'antd'

import {
  RecordLocatorCriteria,
  InputFields,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common'
import RecordLocatorModal from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/RecordLocatorModal'

import type { Dispatch, SetStateAction } from 'react'

import type { ConfigPanelPayload } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum } from 'models/motion/motionBuilder.model'

interface UpdatePanelProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const UpdatePanel = ({ payload, setPayload }: UpdatePanelProps) => {
  return (
    <Layout id='action-container' className='action-container'>
      <div className='list'>
        <RecordLocatorModal payload={payload} setPayload={setPayload} />
        <RecordLocatorCriteria actionType={ActionTypeEnum.Update} payload={payload} />
        <InputFields actionType={ActionTypeEnum.Update} payload={payload} setPayload={setPayload} />
      </div>
    </Layout>
  )
}
UpdatePanel.displayName = 'UpdatePanel'

export default UpdatePanel
