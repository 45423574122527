import { Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MotionOutput from 'components/ActiveMotions/components/MotionCard/components/MotionOutput'
import { Callout, LogoList, Status } from 'components/common'

import type { MotionIntegration, MotionStateEnum, SegmentTotal, StateHistoryType } from 'models/motion.model'

interface MotionCardProps {
  journeyId: string
  name: string
  updatedAt: number
  integrations?: MotionIntegration[]
  version: number
  state: MotionStateEnum
  stateHistory?: StateHistoryType
  segmentData?: SegmentTotal[]
  multipleRuns: boolean
}

const CADENDCE = {
  ONCE: 'One-time Run',
  MULTIPLE: 'Multiple Runs',
}

const MotionCard = ({
  journeyId,
  name,
  updatedAt,
  integrations,
  version,
  state,
  stateHistory,
  segmentData,
  multipleRuns,
}: MotionCardProps) => {
  const navigate = useNavigate()
  const handleClickOnCard = useCallback(
    (id, version) => navigate(`/motions/motion/${id}/${version}/details`),
    [navigate],
  )

  const [latestSegmentTotals, setLatestSegmentTotals] = useState<SegmentTotal | null>(null)

  useEffect(() => {
    if (segmentData) {
      const latestSegmentTotals = [...segmentData].sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))

      if (latestSegmentTotals?.length) {
        setLatestSegmentTotals(latestSegmentTotals[0])
      }
    }
  }, [segmentData])

  return (
    <Row
      className={`active-motions-card-container`}
      onClick={() => handleClickOnCard(journeyId, version)}
      data-testid='active-motion-card'>
      <div
        className={
          latestSegmentTotals && (latestSegmentTotals?.totalUsers > 0 || latestSegmentTotals?.totalAccount > 0)
            ? 'active-motions-card-stats'
            : 'active-motions-card-stats-end'
        }>
        {latestSegmentTotals && (latestSegmentTotals?.totalUsers > 0 || latestSegmentTotals?.totalAccount > 0) && (
          <MotionOutput
            users={latestSegmentTotals?.totalUsers || 0}
            accounts={latestSegmentTotals?.totalAccount || 0}
          />
        )}

        <Callout variant='1'>{multipleRuns ? CADENDCE.MULTIPLE : CADENDCE.ONCE}</Callout>
      </div>

      <div className='active-motions-card-title'>
        <span data-testid='motion-title'>{name}</span>
      </div>

      <div className='active-motions-card-footer'>
        <div className='active-motions-card-timestamp' data-testid='timestamp'>
          <Status state={state} lastUpdatedAt={updatedAt} stateHistory={stateHistory} />
        </div>

        <div className='active-motions-card-integrations'>
          <LogoList integrations={integrations?.map((integration) => integration.platform) ?? []} limit={3} />
        </div>
      </div>
    </Row>
  )
}
MotionCard.displayName = 'MotionCard'

export default MotionCard
