import type { IntegrationNew } from 'models/Integration.model'

export const mockedIntegration: IntegrationNew = {
  key: 'pendo',
  displayName: 'Pendo',
  fieldConfig: {
    type: 'object',
    allOf: [
      {
        type: 'object',
        properties: {
          displayName: {
            type: 'string',
            title: 'Name',
            description: 'Please provide a name for this connection. This is used for your reference.',
            minLength: 1,
            order: 0,
          },
        },
        required: ['displayName'],
      },
      {
        type: 'object',
        required: ['token'],
        properties: {
          token: {
            description: 'API Token provided by this platform for authentication.',
            type: 'string',
            title: 'API Token',
            order: 0,
            minLength: 1,
            isSecret: true,
          },
        },
      },
      {},
    ],
    required: [],
  },
}

export const mockedIntegrations: IntegrationNew[] = [
  {
    key: 'hubspot',
    displayName: 'Hubspot',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {
          type: 'object',
          required: ['start_date'],
          properties: {
            start_date: {
              type: 'string',
              title: 'Start Date',
              description:
                "The UTC date and time from which you'd like to replicate data, in the format YYYY-MM-DDT00:00:00Z. All data generated after this date will be replicated.",
              examples: ['2020-10-15T00:00:00Z'],
              pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$',
              format: 'date-time',
              order: 0,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'intercom',
    displayName: 'Intercom',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {
          type: 'object',
          required: ['start_date'],
          properties: {
            start_date: {
              type: 'string',
              title: 'Start Date',
              description:
                "The UTC date and time from which you'd like to replicate data, in the format YYYY-MM-DDT00:00:00Z. All data generated after this date will be replicated.",
              examples: ['2020-10-15T00:00:00Z'],
              pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$',
              pattern_descriptor: 'YYYY-MM-DDTHH:mm:ssZ',
              format: 'date-time',
              order: 0,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'pendo',
    displayName: 'Pendo',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {},
      ],
      required: [],
    },
  },
  {
    key: 'snowflake',
    displayName: 'Snowflake',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['username', 'password'],
          properties: {
            username: {
              description: 'The username for connecting to this platform.',
              type: 'string',
              title: 'Username',
              order: 0,
              minLength: 1,
            },
            password: {
              description: 'The password associated with the username for this platform.',
              type: 'string',
              title: 'Password',
              order: 1,
              minLength: 1,
            },
          },
        },
        {
          type: 'object',
          required: ['host', 'role', 'warehouse', 'database'],
          properties: {
            host: {
              description:
                'The host domain of the snowflake instance (must include the account, region, cloud environment, and end with snowflakecomputing.com).',
              examples: ['account-name.us-east-2.aws.snowflakecomputing.com'],
              type: 'string',
              title: 'Account Name',
              order: 0,
              minLength: 1,
              format: 'uri',
            },
            role: {
              description: 'The role you created for Magnify to access Snowflake.',
              examples: ['MAGNIFY_ROLE'],
              type: 'string',
              title: 'Role',
              order: 1,
              minLength: 1,
            },
            warehouse: {
              description: 'The warehouse you created for Magnify to access data.',
              examples: ['MAGNIFY_WAREHOUSE'],
              type: 'string',
              title: 'Warehouse',
              order: 2,
              minLength: 1,
            },
            database: {
              description: 'The database you created for Magnify to access data.',
              examples: ['MAGNIFY_DATABASE'],
              type: 'string',
              title: 'Database',
              order: 3,
              minLength: 1,
            },
            schema: {
              description: 'The source Snowflake schema tables. Leave empty to access tables from multiple schemas.',
              examples: ['MAGNIFY_SCHEMA'],
              type: 'string',
              title: 'Schema',
              order: 4,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'zendesk-support',
    displayName: 'Zendesk',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {
          type: 'object',
          required: ['subdomain', 'email'],
          properties: {
            subdomain: {
              description:
                'This is your unique Zendesk subdomain that can be found in your account URL. For example, in https://MY_SUBDOMAIN.zendesk.com/, MY_SUBDOMAIN is the value of your subdomain.',
              examples: ['MY_SUBDOMAIN'],
              type: 'string',
              title: 'Subdomain',
              order: 0,
              minLength: 1,
            },
            email: {
              title: 'Email',
              type: 'string',
              description: 'The user email for your Zendesk account.',
              format: 'email',
              order: 2,
              minLength: 1,
            },
            start_date: {
              type: 'string',
              title: 'Start Date',
              description:
                "The UTC date and time from which you'd like to replicate data, in the format YYYY-MM-DDT00:00:00Z. All data generated after this date will be replicated.",
              examples: ['2020-10-15T00:00:00Z'],
              pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$',
              pattern_descriptor: 'YYYY-MM-DDTHH:mm:ssZ',
              format: 'date-time',
              order: 2,
            },
          },
        },
      ],
      required: [],
    },
  },
  {
    key: 'redshift',
    displayName: 'Redshift',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {},
      ],
      required: [],
    },
  },
  {
    key: 'mongodb',
    displayName: 'MongoDB',
    fieldConfig: {
      type: 'object',
      allOf: [
        {
          type: 'object',
          properties: {
            displayName: {
              type: 'string',
              title: 'Name',
              description: 'Please provide a name for this connection. This is used for your reference.',
              minLength: 1,
              order: 0,
            },
          },
          required: ['displayName'],
        },
        {
          type: 'object',
          required: ['token'],
          properties: {
            token: {
              description: 'API Token provided by this platform for authentication.',
              type: 'string',
              title: 'API Token',
              order: 0,
              minLength: 1,
              isSecret: true,
            },
          },
        },
        {},
      ],
      required: [],
    },
  },
]
