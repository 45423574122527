import type { ColumnsType } from 'antd/es/table'

import type { ParticipatedMotion, ParticipatedMotionsTable } from 'models/account.model'

export const getParticipatedMotionsData = (participatedMotions: ParticipatedMotion[]) => {
  const formattedParticipatedMotions = participatedMotions.map((motion: ParticipatedMotion, index: number) => ({
    key: index,
    journeyName: motion.journeyName,
    participatedSince: formatParticipatedSince(motion.participatedSince),
    participationStatus: mapParticipationStatus(motion.participationStatus),
    users: motion?.users ?? 0,
    actions: motion?.actions ?? 0,
  }))

  return formattedParticipatedMotions
}

export const participatedMotionsColumns: ColumnsType<ParticipatedMotionsTable> = [
  {
    title: 'Motion Name',
    dataIndex: 'journeyName',
    key: 'journeyName',
    className: 'participated-motions__table-motion-name',
  },
  {
    title: 'Participated Since',
    dataIndex: 'participatedSince',
    key: 'participatedSince',
    className: 'participated-motions__table-participated-since',
  },
  {
    title: 'Motion Status',
    dataIndex: 'participationStatus',
    key: 'participationStatus',
    className: 'participated-motions__table-participated-status',
  },
]

const mapParticipationStatus = (status: string) => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'In progress'
    case 'DRAFT':
      return 'Draft'
    case 'CREATED':
      return 'Created'
    case 'SUCCEEDED':
      return 'Succeeded'
    case 'FAILED':
      return 'Failed'
    case 'TIMED_OUT':
      return 'Timed out'
    default:
      return 'No status'
  }
}

export const formatParticipatedSince = (participatedSince: string) => {
  const date = new Date(participatedSince)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
