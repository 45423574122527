import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { renderToString } from 'react-dom/server'

import { DIV_NODE_TYPE, DYNAMIC_INPUT, STRING_TYPE, TEXT_NODE_TYPE, Tag } from 'components/common'
import { checkTypeEquality } from 'components/common/DataSourceContainer/index.utils'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import MultiValueInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/MultiValueInput'
import { onPasteReturnRawText } from 'components/MotionBuilder/Utils/event.utils'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import useInputType from 'components/MotionBuilder/Utils/useInputType'
import useStore from 'store/useStore'

import type { Moment } from 'moment-timezone'

import type { CreateActionFields, Item } from 'models/motion/motionBuilder.model'

interface DynamicFieldInputProps {
  item: Item | CreateActionFields
  borderless?: boolean
  openPopover: (value: boolean) => void
  removeTag: () => void
  changeFieldValue: (
    value:
      | boolean
      | number
      | number[]
      | string
      | string[]
      | moment.Moment
      | moment.Moment[]
      | CreateActionFields
      | null,
    fieldKey?: string,
    index?: number,
  ) => void
}

const DynamicInputRegex = {
  textAfter: /\${dynamicInput}(.+)/,
  textBefore: /.+(\${dynamicInput})/,
  spacesBeforeAndAfter: /\s(\${dynamicInput})\s/,
  textBeforeAndAfter: /.+\${dynamicInput}(.+)/,
  spaceBefore: /\s(\${dynamicInput})/,
  textAndSpaceBefore: /.+(\s\${dynamicInput})/,
  spaceAfter: /(\${dynamicInput})\s/,
  spaceAndTextAfter: /\${dynamicInput}\s(.+)/,
}

const DynamicFieldInput = observer(
  ({ item, borderless = false, openPopover, removeTag, changeFieldValue }: DynamicFieldInputProps) => {
    const [html, setHTML] = useState('')
    const [isTagDeleted, setIsTagDeleted] = useState<boolean>(false)
    const { isMultipleInput, isNullishInput } = useInputType(item)
    const { dynamicInputStore, motionStore } = useStore()

    const { setDynamicInputPosition } = dynamicInputStore

    useEffect(() => {
      if (!(item.value as CreateActionFields)?.platform) {
        setHTML((item.value as string) || '')
      } else if (item.value && (item.value as CreateActionFields)?.platform) {
        const value = (item.value as CreateActionFields)?.value ?? ''
        const hasTextBefore = DynamicInputRegex.textBefore.test(value as string)
        const hasTextAfter = DynamicInputRegex.textAfter.test(value as string)
        const inputValue = (value as string).replace(
          DYNAMIC_INPUT,
          renderToString(
            <>
              {!hasTextBefore && <>&nbsp;</>}
              <Tag
                icon={getMenuItemIcon({
                  name: (item.value as CreateActionFields).platform || '',
                  entityType: 'platform',
                })}
                text={(item.value as CreateActionFields)?.field || ''}
                title={`${(item.value as CreateActionFields)?.object}/${(item.value as CreateActionFields)?.field}`}
                openPopover={openPopover}
                removeTag={removeTag}
              />
              {!hasTextAfter && <>&nbsp;</>}
            </>,
          ),
        )
        setHTML(inputValue)
      }
    }, [(item.value as CreateActionFields)?.value, item.value])

    useEffect(() => {
      if (Array.isArray(item.value) && !isMultipleInput && !isNullishInput) {
        return changeFieldValue('', item?.key)
      }

      if (!Array.isArray(item.value) && isMultipleInput && !isNullishInput) {
        return changeFieldValue([], item?.key)
      }

      if (typeof item.value === 'boolean' && !isNullishInput) {
        return changeFieldValue('', item?.key)
      }
    }, [(item as Item).operator])

    const getCaretPosition = () => {
      if (window.getSelection()?.rangeCount !== 0) {
        const range = window.getSelection()?.getRangeAt(0)
        const selectedObj = window.getSelection()
        let rangeCount = 0
        if (selectedObj?.anchorNode?.parentNode?.childNodes) {
          const childNodes = selectedObj.anchorNode.parentNode.childNodes
          for (let i = 0; i < childNodes.length; i++) {
            if (childNodes[i] === selectedObj.anchorNode) {
              break
            }
            if ((childNodes[i] as Element).outerHTML) rangeCount += (childNodes[i] as Element).outerHTML.length
            else if (childNodes[i].nodeType === TEXT_NODE_TYPE) {
              rangeCount += childNodes[i].textContent?.length || 0
            }
          }
        }

        setDynamicInputPosition({ key: item.key, position: (range?.startOffset || 0) + rangeCount })

        return
      }

      setDynamicInputPosition()
    }

    const onBlur = (event: React.FocusEvent<HTMLElement>) => {
      let dynamicValue = ''
      const element = event.target as HTMLElement
      element.childNodes.forEach((element) => {
        switch (element.nodeType) {
          case DIV_NODE_TYPE:
            /* Add dynamic input variable only if it was selected and restrict to only one per input */
            if (
              (item.value as CreateActionFields)?.platform &&
              !dynamicValue.includes(DYNAMIC_INPUT) &&
              !isTagDeleted
            ) {
              dynamicValue += DYNAMIC_INPUT
            }
            break
          case TEXT_NODE_TYPE:
            dynamicValue += element.textContent
            break
          default:
            dynamicValue += ''
            break
        }
      })

      if (dynamicValue === item.value || dynamicValue === (item.value as CreateActionFields)?.value) {
        return
      }

      if ((item.value as CreateActionFields)?.platform && dynamicValue.includes(DYNAMIC_INPUT) && !isTagDeleted) {
        if (
          DynamicInputRegex.spacesBeforeAndAfter.test(dynamicValue) &&
          !DynamicInputRegex.textBeforeAndAfter.test(dynamicValue)
        ) {
          dynamicValue = dynamicValue.replace(DynamicInputRegex.spacesBeforeAndAfter, DYNAMIC_INPUT)
        } else {
          if (
            DynamicInputRegex.spaceBefore.test(dynamicValue) &&
            !DynamicInputRegex.textAndSpaceBefore.test(dynamicValue)
          ) {
            dynamicValue = dynamicValue.replace(DynamicInputRegex.spaceBefore, DYNAMIC_INPUT)
          }
          if (
            DynamicInputRegex.spaceAfter.test(dynamicValue) &&
            !DynamicInputRegex.spaceAndTextAfter.test(dynamicValue)
          ) {
            dynamicValue = dynamicValue.replace(DynamicInputRegex.spaceAfter, DYNAMIC_INPUT)
          }
        }

        changeFieldValue(
          {
            ...(item.value as CreateActionFields),
            value: dynamicValue,
          },
          item?.key,
        )
      } else {
        changeFieldValue(dynamicValue, item?.key)
      }
    }

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
      const element = event.target as HTMLElement
      switch (element.nodeName) {
        case 'svg':
        case 'path':
          removeTag()
          break
        case 'DIV':
        case 'SPAN': {
          if (element.classList.contains('tag-field') || element.classList.contains('tag-field__name')) {
            openPopover(true)
          } else if (element.classList.contains('remove-btn')) {
            removeTag()
          }
          break
        }
        case 'IMG':
          openPopover(true)
          break
        default:
          break
      }
    }

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        return false
      }

      /* Check if the tag was deleted by backspace */
      if (event.key === 'Backspace' && (item.value as CreateActionFields)?.platform) {
        let isTagVisible
        const element = event.target as HTMLElement

        element.childNodes.forEach((element) => {
          if (element.nodeType === DIV_NODE_TYPE) {
            isTagVisible = true
          }
        })

        if (!isTagVisible) {
          setIsTagDeleted(!!isTagVisible)
        }
      }
    }

    if (isMultipleInput) {
      return <MultiValueInput changeFieldValue={changeFieldValue} item={item} dataType='string' />
    }

    if (isNullishInput) {
      return (
        <FieldInput key='nullish-input' item={item} changeFieldValue={changeFieldValue} disabled={true} range={false} />
      )
    }

    return (
      <div
        className={classNames('dynamic-field-input', {
          borderless: borderless,
          'dynamic-field-input--disabled': motionStore.isSegmentBuilderEditDisabled,
        })}
        {...(item.onlyDynamicInput && { onClick: () => openPopover(true) })}
        data-testid='dynamic-field-input'>
        <ContentEditable
          className='editable-area'
          data-testid='editable-area'
          html={Array.isArray(html) || !html || typeof html === 'boolean' ? '' : html}
          disabled={
            !checkTypeEquality(item.type, STRING_TYPE) ||
            item.onlyDynamicInput ||
            motionStore.isSegmentBuilderEditDisabled
          }
          onBlur={onBlur}
          onPaste={onPasteReturnRawText}
          onClick={onClick}
          onChange={() => {}}
          onKeyDown={onKeyPress}
          onKeyUp={getCaretPosition}
          onMouseUp={getCaretPosition}
        />
      </div>
    )
  },
)
DynamicFieldInput.displayName = 'DynamicFieldInput'

export default DynamicFieldInput
