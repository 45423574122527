import { ImpactEnum } from 'models/insights'
import { AccountListKeyEnum, AccountListTypeEnum, CompositeUnitEnum } from 'models/reporting.model'
import type {
  EmailStatisticsApiResponse,
  AccountListApiResponse,
  GoalMetricsApiResponse,
  MachineLearningMetricsApiResponse,
} from 'models/reporting.model'

const goalMetricsValueMock: GoalMetricsApiResponse = {
  journeyId: 'd6908088-4b45-4790-927b-22f2825a415d',
  metrics: [
    {
      aggregationType: 'countOfValues',
      annotation: {
        label: 180,
        limit: 180,
        tooltip: 'Target',
      },
      data: {
        count: {
          '2022-12-19': 100,
          '2022-12-26': 108,
          '2023-01-02': 116,
          '2023-01-09': 122,
          '2023-01-16': 127,
          '2023-01-23': 135,
          '2023-01-30': 142,
          '2023-02-06': 153,
          '2023-02-13': 160,
          '2023-02-20': 168,
        },
      },
      id: '13854b90-ddaf-4730-877b-06c5d3e32cd7',
      title: {
        chartTitle: 'Number of Accounts',
        yTitle: 'Count',
      },
      visualization: 'barChart',
    },
    {
      aggregationType: 'average',
      annotation: {
        limit: 135,
        label: 135,
        tooltip: 'Target',
      },
      data: {
        averages: {
          '2022-12-19': 101.0,
          '2022-12-26': 101.67,
          '2023-01-02': 106.67,
          '2023-01-09': 105.67,
          '2023-01-16': 107.67,
          '2023-01-23': 106.33,
          '2023-01-30': 113.67,
          '2023-02-06': 112.67,
          '2023-02-13': 107.67,
          '2023-02-20': 111.33,
        },
        quartiles: {
          '2022-12-19': [82.0, 92.0, 101.0, 110.0, 118.0],
          '2022-12-26': [85.0, 93.0, 103.0, 109.0, 122.0],
          '2023-01-02': [89.0, 99.0, 105.0, 116.0, 125.0],
          '2023-01-09': [92.0, 97.0, 104.0, 116.0, 126.0],
          '2023-01-16': [88.0, 96.0, 109.0, 118.0, 126.0],
          '2023-01-23': [89.0, 98.0, 107.0, 114.0, 122.0],
          '2023-01-30': [93.0, 105.0, 113.0, 123.0, 128.0],
          '2023-02-06': [93.0, 103.0, 114.0, 121.0, 128.0],
          '2023-02-13': [90.0, 99.0, 109.0, 115.0, 126.0],
          '2023-02-20': [95.0, 103.0, 111.0, 120.0, 127.0],
        },
      },
      id: '13854b90-ddaf-4730-877b-06c5d3e32cd7',
      title: {
        chartTitle: 'Account distribution for Product Usage',
        yTitle: 'Product Usage',
      },
      visualization: 'quartile',
    },
    {
      aggregationType: 'average',
      annotation: {
        limit: 18,
        label: 18,
        tooltip: 'Target',
      },
      data: {
        averages: {
          '2022-12-19': 16.0,
          '2022-12-26': 18.0,
          '2023-01-02': 16.67,
          '2023-01-09': 16.67,
          '2023-01-16': 16.0,
          '2023-01-23': 18.0,
          '2023-01-30': 17.0,
          '2023-02-06': 17.67,
          '2023-02-13': 21.67,
          '2023-02-20': 22.33,
        },
        quartiles: {
          '2022-12-19': [4.0, 10.0, 16.0, 22.0, 28.0],
          '2022-12-26': [4.0, 14.0, 18.0, 22.0, 27.0],
          '2023-01-02': [3.0, 12.0, 17.0, 21.0, 26.0],
          '2023-01-09': [2.0, 11.0, 16.0, 23.0, 26.0],
          '2023-01-16': [3.0, 10.0, 16.0, 22.0, 26.0],
          '2023-01-23': [6.0, 12.0, 18.0, 24.0, 28.0],
          '2023-01-30': [4.0, 11.0, 17.0, 23.0, 28.0],
          '2023-02-06': [4.0, 13.0, 16.0, 24.0, 28.0],
          '2023-02-13': [9.0, 18.0, 21.0, 26.0, 30.0],
          '2023-02-20': [10.0, 16.0, 24.0, 27.0, 32.0],
        },
      },
      id: '13854b90-ddaf-4730-877b-06c5d3e32cd7',
      title: {
        chartTitle: 'Account distribution for Service Tickets',
        yTitle: 'Service Tickets',
      },
      visualization: 'quartile',
    },
  ],
  weeks: 10,
}

const accountsListMock: AccountListApiResponse = {
  columns: [
    {
      key: AccountListKeyEnum.Account,
      title: 'Account',
      type: AccountListTypeEnum.String,
    },
    {
      key: AccountListKeyEnum.CsManager,
      title: 'CS Manager',
      type: AccountListTypeEnum.String,
    },
    {
      key: AccountListKeyEnum.ParticipatedSince,
      title: 'Participated Since',
      type: AccountListTypeEnum.String,
    },
    {
      key: 'Product Usage' as AccountListKeyEnum,
      title: 'Account distribution for Product Usage',
      type: AccountListTypeEnum.Composite,
    },
    {
      key: AccountListKeyEnum.ServiceTickets,
      title: 'Account distribution for Service Tickets',
      type: AccountListTypeEnum.Composite,
    },
    {
      key: AccountListKeyEnum.ChurnProbability,
      title: 'Churn probability',
      type: AccountListTypeEnum.Composite,
    },
  ],
  page: 1,
  rows: [
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 100.0,
        old: 125.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 32.0,
        old: 24.0,
      },
      account: 'Ryder System, Inc.',
      csManager: 'Carmelo Chico',
      id: '0018V00002apzimQAA',
      participatedSince: '2022-12-19',
      totalUsers: 575,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 115.0,
        old: 113.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 28.0,
        old: 26.0,
      },
      account: 'Monk Home Improvements',
      csManager: 'Boris Gil',
      id: '0018V00002apzljQAA',
      participatedSince: '2022-12-15',
      totalUsers: 328,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 107.0,
        old: 122.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 27.0,
        old: 29.0,
      },
      account: 'H. J. Heinz Company',
      csManager: 'Jean Weaver',
      id: '0018V00002aq03aQAA',
      participatedSince: '2022-12-30',
      totalUsers: 578,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 109.0,
        old: 97.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 27.0,
        old: 29.0,
      },
      account: 'American Apparel',
      csManager: 'Casey Tillotson',
      id: '0018V00002aq03bQAA',
      participatedSince: '2022-12-19',
      totalUsers: 147,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 104.0,
        old: 104.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 20.0,
        old: 30.0,
      },
      account: 'Cisco Systems, Inc.',
      csManager: 'Grant Lee',
      id: '0018V00002aq0B4QAI',
      participatedSince: '2022-12-08',
      totalUsers: 54,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 101.0,
        old: 115.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 19.0,
        old: 22.0,
      },
      account: 'Gap',
      csManager: 'Eric Brittingham',
      id: '0018V00002aq0C2QAI',
      participatedSince: '2022-12-17',
      totalUsers: 424,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 107.0,
        old: 114.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 31.0,
        old: 23.0,
      },
      account: 'VitaGrey',
      csManager: 'Bradley Hosie',
      id: '0018V00002aq0jgQAA',
      participatedSince: '2022-11-24',
      totalUsers: 304,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 127.0,
        old: 93.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 13.0,
        old: 26.0,
      },
      account: 'The Flying Hippo',
      csManager: 'Eric Dennis',
      id: '0018V00002aq0khQAA',
      participatedSince: '2022-11-19',
      totalUsers: 123,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 121.0,
        old: 90.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 13.0,
        old: 15.0,
      },
      account: 'Giant Food',
      csManager: 'Arturo Shedden',
      id: '0018V00002aq0mkQAA',
      participatedSince: '2022-11-30',
      totalUsers: 197,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 119.0,
        old: 109.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 25.0,
        old: 14.0,
      },
      account: 'Take-Two Interactive',
      csManager: 'Susan Hester',
      id: '0018V00002aq0q4QAA',
      participatedSince: '2022-11-26',
      totalUsers: 628,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 122.0,
        old: 102.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 32.0,
        old: 27.0,
      },
      account: 'Dover Corporation',
      csManager: 'Daisy Huang',
      id: '0018V00002aq0zWQAQ',
      participatedSince: '2022-11-25',
      totalUsers: 370,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 120.0,
        old: 121.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 23.0,
        old: 25.0,
      },
      account: 'Starbucks',
      csManager: 'Deborah Webb',
      id: '0018V00002aq12KQAQ',
      participatedSince: '2022-11-10',
      totalUsers: 374,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 126.0,
        old: 115.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 28.0,
        old: 19.0,
      },
      account: 'Westinghouse Digital LLC',
      csManager: 'Rachel Null',
      id: '0018V00002aq12PQAQ',
      participatedSince: '2022-12-02',
      totalUsers: 329,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 114.0,
        old: 115.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 15.0,
        old: 25.0,
      },
      account: 'Zune',
      csManager: 'Christopher Pifer',
      id: '0018V00002aq18dQAA',
      participatedSince: '2022-11-14',
      totalUsers: 68,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 109.0,
        old: 102.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 27.0,
        old: 20.0,
      },
      account: 'Nvidia',
      csManager: 'Robert Vigil',
      id: '0018V00002aq19pQAA',
      participatedSince: '2022-12-03',
      totalUsers: 634,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 122.0,
        old: 113.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 27.0,
        old: 24.0,
      },
      account: 'FedEx',
      csManager: 'Sterling Poe',
      id: '0018V00002aq1ayQAA',
      participatedSince: '2022-11-14',
      totalUsers: 247,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 119.0,
        old: 95.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 25.0,
        old: 12.0,
      },
      account: 'Practi-Plan',
      csManager: 'Michael Martin',
      id: '0018V00002aq1doQAA',
      participatedSince: '2022-11-11',
      totalUsers: 292,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 112.0,
        old: 115.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 17.0,
        old: 28.0,
      },
      account: 'Lennox International',
      csManager: 'Eric Stillwagon',
      id: '0018V00002aq1dtQAA',
      participatedSince: '2022-12-19',
      totalUsers: 474,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 111.0,
        old: 108.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 24.0,
        old: 13.0,
      },
      account: 'A+ Electronics',
      csManager: 'Renee Bruce',
      id: '0018V00002aq1e3QAA',
      participatedSince: '2022-12-10',
      totalUsers: 293,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 109.0,
        old: 98.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 18.0,
        old: 19.0,
      },
      account: 'Wheeling-Pittsburgh Steel',
      csManager: 'Aaron Paulson',
      id: '0018V00002aq1f6QAA',
      participatedSince: '2022-11-08',
      totalUsers: 287,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 117.0,
        old: 102.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 19.0,
        old: 19.0,
      },
      account: 'Adobe Systems Inc.',
      csManager: 'Erin Heritage',
      id: '0018V00002aq1fgQAA',
      participatedSince: '2023-01-02',
      totalUsers: 688,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 117.0,
        old: 112.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 27.0,
        old: 25.0,
      },
      account: 'Anixter International',
      csManager: 'Robert Swift',
      id: '0018V00002aq1fhQAA',
      participatedSince: '2022-12-02',
      totalUsers: 496,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 103.0,
        old: 119.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 31.0,
        old: 22.0,
      },
      account: 'Allen Organ Company',
      csManager: 'Christine Drew',
      id: '0018V00002aq1gAQAQ',
      participatedSince: '2022-11-23',
      totalUsers: 573,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 111.0,
        old: 121.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 11.0,
        old: 20.0,
      },
      account: 'AutoZone',
      csManager: 'Gertie Hamernik',
      id: '0018V00002aq1GyQAI',
      participatedSince: '2023-01-01',
      totalUsers: 431,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 103.0,
        old: 99.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 32.0,
        old: 20.0,
      },
      account: 'PG&E',
      csManager: 'Ryan Lawson',
      id: '0018V00002aq1H6QAI',
      participatedSince: '2022-12-17',
      totalUsers: 438,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 103.0,
        old: 101.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 25.0,
        old: 24.0,
      },
      account: 'Nortax',
      csManager: 'Joseph Bagwell',
      id: '0018V00002aq1hDQAQ',
      participatedSince: '2022-12-26',
      totalUsers: 685,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 102.0,
        old: 126.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 28.0,
        old: 23.0,
      },
      account: 'Medimix International',
      csManager: 'Thomas Craft',
      id: '0018V00002aq1hlQAA',
      participatedSince: '2022-11-29',
      totalUsers: 419,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 109.0,
        old: 90.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 23.0,
        old: 18.0,
      },
      account: 'Fabrik Inc.',
      csManager: 'Janice Schepis',
      id: '0018V00002aq1hqQAA',
      participatedSince: '2022-11-17',
      totalUsers: 252,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 117.0,
        old: 95.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 24.0,
        old: 9.0,
      },
      account: 'America Online',
      csManager: 'Kenneth Reed',
      id: '0018V00002aq1hvQAA',
      participatedSince: '2022-12-11',
      totalUsers: 152,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 126.0,
        old: 116.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 10.0,
        old: 12.0,
      },
      account: 'Millenia Life',
      csManager: 'Annie Hagan',
      id: '0018V00002aq1i0QAA',
      participatedSince: '2022-12-30',
      totalUsers: 161,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 112.0,
        old: 115.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 26.0,
        old: 19.0,
      },
      account: 'Wizards of the Coast',
      csManager: 'Charles Spicer',
      id: '0018V00002aq1I0QAI',
      participatedSince: '2022-12-06',
      totalUsers: 138,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 99.0,
        old: 122.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 28.0,
        old: 17.0,
      },
      account: 'EMC Corporation',
      csManager: 'Earnestine Elliott',
      id: '0018V00002aq1i5QAA',
      participatedSince: '2022-12-17',
      totalUsers: 530,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 125.0,
        old: 110.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 24.0,
        old: 15.0,
      },
      account: 'Complete Tech',
      csManager: 'Mary Flores',
      id: '0018V00002aq1ieQAA',
      participatedSince: '2022-11-27',
      totalUsers: 585,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 104.0,
        old: 123.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 20.0,
        old: 26.0,
      },
      account: 'Future Plan',
      csManager: 'George Gonzalez',
      id: '0018V00002aq1ijQAA',
      participatedSince: '2022-11-17',
      totalUsers: 38,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 111.0,
        old: 94.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 13.0,
        old: 28.0,
      },
      account: 'Quantrix',
      csManager: 'Allison Hamby',
      id: '0018V00002aq1ikQAA',
      participatedSince: '2022-11-03',
      totalUsers: 151,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 96.0,
        old: 111.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 15.0,
        old: 19.0,
      },
      account: 'Advanced Processing & Imaging',
      csManager: 'Susan Cortes',
      id: '0018V00002aq1iUQAQ',
      participatedSince: '2022-12-08',
      totalUsers: 309,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 99.0,
        old: 98.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 14.0,
        old: 21.0,
      },
      account: 'Halliburton',
      csManager: 'Thomas Farlow',
      id: '0018V00002aq1iyQAA',
      participatedSince: '2022-11-07',
      totalUsers: 663,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 122.0,
        old: 95.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 12.0,
        old: 15.0,
      },
      account: 'The Spotted Cougar',
      csManager: 'Melissa Daniels',
      id: '0018V00002aq1izQAA',
      participatedSince: '2022-11-20',
      totalUsers: 498,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 125.0,
        old: 114.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 25.0,
        old: 18.0,
      },
      account: 'SkyWest Airlines',
      csManager: 'Samuel Gunther',
      id: '0018V00002aq1iZQAQ',
      participatedSince: '2022-11-08',
      totalUsers: 471,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 125.0,
        old: 117.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 18.0,
        old: 29.0,
      },
      account: 'Broadcom',
      csManager: 'Jenny Phelps',
      id: '0018V00002aq1jcQAA',
      participatedSince: '2022-12-18',
      totalUsers: 458,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 97.0,
        old: 123.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 25.0,
        old: 19.0,
      },
      account: 'State Street Corporation',
      csManager: 'Henrietta Conley',
      id: '0018V00002aq1jhQAA',
      participatedSince: '2022-12-17',
      totalUsers: 44,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 120.0,
        old: 99.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 13.0,
        old: 26.0,
      },
      account: 'Asian Plan',
      csManager: 'Donald Gravel',
      id: '0018V00002aq1jNQAQ',
      participatedSince: '2022-12-07',
      totalUsers: 280,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 121.0,
        old: 92.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 25.0,
        old: 18.0,
      },
      account: 'The Polka Dot Bear Tavern',
      csManager: 'Carmen Jones',
      id: '0018V00002aq1jSQAQ',
      participatedSince: '2022-12-25',
      totalUsers: 502,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 51.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 120.0,
        old: 109.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 16.0,
        old: 29.0,
      },
      account: 'Magna Consulting',
      csManager: 'Kisha Lewis',
      id: '0018V00002aq1jwQAA',
      participatedSince: '2022-11-17',
      totalUsers: 197,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 118.0,
        old: 110.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 19.0,
        old: 13.0,
      },
      account: 'Reliable Garden Management',
      csManager: 'Paul Walker',
      id: '0018V00002aq1jXQAQ',
      participatedSince: '2022-12-18',
      totalUsers: 332,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 49.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 102.0,
        old: 103.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 27.0,
        old: 25.0,
      },
      account: 'NCR Corporation',
      csManager: 'Bernadine Gerhardt',
      id: '0018V00002aq1k6QAA',
      participatedSince: '2022-11-03',
      totalUsers: 194,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 51.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 109.0,
        old: 122.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 15.0,
        old: 27.0,
      },
      account: 'Tropicana Products',
      csManager: 'Frank Hill',
      id: '0018V00002aq1KSQAY',
      participatedSince: '2022-11-08',
      totalUsers: 150,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 50.0,
        old: 52.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 102.0,
        old: 101.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 30.0,
        old: 21.0,
      },
      account: 'Zenith Electronics',
      csManager: 'Alison Putnam',
      id: '0018V00002aq1RdQAI',
      participatedSince: '2022-12-06',
      totalUsers: 253,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 53.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 95.0,
        old: 98.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 19.0,
        old: 11.0,
      },
      account: 'Bosch Brewing Company',
      csManager: 'Lois Pamintuan',
      id: '0018V00002aq1ReQAI',
      participatedSince: '2022-12-12',
      totalUsers: 672,
    },
    {
      [AccountListKeyEnum.ChurnProbability]: {
        impact: ImpactEnum.Negative,
        new: 48.0,
        old: 50.0,
        unit: CompositeUnitEnum.Percentage,
      },
      [AccountListKeyEnum.ProductStayDuration]: {
        impact: ImpactEnum.Positive,
        new: 121.0,
        old: 100.0,
        unit: CompositeUnitEnum.Months,
      },
      [AccountListKeyEnum.ServiceTickets]: {
        impact: ImpactEnum.Negative,
        new: 11.0,
        old: 29.0,
      },
      account: 'Federal Home Loan Mortgage Corporation',
      csManager: 'Margaret Rowe',
      id: '0018V00002aq1YjQAI',
      participatedSince: '2023-01-02',
      totalUsers: 170,
    },
  ],
  total: 50,
  limit: 0,
  offset: 0,
  search: null,
}

const machineDistributionMock: MachineLearningMetricsApiResponse = {
  metrics: {
    '2022-12-26 13:53:40.296475': 65.5,
    '2023-01-02 13:53:40.296475': 67.0,
    '2023-01-09 13:53:40.296475': 61.3,
    '2023-01-16 13:53:40.296475': 62.0,
    '2023-01-23 13:53:40.296475': 60.0,
    '2023-01-30 13:53:40.296475': 56.04,
    '2023-02-06 13:53:40.296475': 54.0,
    '2023-02-13 13:53:40.296475': 51.54,
    '2023-02-20 13:53:40.296475': 49.52,
  },
  title: 'Churn Probability',
}

const emailStatsMock: EmailStatisticsApiResponse = {
  data: [
    {
      magnifyTemplateId: 'mockTemplateId',
      tenantId: 'mockTenantId',
      journeyId: 'mockMotionId',
      templateName: 'Welcome Email',
      processed: 4,
      delivered: 3,
      open: 2,
      click: 1,
      bounce: 1,
      spamreport: 0,
      createdAt: 1701799287128,
      updatedAt: 1701799287128,
      deliveredPercentage: '75% (3)',
      openPercentage: '66% (2)',
      clickPercentage: '50% (1)',
    },
  ],
}

export { accountsListMock, goalMetricsValueMock, machineDistributionMock, emailStatsMock }
