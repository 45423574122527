import { useEffect, useState } from 'react'

export const useDraggable = () => {
  const [draggable, setDraggable] = useState(false)

  useEffect(() => {
    document.body.classList.toggle('draggable', draggable)
  }, [draggable])

  return { setDraggable }
}
