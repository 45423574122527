import { Modal } from 'antd'

import { Button, Heading } from 'components/common'
import { IconClose } from 'components/common/Icons/Icons'

interface EditSegmentModalProps {
  isModalOpen: boolean
  handleCancel: () => void
  handleClose: () => void
  handleSubmit: () => void
  cancelButtonText: string
  submitButtonText: string
  modalHeadingText: string
  modalBodyText: string
  isSubmitButtonDisabled?: boolean
}

const MotionAlertModal = ({
  isModalOpen,
  handleCancel,
  handleClose,
  handleSubmit,
  cancelButtonText,
  submitButtonText,
  modalHeadingText,
  modalBodyText,
  isSubmitButtonDisabled = false,
}: EditSegmentModalProps) => {
  return (
    <Modal
      open={isModalOpen}
      centered
      width={668}
      className='modal__remove'
      footer={[
        <Button
          key='cancel'
          text={cancelButtonText}
          type='secondary-danger'
          testId='cancel-btn'
          onClickHandler={handleCancel}
        />,
        <Button
          key='submit'
          text={submitButtonText}
          testId='confirm-btn'
          className='m-l-10'
          onClickHandler={handleSubmit}
          disabled={isSubmitButtonDisabled}
        />,
      ]}>
      <div className='modal__remove--heading'>
        <Heading level='1' variant='3' className='bold'>
          {modalHeadingText}
        </Heading>

        <IconClose onClick={handleClose} className='btn__close' data-testid='modal-close' />
      </div>

      <div className='message'>
        <span className='message__text' role='alert' data-testid='modal-message'>
          {modalBodyText}
        </span>
      </div>
    </Modal>
  )
}
MotionAlertModal.displayName = 'MotionAlertModal'

export default MotionAlertModal
