import { useMatch, Navigate } from 'react-router-dom'

const RedirectToMotion = () => {
  // Match against the pattern to capture all of the following path segments
  const match = useMatch('/journeys/*')

  // Replace any instance of journey with motion
  let path = match?.params['*'] || ''
  path = path.replace(/journey/g, 'motion')

  // Construct the new path by appending the processed path to '/motions'
  const newPath = `/motions/${path}`

  // Replace the old route and redirect
  return <Navigate to={newPath} replace />
}

export default RedirectToMotion
