import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import type { LocalDateTime, LocalDateTimeParams } from 'models/motion.model'

interface ParseDateOptions {
  divider?: string
  showTime?: boolean
}

const parseDateTimestamp = (date: number | string, { divider = '-', showTime = false }: ParseDateOptions = {}) => {
  return dayjs(date).format(`YYYY${divider}MM${divider}DD${showTime ? ' HH:mm:ss' : ''}`)
}

const relativeDate = (date: number | string) => {
  dayjs.extend(relativeTime)
  return dayjs().to(dayjs(date))
}

export enum MonthFormatEnum {
  Short = 'short',
  Long = 'long',
}

const months = (format: MonthFormatEnum = MonthFormatEnum.Short) => {
  const months = Array.from({ length: 12 }, (_, index) => {
    return new Date(new Date().getFullYear(), index).toLocaleDateString('en', { month: format })
  })
  // if short format, return them in uppercase to preserve the same format as the old constant array defined
  return format === MonthFormatEnum.Short ? months.map((month) => month.toUpperCase()) : months
}

const shortMonthsToNumber: { [key: string]: number } = {
  JAN: 0,
  FEB: 1,
  MAR: 2,
  APR: 3,
  MAY: 4,
  JUN: 5,
  JUL: 6,
  AUG: 7,
  SEP: 8,
  OCT: 9,
  NOV: 10,
  DEC: 11,
}

/**
 * Converts schedule data into a localized date and time representation.
 *
 * @param {LocalDateTimeParams} scheduleData - The schedule data containing time components.
 * @returns {LocalDateTime} An object containing the localized day of the week, month, time, and day of the month.
 */
const getLocalDateTime = (scheduleData: LocalDateTimeParams): LocalDateTime => {
  // Create a Date object with the provided time components
  const date = new Date()
  date.setUTCMinutes(scheduleData.minutes)
  date.setUTCHours(scheduleData.hours)
  date.setUTCDate(scheduleData.dayOfMonth)
  date.setUTCMonth(shortMonthsToNumber[scheduleData.month])
  date.setUTCFullYear(scheduleData.year ? Number.parseInt(scheduleData.year) : date.getFullYear())

  const formattedDayOfWeek = date.toLocaleDateString(undefined, { weekday: MonthFormatEnum.Long })
  const formattedMonth = date.toLocaleDateString(undefined, { month: MonthFormatEnum.Long })
  const formattedTime = date.toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
  const dayOfMonthNumber = date.getDate()

  return {
    dayOfWeek: formattedDayOfWeek,
    month: formattedMonth,
    time: formattedTime,
    dayOfMonth: dayOfMonthNumber,
  }
}

export const DateService = {
  parseDateTimestamp,
  relativeDate,
  months,
  getLocalDateTime,
}
