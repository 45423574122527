const SpeedometerIndicator = (props: any) => (
  <svg {...props} viewBox='0 0 5 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.16506 0L4.15401 24.3695C4.24887 25.5317 3.33119 26.5273 2.16506 26.5273V26.5273C0.998935 26.5273 0.0812591 25.5317 0.176119 24.3695L2.16506 0Z'
      fill='#3E4558'
    />
  </svg>
)
SpeedometerIndicator.displayName = 'SpeedometerIndicator'

export default SpeedometerIndicator
