import { Tooltip } from 'antd'

import { DateService } from 'services/Utils/date'

import type { StateHistoryType } from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

interface StatusProps {
  state: MotionStateEnum
  lastUpdatedAt: number
  stateHistory?: StateHistoryType
}

const generateStatusDate = (isoDate: string | number): string => {
  const scheduledDate = new Date(isoDate).getTime()
  return DateService.parseDateTimestamp(scheduledDate, { divider: '/' })
}

const generateStatusText = (state: MotionStateEnum, lastUpdatedAt: number, stateHistory?: StateHistoryType) => {
  let statusText = 'Last updated on'
  let statusDate = generateStatusDate(lastUpdatedAt)
  if (stateHistory?.[state]) {
    statusDate = generateStatusDate(`${stateHistory[state]}`)
    switch (state) {
      case MotionStateEnum.Published:
        statusText = 'Published on'
        break
      case MotionStateEnum.Scheduled:
        statusText = 'Scheduled for'
        break
      case MotionStateEnum.Executing:
        statusText = 'Started on'
        break
      case MotionStateEnum.Completed:
        statusText = 'Scheduled on'
        break
      case MotionStateEnum.Suspended:
        statusText = 'Suspended on'
        break
      case MotionStateEnum.Failed:
        statusText = 'Failed on'
        break
      default:
        statusText = 'Last updated on'
    }
  }

  return `${statusText} ${statusDate}`
}

const Status = ({ state, lastUpdatedAt, stateHistory }: StatusProps) => {
  return (
    <div>
      <Tooltip placement='top' title={state}>
        <div className={`status status--${state.toLowerCase()}`} data-testid='status'></div>
      </Tooltip>
      <span data-testid='status-text'>{generateStatusText(state, lastUpdatedAt, stateHistory)}</span>
    </div>
  )
}

export default Status
