import type { ChartEvent, Chart, ActiveElement } from 'chart.js'

const onHover = (event: ChartEvent, _: ActiveElement[], chart: Chart) => {
  const points = chart.getElementsAtEventForMode(
    event as unknown as Event,
    'index',
    { axis: 'x', intersect: true },
    false,
  )

  const target = event.native?.target as HTMLElement
  if (!target) return
  if (points.length) {
    target.style.cursor = 'pointer'
  } else {
    target.style.cursor = 'default'
  }
}

const chartFont = {
  weight: 400,
  family: 'Suisse',
  size: 12,
}

export const sharedChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  onHover,
  scales: {
    y: {
      title: {
        display: true,
        text: '',
        font: chartFont,
      },
      grid: {
        drawTicks: false,
        display: false,
        drawBorder: false,
      },
      ticks: {
        font: chartFont,
        beginAtZero: true,
        padding: 10,
      },
    },
    x: {
      grid: {
        drawTicks: false,
        display: false,
        borderWidth: 3,
      },
      ticks: {
        padding: 10,
        font: chartFont,
      },
    },
  },
}
