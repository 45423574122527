import { Modal } from 'antd'
import { Fragment } from 'react'

import AlertIcon from 'assets/icons/alert'
import { Button, Callout } from 'components/common'
import FilterItem from 'pages/Motions/ExperimentModal/FilterItem'

import type { ABExperimentSegement, ABExperimentSegmentSizes } from 'models/motion.model'

interface ExperimentModalProps {
  showDialog: boolean
  onCancel: () => void
  onConfirm: () => void
  totalAccounts: number | undefined
  selectedExperimentSegementSizes: ABExperimentSegmentSizes
  handleExperimentSegementSizeChange: (value: number, title: ABExperimentSegement, totalAccounts: number) => void
  doesExperimentExist: boolean
  getSelectedSegmentSizeTotal: () => string
}

const ExperimentModal = ({
  showDialog,
  onCancel,
  onConfirm,
  totalAccounts,
  selectedExperimentSegementSizes,
  handleExperimentSegementSizeChange,
  doesExperimentExist,
  getSelectedSegmentSizeTotal,
}: ExperimentModalProps) => {
  const isEnoughAccounts = totalAccounts && totalAccounts > 1

  return (
    <Modal
      key='modal__create-experiment'
      title={
        <>
          {`${doesExperimentExist ? 'Edit' : 'Create'} Experiment`}
          <Callout variant='1'>PREVIEW</Callout>
        </>
      }
      open={showDialog}
      onOk={onConfirm}
      onCancel={onCancel}
      centered
      width={680}
      className='modal__create-experiment'
      footer={[
        <Fragment key='modal__create-experiment--footer'>
          <Button text='Cancel' type='secondary' size='XL' onClickHandler={onCancel} testId='cancel-btn' />

          <Button
            disabled={getSelectedSegmentSizeTotal() !== '100' || !isEnoughAccounts}
            text={'Confirm'}
            size='XL'
            className='m-l-10'
            onClickHandler={onConfirm}
            testId='continue-btn'
          />
        </Fragment>,
      ]}>
      {!isEnoughAccounts ? (
        <div className='modal__create-experiment__warning'>
          <AlertIcon />
          <p>
            There are not enough accounts in this Motion's segment to create an experiment. Please add at least 2
            accounts to the Motion to create an experiment.
          </p>
        </div>
      ) : (
        <>
          <p className='modal__create-experiment__subtitle'>
            By creating experiment, you can separate the same segment into 2 parts for 2 different Motions. You can edit
            the experiment group Motion.
          </p>

          <div className='modal__create-experiment__filters'>
            {Object.keys(selectedExperimentSegementSizes).map((segmentKey) => {
              const segmentKeyTyped = segmentKey as keyof ABExperimentSegmentSizes
              return (
                <FilterItem
                  key={segmentKeyTyped}
                  title={segmentKeyTyped}
                  segmentSize={selectedExperimentSegementSizes[segmentKeyTyped]}
                  totalAccounts={totalAccounts || 0}
                  handleVariantChange={handleExperimentSegementSizeChange}
                />
              )
            })}
          </div>

          {getSelectedSegmentSizeTotal() !== '100' && (
            <div className='modal__create-experiment__warning'>
              <AlertIcon />
              <p>
                The total percentage of the selected segments need to equal 100%. Currently they equal{' '}
                {getSelectedSegmentSizeTotal()}%.
              </p>
            </div>
          )}
        </>
      )}
    </Modal>
  )
}
ExperimentModal.displayName = 'ExperimentModal'

export default ExperimentModal
