import { Checkbox } from 'antd'

import { Button } from 'components/common'

import type { CheckboxValueType } from 'antd/lib/checkbox/Group'

import type { Motion, RefreshSegmentCheckboxInterface } from 'models/motion.model'

interface MotionExecutionModalFooterProps {
  submitButtonIsLoading: boolean
  submitButtonDisabled: boolean
  setDisplayExecuteModal: (isDisplayed: boolean, motionToExecute: Motion | null) => void
  onExecute: () => void
  displayRefreshSegmentToggle: boolean
  refreshSegmentIncludeOptions: RefreshSegmentCheckboxInterface[]
  selectedRefreshSegmentIncludeOptions: CheckboxValueType[]
  handleRefreshSegmentIncludeOptionsChange: (checkedValues: CheckboxValueType[]) => void
}

const MotionExecutionModalFooter = ({
  submitButtonIsLoading,
  submitButtonDisabled,
  setDisplayExecuteModal,
  onExecute,
  displayRefreshSegmentToggle,
  refreshSegmentIncludeOptions,
  selectedRefreshSegmentIncludeOptions,
  handleRefreshSegmentIncludeOptionsChange,
}: MotionExecutionModalFooterProps) => (
  <div
    className={
      displayRefreshSegmentToggle
        ? 'motion-execution-modal-footer-segment-refresh'
        : 'motion-execution-modal-footer-container'
    }>
    {displayRefreshSegmentToggle && (
      <div className='motion-execution-modal-footer-segment-refresh-segment-refresh-container'>
        Include previous run &nbsp;
        <Checkbox.Group
          className='motion-execution-modal-checkbox'
          options={refreshSegmentIncludeOptions}
          value={selectedRefreshSegmentIncludeOptions}
          onChange={handleRefreshSegmentIncludeOptionsChange}
        />
      </div>
    )}

    <div style={{ display: 'flex' }}>
      <Button type='secondary-danger' text='Cancel' onClickHandler={() => setDisplayExecuteModal(false, null)} />
      <Button
        type='primary'
        text='Confirm'
        className='m-l-10'
        disabled={submitButtonDisabled}
        loading={submitButtonIsLoading}
        onClickHandler={onExecute}
      />
    </div>
  </div>
)
MotionExecutionModalFooter.displayName = 'MotionExecutionModalFooter'

export default MotionExecutionModalFooter
