import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import classNames from 'classnames'

import TickIcon from 'assets/icons/tick.svg'

interface InsightsDropdownProps {
  targetOptions: string[]
  handleSelectedTargetClick: (driverTitle: string) => void
  selectedTarget?: string
  capitalize?: boolean
}

const InsightsDropdown = ({
  targetOptions,
  handleSelectedTargetClick,
  selectedTarget = '',
  capitalize = false,
}: InsightsDropdownProps) => {
  const menuTargetOptions = targetOptions.map((option, index) => ({
    key: index,
    label: (
      <div className='insights-dropdown-menu-item' data-testid='insights-dropdown-menu-item'>
        {selectedTarget === option && <img src={TickIcon} alt='tick icon' />}
        <span>{(option[0].toUpperCase() + option.substring(1)).replace('_', ' ')}</span>
      </div>
    ),
  }))

  return (
    <Dropdown
      className='insights-dropdown-container'
      data-testid='insights-dropdown-container'
      menu={{
        onClick: ({ key }) => {
          handleSelectedTargetClick(targetOptions[Number.parseInt(key, 10)])
        },
        items: menuTargetOptions,
        selectedKeys: [selectedTarget],
      }}>
      <span className='insights-dropdown-item' data-testid='insights-dropdown-item' onClick={(e) => e.preventDefault()}>
        <Space>
          <span
            className={classNames({
              'insights-dropdown-item-label-capitalize': capitalize,
              'insights-dropdown-item-label': true,
            })}>
            {selectedTarget}
          </span>
          <CaretDownOutlined />
        </Space>
      </span>
    </Dropdown>
  )
}

export default InsightsDropdown
