import { Popover, Skeleton } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { toFixedIfNecessary } from 'components/Insights/components/InsightRow/utils'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import { getMotionTotals, motionTotalColor, sortByTotalUsers } from 'components/MotionObservability/utils'
import { capitalizeFirstLetter } from 'services/Utils/parseString.utils'
import useStore from 'store/useStore'

import { DataFormatEnum } from 'models/insights'
import type { DateRangeProps, ParticipantTotals } from 'models/observability.model'

interface TopMotionsByUsersProps {
  pastWeeksOption: DateRangeProps
}

const TopMotionsByUsers = observer(({ pastWeeksOption }: TopMotionsByUsersProps) => {
  const { observabilityStore } = useStore()
  const { isLoadingFetchTenantMotionRangeTotals, tenantMotionRangeTotals, rangeToKeyConverter } = observabilityStore

  const tenantCachedActionPastWeeks =
    tenantMotionRangeTotals !== null ? tenantMotionRangeTotals[rangeToKeyConverter(pastWeeksOption)] : null

  const sortedMotions = sortByTotalUsers({ motions: tenantCachedActionPastWeeks?.topJourneys })
  const hasTopMotions = tenantCachedActionPastWeeks?.topJourneys?.length !== 0

  const displayNotEnoughMessage = !hasTopMotions || !sortedMotions.length

  if (isLoadingFetchTenantMotionRangeTotals) {
    return <Skeleton active />
  }

  return (
    <div className='motions-by-user' data-testid='motions-by-user'>
      <div className='header'>
        <h6 className='header__title'>
          Top Motions <span className='header__subtitle'>By Total Participants</span>
        </h6>
      </div>

      {hasTopMotions && (
        <div className='motions-by-user__legend' data-testid='motions-by-user__legend'>
          {Object.keys(tenantCachedActionPastWeeks?.tenantTotals?.participantTotals ?? {})
            .reverse()
            .map((status) => {
              return (
                <div key={status} className='legend__item'>
                  <span
                    className={classNames('legend__item--dot', {
                      [`bg-${motionTotalColor(status as keyof ParticipantTotals)}`]: hasTopMotions,
                      'bg-empty': !hasTopMotions,
                    })}></span>

                  {capitalizeFirstLetter(status)}
                </div>
              )
            })}

          {Object.keys(tenantCachedActionPastWeeks?.tenantTotals?.participantTotals ?? {}).length === 0 && (
            <div className='empty-block' data-testid='empty-block__legend'></div>
          )}
        </div>
      )}

      <div className='motions-list' data-testid='motions-list'>
        {sortedMotions.map((motion) => {
          const motionTotals = getMotionTotals(motion.totals)
          const motionTotalsFormatted = formatNumber({
            number: motionTotals,
            format: DataFormatEnum.Kmb,
            decimal: 1,
          })

          return (
            <div className='list_item' key={motion.journeyId}>
              <div className='item-data'>
                <div className='item-data__name' data-testid='motion-name'>
                  {motion.journeyName}
                </div>
                <div className='item-data__value' data-testid='total-users'>
                  {motionTotalsFormatted}
                </div>
              </div>
              <Popover
                overlayClassName='motion-observability__popover'
                placement='bottom'
                title='Participants by State'
                content={Object.keys(motion.totals)
                  .reverse()
                  .map((status) => {
                    const percentage = toFixedIfNecessary(
                      (motion.totals[status as keyof ParticipantTotals] / motionTotals) * 100,
                      2,
                    )
                    return (
                      <div className='motion-observability__popover__content' key={status}>
                        <div
                          className={classNames('motion-observability__popover__content__fill', {
                            [`bg-${motionTotalColor(status as keyof ParticipantTotals)}`]: hasTopMotions,
                            'bg-empty': !hasTopMotions,
                          })}
                          data-testid='popup-stats'></div>
                        <div>
                          <p>
                            {capitalizeFirstLetter(status)}: {percentage.toFixed(0)}% (
                            {formatNumber({
                              number: motion.totals?.[status as keyof ParticipantTotals],
                              format: DataFormatEnum.Kmb,
                              decimal: 1,
                            })}
                            )
                          </p>
                        </div>
                      </div>
                    )
                  })}>
                <div className='item-bar'>
                  {!motionTotals ? (
                    <div
                      className={'item-bar__fill bg-empty'}
                      style={{
                        width: '100%',
                      }}
                      data-testid='item-bar__fill'
                    />
                  ) : (
                    <>
                      {Object.keys(motion.totals)
                        .reverse()
                        .map((status) => {
                          const percentage = toFixedIfNecessary(
                            (motion.totals[status.toLocaleUpperCase() as keyof ParticipantTotals] / motionTotals) * 100,
                            2,
                          )

                          if (percentage === 0) {
                            return null
                          }

                          return (
                            <div
                              key={status}
                              className={classNames('item-bar__fill', {
                                [`bg-${motionTotalColor(status as keyof ParticipantTotals)}`]: hasTopMotions,
                              })}
                              style={{
                                width: `${percentage}%`,
                              }}
                            />
                          )
                        })}
                    </>
                  )}
                </div>
              </Popover>
            </div>
          )
        })}

        {displayNotEnoughMessage && (
          <div className='no-motions' data-testid='not-enough-motions'>
            {Array.from({ length: 4 }).map((_, idx) => (
              <div className='empty-block' key={idx} data-testid='empty-block__motions'></div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
})
TopMotionsByUsers.displayName = 'TopMotionsByUsers'

export default TopMotionsByUsers
