import { notification } from 'antd'

import { IconClose, IconFeedbackAlert } from 'components/common/Icons/Icons'

export const displayMotionErrorPopUp = (errors: string[]) => {
  notification.destroy()
  notification.error({
    message: (
      <div data-testid='errors-message'>
        <b>
          Motion saved. But we found{' '}
          <span className='errors' data-testid='errors-no'>
            {errors.length} error{errors.length > 1 ? 's ' : ' '}
          </span>
          in this Motion.
        </b>
      </div>
    ),
    description: (
      <ul className='motion__errors--list' data-testid='errors-list'>
        {errors.map((issue) => (
          <li className='motion__errors--item' key={crypto.randomUUID()}>
            {issue}
          </li>
        ))}
      </ul>
    ),
    duration: null,
    placement: 'top',
    className: 'motion__errors--popup',
    icon: <IconFeedbackAlert />,
    closeIcon: <IconClose />,
  })
}
