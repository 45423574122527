import React from 'react'

import IconCreateBriefcase from 'assets/icons/motionBuilder/actionsIcon/create_briefcase.svg?react'
import IconCreateCalendar from 'assets/icons/motionBuilder/actionsIcon/create_calendar.svg?react'
import IconCreateCrown from 'assets/icons/motionBuilder/actionsIcon/create_crown.svg?react'
import IconCreateCustomObject from 'assets/icons/motionBuilder/actionsIcon/create_custom_object.svg?react'
import IconCreateFiles from 'assets/icons/motionBuilder/actionsIcon/create_files.svg?react'
import IconCreateNote from 'assets/icons/motionBuilder/actionsIcon/create_note.svg?react'
import IconCreateUser from 'assets/icons/motionBuilder/actionsIcon/create_user.svg?react'
import IconLightBulb from 'assets/icons/motionBuilder/actionsIcon/light_bulb.svg?react'
import IconSendEmail from 'assets/icons/motionBuilder/actionsIcon/send_mail.svg?react'
import IconSendMessage from 'assets/icons/motionBuilder/actionsIcon/send_message.svg?react'
import IconTriggerEvent from 'assets/icons/motionBuilder/actionsIcon/trigger_event.svg?react'
import IconUpdateCrown from 'assets/icons/motionBuilder/actionsIcon/update_crown.svg?react'
import IconUpdateCustomObject from 'assets/icons/motionBuilder/actionsIcon/update_custom_object.svg?react'
import IconUpdateFiles from 'assets/icons/motionBuilder/actionsIcon/update_files.svg?react'
import IconUpdateUser from 'assets/icons/motionBuilder/actionsIcon/update_user.svg?react'
// actions^
import IconAddContact from 'assets/icons/motionBuilder/add_contact.svg?react'
import IconAlert from 'assets/icons/motionBuilder/alert.svg?react'
import IconCreate from 'assets/icons/motionBuilder/create_opportunity.svg?react'
import IconIfElse from 'assets/icons/motionBuilder/if_else.svg?react'
import IconLoop from 'assets/icons/motionBuilder/loop.svg?react'
import IconMerge from 'assets/icons/motionBuilder/merge.svg?react'
import IconNotification from 'assets/icons/motionBuilder/notification.svg?react'
import IconSegment from 'assets/icons/motionBuilder/segment.svg?react'
import IconTimeDelay from 'assets/icons/motionBuilder/time_delay.svg?react'
import IconUpdate from 'assets/icons/motionBuilder/update_lead.svg?react'
import IconWaitForTrigger from 'assets/icons/motionBuilder/wait_for_trigger.svg?react'

interface BuilderIconProps {
  name: string
  options?: {
    width?: string | number
    height?: string | number
    color?: string
    draggable?: boolean
    className?: string
  }
}
const iconTypes: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  update: IconUpdate,
  segment: IconSegment,
  create: IconCreate,
  ifElse: IconIfElse,
  loop: IconLoop,
  timeDelay: IconTimeDelay,
  waitForTrigger: IconWaitForTrigger,
  addContact: IconAddContact,
  alert: IconAlert,
  notification: IconNotification,
  merge: IconMerge,
  // actions
  createCustomObject: IconCreateCustomObject,
  updateCustomObject: IconUpdateCustomObject,
  createCrown: IconCreateCrown,
  updateCrown: IconUpdateCrown,
  createUser: IconCreateUser,
  updateUser: IconUpdateUser,
  createNote: IconCreateNote,
  createBriefcase: IconCreateBriefcase,
  createCalendar: IconCreateCalendar,
  triggerEvent: IconTriggerEvent,
  createFiles: IconCreateFiles,
  updateFiles: IconUpdateFiles,
  sendMessage: IconSendMessage,
  sendEmail: IconSendEmail,
  lightBulb: IconLightBulb,
}

export const BuilderIcon = ({ name, options }: BuilderIconProps) => {
  const Icon = iconTypes[name] ?? iconTypes.createCustomObject

  return <Icon {...options} />
}
