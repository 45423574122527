import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import SegmentSidebar from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { MetricItem } from 'models/motion.model'

interface DynamicDataFieldProps {
  filterByType?: boolean | string[]
  handleSelectDataField: (item: MetricItem) => void
}

const DynamicDataField = observer(({ filterByType, handleSelectDataField }: DynamicDataFieldProps) => {
  const { motionGoalsStore, metadataStore } = useStore()
  const { metadata, get, setCurrentItem, setBreadCrumbItems } = metadataStore

  useEffect(() => {
    if (!metadata.data.length) {
      get().catch(console.error)
    }
    setCurrentItem(null)
    setBreadCrumbItems([])
    motionGoalsStore.setFilterByType(filterByType)
  }, [motionGoalsStore.dataFieldIndex])

  useMetadataDisplayErrorNotification(metadataStore)

  return <SegmentSidebar handleSelectDataField={handleSelectDataField} />
})
DynamicDataField.displayName = 'DynamicDataField'

export default DynamicDataField
