import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'

import { DragAndDrop, PlaceholderForward, Delete } from 'components/common/Icons/Icons'
import CriteriaInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput'
import { IS_OVER_NEW_GROUP, IS_OVER_FREE_AREA_IN_DROPZONE } from 'components/MotionBuilder/Utils/motionBuilderConstants'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { ConfigPanelPayload, DragEvents, DragOverGroupType, Groups } from 'models/motion/motionBuilder.model'

interface SegmentCriteriaProps {
  dragEvents: DragEvents
  payload: ConfigPanelPayload
  forceDisableDynamicInput?: boolean
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void
  onDragEnterGroup: (e: React.DragEvent<HTMLDivElement>, group: DragOverGroupType) => void
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void
}

export const SegmentCriteria = observer(
  ({
    payload,
    dragEvents,
    forceDisableDynamicInput = false,
    setPayload,
    handleDrop,
    handleDragOver,
    onDragEnterGroup,
  }: SegmentCriteriaProps) => {
    const { motionStore } = useStore()

    const isInitialView = !payload?.groups?.length && !dragEvents?.isDragStarting
    const displayPlaceholderIcon =
      dragEvents.dragOverGroup === IS_OVER_FREE_AREA_IN_DROPZONE && !payload?.groups?.length
    const displayDefaultPlaceholder =
      (dragEvents?.dragOverGroup === IS_OVER_NEW_GROUP && !payload?.groups?.length) ||
      ((dragEvents?.dragOverGroup === IS_OVER_NEW_GROUP ||
        dragEvents?.dragOverGroup === IS_OVER_FREE_AREA_IN_DROPZONE) &&
        payload?.groups?.length > 0)

    const isActiveGroup = (groupIndex: number): boolean => {
      return dragEvents?.isDragStarting && dragEvents?.dragOverGroup === groupIndex
    }

    const isLastGroupOperator = (isLastOperator: boolean) => {
      return dragEvents?.isDragStarting && isLastOperator
    }

    const isLastItemOfActiveGroup = (groupIndex: number, itemIndex: number, itemsLength: number): boolean => {
      return dragEvents?.isDragStarting && dragEvents?.dragOverGroup === groupIndex && itemIndex === itemsLength
    }

    const isPlaceholderOfActiveGroup = (groupIndex: number, itemIndex: number, groupLength: number): boolean => {
      return dragEvents?.isDragStarting && itemIndex === groupLength && dragEvents?.dragOverGroup === groupIndex
    }

    const deleteCondition = (groupIndex: number, itemIndex: number) => {
      setPayload((payload: ConfigPanelPayload) => {
        if (payload?.groups[groupIndex].groups.length === 1) {
          payload.groups.splice(groupIndex, 1)
        } else {
          payload?.groups[groupIndex].groups.splice(itemIndex, 1)
        }

        return { ...payload }
      })
    }

    const deleteGroup = (groupIndex: number) => {
      setPayload((payload: ConfigPanelPayload) => {
        payload.groups.splice(groupIndex, 1)

        return { ...payload }
      })
    }

    return (
      <Layout
        className='segment-builder__criteria'
        data-testid='segment-builder__criteria'
        onDragOver={(e) => handleDragOver(e)}
        onDrop={handleDrop}>
        {!!payload?.groups?.length &&
          payload.groups.map((group: Groups, groupIndex: number) => (
            <div
              key={groupIndex}
              onDragEnter={(e) => {
                onDragEnterGroup(e, groupIndex)
              }}
              className='group-container'
              data-testid='group-container'>
              <div>
                {groupIndex !== 0 && (
                  <div
                    className={`group-container__between-groups ${
                      isActiveGroup(groupIndex)
                        ? 'default-drop-area'
                        : (dragEvents?.isDragStarting && 'inactive-group') || 'default'
                    }`}>
                    <span>
                      <hr />
                    </span>

                    {/* TOGGLE TITLE WITH THE TRASH BUTTON */}
                    <span className={`title ${group.groups.length > 1 ? 'toggle-title' : ''}`}>OR</span>

                    {/* DISPLAY DELETE GROUP BUTTON ONLY WHEN THE GROUP HAS AT LEAST TWO CHILDREN */}
                    {group.groups.length > 1 && (
                      <div className={`delete-group`}>
                        <Delete onClick={() => deleteGroup(groupIndex)} />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`group-container__group-conditions ${groupIndex !== 0 && 'with-operator'} ${
                    (isActiveGroup(groupIndex) && 'active-group') || ''
                  }
                ${isActiveGroup(groupIndex) ? 'active-drop' : (dragEvents?.isDragStarting && 'inactive-drop') || ''}`}>
                  {group.groups.map((item: any, itemIndex: number) => (
                    <div key={itemIndex} className='group-container__item-container'>
                      {!motionStore.isSegmentBuilderEditDisabled && (
                        <Delete className='delete-icon' onClick={() => deleteCondition(groupIndex, itemIndex)} />
                      )}

                      <span
                        className={`group-container__group-operator ${itemIndex === 0 ? 'first' : ''} ${
                          dragEvents?.isDragStarting ? 'inactive' : 'default'
                        }`}>
                        AND
                      </span>

                      <CriteriaInput
                        payload={payload}
                        setPayload={setPayload}
                        indexes={{ group: groupIndex, item: itemIndex }}
                        {...(forceDisableDynamicInput && { forceDisableDynamicInput })}
                      />

                      {/* DISPLAY GROUP OPERATOR AT THE END OF THE GROUP WHEN THE USER STARTS DRAGGING */}
                      {isLastGroupOperator(group.groups.length === itemIndex + 1) && (
                        <span
                          className={`group-container__group-operator last-group-operator ${
                            dragEvents?.dragOverGroup === groupIndex ? 'active' : 'inactive'
                          }`}>
                          AND
                        </span>
                      )}

                      {/* DISPLAY ACTIVE PLACEHOLDER AT THE END OF THE GROUP WHEN THE USER STARTS DRAGGING */}
                      {isLastItemOfActiveGroup(groupIndex, itemIndex, group.groups.length - 1) ? (
                        <div
                          className={`group-container__placeholder ${
                            isPlaceholderOfActiveGroup(groupIndex, itemIndex, group.groups.length - 1)
                              ? 'active-group'
                              : 'inactive-group'
                          } `}>
                          <div></div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

        {/* DEFAULT OPERATOR & DEFAULT PLACEHOLDER DISPLAYED AT THE END OF THE CRITERIA CONDITIONS */}
        {dragEvents?.isDragStarting && (
          <div
            onDragEnter={(e) => {
              onDragEnterGroup(e, IS_OVER_NEW_GROUP)
            }}
            onDragLeave={(e) => onDragEnterGroup(e, IS_OVER_FREE_AREA_IN_DROPZONE)}>
            {payload?.groups?.length > 0 && (
              <div
                className={`group-container__between-groups ${
                  dragEvents?.dragOverGroup === IS_OVER_NEW_GROUP ||
                  dragEvents?.dragOverGroup === IS_OVER_FREE_AREA_IN_DROPZONE
                    ? 'default-drop-area'
                    : 'inactive-group'
                }
            `}>
                <span>
                  <hr />
                </span>

                <span className='title'>OR</span>
              </div>
            )}

            <div
              className={`group-container__placeholder ${
                displayDefaultPlaceholder ? 'active' : !payload?.groups?.length && 'default'
              }`}>
              <div>
                {displayPlaceholderIcon && (
                  <span>
                    <PlaceholderForward />
                    Drop it here
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

        {/* INITIAL CRITERIA VIEW - WHEN THERE ARE NO CONDITIONS */}
        {isInitialView && (
          <div className='segment-builder__criteria--initial-view' data-testid='segment-criteria'>
            <DragAndDrop />
            <span>Drag data attributes from data sources of integrations to build your statement</span>
          </div>
        )}
      </Layout>
    )
  },
)
SegmentCriteria.displayName = 'SegmentCriteria'
