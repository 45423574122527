import { IconLegend } from 'components/common/Icons/Icons'
import colors from 'components/MotionDetails/GoalMetric/Chart/helpers/chart-colors.module.scss'

import type { Align, ChartEvent, LayoutPosition, PointStyle } from 'chart.js'

import { ChartLegendEnum } from 'models/reporting.model'

const averageIcon = new Image(16, 8)
averageIcon.src = IconLegend

const legendTemplate = {
  fontColor: colors.legend,
  hidden: false,
  lineWidth: 1,
  strokeStyle: 'white',
  pointStyle: 'rect' as PointStyle,
  borderRadius: 0,
}

export const legend = {
  display: true,
  position: 'bottom' as LayoutPosition,
  align: 'center' as Align,
  // reverse: true,
  labels: {
    padding: 12,
    usePointStyle: true,
    generateLabels: () => {
      return [
        {
          ...legendTemplate,
          text: ChartLegendEnum.FirstQuartile,
          fillStyle: colors.firstQuartile,
          datasetIndex: 1,
        },
        {
          ...legendTemplate,
          text: ChartLegendEnum.SecondQuartile,
          fillStyle: colors.secondQuartile,
          datasetIndex: 2,
        },
        {
          ...legendTemplate,
          text: ChartLegendEnum.ThirdQuartile,
          fillStyle: colors.thirdQuartile,
          datasetIndex: 3,
        },
        {
          ...legendTemplate,
          text: ChartLegendEnum.FourthQuartile,
          fillStyle: colors.fourthQuartile,
          datasetIndex: 4,
        },
        {
          ...legendTemplate,
          text: ChartLegendEnum.Average,
          fillStyle: colors.average,
          pointStyle: averageIcon as PointStyle,
          datasetIndex: 0,
        },
      ]
    },
  },
  onHover: (e: ChartEvent) => {
    if ((e.native?.target as HTMLElement)?.style) {
      ;(e.native?.target as HTMLElement).style.cursor = 'pointer'
    }
  },
}
