import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { Button } from 'components/common'
import { IconPenButton } from 'components/common/Icons/Icons'
import CriteriaInlineText from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/CriteriaInlineText/CriteriaInlineText'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { ConfigPanelPayload } from 'models/motion/motionBuilder.model'

interface RecordLocatorCriteriaProps {
  actionType: string
  payload: ConfigPanelPayload
}

const RecordLocatorCriteria = observer(({ payload }: RecordLocatorCriteriaProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { configPanelStore, motionStore } = useStore()
  const { setDisplayRecordLocatorModal } = configPanelStore

  return (
    <div
      key={`record-locator-criteria-${segmentBuilderData.id || segmentBuilderData.nodeId}`}
      className='record-locator-criteria'
      data-testid='record-locator-criteria'>
      {payload?.groups?.length ? (
        <>
          <label>
            Record locator criteria
            <Button
              text='Edit'
              link={true}
              size='L'
              icon={{ element: <IconPenButton className='m-l-10' />, position: 'left' }}
              testId='edit-btn'
              onClickHandler={() => setDisplayRecordLocatorModal(true)}
            />
          </label>

          <CriteriaInlineText payload={payload} />
        </>
      ) : (
        <div className='highlight'>
          <label>
            <Button
              text='Define criteria'
              link={true}
              size='L'
              testId='add-btn'
              onClickHandler={() => setDisplayRecordLocatorModal(true)}
              disabled={motionStore.isSegmentBuilderEditDisabled}
            />{' '}
            to locate the records to be updated.
          </label>
        </div>
      )}
    </div>
  )
})
RecordLocatorCriteria.displayName = 'RecordLocatorCriteria'

export default RecordLocatorCriteria
