import { Carousel, Row, Select, Table } from 'antd'
import { useRef, useState } from 'react'

import { Heading, IconChevronLeft, IconChevronRight } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import { Card } from 'components/MotionDetails/OperationalStats/OperationalStatsCard'
import { handleSlideChange } from 'components/MotionDetails/OperationalStats/utils'

import type { CarouselRef } from 'antd/lib/carousel'

const fakeData = [
  {
    userName: 'Jane Smith',
    totalActions: 19,
    marketoTriggerCampaign: 3,
    pendoTriggerGuide: 0,
    slackSendMessage: 16,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Melanie Grainger',
    totalActions: 14,
    marketoTriggerCampaign: 2,
    pendoTriggerGuide: 1,
    slackSendMessage: 10,
    zendeskCreateTicket: 1,
  },
  {
    userName: 'Peter Pantheon',
    totalActions: 8,
    marketoTriggerCampaign: 2,
    pendoTriggerGuide: 2,
    slackSendMessage: 4,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Brooklyn McKay',
    totalActions: 5,
    marketoTriggerCampaign: 2,
    pendoTriggerGuide: 2,
    slackSendMessage: 1,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Tatjana Horvatinčić',
    totalActions: 4,
    marketoTriggerCampaign: 1,
    pendoTriggerGuide: 1,
    slackSendMessage: 1,
    zendeskCreateTicket: 1,
  },
  {
    userName: 'Sophia Fernandes Cardoso',
    totalActions: 2,
    marketoTriggerCampaign: 1,
    pendoTriggerGuide: 1,
    slackSendMessage: 0,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Rodi Donkersloot',
    totalActions: 1,
    marketoTriggerCampaign: 1,
    pendoTriggerGuide: 0,
    slackSendMessage: 0,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Marigold Bracegirdle',
    totalActions: 1,
    marketoTriggerCampaign: 1,
    pendoTriggerGuide: 0,
    slackSendMessage: 0,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Odovacar Sackville',
    totalActions: 1,
    marketoTriggerCampaign: 1,
    pendoTriggerGuide: 0,
    slackSendMessage: 0,
    zendeskCreateTicket: 0,
  },
  {
    userName: 'Belladonna Gardner',
    totalActions: 1,
    marketoTriggerCampaign: 1,
    pendoTriggerGuide: 0,
    slackSendMessage: 0,
    zendeskCreateTicket: 0,
  },
]

const GitHubDemo = () => {
  const slider = useRef<CarouselRef | null>(null)
  const [showArrow, setShowArrow] = useState({
    left: false,
    right: true,
  })

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: false,
    arrows: false,
    beforeChange: (current: number, next: number) =>
      handleSlideChange(
        // AntD Provideds the any type here
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        slider.current?.innerSlider.props.slidesToShow,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        slider.current?.innerSlider.state.slideCount,
        current,
        next,
        setShowArrow,
      ),
    responsive: [
      {
        breakpoint: 6000,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <div className='cards'>
        <div className='participated-motions__header'>
          <Heading variant='2' level='2'>
            Actions across Channels
          </Heading>
          <MockDataCallout />
        </div>
        {showArrow.left && (
          <div
            className='carousel-arrow carousel-arrow--left'
            onClick={() => {
              slider.current?.prev()
            }}>
            <IconChevronLeft />
          </div>
        )}
        <Carousel {...carouselSettings} ref={slider}>
          <Card isRound service='' metric='users' value='520' description='Users' justifyContentStart={true} />
          <Card
            isRound={false}
            service='marketo'
            metric='MARKETO_SEND_EMAIL'
            value='328'
            description='Trigger Campaign'
            justifyContentStart={true}
          />
          <Card
            isRound={false}
            service='pendo'
            metric='PENDO_LAUNCH_GUIDE'
            value='192'
            description='Trigger Guide'
            justifyContentStart={true}
          />
          <Card
            isRound={false}
            service='slack'
            metric='SLACK_SEND_MESSAGE'
            value='32'
            description='Send Message'
            justifyContentStart={true}
          />
          <Card
            isRound={false}
            service='zendesk'
            metric='ZENDESK_CREATE_TICKET'
            value='11'
            description='Create Ticket'
            justifyContentStart={true}
          />
        </Carousel>
        {showArrow.right && (
          <div className='carousel-arrow carousel-arrow--right' onClick={() => slider.current?.next()}>
            <IconChevronRight />
          </div>
        )}
      </div>
      <div className='actions-across-channels'>
        <div className='participated-motions__container' data-testid='participated-motions__container'>
          <div className='actions-across-channels-table'>
            <Row justify='space-between'>
              <Heading level='1' variant='1'>
                &nbsp;
              </Heading>
              <div className='header-right'>
                <Select data-testid='renewal-dropdown' defaultValue='' style={{ width: 240 }} onChange={(value) => {}}>
                  <Select.Option value='' key='rp-all'>
                    Time Period Date: <strong>10 weeks</strong>
                  </Select.Option>
                </Select>
              </div>
            </Row>
            <Table
              dataSource={[
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
                ...fakeData,
              ]}
              columns={[
                {
                  title: 'User Name',
                  dataIndex: 'userName',
                  key: 'userName',
                  className: 'actions-across-channels-cell-user-name',
                },
                {
                  title: 'Total Actions',
                  dataIndex: 'totalActions',
                  key: 'totalActions',
                  className: 'actions-across-channels-cell-count',
                },
                {
                  title: 'Marketo Trigger Campaign',
                  dataIndex: 'marketoTriggerCampaign',
                  key: 'marketoTriggerCampaign',
                  className: 'actions-across-channels-cell-count',
                },
                {
                  title: 'Pendo Trigger Guide',
                  dataIndex: 'pendoTriggerGuide',
                  key: 'pendoTriggerGuide',
                  className: 'actions-across-channels-cell-count',
                },
                {
                  title: 'Slack Send Message',
                  dataIndex: 'slackSendMessage',
                  key: 'slackSendMessage',
                  className: 'actions-across-channels-cell-count',
                },
                {
                  title: 'Zendesk Create Ticket',
                  dataIndex: 'zendeskCreateTicket',
                  key: 'zendeskCreateTicket',
                  className: 'actions-across-channels-cell-count',
                },
              ]}
              pagination={{ position: ['bottomRight'] }}
              rowClassName='participated-motions__table-row'
            />
          </div>
        </div>
      </div>
    </>
  )
}
GitHubDemo.displayName = 'GitHubDemo'

export default GitHubDemo
