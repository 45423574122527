import { Col, InputNumber, Row } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'

import IconWarning from 'assets/icons/insightsDetails/exclamation_mark_circle.svg?react'
import IconTarget from 'assets/icons/insightsDetails/target_mark.svg?react'
import { Button, Card, IconPenUnderlined } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'
import {
  formatNumber,
  getSourceValue,
} from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import useStore from 'store/useStore'

import type { MlMetricCategoryEnum } from 'models/account.model'
import type { Aggregation } from 'models/insights'
import { DataFormatEnum } from 'models/insights'

interface InsightImpactProps {
  aggregations: Aggregation[]
  isEmptyState?: boolean
  targetValue?: number
}

const InsightImpact = observer(({ aggregations, isEmptyState, targetValue }: InsightImpactProps) => {
  const { insightsStore } = useStore()
  const { currentInsight, selectedAccounts, setIsRecalculating, setTarget } = insightsStore
  const [showEditTarget, setShowEditTarget] = useState<boolean>(false)
  const [customTargetValue, setCustomTargetValue] = useState(targetValue)

  const inputTargetRef = useRef<HTMLInputElement>(null)

  const toggleEditTargetHandler = () => {
    setShowEditTarget((current) => !current)
  }

  const handleSetTarget = async () => {
    if (
      !currentInsight ||
      !currentInsight.train_datetime ||
      !currentInsight.infer_datetime ||
      !currentInsight.dataset_date ||
      !currentInsight.driver_id
    ) {
      return
    }

    setIsRecalculating(true)
    const value = Number.parseFloat(inputTargetRef.current?.value ?? '0')
    await setTarget({
      dimension: currentInsight.dimension,
      event: currentInsight.event.short_name as MlMetricCategoryEnum,
      metric_value: value,
      train_datetime: currentInsight.train_datetime,
      infer_datetime: currentInsight.infer_datetime,
      dataset_date: currentInsight.dataset_date,
      driver_id: currentInsight.driver_id,
    })
    setCustomTargetValue(value)
    setIsRecalculating(false)
    toggleEditTargetHandler()
  }

  return (
    <Row gutter={[20, 20]} className='insight-impact' data-testid='insight-impact'>
      {aggregations.map((aggregation, aggregationIndex) => {
        const isFirst = aggregationIndex === 0
        const isTarget = !isFirst && targetValue !== undefined
        const displaySetTarget = isTarget && showEditTarget
        const displayDefaultStats = !showEditTarget || !displaySetTarget

        const cardIcon = isFirst ? (
          <IconWarning className='header-icon__left' />
        ) : (
          <IconTarget className='header-icon__left' />
        )

        return (
          <Col xl={12} sm={24} key={aggregation.title}>
            <Card
              variant='1'
              className={classNames({
                red: isFirst,
                'no-impact': isFirst,
                'target-reached': !isFirst,
              })}
              data-testid={isFirst ? 'no-impact-card' : 'target-reached-card'}>
              <Row className='header'>
                {cardIcon}
                <h3 className='header-text' data-testid={isFirst ? 'no-impact-header' : 'target-reached-header'}>
                  {aggregation.title}
                </h3>
                {isFirst && <MockDataCallout />}

                {isTarget && (
                  <Button
                    text={`${formatNumber({ number: customTargetValue ?? 0, format: DataFormatEnum.Kmb, decimal: 1 })}`}
                    link
                    type='primary'
                    className='m-l-5'
                    icon={{ position: 'right', element: <IconPenUnderlined /> }}
                    onClickHandler={toggleEditTargetHandler}
                  />
                )}
              </Row>

              {displayDefaultStats ? (
                <Row className='body' gutter={[25, 10]}>
                  {aggregation.sources.map((item, itemIndex) => {
                    return (
                      <Col className='item' key={item.title}>
                        <div className={classNames('item-value', { highlight: isFirst && itemIndex > 1 })}>
                          {getSourceValue(item, selectedAccounts)}
                        </div>

                        <div className='item-description'>{item.title}</div>
                      </Col>
                    )
                  })}
                </Row>
              ) : (
                <></>
              )}

              {displaySetTarget ? (
                <Row className='target-body'>
                  <>
                    <Col xs={24}>
                      <p className='target-body__header'>Set a new target value for the Segment</p>
                    </Col>

                    <Col className='target-body__group' xs={24}>
                      <InputNumber
                        ref={inputTargetRef}
                        min={0}
                        defaultValue={customTargetValue}
                        controls={false}
                        className='target-body__input'
                      />
                      <Button text='Set' className='target-body__button' onClickHandler={handleSetTarget}></Button>
                    </Col>
                  </>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        )
      })}

      {isEmptyState && (
        <Col xl={12} sm={24}>
          <Card variant='1' data-testid='empty-state' className='empty-state'>
            <IconTarget width={36} height={36} />

            <p className='empty-state__text' data-testid='empty-state__text'>
              Unlock projected revenue and churn impact by augmenting your customer lifecycle.
            </p>
            <Button text='Learn more here' link type='primary' testId='empty-state__btn'></Button>
          </Card>
        </Col>
      )}
    </Row>
  )
})
InsightImpact.displayName = 'InsightImpact'

export default InsightImpact
