import { useMemo } from 'react'
import isEqual from 'utils/isEqual'

import { isDSLDifferent, isOperatorAndActionDSLDifferent, isSegmentDSLDifferent } from 'pages/Motions/Motion.utils'
import { reactFlowToDSL } from 'services/Utils/dslConversion/reactFlowToDSL/reactFlowToDSL.utils'
import useStore from 'store/useStore'

import type { Elements } from 'react-flow-renderer'

import type { Dsl } from 'models/motion.model'

/**
 * A custom react hook that provides state and functions for checking the possible differences in a Motion's state
 * @param {Elements<any>} elements The elements of the Motion flow.
 * @param {Elements<any>} experimentElements The elements of the experiment flow.
 * @returns {Object} An object containing the state and functions for checking the differences.
 */
const useCheckMotionDifferences = (elements: Elements<any>, experimentElements: Elements<any>) => {
  const { motionStore, motionGoalsStore } = useStore()

  /**
   * Returns an array of boolean values indicating if there are any differences between the Motion DSL
   * and the local DSL in terms of overall structure, segment structure, operation and action structure, and experiment structure.
   * @returns {boolean[]} An array of four boolean values, corresponding to hasOverallDSLChanged, hasSegmentDSLChanged,
   * hasOperationAndActionDSLChanged, and hasExperimentDSLChanged.
   */
  const [hasOverallDSLChanged, hasSegmentDSLChanged, hasOperationAndActionDSLChanged, hasExperimentDSLChanged] =
    useMemo(() => {
      if (!motionStore.currentMotion) {
        return [true, true, true, true]
      }

      const dsl = { ...motionStore.currentMotion.dsl }
      const localDSL = reactFlowToDSL({
        elements,
        aggregations: motionStore.currentMotion?.dsl?.aggregations,
      })

      const experimentDsl = { ...motionStore.currentMotion?.experiment?.dsl }
      const localexperimentDSL = reactFlowToDSL({
        elements: experimentElements,
        // aggregations: aggregationsDataStore.experimentAggregationsList, // TODO: Add aggregations to experiment
      })

      const overallDSLDifferent = isDSLDifferent(dsl, localDSL)
      const segmentDSLDifferent = isSegmentDSLDifferent(dsl, localDSL)
      const actionDSLDifferent = isOperatorAndActionDSLDifferent(dsl, localDSL)
      const experimentDSLDifferent =
        experimentElements.length > 0 ? isDSLDifferent(experimentDsl as Dsl, localexperimentDSL) : false

      return [overallDSLDifferent, segmentDSLDifferent, actionDSLDifferent, experimentDSLDifferent]
    }, [elements, experimentElements, motionStore.currentMotion])

  /**
   * Returns a boolean value indicating if there are any differences between the Motion title or description and the current Motion goals.
   * @returns {boolean} A boolean value corresponding to hasTitleOrDescriptionChanged.
   */
  const hasTitleOrDescriptionChanged = useMemo(() => {
    const isTitleChanged = motionGoalsStore.currentMotionGoals.title !== motionStore.currentMotion?.title

    const isDescriptionChanged =
      motionGoalsStore.currentMotionGoals.description !== motionStore.currentMotion?.description

    return isTitleChanged || isDescriptionChanged
  }, [motionGoalsStore.currentMotionGoals, motionStore.currentMotion])

  /**
   * Returns a boolean value indicating if there are any differences between the motionGoalsStore currentMotion goals
   * and the motionStore's currentMotion goals.
   * @returns {boolean} A boolean value corresponding to hasGoalsChanged.
   */
  const hasGoalsChanged = !isEqual(motionGoalsStore.currentMotionGoals.goal, motionStore.currentMotion?.goal || {})

  /**
   * Returns a boolean value indicating if there are any differences between the Motion metrics and the current Motion goals.
   * @returns {boolean} A boolean value corresponding to hasMetricsChanged.
   */
  const hasMetricsChanged = !isEqual(
    motionGoalsStore.currentMotionGoals.metrics,
    motionStore.currentMotion?.metrics || [],
  )

  return {
    hasOverallDSLChanged,
    hasSegmentDSLChanged,
    hasOperationAndActionDSLChanged,
    hasExperimentDSLChanged,
    hasTitleOrDescriptionChanged,
    hasGoalsChanged,
    hasMetricsChanged,
  }
}

export default useCheckMotionDifferences
