import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { DataFormatEnum } from 'models/insights'

interface StatsSummaryProps {
  summaryItems: { title: string; value: number }[]
  hasAnyActionsRun: boolean
}

const StatsSummary = ({ summaryItems, hasAnyActionsRun }: StatsSummaryProps) => {
  return (
    <div className='stats-summary' data-testid='stats-summary'>
      {summaryItems.map((item) => {
        return (
          <div key={item.title} className='stats-summary__item'>
            <h6>{item.title}</h6>
            {hasAnyActionsRun ? (
              <p>
                {formatNumber({
                  number: item.value,
                  format: DataFormatEnum.CurrencyKMB,
                  decimal: 1,
                }).slice(1)}
              </p>
            ) : (
              <div className='motion-observability__card__value--empty' />
            )}
          </div>
        )
      })}
    </div>
  )
}
StatsSummary.displayName = 'StatsSummary'

export default StatsSummary
