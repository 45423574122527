import type { CreateActionFields, ExtendedSelectOptions, Item } from 'models/motion/motionBuilder.model'

interface CriteriaItemKeyProps {
  item: Item | CreateActionFields
  itemKey?: string
  isAction?: boolean
}
export const criteriaItemKey = ({ item, itemKey, isAction }: CriteriaItemKeyProps) => {
  const key = `${item.platform}${item.object}${itemKey ?? item.key ?? item.field}`
  return isAction ? `action${key}` : key
}

/**
 * MAGPROD-969
 * Filter select 'playbook' options based on the value of another field ( type )
 * @param item Item | CreateActionFields
 * @param criteriaSelect object with options prop, derived from metadata store
 * @returns a list of options for the select input
 */
export const filteredSelectOptions = (
  item: Item | CreateActionFields,
  criteriaSelect: {
    options: Map<string, CreateActionFields[]>
  },
  isAction?: boolean,
) => {
  const { platform, object, key, ctaTypeValue } = item as CreateActionFields

  if (
    platform?.toLowerCase() === 'gainsight' &&
    object?.toLowerCase() === 'call_to_action' &&
    key?.toLowerCase() === 'playbook'
  ) {
    if (ctaTypeValue) {
      const criteriaItemKeyForType = criteriaItemKey({ item, itemKey: 'Type', isAction })

      const ctaTypeIdFromSelectedType =
        criteriaSelect.options.get(criteriaItemKeyForType)?.find((option) => option.value === ctaTypeValue)
          ?.ctaTypeId ?? ''

      return criteriaSelect.options
        .get(criteriaItemKey({ item, isAction }))
        ?.filter((option) => option.ctaTypeId === ctaTypeIdFromSelectedType)
    }
  }
  return criteriaSelect.options.get(criteriaItemKey({ item, isAction }))
}

/**
 * Sets the label of each option to its value if the label is empty.
 * A label is required for search and to display the selected option.
 *
 * @param options - An array of ExtendedSelectOptions objects.
 * @returns A new array of ExtendedSelectOptions with labels set if they were empty.
 */
export const setLabelIfEmpty = (options: ExtendedSelectOptions[]): ExtendedSelectOptions[] => {
  if (!options) return []

  return options.map((option) => {
    if (option && !option.label) {
      option.label = typeof option.value === 'string' ? option.value : ''
    }
    return option
  })
}
