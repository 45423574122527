import { useMemo } from 'react'

import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import { getIconUrl } from 'pages/Integrations/utils'

import type { Integration } from 'models/Integration.model'

interface StatCardProps {
  title: string
  integrations: Integration[]
  text: string
}

export const StatCard = ({ title, integrations, text }: StatCardProps) => {
  const getIcons = useMemo(() => {
    if (!integrations || integrations.length < 1) return null

    return integrations.slice(0, 3).map((integration: Integration) => {
      return (
        <DynamicImage key={integration.id} iconUrl={getIconUrl(integration.id)} iconAlt={integration.displayName} />
      )
    })
  }, [integrations])

  return (
    <div className='stat-card'>
      <div className='stat-card__heading'>
        <p>{title}</p>
        <div className='stat-card__icons'>
          {getIcons}
          {integrations.length > 3 && <span className='stat-card__icons__more'>+{integrations.length - 3}</span>}
        </div>
      </div>
      <p className='stat-card__text'>{text}</p>
    </div>
  )
}
StatCard.displayName = 'StatCard'
