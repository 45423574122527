import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'

interface BreadcrumbTagProps {
  breadcrumb: {
    platform?: string
    object?: string
    field: string
    magnifyDisplayName?: string
  }
  separator?: string
}

const BreadcrumbTag = ({ breadcrumb, separator = '/' }: BreadcrumbTagProps) => {
  return (
    <div className='breadcrumb-tag' data-testid='breadcrumb-tag'>
      {breadcrumb.platform && (
        <span className='breadcrumb-tag__name' data-testid='breadcrumb-tag__platform' title={breadcrumb.platform}>
          {getMenuItemIcon({ entityType: 'platform', name: breadcrumb.platform })}
        </span>
      )}
      {breadcrumb.object && (
        <>
          <span className='breadcrumb-tag__separator'>{separator}</span>
          <span
            className='breadcrumb-tag__name'
            data-testid='breadcrumb-tag__object'
            title={breadcrumb.magnifyDisplayName ?? breadcrumb.object}>
            {breadcrumb.magnifyDisplayName ?? breadcrumb.object}
          </span>

          <span className='breadcrumb-tag__separator'>{separator}</span>
        </>
      )}
      {breadcrumb.field && (
        <span className='breadcrumb-tag__name' data-testid='breadcrumb-tag__field' title={breadcrumb.field}>
          {breadcrumb.field}
        </span>
      )}
    </div>
  )
}
BreadcrumbTag.displayName = 'BreadcrumbTag'

export default BreadcrumbTag
