import axios from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetAllUsers } from 'api/mockResponses/demo/users.mock'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type { IdentityResponse, User, UserPost } from 'models/users.model'

export const post = async (user: UserPost): Promise<User> => {
  try {
    const { data } = await axios.post<User>(`${getBaseUrl('CORE_API')}/v1/core/users`, user)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'post users error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to create user')
    }
  }
}

export const getAll = async () => {
  try {
    const { data } = await axios.get<User[]>(`${getBaseUrl('CORE_API')}/v1/core/users`, {
      demoData: demoGetAllUsers,
    } as DemoAxiosRequestConfig)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll users error', error: error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch users')
    }
  }
}

export const get = async (id: string): Promise<User> => {
  try {
    const { data } = await axios.get<User>(`${getBaseUrl('CORE_API')}/v1/core/users/${id}`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get user error', error: error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch user')
    }
  }
}

export const update = async (user: Partial<User>): Promise<User> => {
  try {
    const { data } = await axios.put<User>(`${getBaseUrl('CORE_API')}/v1/core/users/${user.username}`, user)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'update users error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to update user')
    }
  }
}

export const remove = async (username: string) => {
  try {
    const { data } = await axios.delete<User>(`${getBaseUrl('CORE_API')}/v1/core/users/${username}`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getUsersAPI error', error })
    return []
  }
}

export const getIdentity = async (email: string) => {
  try {
    const { data } = await axios.get<IdentityResponse>(`${getBaseUrl('CORE_API')}/v1/core/users/identity`, {
      params: { email },
    })
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getIdentity users error', error })
    return null
  }
}
