import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { NOTIFICATIONS_PER_PAGE } from 'configs'
import type { ChildStore } from 'store/StoreTypes'

import type { NotificationData } from 'models/notification.model'

const wssBaseURL: string = import.meta.env.VITE_WS_URL || ''

export class NotificationStore implements ChildStore {
  notifications: NotificationData[] = []
  unSeenNotifications: NotificationData[] = []
  isLoading: boolean = true
  token: string = ''
  selfClosing = false
  websocket: null = null
  wssUrl: string = wssBaseURL
  isNotificationPushed: boolean = false

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.notifications = []
    this.unSeenNotifications = []
    this.isLoading = true
    this.token = ''
    this.selfClosing = false
    this.websocket = null
    this.wssUrl = wssBaseURL
    this.isNotificationPushed = false
    this.apiError = null
  }

  setNotifications = (notifications: NotificationData[], afterDelete?: boolean) => {
    if (!afterDelete) {
      // store the notification ids in order to eliminate the duplicates of notifications
      // there is a case when

      const ids = new Set(this.notifications.map((notification) => notification.id))
      this.notifications.push(...notifications.filter((d: NotificationData) => !ids.has(d.id)))
    } else {
      this.notifications = notifications
    }
  }

  getUnseenNotifications = async (): Promise<void> => {
    try {
      const { notifications } = await API.notifications.getAll({ unseen: true, limit: NOTIFICATIONS_PER_PAGE })
      runInAction(() => {
        if (notifications && !(notifications instanceof Error)) {
          this.unSeenNotifications.push(...notifications)
        }
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  markSeenNotifications = async (): Promise<void> => {
    const response = await API.notifications.update()
    runInAction(() => {
      if (!(response instanceof Error)) {
        this.unSeenNotifications = []
      }
    })
  }

  removeNotification = async (notificationId: string): Promise<void> => {
    this.setIsLoading(true)
    const removedNotification = await API.notifications.remove(notificationId)
    const notificationIndex = this.notifications.findIndex((notification) => notification.id === notificationId)

    runInAction(() => {
      if (!(removedNotification instanceof Error)) {
        if (removedNotification) {
          this.notifications.splice(notificationIndex, 1)
          this.setIsLoading(false)
          return removedNotification
        }
      } else {
        return removedNotification
      }
    })
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
