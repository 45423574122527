import { Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import HorizontalStackedChart from 'components/MotionObservability/components/HorizontalStackedChart'
import { capitalizeFirstLetter } from 'services/Utils/parseString.utils'
import useStore from 'store/useStore'

import { DataFormatEnum } from 'models/insights'
import type { SegmentReportingItems } from 'models/observability.model'

interface SegmentReportingProps {
  title: string
  items: SegmentReportingItems
}

const SegmentReporting = observer(({ title, items }: SegmentReportingProps) => {
  const { observabilityStore } = useStore()
  const itemsTotal = Object.values(items).reduce((acc, item) => acc + item.total, 0)

  if (observabilityStore.isLoadingFetchTenantInMotionReportingTotals) {
    return (
      <div className='segment-reporting'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='segment-reporting'>
      <div className='segment-reporting__header'>
        <h5 className='segment-reporting__header__title'>{title}</h5>
        <p className='segment-reporting__header__total'>
          {formatNumber({ number: itemsTotal, format: DataFormatEnum.Kmb, decimal: 1 })}
        </p>
      </div>

      <HorizontalStackedChart title='' items={items} hasAnyData={true} showLegend={false} barHeight='sm' />

      <div className='segment-reporting__summary'>
        {Object.entries(items).map(([key, value], index) => {
          return (
            <div className='segment-reporting__summary__item' key={`summary-item-${key}-${index}`}>
              <div className='segment-reporting__summary__item__label'>
                <div
                  className={classNames('segment-reporting__summary__item__label__dot', {
                    [`bg-${value.segmentColor}`]: value.segmentColor,
                  })}
                />
                <p className='segment-reporting__summary__item__label__text'>{capitalizeFirstLetter(key)}</p>
              </div>
              <p className='segment-reporting__summary__item__stat'>
                {formatNumber({ number: value.total, format: DataFormatEnum.Kmb, decimal: 1 })} (
                {Number(value.percentageTotal).toFixed(0)}%)
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
})

export default SegmentReporting
