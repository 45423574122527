import { IconGripper, IconPenUnderlined, IconTrash } from 'components/common'
import Button from 'components/common/Button'
import useStore from 'store/useStore'

import type { DragEvent } from 'react'

import type { AggregationData } from 'models/motion/motionBuilder.model'

interface CalculatedDataItemProps {
  index: number
  item: AggregationData
  onDragStart: (e: DragEvent<HTMLDivElement>, item: AggregationData) => void
  handleDragEnd?: (e: DragEvent<HTMLDivElement>) => void
}

const CalculatedDataItem = ({ item, index, onDragStart, handleDragEnd }: CalculatedDataItemProps) => {
  const { aggregationsDataStore } = useStore()
  const { setCurrentItem, setCurrentUuid, setDisplayAggregationModal } = aggregationsDataStore

  const handleEditItem = () => {
    setCurrentItem(item)
    setDisplayAggregationModal(true, true)
  }

  const handleRemoveAggreagtion = () => {
    setCurrentUuid(item.aggregationUuid || null)
  }

  return (
    <div
      id={`${item.name}-${index}`}
      onDragStart={(e: DragEvent<HTMLDivElement>) => {
        onDragStart(e, item)
      }}
      onDragEnd={(e: DragEvent<HTMLDivElement>) => {
        handleDragEnd?.(e)
      }}
      draggable={true}
      className='menu__item'
      data-testid='aggregation__menu-item'>
      <span className='menu__name'>{item.name}</span>
      <Button
        text=''
        icon={{ element: <IconPenUnderlined /> }}
        type='secondary'
        link
        className='menu__item--edit-action'
        testId='menu__item--edit-action'
        onClickHandler={handleEditItem}
      />
      <Button
        text=''
        icon={{ element: <IconTrash /> }}
        type='secondary'
        link
        className='menu__item--remove-action'
        testId='menu__item--remove-action'
        onClickHandler={handleRemoveAggreagtion}
      />
      <IconGripper />
    </div>
  )
}

export default CalculatedDataItem
