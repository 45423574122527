import { useMemo } from 'react'

import Sandbox from 'configs/sandbox'

/**
 * Custom hook to determine whether the sandbox env is enabled
 * SANDBOX_ENV_ENABLED_KEY gets set to 'true' if the application loads and sandbox flag from launch darkly is true for the user and/or tenant
 * This is needed to access the sandbox flag outside of component files
 */
export const useSandboxEnv = () => {
  const isSandboxEnvEnabled = useMemo(() => Sandbox.isEnabled(), [localStorage])

  const enableSandboxEnv = () => {
    Sandbox.enable()
  }

  const disableSandboxEnv = () => {
    Sandbox.disable()
  }

  return {
    isSandboxEnvEnabled,
    enableSandboxEnv,
    disableSandboxEnv,
  }
}

export default useSandboxEnv
