import axios from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetAllNotifications } from 'api/mockResponses/demo/notifications.mock'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type { Notification, NotificationData, NotificationUpdate } from 'models/notification.model'

export const getAll = async (options?: { page?: number; unseen?: boolean; limit: number }) => {
  try {
    const { data } = await axios.get<Notification>(`${getBaseUrl('CORE_API')}/v1/core/notifications`, {
      params: options,
      demoData: demoGetAllNotifications,
    } as DemoAxiosRequestConfig)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll notifications error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch notifications')
    }
  }
}

export const update = async (notificationId?: string, updateData?: NotificationUpdate) => {
  try {
    const { data } = await axios.put<Notification>(
      `${getBaseUrl('CORE_API')}/v1/core/notifications/${notificationId ? notificationId : ''}`,
      updateData,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'update notification error', error })
    return error
  }
}

export const remove = async (notificationId: string) => {
  try {
    const { data } = await axios.delete<NotificationData>(
      `${getBaseUrl('CORE_API')}/v1/core/notifications/${notificationId}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'remove notification error', error })
    return error
  }
}
