// API Endpoints
export const CORE_API: string = import.meta.env.VITE_CORE_API || 'http://core-api-development'
export const ADMIN_API: string = import.meta.env.VITE_ADMIN_API || 'http://admin-api-development'
export const INTEGRATIONS_API: string =
  import.meta.env.VITE_INTEGRATIONS_API || 'http://MISSING_VITE_INTEGRATIONS_API_ENV'
export const endpoints: Record<string, string> = {
  CORE_API,
  ADMIN_API,
  INTEGRATIONS_API,
}

/**
 * Get the base URL for the API call with optional overrides.
 * @param {key} baseUrl The default base URL for the API and the suffix of the key to look for in localStorage
 */
export const getBaseUrl = (key: keyof typeof endpoints): string => {
  try {
    const localStorageValue = localStorage.getItem(`API_URL_${key}`)
    if (localStorageValue != null) {
      return localStorageValue
    }
  } catch (error: unknown) {
    console.error(error)
  }
  return endpoints[key] ?? ''
}
