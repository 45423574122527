import type { CreateActionFields, CreateActionFieldsGainsight } from 'models/motion/motionBuilder.model'

export interface MetadataDescription extends MetadataRoot {
  name: string
  entityType?: string
  type?: string
  key?: string
  platform?: string
  object?: string
  field?: string
  solutionInstanceId?: string
  connections: MetadataConnections[]
}
export interface MetadataRoot {
  data: MetadataDescription[]
  name?: string
  magnifyDisplayName?: string
  // any is set for field values eg dropdownValues of AccountSource. I don't think we need that
  dropdownValues?: Record<string, boolean | string | null>[]
}

export interface SetMetadata extends MetadataTypes {
  newMetadata: MetadataRoot
}

export interface MetadataTypes {
  action?: string
  field?: string
  magnifyDisplayName?: string
  object?: string
  platform?: string
  solutionInstanceId?: string
}
export interface TransversMetadata {
  metadata: MetadataRoot
  options: MetadataTypes
  newMetadata: MetadataRoot
  iterationNumber: number
  nextSearchItem?: string
}

export interface MetadataActions {
  actionName: string
  id: string
  metadataObject: string
  actionLevel: string
  platform: string
  type: string
}
export interface CreateActionDataTypes {
  name: string
  type: string
  required: boolean
}

export interface MetadataItem {
  key: string
  magnifyDisplayName?: string
  name: string
  solutionInstanceId?: string
  type?: string
}

export interface MetadataSearchItem {
  data?: MetadataDescription[]
  magnifyDisplayName?: string
  name?: string
  order: MetadataItem[]
  type: string
}

export interface MetadataSearch {
  data: MetadataSearchItem[]
}

export interface CriteriaLocatorMetadata {
  hasError: boolean
  isOpen: boolean
  metadataViewList: MetadataDescription[]
}

export interface MetadataConnections {
  name: string
  date: number
  solutionInstanceId: string
}

export interface MetadataSearchPayload {
  search: string
  platform?: string
}

export interface CriteriaSelectOptions {
  active: boolean
  defaultValue: boolean
  label: string
  validFor: null
  value: string
}

export interface MetadataPlatformResponse {
  data: MetadataPlatform[] | CreateActionFields[] | CreateActionFieldsGainsight
}

export interface MetadataPlatform {
  name: string
  connections: MetadataConnection[]
}

export interface MetadataConnection {
  name: string
  date: number
  solutionInstanceId: string
}

export enum FieldTypeEnum {
  Int = 'int',
  Integer = 'integer',
  Number = 'number',
  Currency = 'currency',
  Percent = 'percent',
  Double = 'double',
  Decimal = 'decimal',
  Url = 'url',
  Id = 'id',
  Address = 'address',
  String = 'string',
  Phone = 'phone',
  Text = 'text',
  Reference = 'reference',
  Regexp = 'regexp',
  Textarea = 'textarea',
  Date = 'date',
  Datetime = 'datetime',
  Datepicker = 'datepicker',
  Picklist = 'picklist',
  Collection = 'collection',
  Dropdown = 'dropdown',
  Select = 'select',
  Boolean = 'boolean',
  Email = 'email',
  Payload = 'payload',
  HTML = 'html',
  Others = 'others',
  Hidden = 'hidden',
}
