abstract class Sandbox {
  private static SANDBOX_ENV_ENABLED_KEY = 'sandboxEnvEnabled'

  public static enable() {
    localStorage.setItem(Sandbox.SANDBOX_ENV_ENABLED_KEY, 'true')
  }

  public static disable() {
    localStorage.removeItem(Sandbox.SANDBOX_ENV_ENABLED_KEY)
  }

  public static isEnabled(): boolean {
    return localStorage.getItem(Sandbox.SANDBOX_ENV_ENABLED_KEY) === 'true'
  }
}

export default Sandbox
