import classNames from 'classnames'
import React from 'react'

import type { variant } from 'models'

interface CardProps {
  variant: variant
  className?: string
  style?: React.CSSProperties
  children: React.JSX.Element | React.JSX.Element[] | string
}

export const Card = ({ variant, className, style, children }: CardProps) => {
  return (
    <div className={classNames(`c-card c-card--${variant}`, className)} style={style} data-testid='card'>
      {children}
    </div>
  )
}
Card.displayName = 'Card'
