export const sorterNumbers = (a: number | null, b: number | null) => {
  if (a === b) return 0
  if (a === null) return 1
  if (b === null) return -1
  return a - b
}

export const sortAlphabetically = (a: string, b: string) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1
  }
  return 0
}
