import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'

import { Button, Heading, EMPTY_GROUPS_PAYLOAD } from 'components/common'
import { IconAlert, IconTrash, IconClose } from 'components/common/Icons/Icons'
import { DataSourcePanel } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/DataSourcePanel/DataSourcePanel'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { clone } from 'services/Utils/misc'
import { validateCriteriaPayload } from 'services/Utils/validator'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { MetadataDescription } from 'models/metadata.model'
import type { BreadcrumbInfo, ConfigPanelPayload } from 'models/motion/motionBuilder.model'

interface RecordLocatorModalProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const RecordLocatorModal = observer(({ payload, setPayload }: RecordLocatorModalProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { configPanelStore, metadataStore } = useStore()
  const { get, setCurrentItem, setBreadCrumbItems, setFreezeBreadcrumbs } = metadataStore
  const {
    displayRecordLocatorModal,
    displayConfirmationModal,
    setDisplayRecordLocatorModal,
    setDisplayConfirmationModal,
  } = configPanelStore
  const [modalPayload, setModalPayload] = useState(EMPTY_GROUPS_PAYLOAD as ConfigPanelPayload)
  const [modalPayloadError, setModalPayloadError] = useState(false)

  useEffect(() => {
    if (displayRecordLocatorModal) {
      const options = {
        platform: segmentBuilderData.platform,
        object: segmentBuilderData.object,
        solutionInstanceId: segmentBuilderData.solutionInstanceId,
        ...(segmentBuilderData.magnifyDisplayName && { magnifyDisplayName: segmentBuilderData.magnifyDisplayName }),
      }
      const defaultBreadcrumbs: BreadcrumbInfo[] = [
        {
          name: segmentBuilderData.platform,
          path: [segmentBuilderData.platform],
          entityType: 'platform',
        },
        {
          name: segmentBuilderData.object,
          path: [segmentBuilderData.platform, segmentBuilderData.object],
          entityType: 'object',
          ...(segmentBuilderData.magnifyDisplayName && { magnifyDisplayName: segmentBuilderData.magnifyDisplayName }),
        },
      ]

      get(options).catch(console.error)
      setBreadCrumbItems(defaultBreadcrumbs)
      setCurrentItem(options as MetadataDescription, true)
      setModalPayload(clone(payload || EMPTY_GROUPS_PAYLOAD))
      setFreezeBreadcrumbs(true)
    } else {
      setFreezeBreadcrumbs(false)
    }
  }, [displayRecordLocatorModal])

  useMetadataDisplayErrorNotification(metadataStore)

  const handleSubmit = () => {
    const validCriteria: boolean = validateCriteriaPayload({ payload: modalPayload })
    if (validCriteria) {
      setPayload((prev) => {
        return { ...prev, ...modalPayload }
      })
      setDisplayRecordLocatorModal(false)
    } else {
      setModalPayloadError(true)
      setTimeout(() => {
        setModalPayloadError(false)
      }, 5000)
    }
  }

  const handleCancel = () => {
    setModalPayload(clone(payload || EMPTY_GROUPS_PAYLOAD))
    setDisplayRecordLocatorModal(false)
  }

  const handleRemoveCriteria = () => {
    if (modalPayload?.groups?.length) {
      setDisplayConfirmationModal(true)
    }
  }

  const handleConfirmRemove = () => {
    setModalPayload(EMPTY_GROUPS_PAYLOAD as ConfigPanelPayload)
    setPayload((prev) => {
      return {
        ...prev,
        ...EMPTY_GROUPS_PAYLOAD,
      }
    })

    setDisplayConfirmationModal(false)
    setDisplayRecordLocatorModal(false)
  }

  const handleCancelRemove = () => {
    setDisplayConfirmationModal(false)
  }

  return (
    <>
      <Modal
        key={`data-source-modal-${segmentBuilderData.id || segmentBuilderData.nodeId}`}
        open={displayRecordLocatorModal}
        closable={false}
        centered
        width={900}
        className='modal__form modal__data-source'
        footer={[
          <React.Fragment
            key={`modal__form--footer modal-footer-${segmentBuilderData.id || segmentBuilderData.nodeId}`}>
            {modalPayloadError && (
              <label className='error-message' data-testid='error-message'>
                You cannot apply criteria without a value.
              </label>
            )}
            <Button type='secondary' text='Cancel' testId='cancel-btn' onClickHandler={handleCancel} />
            <Button type='primary' text='Apply' testId='apply-btn' className='m-l-10' onClickHandler={handleSubmit} />
          </React.Fragment>,
        ]}>
        <div className='modal__form--header'>
          <Heading level='1' variant='2'>
            Record locator criteria
          </Heading>

          <div className='header--buttons' data-testid='header--buttons'>
            <Button
              text='Remove'
              type='danger'
              size='L'
              icon={{ element: <IconTrash /> }}
              link={true}
              disabled={!modalPayload?.groups?.length}
              className='m-r-15'
              testId='remove-btn'
              onClickHandler={handleRemoveCriteria}
            />
            <IconClose onClick={handleCancel} className='btn__close' data-testid='modal-close' />
          </div>
        </div>

        <div className='modal__form--body segment-builder' data-testid='segment-builder'>
          <DataSourcePanel displayHeader={false} payload={modalPayload} setPayload={setModalPayload} />
        </div>
      </Modal>

      <Modal
        key={`confirmation-modal-${segmentBuilderData.id || segmentBuilderData.nodeId}`}
        open={displayConfirmationModal && displayConfirmationModal}
        closable={false}
        onCancel={handleCancelRemove}
        centered
        width={668}
        className='modal__remove modal__data-source--confirmation'
        footer={[
          <Button
            key='cancel'
            type='secondary'
            text='Cancel'
            testId='cancel-btn'
            onClickHandler={handleCancelRemove}
          />,
          <Button
            key='confirm'
            type='primary'
            text='Confirm'
            className='m-l-10'
            testId='confirm-btn'
            onClickHandler={handleConfirmRemove}
          />,
        ]}>
        <div className='modal__remove--heading' data-testid='modal__remove--heading'>
          <Heading level='1' variant='3'>
            Remove Criteria Locator?
          </Heading>

          <IconClose onClick={handleCancelRemove} className='btn__close' data-testid='modal-close' />
        </div>

        <div className='message'>
          <IconAlert />
          <span className='message__text' role='alert' data-testid='modal-message'>
            Please note that this will remove all criteria locators.
          </span>
        </div>
      </Modal>
    </>
  )
})

export default RecordLocatorModal
