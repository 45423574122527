/**
 * This component is the edit view of a template. It is going to perform a look up to see if a template is already in the MobX store
 * Otherwise, it will fetch the template based on the ID from the TemplateList component
 */

import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Editor } from '@tinymce/tinymce-react'
import { Dropdown, Form, Input, Spin, Button as CloseButton, type MenuProps } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { domToJpeg } from 'modern-screenshot'
import { useCallback, useEffect, useRef, useState } from 'react'

import { API } from 'api/api'
import { Button, IconDynamicInput, IconGear, IconMailGray, IconPenUnderlined } from 'components/common'
import DynamicInputModal from 'components/common/DynamicInputModal'
import DiscardChangesModal from 'components/EmailTemplates/modals/DiscardChangesModal'
import SaveAsNewTemplateModal from 'components/EmailTemplates/modals/SaveAsNewTemplateModal'
import SendTestEmailModal from 'components/EmailTemplates/modals/SendTestEmailModal'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { CreateActionFields, Item } from 'models/motion/motionBuilder.model'

const MAGNIFY_SEND_EMAIL_CONSTANTS = {
  platform: 'magnify',
  object: 'email',
}

interface EditTemplateProps {
  changeActionConfigPanelFieldValue?: (value: string | number, key: string) => void
  onCloseEditView: () => void
  closeTemplateListModal?: () => void
  isInActionFlow?: boolean
  isNewTemplate: boolean
  initializeEdit?: { isEdit: boolean; templateId: string | null; templateVersion: number | null }
}

export interface SaveTemplatePayload {
  htmlContent: string
  name: string
  dynamicInputs: Record<string, any>
}

const EditTemplate = observer(
  ({
    changeActionConfigPanelFieldValue,
    isInActionFlow = false,
    onCloseEditView,
    closeTemplateListModal,
    isNewTemplate,
    initializeEdit = { isEdit: false, templateId: null, templateVersion: null },
  }: EditTemplateProps) => {
    const { dynamicInputStore, emailTemplatesStore } = useStore()
    const {
      data: { template },
      loading: { isTemplateLoading },
      getTemplateList,
      getTemplate,
      setTemplate,
      updateTemplate,
      createTemplate,
      resetSelectedTemplate,
    } = emailTemplatesStore
    const { fetchTokenList, tokenList } = dynamicInputStore

    useDisplayErrorNotification(dynamicInputStore)
    useDisplayErrorNotification(emailTemplatesStore)

    // Editor View
    const [isEditingName, setIsEditingName] = useState(false)
    const [dynamicInputs, setDynamicInputs] = useState<Record<string, CreateActionFields>>({})
    const [form] = Form.useForm()

    // Explicitly used for rendering what is in the MobX store and for local user edits
    const [name, setName] = useState('')
    const [templateHtml, setTemplateHtml] = useState('')

    // Modal state
    const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] = useState(false)
    const [isDiscardChangesModalOpen, setIsDiscardChangesModalOpen] = useState(false)
    const [isSaveAsNewTempateModalOpen, setIsSaveAsNewTempateModalOpen] = useState(false)
    const [isDynamicInputModalOpen, setIsDynamicInputModalOpen] = useState(false)

    const [isTemplateEdited, setIsTemplateEdited] = useState(false)

    /** Thumbnail Generation Container */
    const ref = useRef<HTMLDivElement>(null)

    /** Generate a thumbnail image as a data-uri. */
    const generateThumbnail = useCallback(async () => {
      let thumbnailDataUrl = ''
      if (ref.current !== null) {
        try {
          // We need to wait for Safari to render the iframe before we can take a screenshot of it.
          await new Promise((r) => setTimeout(r, 250))
          thumbnailDataUrl = await domToJpeg(ref.current, {
            backgroundColor: '#ffffff',
            quality: 0.75,
          })
        } catch (error: unknown) {
          console.error(error)
        }
        // NOTE: This is left here as the easiest way to verify the image while debugging it.
        // const a = document.createElement('a')
        // a.download = 'test.jpg'
        // a.href = thumbnailDataUrl
        // a.click()
      }
      return thumbnailDataUrl
    }, [ref])

    useEffect(() => {
      const fetchTemplate = async () => {
        const magnifyTemplateId =
          initializeEdit.isEdit && initializeEdit.templateId ? initializeEdit.templateId : template.magnifyTemplateId
        const magnifyVersion =
          initializeEdit.isEdit && initializeEdit.templateVersion
            ? initializeEdit.templateVersion
            : template.magnifyVersion

        const existingTemplate = await getTemplate({ magnifyTemplateId, magnifyVersion })
        setName(existingTemplate!.name)
        setTemplateHtml(existingTemplate!.htmlContent!)
        setDynamicInputs(existingTemplate!.dynamicInputs || {})
      }

      if (!isNewTemplate) {
        fetchTemplate().catch(console.error)
      } else {
        setName('New Template')
        setTemplateHtml('New Template')
        setDynamicInputs({})
      }
    }, [])

    /**
     * We need to wait for the tokenList to load before rendering the field, we cannot update the TinyMCE editor after it has been rendered.
     */
    useEffect(() => {
      fetchTokenList(MAGNIFY_SEND_EMAIL_CONSTANTS as Item).catch(console.error)
    }, [])

    useEffect(() => {
      setIsTemplateEdited(templateHtml !== template.htmlContent)
    }, [templateHtml, template])

    /** Save the current template. */
    const saveTemplate = async (magnifyTemplateId: string | undefined, payload: SaveTemplatePayload) => {
      // Generate the thumbnail image to save with the template.
      const thumbnailDataUrl = await generateThumbnail()

      // Check for and remove any dynamicInputs that are no longer in the template.
      const dynamicInputsToRemove = Object.keys(payload.dynamicInputs).filter(
        (key) => !payload.htmlContent.includes(key),
      )
      dynamicInputsToRemove.forEach((key) => delete payload.dynamicInputs[key])

      let refreshTemplateData
      if (magnifyTemplateId) {
        const updatedTemplate = await updateTemplate({
          magnifyTemplateId,
          payload: { ...payload, thumbnailDataUrl },
        })
        if (updatedTemplate) {
          setName(updatedTemplate.name)
          setTemplate({ ...updatedTemplate, name: updatedTemplate.name, htmlContent: templateHtml })
        }

        refreshTemplateData = updatedTemplate
      } else {
        const newTemplate = await createTemplate({ ...payload, thumbnailDataUrl })
        if (newTemplate) {
          setName(newTemplate.name)
          setTemplate({ ...newTemplate, name: newTemplate.name, htmlContent: templateHtml })
        }

        refreshTemplateData = newTemplate
      }

      // Re-fetch all the templates in order to rehydrate the list with the latest changes
      await getTemplateList()
      return refreshTemplateData
    }

    /** Handle updating the name. */
    const handleOnBlurName = (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target.value) {
        setName(event.target.value)
      } else {
        event.stopPropagation()
      }
      setIsEditingName(false)
    }

    /**
     * When part of a Motion action flow, save the fields back to the action - into the MobX store!
     * This method is used on the button that is only available if the user has navigated through the action config flow
     * */
    const selectTemplate = async () => {
      if (isInActionFlow && changeActionConfigPanelFieldValue && closeTemplateListModal) {
        const updatedTemplate = await saveTemplate(template ? template.magnifyTemplateId : '', {
          htmlContent: templateHtml || template?.htmlContent || '',
          name: name || template?.name,
          dynamicInputs,
        })

        if (updatedTemplate) {
          changeActionConfigPanelFieldValue(updatedTemplate.magnifyTemplateId, 'templateId')
          changeActionConfigPanelFieldValue(updatedTemplate.magnifyVersion, 'templateVersion')
          changeActionConfigPanelFieldValue(updatedTemplate.name, 'template')
        }

        onCloseEditView()
        closeTemplateListModal()
      }
    }

    const handleSendTestEmail = async () => {
      if (isTemplateEdited) {
        await saveTemplate(template?.magnifyTemplateId, {
          htmlContent: templateHtml || '',
          name: name || template?.name,
          dynamicInputs,
        })
      }
      setIsSendTestEmailModalOpen(true)
    }

    const saveAsNewTemplate = async (templateName: string) => {
      await saveTemplate('', {
        htmlContent: templateHtml || '',
        name: templateName || 'New Template',
        dynamicInputs,
      })
    }

    const handleSaveTemplate = async () => {
      await saveTemplate(template ? template.magnifyTemplateId : '', {
        htmlContent: templateHtml || '',
        name: name || template.name,
        dynamicInputs,
      })
    }

    const handleDiscard = () => {
      if (isTemplateEdited) {
        setIsDiscardChangesModalOpen(true)
      } else {
        onCloseEditView()
        resetSelectedTemplate()
      }
    }

    const handlePreviewInBrowser = async () => {
      let version = template.magnifyVersion

      if (isTemplateEdited) {
        await saveTemplate(template.magnifyTemplateId, {
          htmlContent: templateHtml || '',
          name: name || template.name,
          dynamicInputs,
        })

        version++
      }

      const newWindow = window.open(
        `${window.location.origin}/motions/motion/emailpreview/${template.magnifyTemplateId}/${version}`,
        '_blank',
        'noopener,noreferrer',
      )
      if (newWindow) newWindow.opener = null
    }

    const items: MenuProps['items'] = [
      {
        label: 'Preview in Browser',
        key: 'preview-in-browser',
        className: `dropdown__item ${isNewTemplate && !template.magnifyTemplateId ? 'dropdown__item--hidden' : ''}`,
        onClick: handlePreviewInBrowser,
      },
      {
        label: 'Send Test Email',
        key: 'send-test-email',
        className: 'dropdown__item',
        onClick: handleSendTestEmail,
      },
      {
        label: 'Save as New Email',
        key: 'save-as-new-template',
        className: 'dropdown__item',
        onClick: () => setIsSaveAsNewTempateModalOpen(true),
      },
      {
        label: 'Discard',
        key: 'discard-email-template-changes',
        className: 'dropdown__item',
        onClick: handleDiscard,
      },
    ]

    /** Used for pluralizing the dynamic inputs text. */
    const dynamicInputCount = Object.keys(dynamicInputs).length

    return (
      <>
        <SendTestEmailModal
          isModalOpen={isSendTestEmailModalOpen}
          setIsModalOpen={setIsSendTestEmailModalOpen}
          htmlContent={template.htmlContent || ''}
          templateName={template.name || ''}
        />
        <DiscardChangesModal
          isModalOpen={isDiscardChangesModalOpen}
          setIsModalOpen={setIsDiscardChangesModalOpen}
          handleCloseEditView={() => {
            resetSelectedTemplate()
            onCloseEditView()
          }}
        />
        <SaveAsNewTemplateModal
          isModalOpen={isSaveAsNewTempateModalOpen}
          setIsModalOpen={setIsSaveAsNewTempateModalOpen}
          templateName={template.name || ''}
          saveAsNewTemplate={saveAsNewTemplate}
        />
        <DynamicInputModal
          isModalOpen={isDynamicInputModalOpen}
          setIsModalOpen={setIsDynamicInputModalOpen}
          setDynamicInputs={setDynamicInputs}
          dynamicInputs={dynamicInputs}
          handleSaveTemplate={handleSaveTemplate}
        />
        <section
          className={`email-template-editor-container ${isInActionFlow ? 'modal' : ''}`}
          id='email-template-editor'
          data-testid='email-template-editor'>
          <header>
            <div className='title-editor' title={name}>
              {isEditingName ? (
                <Form
                  form={form}
                  name='email-template-title-editor-form'
                  labelCol={{ span: 10 }}
                  autoComplete='off'
                  style={{ display: 'flex' }}
                  initialValues={{ title: name }}>
                  <Form.Item
                    name='title'
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Name can't be empty",
                      },
                    ]}
                    validateStatus={name ? 'success' : 'error'}
                    data-testid='title-editor-form-item'>
                    <Input
                      autoFocus={true}
                      autoComplete='off'
                      onBlur={handleOnBlurName}
                      className='title-editor__input'
                    />
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <IconMailGray className='m-r-10' />
                  <span className='title-editor__name' onClick={() => setIsEditingName(true)}>
                    {name}
                  </span>
                  <IconPenUnderlined
                    className='m-l-15 m-r-10 title-editor__edit-btn'
                    data-testid='edit-title-btn'
                    onClick={() => setIsEditingName(true)}
                  />
                </>
              )}
            </div>
            <div className='buttons__wrapper'>
              {dynamicInputCount > 0 && (
                <div
                  className='dynamic-input-container'
                  onClick={() => setIsDynamicInputModalOpen(true)}
                  title={`${dynamicInputCount} Dynamic Input${dynamicInputCount > 1 ? 's' : ''}`}>
                  <IconDynamicInput />
                  <IconGear />
                </div>
              )}
              <Dropdown
                overlayClassName='motions-table-row-actions'
                menu={{ items }}
                trigger={['click']}
                placement='bottomRight'>
                <CloseButton className='motions-table-row-actions-button'>
                  <EllipsisOutlined className='dropdown__icon' />
                </CloseButton>
              </Dropdown>

              {isInActionFlow && (
                <Button text='Use This Email' className='smaller-button' size='L' onClickHandler={selectTemplate} />
              )}
              <Button
                type='secondary'
                text='Save'
                className='smaller-button'
                size='L'
                onClickHandler={handleSaveTemplate}
              />
              <CloseButton className='close-btn' onClick={handleDiscard}>
                <CloseOutlined />
              </CloseButton>
            </div>
          </header>
          <Spin spinning={isTemplateLoading || tokenList.isLoading}>
            <div style={{ height: '100%' }}>
              <Editor
                apiKey={import.meta.env.VITE_TINYMCE_API_KEY ?? ''}
                onEditorChange={(content, editor) => {
                  const newEditor = editor as unknown as Editor & { magnifyDynamicVariables: Set<string> }
                  setTemplateHtml(content)
                  editor.on('ExecCommand', (event) => {
                    // We need to extract the tokens from the TinyMCE editor so we can set them up to be replaced later on in the Motion.
                    // This is the safest way to extract token at the moment as it is the least amount of text around them.
                    // If a token is removed, or entered by hand in the source editor it will not be represented here.
                    // We remove unused tags before saving by scanning the text for each key in the dynamicInputs object.
                    if (
                      event?.command === 'mceInsertContent' &&
                      typeof event?.value === 'string' &&
                      event?.value.startsWith('<span class="mce-mergetag"')
                    ) {
                      // The TinyMCE editor will insert the token with a span around it, extract the token from that.
                      const [_, chunk] = event.value.split('{{</span>')
                      const [tag] = chunk.split('<span class="mce-mergetag-affix"')
                      // We need to pass these back to the payload.
                      newEditor.magnifyDynamicVariables.add(JSON.stringify(toJS(tokenList?.lookup[tag])))
                      setDynamicInputs((prev) => ({
                        ...prev,
                        [tag]: tokenList?.lookup[tag],
                      }))
                      setIsDynamicInputModalOpen(true)
                    }
                  })
                }}
                value={templateHtml}
                init={{
                  height: 604,
                  menubar: 'file edit view insert format tools table tc help',
                  // All Plugins, even premium that throw warnings: 'preview powerpaste casechange searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed advtemplate codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker editimage help formatpainter permanentpen pageembed charmap quickbars linkchecker emoticons advtable export mentions tinycomments typography footnotes mergetags autocorrect',
                  plugins:
                    'preview casechange searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed advtemplate codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount editimage help formatpainter permanentpen pageembed charmap quickbars linkchecker emoticons advtable export mentions footnotes mergetags',
                  toolbar:
                    'undo redo | bold italic underline | blocks fontfamily fontsizeinput | link image media pageembed table mergetags | align lineheight numlist bullist checklist | outdent indent | strikethrough forecolor backcolor formatpainter removeformat | code fullscreen preview | spellcheckdialog a11ycheck typography',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  statusbar: false,
                  mergetags_list: tokenList?.data,
                  mergetags_prefix: '{{',
                  mergetags_suffix: '}}',
                  icons_url: '/tinymce-icons-magnify.js',
                  icons: 'magnify',
                  content_css: '/tinymce-content-magnify.css',
                  images_upload_handler: async (blobInfo) => {
                    const formData = new FormData()
                    formData.append('file', blobInfo.blob(), blobInfo.filename())

                    return API.fileAttachments.uploadAsset(formData)
                  },
                  setup: (editor) => {
                    // TypeScript doesn't want to add a key to the object, so we need to cast it to any.
                    const newEditor = editor as unknown as Editor & { magnifyDynamicVariables: Set<string> }
                    newEditor.magnifyDynamicVariables = new Set()
                  },
                }}
              />
            </div>
          </Spin>
          <div ref={ref} className='thumbnail-iframe-container'>
            <iframe
              aria-hidden='true'
              title='Thumbnail Generator'
              srcDoc={`<!DOCTYPE html><html><head><style>html, body { background-color: white; margin: 0; padding: 0 }</style></head><body>${
                templateHtml || ''
              }</body></html>`}
            />
          </div>
        </section>
      </>
    )
  },
)

export default EditTemplate
