import type { ParticipatedMotion } from 'models/account.model'

export const participated_motions_payload_demo: ParticipatedMotion[] | null = [
  {
    'accountId#tenantId': '1#1',
    journeyId: '3161a7db-92e3-43af-9f0f-97d8f6f4e1b6',
    journeyVersion: '5',
    journeyName: 'Tech Touch Motion',
    participatedSince: '2024-02-15',
    participationStatus: 'IN_PROGRESS',
    firstExecutionId: '1',
    latestExecutionId: '1',
    users: 324,
    actions: 832,
  },
  {
    'accountId#tenantId': '2#1',
    journeyId: 'dbd9b833-8bb7-4651-8ff3-12b5f7c4f4cf',
    journeyVersion: '2',
    journeyName: 'Re-engage Customer',
    participatedSince: '2024-02-17',
    participationStatus: 'DRAFT',
    firstExecutionId: '1',
    latestExecutionId: '1',
    users: 72,
    actions: 192,
  },
  {
    'accountId#tenantId': '3#1',
    journeyId: 'a71299a6-5862-47eb-8201-7ec1060c7309',
    journeyVersion: '11',
    journeyName: 'Onboarding Motion',
    participatedSince: '2024-02-21',
    participationStatus: 'SUCCEEDED',
    firstExecutionId: '1',
    latestExecutionId: '1',
    users: 8,
    actions: 121,
  },
]

export const participated_motions_payload_sandbox: ParticipatedMotion[] | null = [
  {
    'accountId#tenantId': '1#1',
    journeyId: '784c1f58-0ef5-4711-a3f5-b65c41b0aa63',
    journeyVersion: '2',
    journeyName: 'Feature adoption: general integration feature',
    participatedSince: '2023-10-15',
    participationStatus: 'DRAFT',
    firstExecutionId: '1',
    latestExecutionId: '1',
    users: 324,
    actions: 832,
  },
  {
    'accountId#tenantId': '2#1',
    journeyId: '7d32b75d-58cb-44ad-a3fc-1b25d5331c16',
    journeyVersion: '1',
    journeyName: 'Join service group email campaign',
    participatedSince: '2023-10-17',
    participationStatus: 'DRAFT',
    firstExecutionId: '1',
    latestExecutionId: '1',
    users: 72,
    actions: 192,
  },
  {
    'accountId#tenantId': '3#1',
    journeyId: '45091118-1292-48f8-b2e1-8912b6825855',
    journeyVersion: '1',
    journeyName: 'Complete onboarding modules',
    participatedSince: '2023-10-02',
    participationStatus: 'SUCCEEDED',
    firstExecutionId: '1',
    latestExecutionId: '1',
    users: 8,
    actions: 121,
  },
]
